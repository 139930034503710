/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivityHistoryModel,
    ActivityHistoryModelFromJSON,
    ActivityHistoryModelToJSON,
    ActivityHistoryResponseDto,
    ActivityHistoryResponseDtoFromJSON,
    ActivityHistoryResponseDtoToJSON,
    Bill,
    BillFromJSON,
    BillToJSON,
    BillsHistoryResponseDto,
    BillsHistoryResponseDtoFromJSON,
    BillsHistoryResponseDtoToJSON,
    CommentDto,
    CommentDtoFromJSON,
    CommentDtoToJSON,
    CustomerBillsDashboardResponseDto,
    CustomerBillsDashboardResponseDtoFromJSON,
    CustomerBillsDashboardResponseDtoToJSON,
    CustomerBillsPreviewListDto,
    CustomerBillsPreviewListDtoFromJSON,
    CustomerBillsPreviewListDtoToJSON,
    EditBillDto,
    EditBillDtoFromJSON,
    EditBillDtoToJSON,
    ExchangeRate,
    ExchangeRateFromJSON,
    ExchangeRateToJSON,
    FileUrlDto,
    FileUrlDtoFromJSON,
    FileUrlDtoToJSON,
    ObservationsResponseDto,
    ObservationsResponseDtoFromJSON,
    ObservationsResponseDtoToJSON,
    UpdateBillDto,
    UpdateBillDtoFromJSON,
    UpdateBillDtoToJSON,
} from '../models';

export interface CustomerBillsControllerDebugClusterRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerDeleteRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerEditBillRequest {
    customerId: number;
    billId: number;
    editBillDto: EditBillDto;
}

export interface CustomerBillsControllerGetBillActivityHistoryRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerGetBillExchangeRatesRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerGetBillForCustomerByIdRequest {
    customerId: number;
    billId: number;
}

export interface CustomerBillsControllerGetBillHistoryRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerGetBillJSONRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerGetBillObservationsRequest {
    customerId: number;
    billId: number;
}

export interface CustomerBillsControllerGetBillPdfFileRequest {
    customerId: number;
    billId: number;
}

export interface CustomerBillsControllerGetBillsDashboardByCustomerIdRequest {
    customerId: number;
}

export interface CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerBillsControllerGetBillsPreviewsByCustomerIdExcludeEnum>;
    orderBy?: Array<string>;
    searchQuery?: string;
    amountDueMin?: number;
    amountDueMax?: number;
    loadedDateMin?: Date;
    loadedDateMax?: Date;
    statementDateMin?: Date;
    statementDateMax?: Date;
    errorTypes?: Array<string>;
    locationIds?: Array<number>;
    billTypes?: Array<CustomerBillsControllerGetBillsPreviewsByCustomerIdBillTypesEnum>;
    commodities?: Array<string>;
    vendorCodes?: Array<string>;
    workflowStates?: Array<CustomerBillsControllerGetBillsPreviewsByCustomerIdWorkflowStatesEnum>;
    controlCode?: string;
    accountCode?: string;
    meterId?: number;
    billErrorId?: number;
    virtualAccountId?: number;
    virtualAccountGroupId?: number;
    criteria?: boolean;
    serviceLocation?: string;
    billingLineItemDescription?: string;
}

export interface CustomerBillsControllerInsertCommentRequest {
    customerId: number;
    billId: number;
    commentDto: CommentDto;
}

export interface CustomerBillsControllerReparseRequest {
    billId: number;
    customerId: number;
}

export interface CustomerBillsControllerUpdateBillRequest {
    customerId: number;
    billId: number;
    updateBillDto: UpdateBillDto;
}

/**
 * 
 */
export class CustomerBillsApi extends runtime.BaseAPI {

    /**
     * Debug bill cluster validation
     */
    async customerBillsControllerDebugClusterRaw(requestParameters: CustomerBillsControllerDebugClusterRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerDebugCluster.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerDebugCluster.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/debug-cluster`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Debug bill cluster validation
     */
    async customerBillsControllerDebugCluster(requestParameters: CustomerBillsControllerDebugClusterRequest): Promise<string> {
        const response = await this.customerBillsControllerDebugClusterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a bill
     */
    async customerBillsControllerDeleteRaw(requestParameters: CustomerBillsControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerDelete.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a bill
     */
    async customerBillsControllerDelete(requestParameters: CustomerBillsControllerDeleteRequest): Promise<void> {
        await this.customerBillsControllerDeleteRaw(requestParameters);
    }

    /**
     * Edit bill
     */
    async customerBillsControllerEditBillRaw(requestParameters: CustomerBillsControllerEditBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerEditBill.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerEditBill.');
        }

        if (requestParameters.editBillDto === null || requestParameters.editBillDto === undefined) {
            throw new runtime.RequiredError('editBillDto','Required parameter requestParameters.editBillDto was null or undefined when calling customerBillsControllerEditBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditBillDtoToJSON(requestParameters.editBillDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Edit bill
     */
    async customerBillsControllerEditBill(requestParameters: CustomerBillsControllerEditBillRequest): Promise<void> {
        await this.customerBillsControllerEditBillRaw(requestParameters);
    }

    /**
     * Get activity history for bill
     */
    async customerBillsControllerGetBillActivityHistoryRaw(requestParameters: CustomerBillsControllerGetBillActivityHistoryRequest): Promise<runtime.ApiResponse<ActivityHistoryResponseDto>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillActivityHistory.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillActivityHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/activity-history`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityHistoryResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get activity history for bill
     */
    async customerBillsControllerGetBillActivityHistory(requestParameters: CustomerBillsControllerGetBillActivityHistoryRequest): Promise<ActivityHistoryResponseDto> {
        const response = await this.customerBillsControllerGetBillActivityHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get exchange rate for bill
     */
    async customerBillsControllerGetBillExchangeRatesRaw(requestParameters: CustomerBillsControllerGetBillExchangeRatesRequest): Promise<runtime.ApiResponse<ExchangeRate>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillExchangeRates.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillExchangeRates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/exchange-rate`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeRateFromJSON(jsonValue));
    }

    /**
     * Get exchange rate for bill
     */
    async customerBillsControllerGetBillExchangeRates(requestParameters: CustomerBillsControllerGetBillExchangeRatesRequest): Promise<ExchangeRate> {
        const response = await this.customerBillsControllerGetBillExchangeRatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a bill for a given customer.
     */
    async customerBillsControllerGetBillForCustomerByIdRaw(requestParameters: CustomerBillsControllerGetBillForCustomerByIdRequest): Promise<runtime.ApiResponse<Bill>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillForCustomerById.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillForCustomerById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillFromJSON(jsonValue));
    }

    /**
     * Returns a bill for a given customer.
     */
    async customerBillsControllerGetBillForCustomerById(requestParameters: CustomerBillsControllerGetBillForCustomerByIdRequest): Promise<Bill> {
        const response = await this.customerBillsControllerGetBillForCustomerByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get bill history
     */
    async customerBillsControllerGetBillHistoryRaw(requestParameters: CustomerBillsControllerGetBillHistoryRequest): Promise<runtime.ApiResponse<BillsHistoryResponseDto>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillHistory.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/bill-history`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillsHistoryResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get bill history
     */
    async customerBillsControllerGetBillHistory(requestParameters: CustomerBillsControllerGetBillHistoryRequest): Promise<BillsHistoryResponseDto> {
        const response = await this.customerBillsControllerGetBillHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get JSON for a bill
     */
    async customerBillsControllerGetBillJSONRaw(requestParameters: CustomerBillsControllerGetBillJSONRequest): Promise<runtime.ApiResponse<Bill>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillJSON.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillJSON.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/json`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillFromJSON(jsonValue));
    }

    /**
     * Get JSON for a bill
     */
    async customerBillsControllerGetBillJSON(requestParameters: CustomerBillsControllerGetBillJSONRequest): Promise<Bill> {
        const response = await this.customerBillsControllerGetBillJSONRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get observations for a bill for a given customer
     */
    async customerBillsControllerGetBillObservationsRaw(requestParameters: CustomerBillsControllerGetBillObservationsRequest): Promise<runtime.ApiResponse<ObservationsResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillObservations.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillObservations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/observations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ObservationsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get observations for a bill for a given customer
     */
    async customerBillsControllerGetBillObservations(requestParameters: CustomerBillsControllerGetBillObservationsRequest): Promise<ObservationsResponseDto> {
        const response = await this.customerBillsControllerGetBillObservationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get PDF file for a bill
     */
    async customerBillsControllerGetBillPdfFileRaw(requestParameters: CustomerBillsControllerGetBillPdfFileRequest): Promise<runtime.ApiResponse<FileUrlDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillPdfFile.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerGetBillPdfFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/pdf`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileUrlDtoFromJSON(jsonValue));
    }

    /**
     * Get PDF file for a bill
     */
    async customerBillsControllerGetBillPdfFile(requestParameters: CustomerBillsControllerGetBillPdfFileRequest): Promise<FileUrlDto> {
        const response = await this.customerBillsControllerGetBillPdfFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the information for the dashboard view for the bills of a given customer.
     */
    async customerBillsControllerGetBillsDashboardByCustomerIdRaw(requestParameters: CustomerBillsControllerGetBillsDashboardByCustomerIdRequest): Promise<runtime.ApiResponse<CustomerBillsDashboardResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillsDashboardByCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/dashboard`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerBillsDashboardResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get the information for the dashboard view for the bills of a given customer.
     */
    async customerBillsControllerGetBillsDashboardByCustomerId(requestParameters: CustomerBillsControllerGetBillsDashboardByCustomerIdRequest): Promise<CustomerBillsDashboardResponseDto> {
        const response = await this.customerBillsControllerGetBillsDashboardByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of bills for a given customer with meta filters included.
     */
    async customerBillsControllerGetBillsPreviewsByCustomerIdRaw(requestParameters: CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest): Promise<runtime.ApiResponse<CustomerBillsPreviewListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerGetBillsPreviewsByCustomerId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.amountDueMin !== undefined) {
            queryParameters['amountDueMin'] = requestParameters.amountDueMin;
        }

        if (requestParameters.amountDueMax !== undefined) {
            queryParameters['amountDueMax'] = requestParameters.amountDueMax;
        }

        if (requestParameters.loadedDateMin !== undefined) {
            queryParameters['loadedDateMin'] = (requestParameters.loadedDateMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.loadedDateMax !== undefined) {
            queryParameters['loadedDateMax'] = (requestParameters.loadedDateMax as any).toISOString().substr(0,10);
        }

        if (requestParameters.statementDateMin !== undefined) {
            queryParameters['statementDateMin'] = (requestParameters.statementDateMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.statementDateMax !== undefined) {
            queryParameters['statementDateMax'] = (requestParameters.statementDateMax as any).toISOString().substr(0,10);
        }

        if (requestParameters.errorTypes) {
            queryParameters['errorTypes'] = requestParameters.errorTypes;
        }

        if (requestParameters.locationIds) {
            queryParameters['locationIds'] = requestParameters.locationIds;
        }

        if (requestParameters.billTypes) {
            queryParameters['billTypes'] = requestParameters.billTypes;
        }

        if (requestParameters.commodities) {
            queryParameters['commodities'] = requestParameters.commodities;
        }

        if (requestParameters.vendorCodes) {
            queryParameters['vendorCodes'] = requestParameters.vendorCodes;
        }

        if (requestParameters.workflowStates) {
            queryParameters['workflowStates'] = requestParameters.workflowStates;
        }

        if (requestParameters.controlCode !== undefined) {
            queryParameters['controlCode'] = requestParameters.controlCode;
        }

        if (requestParameters.accountCode !== undefined) {
            queryParameters['accountCode'] = requestParameters.accountCode;
        }

        if (requestParameters.meterId !== undefined) {
            queryParameters['meterId'] = requestParameters.meterId;
        }

        if (requestParameters.billErrorId !== undefined) {
            queryParameters['billErrorId'] = requestParameters.billErrorId;
        }

        if (requestParameters.virtualAccountId !== undefined) {
            queryParameters['virtualAccountId'] = requestParameters.virtualAccountId;
        }

        if (requestParameters.virtualAccountGroupId !== undefined) {
            queryParameters['virtualAccountGroupId'] = requestParameters.virtualAccountGroupId;
        }

        if (requestParameters.criteria !== undefined) {
            queryParameters['criteria'] = requestParameters.criteria;
        }

        if (requestParameters.serviceLocation !== undefined) {
            queryParameters['serviceLocation'] = requestParameters.serviceLocation;
        }

        if (requestParameters.billingLineItemDescription !== undefined) {
            queryParameters['billingLineItemDescription'] = requestParameters.billingLineItemDescription;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerBillsPreviewListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of bills for a given customer with meta filters included.
     */
    async customerBillsControllerGetBillsPreviewsByCustomerId(requestParameters: CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest): Promise<CustomerBillsPreviewListDto> {
        const response = await this.customerBillsControllerGetBillsPreviewsByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Add comment in activity history for bill
     */
    async customerBillsControllerInsertCommentRaw(requestParameters: CustomerBillsControllerInsertCommentRequest): Promise<runtime.ApiResponse<ActivityHistoryModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerInsertComment.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerInsertComment.');
        }

        if (requestParameters.commentDto === null || requestParameters.commentDto === undefined) {
            throw new runtime.RequiredError('commentDto','Required parameter requestParameters.commentDto was null or undefined when calling customerBillsControllerInsertComment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/comments`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommentDtoToJSON(requestParameters.commentDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivityHistoryModelFromJSON(jsonValue));
    }

    /**
     * Add comment in activity history for bill
     */
    async customerBillsControllerInsertComment(requestParameters: CustomerBillsControllerInsertCommentRequest): Promise<ActivityHistoryModel> {
        const response = await this.customerBillsControllerInsertCommentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reparse a bill
     */
    async customerBillsControllerReparseRaw(requestParameters: CustomerBillsControllerReparseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerReparse.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerReparse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}/reparse`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reparse a bill
     */
    async customerBillsControllerReparse(requestParameters: CustomerBillsControllerReparseRequest): Promise<void> {
        await this.customerBillsControllerReparseRaw(requestParameters);
    }

    /**
     * Update a bill for a given customer.
     */
    async customerBillsControllerUpdateBillRaw(requestParameters: CustomerBillsControllerUpdateBillRequest): Promise<runtime.ApiResponse<Bill>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillsControllerUpdateBill.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillsControllerUpdateBill.');
        }

        if (requestParameters.updateBillDto === null || requestParameters.updateBillDto === undefined) {
            throw new runtime.RequiredError('updateBillDto','Required parameter requestParameters.updateBillDto was null or undefined when calling customerBillsControllerUpdateBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bills/{billId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBillDtoToJSON(requestParameters.updateBillDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillFromJSON(jsonValue));
    }

    /**
     * Update a bill for a given customer.
     */
    async customerBillsControllerUpdateBill(requestParameters: CustomerBillsControllerUpdateBillRequest): Promise<Bill> {
        const response = await this.customerBillsControllerUpdateBillRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerBillsControllerGetBillsPreviewsByCustomerIdExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerBillsControllerGetBillsPreviewsByCustomerIdBillTypesEnum {
    Historical = 'historical',
    Setup = 'setup',
    Live = 'live',
    Free = 'free',
    Maintenance = 'maintenance',
    Special = 'special'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerBillsControllerGetBillsPreviewsByCustomerIdWorkflowStatesEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}
