/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationMonthlyAttributesValues
 */
export interface LocationMonthlyAttributesValues {
    /**
     * 
     * @type {Date}
     * @memberof LocationMonthlyAttributesValues
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof LocationMonthlyAttributesValues
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof LocationMonthlyAttributesValues
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMonthlyAttributesValues
     */
    locationMonthlyAttributesMetadataId: number;
    /**
     * 
     * @type {string}
     * @memberof LocationMonthlyAttributesValues
     */
    attributeValue: string;
    /**
     * 
     * @type {Date}
     * @memberof LocationMonthlyAttributesValues
     */
    dateMonth: Date;
}

export function LocationMonthlyAttributesValuesFromJSON(json: any): LocationMonthlyAttributesValues {
    return LocationMonthlyAttributesValuesFromJSONTyped(json, false);
}

export function LocationMonthlyAttributesValuesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationMonthlyAttributesValues {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'locationId': json['locationId'],
        'locationMonthlyAttributesMetadataId': json['locationMonthlyAttributesMetadataId'],
        'attributeValue': json['attributeValue'],
        'dateMonth': (new Date(json['dateMonth'])),
    };
}

export function LocationMonthlyAttributesValuesToJSON(value?: LocationMonthlyAttributesValues | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'locationId': value.locationId,
        'locationMonthlyAttributesMetadataId': value.locationMonthlyAttributesMetadataId,
        'attributeValue': value.attributeValue,
        'dateMonth': (value.dateMonth.toISOString()),
    };
}


