/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PowerBIReportModel,
    PowerBIReportModelFromJSON,
    PowerBIReportModelToJSON,
    PowerBIReportsListDto,
    PowerBIReportsListDtoFromJSON,
    PowerBIReportsListDtoToJSON,
    ReportDto,
    ReportDtoFromJSON,
    ReportDtoToJSON,
} from '../models';

export interface PowerBIControllerCreateReportRequest {
    customerId: number;
    reportDto: ReportDto;
}

export interface PowerBIControllerDeleteReportRequest {
    customerId: number;
    reportId: number;
}

export interface PowerBIControllerGetCustomerEmbedTokenRequest {
    customerId: number;
    datasetId?: string;
    groupId?: string;
    reportId?: string;
    rls?: boolean;
}

export interface PowerBIControllerGetReportRequest {
    customerId: number;
    reportId: number;
}

export interface PowerBIControllerGetReportsRequest {
    customerId: number;
}

export interface PowerBIControllerUpdateReportRequest {
    customerId: number;
    reportId: number;
    reportDto: ReportDto;
}

/**
 * 
 */
export class PowerBiApi extends runtime.BaseAPI {

    /**
     * Create PowerBI report
     */
    async powerBIControllerCreateReportRaw(requestParameters: PowerBIControllerCreateReportRequest): Promise<runtime.ApiResponse<PowerBIReportModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling powerBIControllerCreateReport.');
        }

        if (requestParameters.reportDto === null || requestParameters.reportDto === undefined) {
            throw new runtime.RequiredError('reportDto','Required parameter requestParameters.reportDto was null or undefined when calling powerBIControllerCreateReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/power-bi/{customerId}/reports`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDtoToJSON(requestParameters.reportDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportModelFromJSON(jsonValue));
    }

    /**
     * Create PowerBI report
     */
    async powerBIControllerCreateReport(requestParameters: PowerBIControllerCreateReportRequest): Promise<PowerBIReportModel> {
        const response = await this.powerBIControllerCreateReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete PowerBI report
     */
    async powerBIControllerDeleteReportRaw(requestParameters: PowerBIControllerDeleteReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling powerBIControllerDeleteReport.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling powerBIControllerDeleteReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/power-bi/{customerId}/reports/{reportId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete PowerBI report
     */
    async powerBIControllerDeleteReport(requestParameters: PowerBIControllerDeleteReportRequest): Promise<void> {
        await this.powerBIControllerDeleteReportRaw(requestParameters);
    }

    /**
     * Get the PowerBI report embed token for a specific customer
     */
    async powerBIControllerGetCustomerEmbedTokenRaw(requestParameters: PowerBIControllerGetCustomerEmbedTokenRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling powerBIControllerGetCustomerEmbedToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.datasetId !== undefined) {
            queryParameters['datasetId'] = requestParameters.datasetId;
        }

        if (requestParameters.groupId !== undefined) {
            queryParameters['groupId'] = requestParameters.groupId;
        }

        if (requestParameters.reportId !== undefined) {
            queryParameters['reportId'] = requestParameters.reportId;
        }

        if (requestParameters.rls !== undefined) {
            queryParameters['rls'] = requestParameters.rls;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/power-bi/{customerId}/get-embed-token`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get the PowerBI report embed token for a specific customer
     */
    async powerBIControllerGetCustomerEmbedToken(requestParameters: PowerBIControllerGetCustomerEmbedTokenRequest): Promise<void> {
        await this.powerBIControllerGetCustomerEmbedTokenRaw(requestParameters);
    }

    /**
     * Fetch PowerBI report
     */
    async powerBIControllerGetReportRaw(requestParameters: PowerBIControllerGetReportRequest): Promise<runtime.ApiResponse<PowerBIReportModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling powerBIControllerGetReport.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling powerBIControllerGetReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/power-bi/{customerId}/reports/{reportId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportModelFromJSON(jsonValue));
    }

    /**
     * Fetch PowerBI report
     */
    async powerBIControllerGetReport(requestParameters: PowerBIControllerGetReportRequest): Promise<PowerBIReportModel> {
        const response = await this.powerBIControllerGetReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch PowerBI reports list
     */
    async powerBIControllerGetReportsRaw(requestParameters: PowerBIControllerGetReportsRequest): Promise<runtime.ApiResponse<PowerBIReportsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling powerBIControllerGetReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/power-bi/{customerId}/reports`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportsListDtoFromJSON(jsonValue));
    }

    /**
     * Fetch PowerBI reports list
     */
    async powerBIControllerGetReports(requestParameters: PowerBIControllerGetReportsRequest): Promise<PowerBIReportsListDto> {
        const response = await this.powerBIControllerGetReportsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update PowerBI report
     */
    async powerBIControllerUpdateReportRaw(requestParameters: PowerBIControllerUpdateReportRequest): Promise<runtime.ApiResponse<PowerBIReportModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling powerBIControllerUpdateReport.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling powerBIControllerUpdateReport.');
        }

        if (requestParameters.reportDto === null || requestParameters.reportDto === undefined) {
            throw new runtime.RequiredError('reportDto','Required parameter requestParameters.reportDto was null or undefined when calling powerBIControllerUpdateReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/power-bi/{customerId}/reports/{reportId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReportDtoToJSON(requestParameters.reportDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PowerBIReportModelFromJSON(jsonValue));
    }

    /**
     * Update PowerBI report
     */
    async powerBIControllerUpdateReport(requestParameters: PowerBIControllerUpdateReportRequest): Promise<PowerBIReportModel> {
        const response = await this.powerBIControllerUpdateReportRaw(requestParameters);
        return await response.value();
    }

}
