/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerFilterDto,
    CustomerFilterDtoFromJSON,
    CustomerFilterDtoFromJSONTyped,
    CustomerFilterDtoToJSON,
    PaymentEventDefinitionModel,
    PaymentEventDefinitionModelFromJSON,
    PaymentEventDefinitionModelFromJSONTyped,
    PaymentEventDefinitionModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentEventsFiltersMeta
 */
export interface PaymentEventsFiltersMeta {
    /**
     * 
     * @type {Array<CustomerFilterDto>}
     * @memberof PaymentEventsFiltersMeta
     */
    customers: Array<CustomerFilterDto>;
    /**
     * 
     * @type {Array<PaymentEventDefinitionModel>}
     * @memberof PaymentEventsFiltersMeta
     */
    events: Array<PaymentEventDefinitionModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentEventsFiltersMeta
     */
    eventTypes: Array<PaymentEventsFiltersMetaEventTypesEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PaymentEventsFiltersMetaEventTypesEnum {
    Information = 'Information',
    Error = 'Error',
    Warning = 'Warning'
}

export function PaymentEventsFiltersMetaFromJSON(json: any): PaymentEventsFiltersMeta {
    return PaymentEventsFiltersMetaFromJSONTyped(json, false);
}

export function PaymentEventsFiltersMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEventsFiltersMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customers': ((json['customers'] as Array<any>).map(CustomerFilterDtoFromJSON)),
        'events': ((json['events'] as Array<any>).map(PaymentEventDefinitionModelFromJSON)),
        'eventTypes': json['eventTypes'],
    };
}

export function PaymentEventsFiltersMetaToJSON(value?: PaymentEventsFiltersMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customers': ((value.customers as Array<any>).map(CustomerFilterDtoToJSON)),
        'events': ((value.events as Array<any>).map(PaymentEventDefinitionModelToJSON)),
        'eventTypes': value.eventTypes,
    };
}


