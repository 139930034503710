import {
  CustomerLocationsApi,
  CustomerLocationsControllerGetCustomerLocationsRequest,
  CustomerPermissionsApi,
  CustomerPermissionsControllerSetLocationsRequest,
  HierarchiesApi,
  HierarchiesControllerGetDefaultHierarchyRequest,
  HierarchiesControllerGetHierarchyUnmatchedLocationsRequest,
  HierarchyListDto,
  Location,
  LocationListDto,
  ResetPasswordModel,
  User,
  UserActivationModel,
  UserCustomersApi,
  UserCustomersControllerAssignUserRequest,
  UserCustomersControllerCreateUserRequest,
  UserCustomersControllerUnassignUserRequest,
  UsersApi,
  UsersControllerActivateAccountByIdRequest,
  UsersControllerDeactivateAccountByIdRequest,
  UsersControllerGetUserActivationLinkRequest,
  UsersControllerGetUserPasswordResetLinkRequest,
  UsersControllerGetUserRequest,
  UsersControllerGetUsersRequest,
  UsersControllerSendActivationRequest,
  UsersControllerSoftDeleteUserRequest,
  UsersControllerUpdateUserProfileRequest,
} from '@/SharedModule/Api'
import { UsersListDto } from '@/SharedModule/Api/models/UsersListDto'
import { ApiService } from '@/SharedModule/Services/service.api'

export class UsersService extends ApiService {
  private readonly usersApi = new UsersApi(this.getBaseOptions())
  private readonly userCustomersApi = new UserCustomersApi(
    this.getBaseOptions(),
  )
  private readonly customerPermissionsApi = new CustomerPermissionsApi(
    this.getBaseOptions(),
  )
  private readonly hierarchiesApi = new HierarchiesApi(this.getBaseOptions())
  private readonly customerLocationsApi = new CustomerLocationsApi(
    this.getBaseOptions(),
  )
  public getUsers(
    requestParameters: UsersControllerGetUsersRequest,
  ): Promise<UsersListDto> {
    return this.usersApi.usersControllerGetUsers(requestParameters)
  }
  public createUserAndAssign(
    requestParameters: UserCustomersControllerCreateUserRequest,
  ): Promise<User> {
    return this.userCustomersApi.userCustomersControllerCreateUser(
      requestParameters,
    )
  }
  public getUser(
    requestParameters: UsersControllerGetUserRequest,
  ): Promise<User> {
    return this.usersApi.usersControllerGetUser(requestParameters)
  }
  public assignUserToCustomer(
    requestParameters: UserCustomersControllerAssignUserRequest,
  ): Promise<number[]> {
    return this.userCustomersApi.userCustomersControllerAssignUser(
      requestParameters,
    )
  }
  public sendActivation(
    requestParameters: UsersControllerSendActivationRequest,
  ): Promise<void> {
    return this.usersApi.usersControllerSendActivation(requestParameters)
  }
  public setLocations(
    requestParameters: CustomerPermissionsControllerSetLocationsRequest,
  ): Promise<Array<Location>> {
    return this.customerPermissionsApi.customerPermissionsControllerSetLocations(
      requestParameters,
    )
  }
  public updateUserProfile(
    requestParameters: UsersControllerUpdateUserProfileRequest,
  ): Promise<void> {
    return this.usersApi.usersControllerUpdateUserProfile(requestParameters)
  }
  public getDefaultHierarchy(
    requestParameters: HierarchiesControllerGetDefaultHierarchyRequest,
  ): Promise<HierarchyListDto> {
    return this.hierarchiesApi.hierarchiesControllerGetDefaultHierarchy(
      requestParameters,
    )
  }
  public getCustomerLocations(
    requestParameters: CustomerLocationsControllerGetCustomerLocationsRequest,
  ): Promise<LocationListDto> {
    return this.customerLocationsApi.customerLocationsControllerGetCustomerLocations(
      requestParameters,
    )
  }
  public getHierarchyUnmatchedLocations(
    requestParameters: HierarchiesControllerGetHierarchyUnmatchedLocationsRequest,
  ): Promise<LocationListDto> {
    return this.hierarchiesApi.hierarchiesControllerGetHierarchyUnmatchedLocations(
      requestParameters,
    )
  }
  public deactivateAccountById(
    requestParameters: UsersControllerDeactivateAccountByIdRequest,
  ): Promise<void> {
    return this.usersApi.usersControllerDeactivateAccountById(requestParameters)
  }
  public activateAccountById(
    requestParameters: UsersControllerActivateAccountByIdRequest,
  ): Promise<void> {
    return this.usersApi.usersControllerActivateAccountById(requestParameters)
  }
  public getUserPasswordResetLink(
    requestParameters: UsersControllerGetUserPasswordResetLinkRequest,
  ): Promise<ResetPasswordModel> {
    return this.usersApi.usersControllerGetUserPasswordResetLink(
      requestParameters,
    )
  }
  public softDeleteUser(
    requestParameters: UsersControllerSoftDeleteUserRequest,
  ): Promise<void> {
    return this.usersApi.usersControllerSoftDeleteUser(requestParameters)
  }
  public unassignUser(
    requestParameters: UserCustomersControllerUnassignUserRequest,
  ): Promise<Array<number>> {
    return this.userCustomersApi.userCustomersControllerUnassignUser(
      requestParameters,
    )
  }
  public getUserActivationLink(
    requestParameters: UsersControllerGetUserActivationLinkRequest,
  ): Promise<UserActivationModel> {
    return this.usersApi.usersControllerGetUserActivationLink(requestParameters)
  }
}
