/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ColumnStyle,
    ColumnStyleFromJSON,
    ColumnStyleFromJSONTyped,
    ColumnStyleToJSON,
} from './';

/**
 * 
 * @export
 * @interface ColumnFormatRule
 */
export interface ColumnFormatRule {
    /**
     * 
     * @type {string}
     * @memberof ColumnFormatRule
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ColumnFormatRule
     */
    header: string;
    /**
     * 
     * @type {ColumnStyle}
     * @memberof ColumnFormatRule
     */
    style?: ColumnStyle;
}

export function ColumnFormatRuleFromJSON(json: any): ColumnFormatRule {
    return ColumnFormatRuleFromJSONTyped(json, false);
}

export function ColumnFormatRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnFormatRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': json['key'],
        'header': json['header'],
        'style': !exists(json, 'style') ? undefined : ColumnStyleFromJSON(json['style']),
    };
}

export function ColumnFormatRuleToJSON(value?: ColumnFormatRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': value.key,
        'header': value.header,
        'style': ColumnStyleToJSON(value.style),
    };
}


