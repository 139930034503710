/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneratePaymentFileConfigDto
 */
export interface GeneratePaymentFileConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePaymentFileConfigDto
     */
    saveDataToDB: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePaymentFileConfigDto
     */
    sendErrorEmails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePaymentFileConfigDto
     */
    sendAttachmentEmails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePaymentFileConfigDto
     */
    uploadToStorage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GeneratePaymentFileConfigDto
     */
    uploadToSFTP: boolean;
}

export function GeneratePaymentFileConfigDtoFromJSON(json: any): GeneratePaymentFileConfigDto {
    return GeneratePaymentFileConfigDtoFromJSONTyped(json, false);
}

export function GeneratePaymentFileConfigDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneratePaymentFileConfigDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'saveDataToDB': json['saveDataToDB'],
        'sendErrorEmails': json['sendErrorEmails'],
        'sendAttachmentEmails': json['sendAttachmentEmails'],
        'uploadToStorage': json['uploadToStorage'],
        'uploadToSFTP': json['uploadToSFTP'],
    };
}

export function GeneratePaymentFileConfigDtoToJSON(value?: GeneratePaymentFileConfigDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'saveDataToDB': value.saveDataToDB,
        'sendErrorEmails': value.sendErrorEmails,
        'sendAttachmentEmails': value.sendAttachmentEmails,
        'uploadToStorage': value.uploadToStorage,
        'uploadToSFTP': value.uploadToSFTP,
    };
}


