import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, { error: _ctx.errorMessage }, {
    default: _withCtx(() => [
      _createVNode(_component_el_input, _mergeProps({
        placeholder: _ctx.label,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, _ctx.$attrs), {
        prefix: _withCtx(() => [
          _renderSlot(_ctx.$slots, "prefix")
        ]),
        _: 3
      }, 16, ["placeholder", "modelValue"])
    ]),
    _: 3
  }, 8, ["error"]))
}