/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
    ContractDetails,
    ContractDetailsFromJSON,
    ContractDetailsFromJSONTyped,
    ContractDetailsToJSON,
    Entitlements,
    EntitlementsFromJSON,
    EntitlementsFromJSONTyped,
    EntitlementsToJSON,
    PermissionMeta,
    PermissionMetaFromJSON,
    PermissionMetaFromJSONTyped,
    PermissionMetaToJSON,
    TargetHours,
    TargetHoursFromJSON,
    TargetHoursFromJSONTyped,
    TargetHoursToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAccessCustomer
 */
export interface UserAccessCustomer {
    /**
     * 
     * @type {number}
     * @memberof UserAccessCustomer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserAccessCustomer
     */
    name: string;
    /**
     * 
     * @type {Entitlements}
     * @memberof UserAccessCustomer
     */
    entitlements: Entitlements;
    /**
     * 
     * @type {boolean}
     * @memberof UserAccessCustomer
     */
    active: boolean;
    /**
     * 
     * @type {TargetHours}
     * @memberof UserAccessCustomer
     */
    targetHours: TargetHours;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAccessCustomer
     */
    paymentFileTypes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserAccessCustomer
     */
    fdgCode: string;
    /**
     * 
     * @type {object}
     * @memberof UserAccessCustomer
     */
    profile: object;
    /**
     * 
     * @type {ContractDetails}
     * @memberof UserAccessCustomer
     */
    contractDetails: ContractDetails;
    /**
     * 
     * @type {AddressModel}
     * @memberof UserAccessCustomer
     */
    address: AddressModel;
    /**
     * 
     * @type {string}
     * @memberof UserAccessCustomer
     */
    tin: string;
    /**
     * 
     * @type {Date}
     * @memberof UserAccessCustomer
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<PermissionMeta>}
     * @memberof UserAccessCustomer
     */
    permissions: Array<PermissionMeta>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserAccessCustomer
     */
    locations: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof UserAccessCustomer
     */
    totalLocations: number;
}

export function UserAccessCustomerFromJSON(json: any): UserAccessCustomer {
    return UserAccessCustomerFromJSONTyped(json, false);
}

export function UserAccessCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAccessCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'entitlements': EntitlementsFromJSON(json['entitlements']),
        'active': json['active'],
        'targetHours': TargetHoursFromJSON(json['targetHours']),
        'paymentFileTypes': json['paymentFileTypes'],
        'fdgCode': json['fdgCode'],
        'profile': json['profile'],
        'contractDetails': ContractDetailsFromJSON(json['contractDetails']),
        'address': AddressModelFromJSON(json['address']),
        'tin': json['tin'],
        'createdAt': (new Date(json['createdAt'])),
        'permissions': ((json['permissions'] as Array<any>).map(PermissionMetaFromJSON)),
        'locations': json['locations'],
        'totalLocations': json['totalLocations'],
    };
}

export function UserAccessCustomerToJSON(value?: UserAccessCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'entitlements': EntitlementsToJSON(value.entitlements),
        'active': value.active,
        'targetHours': TargetHoursToJSON(value.targetHours),
        'paymentFileTypes': value.paymentFileTypes,
        'fdgCode': value.fdgCode,
        'profile': value.profile,
        'contractDetails': ContractDetailsToJSON(value.contractDetails),
        'address': AddressModelToJSON(value.address),
        'tin': value.tin,
        'createdAt': (value.createdAt.toISOString()),
        'permissions': ((value.permissions as Array<any>).map(PermissionMetaToJSON)),
        'locations': value.locations,
        'totalLocations': value.totalLocations,
    };
}


