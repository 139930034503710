/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WeatherDaily,
    WeatherDailyFromJSON,
    WeatherDailyFromJSONTyped,
    WeatherDailyToJSON,
    WeatherDates,
    WeatherDatesFromJSON,
    WeatherDatesFromJSONTyped,
    WeatherDatesToJSON,
} from './';

/**
 * 
 * @export
 * @interface WeatherDailyListDto
 */
export interface WeatherDailyListDto {
    /**
     * 
     * @type {Array<WeatherDaily>}
     * @memberof WeatherDailyListDto
     */
    results: Array<WeatherDaily>;
    /**
     * 
     * @type {WeatherDates}
     * @memberof WeatherDailyListDto
     */
    dates: WeatherDates;
}

export function WeatherDailyListDtoFromJSON(json: any): WeatherDailyListDto {
    return WeatherDailyListDtoFromJSONTyped(json, false);
}

export function WeatherDailyListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherDailyListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(WeatherDailyFromJSON)),
        'dates': WeatherDatesFromJSON(json['dates']),
    };
}

export function WeatherDailyListDtoToJSON(value?: WeatherDailyListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(WeatherDailyToJSON)),
        'dates': WeatherDatesToJSON(value.dates),
    };
}


