
import { useField } from 'vee-validate'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FormFieldInput',
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { value, errorMessage } = useField(props.name)

    return {
      value,
      errorMessage,
    }
  },
})
