/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Batch,
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
    BatchesFiltersMeta,
    BatchesFiltersMetaFromJSON,
    BatchesFiltersMetaFromJSONTyped,
    BatchesFiltersMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface BatchesListDto
 */
export interface BatchesListDto {
    /**
     * 
     * @type {Array<Batch>}
     * @memberof BatchesListDto
     */
    results: Array<Batch>;
    /**
     * 
     * @type {number}
     * @memberof BatchesListDto
     */
    total?: number;
    /**
     * 
     * @type {BatchesFiltersMeta}
     * @memberof BatchesListDto
     */
    filters?: BatchesFiltersMeta;
    /**
     * 
     * @type {object}
     * @memberof BatchesListDto
     */
    extra?: object;
}

export function BatchesListDtoFromJSON(json: any): BatchesListDto {
    return BatchesListDtoFromJSONTyped(json, false);
}

export function BatchesListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchesListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(BatchFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : BatchesFiltersMetaFromJSON(json['filters']),
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function BatchesListDtoToJSON(value?: BatchesListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(BatchToJSON)),
        'total': value.total,
        'filters': BatchesFiltersMetaToJSON(value.filters),
        'extra': value.extra,
    };
}


