/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailDeliveryStatusPayload,
    EmailDeliveryStatusPayloadFromJSON,
    EmailDeliveryStatusPayloadFromJSONTyped,
    EmailDeliveryStatusPayloadToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmailDeliveryStatus
 */
export interface EmailDeliveryStatus {
    /**
     * 
     * @type {number}
     * @memberof EmailDeliveryStatus
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmailDeliveryStatus
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof EmailDeliveryStatus
     */
    customerName: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDeliveryStatus
     */
    emailStatus: EmailDeliveryStatusEmailStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailDeliveryStatus
     */
    emailStatusType: EmailDeliveryStatusEmailStatusTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof EmailDeliveryStatus
     */
    statusDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailDeliveryStatus
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmailDeliveryStatus
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailDeliveryStatus
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof EmailDeliveryStatus
     */
    emailType: EmailDeliveryStatusEmailTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EmailDeliveryStatus
     */
    emailSubject: string;
    /**
     * 
     * @type {EmailDeliveryStatusPayload}
     * @memberof EmailDeliveryStatus
     */
    payload: EmailDeliveryStatusPayload;
}

/**
* @export
* @enum {string}
*/
export enum EmailDeliveryStatusEmailStatusEnum {
    Processed = 'processed',
    Deferred = 'deferred',
    Delivered = 'delivered',
    Open = 'open',
    Dropped = 'dropped',
    Bounce = 'bounce',
    Blocked = 'blocked',
    None = 'none'
}/**
* @export
* @enum {string}
*/
export enum EmailDeliveryStatusEmailStatusTypeEnum {
    Information = 'information',
    Warning = 'warning',
    Error = 'error'
}/**
* @export
* @enum {string}
*/
export enum EmailDeliveryStatusEmailTypeEnum {
    Information = 'information',
    Reporting = 'reporting',
    Payments = 'payments',
    InternalNotification = 'internal-notification'
}

export function EmailDeliveryStatusFromJSON(json: any): EmailDeliveryStatus {
    return EmailDeliveryStatusFromJSONTyped(json, false);
}

export function EmailDeliveryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDeliveryStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'customerName': json['customerName'],
        'emailStatus': json['emailStatus'],
        'emailStatusType': json['emailStatusType'],
        'statusDate': (new Date(json['statusDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'emailAddress': json['emailAddress'],
        'emailType': json['emailType'],
        'emailSubject': json['emailSubject'],
        'payload': EmailDeliveryStatusPayloadFromJSON(json['payload']),
    };
}

export function EmailDeliveryStatusToJSON(value?: EmailDeliveryStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'customerName': value.customerName,
        'emailStatus': value.emailStatus,
        'emailStatusType': value.emailStatusType,
        'statusDate': (value.statusDate.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'emailAddress': value.emailAddress,
        'emailType': value.emailType,
        'emailSubject': value.emailSubject,
        'payload': EmailDeliveryStatusPayloadToJSON(value.payload),
    };
}


