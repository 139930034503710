/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserAccessCustomer,
    UserAccessCustomerFromJSON,
    UserAccessCustomerFromJSONTyped,
    UserAccessCustomerToJSON,
    UserProfile,
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof User
     */
    profile: UserProfile;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    newUser: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ssoEmail: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ssoObjectId: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    deletedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    activatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastLogin: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    roles: Array<UserRolesEnum>;
    /**
     * 
     * @type {Array<UserAccessCustomer>}
     * @memberof User
     */
    customers?: Array<UserAccessCustomer>;
}

/**
* @export
* @enum {string}
*/
export enum UserRolesEnum {
    WebAdmin = 'web_admin',
    Admin = 'admin',
    Provider = 'provider',
    Operator = 'operator',
    MAdminCustomer = 'm_admin_customer'
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'profile': UserProfileFromJSON(json['profile']),
        'active': json['active'],
        'newUser': json['newUser'],
        'ssoEmail': json['ssoEmail'],
        'ssoObjectId': json['ssoObjectId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deletedAt': (new Date(json['deletedAt'])),
        'activatedAt': (new Date(json['activatedAt'])),
        'lastLogin': (new Date(json['lastLogin'])),
        'roles': json['roles'],
        'customers': !exists(json, 'customers') ? undefined : ((json['customers'] as Array<any>).map(UserAccessCustomerFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'profile': UserProfileToJSON(value.profile),
        'active': value.active,
        'newUser': value.newUser,
        'ssoEmail': value.ssoEmail,
        'ssoObjectId': value.ssoObjectId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'deletedAt': (value.deletedAt.toISOString()),
        'activatedAt': (value.activatedAt.toISOString()),
        'lastLogin': (value.lastLogin.toISOString()),
        'roles': value.roles,
        'customers': value.customers === undefined ? undefined : ((value.customers as Array<any>).map(UserAccessCustomerToJSON)),
    };
}


