/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExportBillDto
 */
export interface ExportBillDto {
    /**
     * 
     * @type {number}
     * @memberof ExportBillDto
     */
    retryCount: number;
    /**
     * 
     * @type {number}
     * @memberof ExportBillDto
     */
    billId: number;
    /**
     * 
     * @type {number}
     * @memberof ExportBillDto
     */
    customerId: number;
    /**
     * 
     * @type {object}
     * @memberof ExportBillDto
     */
    customerReady: object;
    /**
     * 
     * @type {object}
     * @memberof ExportBillDto
     */
    locationRequired: object;
}

export function ExportBillDtoFromJSON(json: any): ExportBillDto {
    return ExportBillDtoFromJSONTyped(json, false);
}

export function ExportBillDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportBillDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retryCount': json['retryCount'],
        'billId': json['billId'],
        'customerId': json['customerId'],
        'customerReady': json['customerReady'],
        'locationRequired': json['locationRequired'],
    };
}

export function ExportBillDtoToJSON(value?: ExportBillDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retryCount': value.retryCount,
        'billId': value.billId,
        'customerId': value.customerId,
        'customerReady': value.customerReady,
        'locationRequired': value.locationRequired,
    };
}


