/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillValidationDefinitionsListDto,
    BillValidationDefinitionsListDtoFromJSON,
    BillValidationDefinitionsListDtoToJSON,
    ResourceEntity,
    ResourceEntityFromJSON,
    ResourceEntityToJSON,
} from '../models';

export interface ResourcesControllerGetBillValidationDefinitionsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<ResourcesControllerGetBillValidationDefinitionsExcludeEnum>;
    orderBy?: Array<ResourcesControllerGetBillValidationDefinitionsOrderByEnum>;
    id?: Array<number>;
    name?: string;
    category?: Array<ResourcesControllerGetBillValidationDefinitionsCategoryEnum>;
    workflowState?: Array<ResourcesControllerGetBillValidationDefinitionsWorkflowStateEnum>;
    severity?: Array<number>;
    resolvable?: boolean;
    autoResolvable?: boolean;
    bulkResolvable?: boolean;
}

/**
 * 
 */
export class ResourcesApi extends runtime.BaseAPI {

    /**
     * Get the list of Bill Validation Error Definitions
     */
    async resourcesControllerGetBillValidationDefinitionsRaw(requestParameters: ResourcesControllerGetBillValidationDefinitionsRequest): Promise<runtime.ApiResponse<BillValidationDefinitionsListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.id) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.category) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.workflowState) {
            queryParameters['workflowState'] = requestParameters.workflowState;
        }

        if (requestParameters.severity) {
            queryParameters['severity'] = requestParameters.severity;
        }

        if (requestParameters.resolvable !== undefined) {
            queryParameters['resolvable'] = requestParameters.resolvable;
        }

        if (requestParameters.autoResolvable !== undefined) {
            queryParameters['autoResolvable'] = requestParameters.autoResolvable;
        }

        if (requestParameters.bulkResolvable !== undefined) {
            queryParameters['bulkResolvable'] = requestParameters.bulkResolvable;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/resources/bill-validation-definitions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillValidationDefinitionsListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of Bill Validation Error Definitions
     */
    async resourcesControllerGetBillValidationDefinitions(requestParameters: ResourcesControllerGetBillValidationDefinitionsRequest): Promise<BillValidationDefinitionsListDto> {
        const response = await this.resourcesControllerGetBillValidationDefinitionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get all resources
     */
    async resourcesControllerGetResourcesRaw(): Promise<runtime.ApiResponse<Array<ResourceEntity>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResourceEntityFromJSON));
    }

    /**
     * Get all resources
     */
    async resourcesControllerGetResources(): Promise<Array<ResourceEntity>> {
        const response = await this.resourcesControllerGetResourcesRaw();
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ResourcesControllerGetBillValidationDefinitionsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum ResourcesControllerGetBillValidationDefinitionsOrderByEnum {
    IdAsc = 'id.asc',
    NameAsc = 'name.asc',
    CategoryAsc = 'category.asc',
    WorkflowStateAsc = 'workflowState.asc',
    SeverityAsc = 'severity.asc',
    ResolvableAsc = 'resolvable.asc',
    AutoResolvableAsc = 'autoResolvable.asc',
    BulkResolvableAsc = 'bulkResolvable.asc',
    IdDesc = 'id.desc',
    NameDesc = 'name.desc',
    CategoryDesc = 'category.desc',
    WorkflowStateDesc = 'workflowState.desc',
    SeverityDesc = 'severity.desc',
    ResolvableDesc = 'resolvable.desc',
    AutoResolvableDesc = 'autoResolvable.desc',
    BulkResolvableDesc = 'bulkResolvable.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum ResourcesControllerGetBillValidationDefinitionsCategoryEnum {
    Adapter = 'Adapter',
    PearDatastore = 'Pear Datastore',
    Analysis = 'Analysis',
    Review = 'Review'
}
/**
    * @export
    * @enum {string}
    */
export enum ResourcesControllerGetBillValidationDefinitionsWorkflowStateEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}
