/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface ActivityHistoryModel
 */
export interface ActivityHistoryModel {
    /**
     * 
     * @type {number}
     * @memberof ActivityHistoryModel
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ActivityHistoryModel
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ActivityHistoryModel
     */
    billMetaId: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityHistoryModel
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityHistoryModel
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityHistoryModel
     */
    text: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityHistoryModel
     */
    chatTags?: Array<string>;
    /**
     * 
     * @type {User}
     * @memberof ActivityHistoryModel
     */
    user?: User;
}

export function ActivityHistoryModelFromJSON(json: any): ActivityHistoryModel {
    return ActivityHistoryModelFromJSONTyped(json, false);
}

export function ActivityHistoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityHistoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'billMetaId': json['billMetaId'],
        'userId': json['userId'],
        'type': json['type'],
        'text': json['text'],
        'chatTags': !exists(json, 'chatTags') ? undefined : json['chatTags'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function ActivityHistoryModelToJSON(value?: ActivityHistoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'billMetaId': value.billMetaId,
        'userId': value.userId,
        'type': value.type,
        'text': value.text,
        'chatTags': value.chatTags,
        'user': UserToJSON(value.user),
    };
}


