/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentEventDefinitionModel
 */
export interface PaymentEventDefinitionModel {
    /**
     * 
     * @type {number}
     * @memberof PaymentEventDefinitionModel
     */
    type: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentEventDefinitionModel
     */
    event: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentEventDefinitionModel
     */
    description: string;
}

export function PaymentEventDefinitionModelFromJSON(json: any): PaymentEventDefinitionModel {
    return PaymentEventDefinitionModelFromJSONTyped(json, false);
}

export function PaymentEventDefinitionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEventDefinitionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'event': json['event'],
        'description': json['description'],
    };
}

export function PaymentEventDefinitionModelToJSON(value?: PaymentEventDefinitionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'event': value.event,
        'description': value.description,
    };
}


