import {
  CustomerLocationMetadataApi,
  CustomerLocationsMetadataControllerCreateMetadataAttributeRequest,
  CustomerLocationsMetadataControllerDeleteMetadataAttributeRequest,
  CustomerLocationsMetadataControllerGetMetadataAttributesRequest,
  CustomerLocationsMetadataControllerSortMetadataAttributesRequest,
  CustomerLocationsMetadataControllerUpdateMetadataAttributeRequest,
  LocationMetadata,
  LocationMetadataAttributesListDto,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomerLocationMetadataService extends ApiService {
  private customerLocationMetadataApi = new CustomerLocationMetadataApi(
    this.getBaseOptions(),
  )

  public getLocationMetadata = async (
    request: CustomerLocationsMetadataControllerGetMetadataAttributesRequest,
  ): Promise<LocationMetadataAttributesListDto> => {
    return await this.customerLocationMetadataApi.customerLocationsMetadataControllerGetMetadataAttributes(
      request,
    )
  }

  public updateLocationMetadata = async (
    request: CustomerLocationsMetadataControllerUpdateMetadataAttributeRequest,
  ): Promise<LocationMetadata> => {
    return await this.customerLocationMetadataApi.customerLocationsMetadataControllerUpdateMetadataAttribute(
      request,
    )
  }

  public deleteLocationMetadata = async (
    request: CustomerLocationsMetadataControllerDeleteMetadataAttributeRequest,
  ): Promise<void> => {
    return await this.customerLocationMetadataApi.customerLocationsMetadataControllerDeleteMetadataAttribute(
      request,
    )
  }

  public createLocationMetadata = async (
    request: CustomerLocationsMetadataControllerCreateMetadataAttributeRequest,
  ): Promise<LocationMetadata> => {
    return await this.customerLocationMetadataApi.customerLocationsMetadataControllerCreateMetadataAttribute(
      request,
    )
  }

  public sortLocationMetadata = async (
    request: CustomerLocationsMetadataControllerSortMetadataAttributesRequest,
  ): Promise<void> => {
    return await this.customerLocationMetadataApi.customerLocationsMetadataControllerSortMetadataAttributes(
      request,
    )
  }
}
