/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface LoginResponseDto
 */
export interface LoginResponseDto {
    /**
     * 
     * @type {User}
     * @memberof LoginResponseDto
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    refreshToken: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResponseDto
     */
    refreshTokenExpiresInSeconds: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseDto
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof LoginResponseDto
     */
    tokenExpiresInSeconds: number;
}

export function LoginResponseDtoFromJSON(json: any): LoginResponseDto {
    return LoginResponseDtoFromJSONTyped(json, false);
}

export function LoginResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserFromJSON(json['user']),
        'refreshToken': json['refreshToken'],
        'refreshTokenExpiresInSeconds': json['refreshTokenExpiresInSeconds'],
        'token': json['token'],
        'tokenExpiresInSeconds': json['tokenExpiresInSeconds'],
    };
}

export function LoginResponseDtoToJSON(value?: LoginResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'refreshToken': value.refreshToken,
        'refreshTokenExpiresInSeconds': value.refreshTokenExpiresInSeconds,
        'token': value.token,
        'tokenExpiresInSeconds': value.tokenExpiresInSeconds,
    };
}


