/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Batch,
    BatchFromJSON,
    BatchFromJSONTyped,
    BatchToJSON,
} from './';

/**
 * 
 * @export
 * @interface BatchParseBodyDto
 */
export interface BatchParseBodyDto {
    /**
     * 
     * @type {number}
     * @memberof BatchParseBodyDto
     */
    retryCount: number;
    /**
     * 
     * @type {Batch}
     * @memberof BatchParseBodyDto
     */
    batch: Batch;
}

export function BatchParseBodyDtoFromJSON(json: any): BatchParseBodyDto {
    return BatchParseBodyDtoFromJSONTyped(json, false);
}

export function BatchParseBodyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchParseBodyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retryCount': json['retryCount'],
        'batch': BatchFromJSON(json['batch']),
    };
}

export function BatchParseBodyDtoToJSON(value?: BatchParseBodyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retryCount': value.retryCount,
        'batch': BatchToJSON(value.batch),
    };
}


