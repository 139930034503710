/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VendorProviderAddressDto
 */
export interface VendorProviderAddressDto {
    /**
     * Address line 1
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    line1: string;
    /**
     * Address line 2
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    line2?: string;
    /**
     * Address line 3
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    line3?: string;
    /**
     * Address line 4
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    line4?: string;
    /**
     * Address line 1
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    city: string;
    /**
     * Address state
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    state: string;
    /**
     * Address postal code
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    postalCode: string;
    /**
     * Address country
     * @type {string}
     * @memberof VendorProviderAddressDto
     */
    country?: string;
}

export function VendorProviderAddressDtoFromJSON(json: any): VendorProviderAddressDto {
    return VendorProviderAddressDtoFromJSONTyped(json, false);
}

export function VendorProviderAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorProviderAddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line1': json['line1'],
        'line2': !exists(json, 'line2') ? undefined : json['line2'],
        'line3': !exists(json, 'line3') ? undefined : json['line3'],
        'line4': !exists(json, 'line4') ? undefined : json['line4'],
        'city': json['city'],
        'state': json['state'],
        'postalCode': json['postalCode'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function VendorProviderAddressDtoToJSON(value?: VendorProviderAddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line1': value.line1,
        'line2': value.line2,
        'line3': value.line3,
        'line4': value.line4,
        'city': value.city,
        'state': value.state,
        'postalCode': value.postalCode,
        'country': value.country,
    };
}


