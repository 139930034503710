/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerDashboardModel,
    CustomerDashboardModelFromJSON,
    CustomerDashboardModelFromJSONTyped,
    CustomerDashboardModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerBillsDashboardResponseDto
 */
export interface CustomerBillsDashboardResponseDto {
    /**
     * 
     * @type {Array<CustomerDashboardModel>}
     * @memberof CustomerBillsDashboardResponseDto
     */
    data: Array<CustomerDashboardModel>;
}

export function CustomerBillsDashboardResponseDtoFromJSON(json: any): CustomerBillsDashboardResponseDto {
    return CustomerBillsDashboardResponseDtoFromJSONTyped(json, false);
}

export function CustomerBillsDashboardResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerBillsDashboardResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(CustomerDashboardModelFromJSON)),
    };
}

export function CustomerBillsDashboardResponseDtoToJSON(value?: CustomerBillsDashboardResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(CustomerDashboardModelToJSON)),
    };
}


