/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface CommoditiesControllerGetCommoditiesAtLocationRequest {
    locationId: number;
    customerId: number;
}

/**
 * 
 */
export class CommoditiesApi extends runtime.BaseAPI {

    /**
     * Get available commodities at a given location - customer ready
     */
    async commoditiesControllerGetCommoditiesAtLocationRaw(requestParameters: CommoditiesControllerGetCommoditiesAtLocationRequest): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling commoditiesControllerGetCommoditiesAtLocation.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling commoditiesControllerGetCommoditiesAtLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/commodities/{customerId}/location/{locationId}`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get available commodities at a given location - customer ready
     */
    async commoditiesControllerGetCommoditiesAtLocation(requestParameters: CommoditiesControllerGetCommoditiesAtLocationRequest): Promise<Array<string>> {
        const response = await this.commoditiesControllerGetCommoditiesAtLocationRaw(requestParameters);
        return await response.value();
    }

}
