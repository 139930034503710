/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Hierarchy
 */
export interface Hierarchy {
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    parentId: number;
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    locationId: number;
    /**
     * 
     * @type {string}
     * @memberof Hierarchy
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    orderValue: number;
    /**
     * 
     * @type {Date}
     * @memberof Hierarchy
     */
    createdAt: Date;
    /**
     * 
     * @type {object}
     * @memberof Hierarchy
     */
    payload: object;
    /**
     * 
     * @type {number}
     * @memberof Hierarchy
     */
    level: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Hierarchy
     */
    path: Array<number>;
    /**
     * 
     * @type {Location}
     * @memberof Hierarchy
     */
    location: Location;
}

export function HierarchyFromJSON(json: any): Hierarchy {
    return HierarchyFromJSONTyped(json, false);
}

export function HierarchyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hierarchy {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'parentId': json['parentId'],
        'locationId': json['locationId'],
        'name': json['name'],
        'orderValue': json['orderValue'],
        'createdAt': (new Date(json['createdAt'])),
        'payload': json['payload'],
        'level': json['level'],
        'path': json['path'],
        'location': LocationFromJSON(json['location']),
    };
}

export function HierarchyToJSON(value?: Hierarchy | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'parentId': value.parentId,
        'locationId': value.locationId,
        'name': value.name,
        'orderValue': value.orderValue,
        'createdAt': (value.createdAt.toISOString()),
        'payload': value.payload,
        'level': value.level,
        'path': value.path,
        'location': LocationToJSON(value.location),
    };
}


