/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillDetailsWorkflowStateModel,
    BillDetailsWorkflowStateModelFromJSON,
    BillDetailsWorkflowStateModelFromJSONTyped,
    BillDetailsWorkflowStateModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface BulkBillDetailsWorkflowStateModel
 */
export interface BulkBillDetailsWorkflowStateModel {
    /**
     * 
     * @type {string}
     * @memberof BulkBillDetailsWorkflowStateModel
     */
    trackingId?: string;
    /**
     * 
     * @type {Array<BillDetailsWorkflowStateModel>}
     * @memberof BulkBillDetailsWorkflowStateModel
     */
    bills: Array<BillDetailsWorkflowStateModel>;
}

export function BulkBillDetailsWorkflowStateModelFromJSON(json: any): BulkBillDetailsWorkflowStateModel {
    return BulkBillDetailsWorkflowStateModelFromJSONTyped(json, false);
}

export function BulkBillDetailsWorkflowStateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkBillDetailsWorkflowStateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trackingId': !exists(json, 'trackingId') ? undefined : json['trackingId'],
        'bills': ((json['bills'] as Array<any>).map(BillDetailsWorkflowStateModelFromJSON)),
    };
}

export function BulkBillDetailsWorkflowStateModelToJSON(value?: BulkBillDetailsWorkflowStateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trackingId': value.trackingId,
        'bills': ((value.bills as Array<any>).map(BillDetailsWorkflowStateModelToJSON)),
    };
}


