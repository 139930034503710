/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CubeFilter
 */
export interface CubeFilter {
    /**
     * 
     * @type {string}
     * @memberof CubeFilter
     */
    member: string;
    /**
     * 
     * @type {string}
     * @memberof CubeFilter
     */
    operator: CubeFilterOperatorEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CubeFilter
     */
    values: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum CubeFilterOperatorEnum {
    Equals = 'equals',
    NotEquals = 'notEquals',
    Set = 'set',
    NotSet = 'notSet',
    Gt = 'gt',
    Lt = 'lt',
    Gte = 'gte',
    Lte = 'lte'
}

export function CubeFilterFromJSON(json: any): CubeFilter {
    return CubeFilterFromJSONTyped(json, false);
}

export function CubeFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): CubeFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'member': json['member'],
        'operator': json['operator'],
        'values': json['values'],
    };
}

export function CubeFilterToJSON(value?: CubeFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'member': value.member,
        'operator': value.operator,
        'values': value.values,
    };
}


