/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RunReportDto
 */
export interface RunReportDto {
    /**
     * 
     * @type {number}
     * @memberof RunReportDto
     */
    reportTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof RunReportDto
     */
    customReportName: string;
    /**
     * 
     * @type {object}
     * @memberof RunReportDto
     */
    appliedFilters: object;
    /**
     * 
     * @type {string}
     * @memberof RunReportDto
     */
    saveFormat: string;
    /**
     * 
     * @type {string}
     * @memberof RunReportDto
     */
    timezone: string;
}

export function RunReportDtoFromJSON(json: any): RunReportDto {
    return RunReportDtoFromJSONTyped(json, false);
}

export function RunReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportTemplateId': json['reportTemplateId'],
        'customReportName': json['customReportName'],
        'appliedFilters': json['appliedFilters'],
        'saveFormat': json['saveFormat'],
        'timezone': json['timezone'],
    };
}

export function RunReportDtoToJSON(value?: RunReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportTemplateId': value.reportTemplateId,
        'customReportName': value.customReportName,
        'appliedFilters': value.appliedFilters,
        'saveFormat': value.saveFormat,
        'timezone': value.timezone,
    };
}


