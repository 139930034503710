/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Entitlements
 */
export interface Entitlements {
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    energyStar: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    weather: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    payments: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    powerBi: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    constellation: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    carbonFootprint: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    reportDeliverySettings: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    budgeting: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    activityHistoryChat: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Entitlements
     */
    billPay: boolean;
}

export function EntitlementsFromJSON(json: any): Entitlements {
    return EntitlementsFromJSONTyped(json, false);
}

export function EntitlementsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Entitlements {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'energyStar': json['energyStar'],
        'weather': json['weather'],
        'payments': json['payments'],
        'powerBi': json['powerBi'],
        'constellation': json['constellation'],
        'carbonFootprint': json['carbonFootprint'],
        'reportDeliverySettings': json['reportDeliverySettings'],
        'budgeting': json['budgeting'],
        'activityHistoryChat': json['activityHistoryChat'],
        'billPay': json['billPay'],
    };
}

export function EntitlementsToJSON(value?: Entitlements | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'energyStar': value.energyStar,
        'weather': value.weather,
        'payments': value.payments,
        'powerBi': value.powerBi,
        'constellation': value.constellation,
        'carbonFootprint': value.carbonFootprint,
        'reportDeliverySettings': value.reportDeliverySettings,
        'budgeting': value.budgeting,
        'activityHistoryChat': value.activityHistoryChat,
        'billPay': value.billPay,
    };
}


