import {
  Customer,
  CustomerListDto,
  CustomersApi,
  CustomersControllerCreateCustomerRequest,
  CustomersControllerGetCustomerRequest,
  CustomersControllerGetListRequest,
  CustomersControllerGetUsersByCustomerIdRequest,
  UsersListDto,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomersService extends ApiService {
  private customerApi = new CustomersApi(this.getBaseOptions())

  public getCustomer = (
    requestParameters: CustomersControllerGetCustomerRequest,
  ): Promise<Customer> => {
    return this.customerApi.customersControllerGetCustomer(requestParameters)
  }

  public getUsersByCustomerId = (
    requestParameters: CustomersControllerGetUsersByCustomerIdRequest,
  ): Promise<UsersListDto> => {
    return this.customerApi.customersControllerGetUsersByCustomerId(
      requestParameters,
    )
  }

  public getCustomers = (
    requestParameters: CustomersControllerGetListRequest,
  ): Promise<CustomerListDto> => {
    return this.customerApi.customersControllerGetList(requestParameters)
  }
  public createCustomer = async (
    requestParameters: CustomersControllerCreateCustomerRequest,
  ): Promise<Customer> => {
    return this.customerApi.customersControllerCreateCustomer(requestParameters)
  }
}
