/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillPreview
 */
export interface BillPreview {
    /**
     * 
     * @type {number}
     * @memberof BillPreview
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillPreview
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof BillPreview
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof BillPreview
     */
    prettyName: string;
    /**
     * 
     * @type {Date}
     * @memberof BillPreview
     */
    statementDate: Date;
    /**
     * 
     * @type {string}
     * @memberof BillPreview
     */
    workflowState: string;
    /**
     * 
     * @type {Date}
     * @memberof BillPreview
     */
    targetDate: Date;
    /**
     * 
     * @type {number}
     * @memberof BillPreview
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof BillPreview
     */
    billType: BillPreviewBillTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof BillPreview
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillPreview
     */
    originalTargetDate: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillPreview
     */
    versions: Array<BillPreviewVersionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof BillPreview
     */
    totalBillCharges: number;
    /**
     * 
     * @type {string}
     * @memberof BillPreview
     */
    userName: string;
    /**
     * 
     * @type {number}
     * @memberof BillPreview
     */
    batchId: number;
    /**
     * 
     * @type {boolean}
     * @memberof BillPreview
     */
    isParked: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillPreview
     */
    accountCodes: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum BillPreviewBillTypeEnum {
    Historical = 'historical',
    Setup = 'setup',
    Live = 'live',
    Free = 'free',
    Maintenance = 'maintenance',
    Special = 'special'
}/**
* @export
* @enum {string}
*/
export enum BillPreviewVersionsEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}

export function BillPreviewFromJSON(json: any): BillPreview {
    return BillPreviewFromJSONTyped(json, false);
}

export function BillPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'userId': json['userId'],
        'prettyName': json['prettyName'],
        'statementDate': (new Date(json['statementDate'])),
        'workflowState': json['workflowState'],
        'targetDate': (new Date(json['targetDate'])),
        'priority': json['priority'],
        'billType': json['billType'],
        'createdAt': (new Date(json['createdAt'])),
        'originalTargetDate': (new Date(json['originalTargetDate'])),
        'versions': json['versions'],
        'totalBillCharges': json['totalBillCharges'],
        'userName': json['userName'],
        'batchId': json['batchId'],
        'isParked': json['isParked'],
        'accountCodes': json['accountCodes'],
    };
}

export function BillPreviewToJSON(value?: BillPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'userId': value.userId,
        'prettyName': value.prettyName,
        'statementDate': (value.statementDate.toISOString()),
        'workflowState': value.workflowState,
        'targetDate': (value.targetDate.toISOString()),
        'priority': value.priority,
        'billType': value.billType,
        'createdAt': (value.createdAt.toISOString()),
        'originalTargetDate': (value.originalTargetDate.toISOString()),
        'versions': value.versions,
        'totalBillCharges': value.totalBillCharges,
        'userName': value.userName,
        'batchId': value.batchId,
        'isParked': value.isParked,
        'accountCodes': value.accountCodes,
    };
}


