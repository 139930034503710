/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ObservationModel,
    ObservationModelFromJSON,
    ObservationModelFromJSONTyped,
    ObservationModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface ObservationsResponseDto
 */
export interface ObservationsResponseDto {
    /**
     * 
     * @type {Array<ObservationModel>}
     * @memberof ObservationsResponseDto
     */
    results: Array<ObservationModel>;
    /**
     * 
     * @type {number}
     * @memberof ObservationsResponseDto
     */
    total: number;
}

export function ObservationsResponseDtoFromJSON(json: any): ObservationsResponseDto {
    return ObservationsResponseDtoFromJSONTyped(json, false);
}

export function ObservationsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObservationsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ObservationModelFromJSON)),
        'total': json['total'],
    };
}

export function ObservationsResponseDtoToJSON(value?: ObservationsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ObservationModelToJSON)),
        'total': value.total,
    };
}


