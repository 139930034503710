/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationChangesDto,
    LocationChangesDtoFromJSON,
    LocationChangesDtoFromJSONTyped,
    LocationChangesDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LocationBulkValidateResponseDto
 */
export interface LocationBulkValidateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof LocationBulkValidateResponseDto
     */
    createCount: number;
    /**
     * 
     * @type {number}
     * @memberof LocationBulkValidateResponseDto
     */
    updateCount: number;
    /**
     * 
     * @type {number}
     * @memberof LocationBulkValidateResponseDto
     */
    geocodeCount: number;
    /**
     * 
     * @type {Array<LocationChangesDto>}
     * @memberof LocationBulkValidateResponseDto
     */
    changes: Array<LocationChangesDto>;
}

export function LocationBulkValidateResponseDtoFromJSON(json: any): LocationBulkValidateResponseDto {
    return LocationBulkValidateResponseDtoFromJSONTyped(json, false);
}

export function LocationBulkValidateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationBulkValidateResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createCount': json['createCount'],
        'updateCount': json['updateCount'],
        'geocodeCount': json['geocodeCount'],
        'changes': ((json['changes'] as Array<any>).map(LocationChangesDtoFromJSON)),
    };
}

export function LocationBulkValidateResponseDtoToJSON(value?: LocationBulkValidateResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createCount': value.createCount,
        'updateCount': value.updateCount,
        'geocodeCount': value.geocodeCount,
        'changes': ((value.changes as Array<any>).map(LocationChangesDtoToJSON)),
    };
}


