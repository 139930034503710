/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LocationMetadata,
    LocationMetadataFromJSON,
    LocationMetadataToJSON,
    LocationMetadataAttributesListDto,
    LocationMetadataAttributesListDtoFromJSON,
    LocationMetadataAttributesListDtoToJSON,
    LocationMetadataAttributesValuesListDto,
    LocationMetadataAttributesValuesListDtoFromJSON,
    LocationMetadataAttributesValuesListDtoToJSON,
    LocationMetadataBulkDto,
    LocationMetadataBulkDtoFromJSON,
    LocationMetadataBulkDtoToJSON,
    MetadataAttributeDto,
    MetadataAttributeDtoFromJSON,
    MetadataAttributeDtoToJSON,
    SortMetadataAttributeDto,
    SortMetadataAttributeDtoFromJSON,
    SortMetadataAttributeDtoToJSON,
    UpdateMetadataAttributeDto,
    UpdateMetadataAttributeDtoFromJSON,
    UpdateMetadataAttributeDtoToJSON,
} from '../models';

export interface CustomerLocationsMetadataControllerCreateLocationMetadataFromCSVRequest {
    customerId: number;
    locationMetadataBulkDto: LocationMetadataBulkDto;
}

export interface CustomerLocationsMetadataControllerCreateMetadataAttributeRequest {
    customerId: number;
    metadataAttributeDto: MetadataAttributeDto;
}

export interface CustomerLocationsMetadataControllerDeleteMetadataAttributeRequest {
    id: number;
    customerId: number;
}

export interface CustomerLocationsMetadataControllerGetMetadataAttributesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerLocationsMetadataControllerGetMetadataAttributesExcludeEnum>;
    orderBy?: Array<CustomerLocationsMetadataControllerGetMetadataAttributesOrderByEnum>;
}

export interface CustomerLocationsMetadataControllerGetMetadataAttributesValuesRequest {
    id: number;
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerLocationsMetadataControllerGetMetadataAttributesValuesExcludeEnum>;
    searchQuery?: string;
}

export interface CustomerLocationsMetadataControllerSortMetadataAttributesRequest {
    customerId: number;
    sortMetadataAttributeDto: Array<SortMetadataAttributeDto>;
}

export interface CustomerLocationsMetadataControllerUpdateMetadataAttributeRequest {
    id: number;
    customerId: number;
    updateMetadataAttributeDto: UpdateMetadataAttributeDto;
}

/**
 * 
 */
export class CustomerLocationMetadataApi extends runtime.BaseAPI {

    /**
     * Bulk insert of Location metadata - custom attributes
     */
    async customerLocationsMetadataControllerCreateLocationMetadataFromCSVRaw(requestParameters: CustomerLocationsMetadataControllerCreateLocationMetadataFromCSVRequest): Promise<runtime.ApiResponse<Array<LocationMetadata>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerCreateLocationMetadataFromCSV.');
        }

        if (requestParameters.locationMetadataBulkDto === null || requestParameters.locationMetadataBulkDto === undefined) {
            throw new runtime.RequiredError('locationMetadataBulkDto','Required parameter requestParameters.locationMetadataBulkDto was null or undefined when calling customerLocationsMetadataControllerCreateLocationMetadataFromCSV.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/load-csv`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationMetadataBulkDtoToJSON(requestParameters.locationMetadataBulkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationMetadataFromJSON));
    }

    /**
     * Bulk insert of Location metadata - custom attributes
     */
    async customerLocationsMetadataControllerCreateLocationMetadataFromCSV(requestParameters: CustomerLocationsMetadataControllerCreateLocationMetadataFromCSVRequest): Promise<Array<LocationMetadata>> {
        const response = await this.customerLocationsMetadataControllerCreateLocationMetadataFromCSVRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new location attribute for a given customer
     */
    async customerLocationsMetadataControllerCreateMetadataAttributeRaw(requestParameters: CustomerLocationsMetadataControllerCreateMetadataAttributeRequest): Promise<runtime.ApiResponse<LocationMetadata>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerCreateMetadataAttribute.');
        }

        if (requestParameters.metadataAttributeDto === null || requestParameters.metadataAttributeDto === undefined) {
            throw new runtime.RequiredError('metadataAttributeDto','Required parameter requestParameters.metadataAttributeDto was null or undefined when calling customerLocationsMetadataControllerCreateMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetadataAttributeDtoToJSON(requestParameters.metadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMetadataFromJSON(jsonValue));
    }

    /**
     * Create a new location attribute for a given customer
     */
    async customerLocationsMetadataControllerCreateMetadataAttribute(requestParameters: CustomerLocationsMetadataControllerCreateMetadataAttributeRequest): Promise<LocationMetadata> {
        const response = await this.customerLocationsMetadataControllerCreateMetadataAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an existing virtual account attribute for a given customer
     */
    async customerLocationsMetadataControllerDeleteMetadataAttributeRaw(requestParameters: CustomerLocationsMetadataControllerDeleteMetadataAttributeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerLocationsMetadataControllerDeleteMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerDeleteMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing virtual account attribute for a given customer
     */
    async customerLocationsMetadataControllerDeleteMetadataAttribute(requestParameters: CustomerLocationsMetadataControllerDeleteMetadataAttributeRequest): Promise<void> {
        await this.customerLocationsMetadataControllerDeleteMetadataAttributeRaw(requestParameters);
    }

    /**
     * Get the list of location attributes metadata for a given customer
     */
    async customerLocationsMetadataControllerGetMetadataAttributesRaw(requestParameters: CustomerLocationsMetadataControllerGetMetadataAttributesRequest): Promise<runtime.ApiResponse<LocationMetadataAttributesListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerGetMetadataAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations-metadata`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMetadataAttributesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of location attributes metadata for a given customer
     */
    async customerLocationsMetadataControllerGetMetadataAttributes(requestParameters: CustomerLocationsMetadataControllerGetMetadataAttributesRequest): Promise<LocationMetadataAttributesListDto> {
        const response = await this.customerLocationsMetadataControllerGetMetadataAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of location attributes metadata values for a given customer and custom attribute
     */
    async customerLocationsMetadataControllerGetMetadataAttributesValuesRaw(requestParameters: CustomerLocationsMetadataControllerGetMetadataAttributesValuesRequest): Promise<runtime.ApiResponse<LocationMetadataAttributesValuesListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerLocationsMetadataControllerGetMetadataAttributesValues.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerGetMetadataAttributesValues.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations-metadata/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMetadataAttributesValuesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of location attributes metadata values for a given customer and custom attribute
     */
    async customerLocationsMetadataControllerGetMetadataAttributesValues(requestParameters: CustomerLocationsMetadataControllerGetMetadataAttributesValuesRequest): Promise<LocationMetadataAttributesValuesListDto> {
        const response = await this.customerLocationsMetadataControllerGetMetadataAttributesValuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sort/reorder virtual account attributes for a given customer
     */
    async customerLocationsMetadataControllerSortMetadataAttributesRaw(requestParameters: CustomerLocationsMetadataControllerSortMetadataAttributesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerSortMetadataAttributes.');
        }

        if (requestParameters.sortMetadataAttributeDto === null || requestParameters.sortMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('sortMetadataAttributeDto','Required parameter requestParameters.sortMetadataAttributeDto was null or undefined when calling customerLocationsMetadataControllerSortMetadataAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/sort`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.sortMetadataAttributeDto.map(SortMetadataAttributeDtoToJSON),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sort/reorder virtual account attributes for a given customer
     */
    async customerLocationsMetadataControllerSortMetadataAttributes(requestParameters: CustomerLocationsMetadataControllerSortMetadataAttributesRequest): Promise<void> {
        await this.customerLocationsMetadataControllerSortMetadataAttributesRaw(requestParameters);
    }

    /**
     * Update an existing location attribute for a given customer
     */
    async customerLocationsMetadataControllerUpdateMetadataAttributeRaw(requestParameters: CustomerLocationsMetadataControllerUpdateMetadataAttributeRequest): Promise<runtime.ApiResponse<LocationMetadata>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerLocationsMetadataControllerUpdateMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsMetadataControllerUpdateMetadataAttribute.');
        }

        if (requestParameters.updateMetadataAttributeDto === null || requestParameters.updateMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('updateMetadataAttributeDto','Required parameter requestParameters.updateMetadataAttributeDto was null or undefined when calling customerLocationsMetadataControllerUpdateMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMetadataAttributeDtoToJSON(requestParameters.updateMetadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMetadataFromJSON(jsonValue));
    }

    /**
     * Update an existing location attribute for a given customer
     */
    async customerLocationsMetadataControllerUpdateMetadataAttribute(requestParameters: CustomerLocationsMetadataControllerUpdateMetadataAttributeRequest): Promise<LocationMetadata> {
        const response = await this.customerLocationsMetadataControllerUpdateMetadataAttributeRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsMetadataControllerGetMetadataAttributesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsMetadataControllerGetMetadataAttributesOrderByEnum {
    SortOrderAsc = 'sortOrder.asc',
    CreatedAtAsc = 'createdAt.asc',
    UpdatedAtAsc = 'updatedAt.asc',
    AttributeNameAsc = 'attributeName.asc',
    AttributeTypeAsc = 'attributeType.asc',
    AttributeUomAsc = 'attributeUom.asc',
    SortOrderDesc = 'sortOrder.desc',
    CreatedAtDesc = 'createdAt.desc',
    UpdatedAtDesc = 'updatedAt.desc',
    AttributeNameDesc = 'attributeName.desc',
    AttributeTypeDesc = 'attributeType.desc',
    AttributeUomDesc = 'attributeUom.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsMetadataControllerGetMetadataAttributesValuesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
