/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Widget,
    WidgetFromJSON,
    WidgetToJSON,
    WidgetCreateDto,
    WidgetCreateDtoFromJSON,
    WidgetCreateDtoToJSON,
    WidgetDataDto,
    WidgetDataDtoFromJSON,
    WidgetDataDtoToJSON,
    WidgetMetadataResponseDto,
    WidgetMetadataResponseDtoFromJSON,
    WidgetMetadataResponseDtoToJSON,
    WidgetUpdateDto,
    WidgetUpdateDtoFromJSON,
    WidgetUpdateDtoToJSON,
    WidgetsListDto,
    WidgetsListDtoFromJSON,
    WidgetsListDtoToJSON,
} from '../models';

export interface WidgetsControllerCreateWidgetRequest {
    customerId: number;
    widgetCreateDto: WidgetCreateDto;
}

export interface WidgetsControllerDeleteWidgetRequest {
    customerId: number;
    widgetId: number;
}

export interface WidgetsControllerGetWidgetByIdRequest {
    customerId: number;
    widgetId: number;
}

export interface WidgetsControllerGetWidgetDataRequest {
    customerId: number;
    widgetId: number;
    limit?: number;
    offset?: number;
}

export interface WidgetsControllerGetWidgetMetadataRequest {
    customerId: number;
    widgetType: WidgetsControllerGetWidgetMetadataWidgetTypeEnum;
}

export interface WidgetsControllerGetWidgetsListRequest {
    customerId: number;
}

export interface WidgetsControllerUpdateWidgetsRequest {
    customerId: number;
    widgetUpdateDto: Array<WidgetUpdateDto>;
}

/**
 * 
 */
export class WidgetsApi extends runtime.BaseAPI {

    /**
     * Create a widget
     */
    async widgetsControllerCreateWidgetRaw(requestParameters: WidgetsControllerCreateWidgetRequest): Promise<runtime.ApiResponse<Widget>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerCreateWidget.');
        }

        if (requestParameters.widgetCreateDto === null || requestParameters.widgetCreateDto === undefined) {
            throw new runtime.RequiredError('widgetCreateDto','Required parameter requestParameters.widgetCreateDto was null or undefined when calling widgetsControllerCreateWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WidgetCreateDtoToJSON(requestParameters.widgetCreateDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetFromJSON(jsonValue));
    }

    /**
     * Create a widget
     */
    async widgetsControllerCreateWidget(requestParameters: WidgetsControllerCreateWidgetRequest): Promise<Widget> {
        const response = await this.widgetsControllerCreateWidgetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a widget
     */
    async widgetsControllerDeleteWidgetRaw(requestParameters: WidgetsControllerDeleteWidgetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerDeleteWidget.');
        }

        if (requestParameters.widgetId === null || requestParameters.widgetId === undefined) {
            throw new runtime.RequiredError('widgetId','Required parameter requestParameters.widgetId was null or undefined when calling widgetsControllerDeleteWidget.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets/{widgetId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"widgetId"}}`, encodeURIComponent(String(requestParameters.widgetId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a widget
     */
    async widgetsControllerDeleteWidget(requestParameters: WidgetsControllerDeleteWidgetRequest): Promise<void> {
        await this.widgetsControllerDeleteWidgetRaw(requestParameters);
    }

    /**
     * Get widget details by id
     */
    async widgetsControllerGetWidgetByIdRaw(requestParameters: WidgetsControllerGetWidgetByIdRequest): Promise<runtime.ApiResponse<Widget>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerGetWidgetById.');
        }

        if (requestParameters.widgetId === null || requestParameters.widgetId === undefined) {
            throw new runtime.RequiredError('widgetId','Required parameter requestParameters.widgetId was null or undefined when calling widgetsControllerGetWidgetById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets/{widgetId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"widgetId"}}`, encodeURIComponent(String(requestParameters.widgetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetFromJSON(jsonValue));
    }

    /**
     * Get widget details by id
     */
    async widgetsControllerGetWidgetById(requestParameters: WidgetsControllerGetWidgetByIdRequest): Promise<Widget> {
        const response = await this.widgetsControllerGetWidgetByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get widget data by id
     */
    async widgetsControllerGetWidgetDataRaw(requestParameters: WidgetsControllerGetWidgetDataRequest): Promise<runtime.ApiResponse<WidgetDataDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerGetWidgetData.');
        }

        if (requestParameters.widgetId === null || requestParameters.widgetId === undefined) {
            throw new runtime.RequiredError('widgetId','Required parameter requestParameters.widgetId was null or undefined when calling widgetsControllerGetWidgetData.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets/{widgetId}/data`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"widgetId"}}`, encodeURIComponent(String(requestParameters.widgetId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetDataDtoFromJSON(jsonValue));
    }

    /**
     * Get widget data by id
     */
    async widgetsControllerGetWidgetData(requestParameters: WidgetsControllerGetWidgetDataRequest): Promise<WidgetDataDto> {
        const response = await this.widgetsControllerGetWidgetDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get widget metadata for a given customer
     */
    async widgetsControllerGetWidgetMetadataRaw(requestParameters: WidgetsControllerGetWidgetMetadataRequest): Promise<runtime.ApiResponse<WidgetMetadataResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerGetWidgetMetadata.');
        }

        if (requestParameters.widgetType === null || requestParameters.widgetType === undefined) {
            throw new runtime.RequiredError('widgetType','Required parameter requestParameters.widgetType was null or undefined when calling widgetsControllerGetWidgetMetadata.');
        }

        const queryParameters: any = {};

        if (requestParameters.widgetType !== undefined) {
            queryParameters['widgetType'] = requestParameters.widgetType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets/metadata`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetMetadataResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get widget metadata for a given customer
     */
    async widgetsControllerGetWidgetMetadata(requestParameters: WidgetsControllerGetWidgetMetadataRequest): Promise<WidgetMetadataResponseDto> {
        const response = await this.widgetsControllerGetWidgetMetadataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get widgets list
     */
    async widgetsControllerGetWidgetsListRaw(requestParameters: WidgetsControllerGetWidgetsListRequest): Promise<runtime.ApiResponse<WidgetsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerGetWidgetsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WidgetsListDtoFromJSON(jsonValue));
    }

    /**
     * Get widgets list
     */
    async widgetsControllerGetWidgetsList(requestParameters: WidgetsControllerGetWidgetsListRequest): Promise<WidgetsListDto> {
        const response = await this.widgetsControllerGetWidgetsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update widgets
     */
    async widgetsControllerUpdateWidgetsRaw(requestParameters: WidgetsControllerUpdateWidgetsRequest): Promise<runtime.ApiResponse<Array<Widget>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling widgetsControllerUpdateWidgets.');
        }

        if (requestParameters.widgetUpdateDto === null || requestParameters.widgetUpdateDto === undefined) {
            throw new runtime.RequiredError('widgetUpdateDto','Required parameter requestParameters.widgetUpdateDto was null or undefined when calling widgetsControllerUpdateWidgets.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/widgets`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.widgetUpdateDto.map(WidgetUpdateDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WidgetFromJSON));
    }

    /**
     * Update widgets
     */
    async widgetsControllerUpdateWidgets(requestParameters: WidgetsControllerUpdateWidgetsRequest): Promise<Array<Widget>> {
        const response = await this.widgetsControllerUpdateWidgetsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum WidgetsControllerGetWidgetMetadataWidgetTypeEnum {
    HighestCostLocations = 'highest_cost_locations',
    HighestConsumptionLocations = 'highest_consumption_locations',
    CostByUtility = 'cost_by_utility',
    HighestUnitCostLocations = 'highest_unit_cost_locations',
    MonthlyCostByLocation = 'monthly_cost_by_location',
    MonthlyConsumptionByLocation = 'monthly_consumption_by_location',
    ChargesByVendor = 'charges_by_vendor',
    CostsByChargeType = 'costs_by_charge_type'
}
