import {
  BillsApi,
  BillsControllerBulkActionsRequest,
  BillsControllerBulkAutoResolveErrorsRequest,
  BillsControllerCacheGetBulkBillsWorkflowStateRequest,
  BillsControllerGetBillsDashboardPreviewRequest,
  BillsControllerCheckBillsInProcessRequest,
  BillsControllerGetBillsPreviewsRequest,
  BillsControllerGetMyBillsPreviewsRequest,
  BillsDashboardListDto,
  BillsPreviewListDto,
  BulkBillWorkflowStateModel,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class BillsService extends ApiService {
  private readonly billsApi = new BillsApi(this.getBaseOptions())

  public getBulkBillsWorkflowState(
    requestParameters: BillsControllerCacheGetBulkBillsWorkflowStateRequest,
  ): Promise<BulkBillWorkflowStateModel> {
    return this.billsApi.billsControllerCacheGetBulkBillsWorkflowState(
      requestParameters,
    )
  }

  public bulkAutoResolveErrors(
    requestParameters: BillsControllerBulkAutoResolveErrorsRequest,
  ): Promise<BulkBillWorkflowStateModel> {
    return this.billsApi.billsControllerBulkAutoResolveErrors(requestParameters)
  }

  public getMyBillsPreviews(
    requestParameters: BillsControllerGetMyBillsPreviewsRequest,
  ): Promise<BillsPreviewListDto> {
    return this.billsApi.billsControllerGetMyBillsPreviews(requestParameters)
  }

  public getBillsPreviews(
    requestParameters: BillsControllerGetBillsPreviewsRequest,
  ): Promise<BillsPreviewListDto> {
    return this.billsApi.billsControllerGetBillsPreviews(requestParameters)
  }

  public checkProcessingList(
    requestParameters: BillsControllerCheckBillsInProcessRequest,
  ): Promise<Array<number>> {
    return this.billsApi.billsControllerCheckBillsInProcess(requestParameters)
  }

  public reprioritizeBills(): Promise<void> {
    return this.billsApi.billsControllerReprioritize()
  }

  public bulkActions(
    requestParameters: BillsControllerBulkActionsRequest,
  ): Promise<Array<number>> {
    return this.billsApi.billsControllerBulkActions(requestParameters)
  }
  public getBillsDashboard(
    requestParameters: BillsControllerGetBillsDashboardPreviewRequest,
  ): Promise<BillsDashboardListDto> {
    return this.billsApi.billsControllerGetBillsDashboardPreview(
      requestParameters,
    )
  }
}
