import {
  TableRepository,
  TableOptions,
  TableQuery,
  TableRepositoryList,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import { ref, Ref } from 'vue'
import { useTablePaginationMeta } from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { User, UserAccessCustomer } from '@/SharedModule/Api'
import { customerRolesPermissions } from '@/SharedModule/Helpers/helper.user-role-selection'
import { useStore } from 'vuex'

const useQuery = (): TableQuery => {
  return {
    filters: ref({}),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({}),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('id'),
      },
      {
        prettyName: ref('Name'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('name'),
      },
      {
        prettyName: ref('User Role'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('userRole'),
      },
      {
        prettyName: ref('Locations'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('locations'),
      },
      {
        prettyName: ref('Actions'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('actions'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'user.info',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
    }),
  }
}

const useListRef = (): Ref<TableRepositoryList> =>
  ref({
    results: [],
    total: 0,
  }) as Ref<TableRepositoryList>

export default class RepositoryUserInfo
  implements TableRepository<TableRepositoryList>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  private readonly userGetter: (userId: number) => User
  private loadUserInStore: (userId: number) => Promise<void>

  constructor(userId: number | null = null) {
    this.options.meta.value.userId = userId
    const { getters, dispatch } = useStore()
    this.userGetter = (userId: number): User => getters['users/getUser'](userId)
    this.loadUserInStore = (userId: number): Promise<void> =>
      dispatch('users/loadUser', { userId, forceReload: false })
  }

  getList = async (): Promise<void> => {
    let user: User = {} as User
    await this.loadUserInStore(this.options.meta.value.userId).then(() => {
      user = this.userGetter(this.options.meta.value.userId)
    })
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const tempListValue: any[] = []
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    user?.customers?.forEach((item: any) => {
      tempListValue.push({
        ...item,
        locations: this.customerLocations(item),
        userRole: this.userRole(item),
      })
    })

    this.list.value = {
      results: tempListValue as [],
      total: tempListValue.length,
    }
    this.options.meta.value.excludePaginationData = false
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  isFullAccess(customer: UserAccessCustomer): {
    isFullAcc: boolean
    permissionsLength: number
  } {
    const isFullAcc =
      customer &&
      customer.permissions &&
      !!customer.permissions.find(
        (p) =>
          p.feature ===
            customerRolesPermissions.customerFullAccess[0].feature &&
          p.permission ===
            customerRolesPermissions.customerFullAccess[0].permission,
      )
    const permissionsLength = customer && customer.permissions.length
    return { isFullAcc, permissionsLength }
  }

  userRole(customer: UserAccessCustomer): string {
    const fullAccess = this.isFullAccess(customer)
    if (!fullAccess.isFullAcc && fullAccess.permissionsLength > 0) {
      return 'Custom Role'
    }
    if (fullAccess.isFullAcc) {
      return 'Full Access'
    }
    return 'Standard User'
  }

  customerLocations(customer: UserAccessCustomer): string {
    const fullAccess = this.isFullAccess(customer)
    const locations = customer.locations
    const totalLocations = customer.totalLocations
    let result = '-'
    if (locations !== null && locations !== undefined) {
      if (locations.length === totalLocations || fullAccess.isFullAcc) {
        result = 'All'
      } else if (locations.length > 0) {
        result = locations.length.toString()
      }
    }
    return result
  }
}
