/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileUrlDto,
    FileUrlDtoFromJSON,
    FileUrlDtoToJSON,
} from '../models';

export interface CustomerFilesControllerGetFileRequest {
    customerId: number;
    fileId: number;
}

/**
 * 
 */
export class FilesApi extends runtime.BaseAPI {

    /**
     * Get customer file url
     */
    async customerFilesControllerGetFileRaw(requestParameters: CustomerFilesControllerGetFileRequest): Promise<runtime.ApiResponse<FileUrlDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerFilesControllerGetFile.');
        }

        if (requestParameters.fileId === null || requestParameters.fileId === undefined) {
            throw new runtime.RequiredError('fileId','Required parameter requestParameters.fileId was null or undefined when calling customerFilesControllerGetFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/files/{fileId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters.fileId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileUrlDtoFromJSON(jsonValue));
    }

    /**
     * Get customer file url
     */
    async customerFilesControllerGetFile(requestParameters: CustomerFilesControllerGetFileRequest): Promise<FileUrlDto> {
        const response = await this.customerFilesControllerGetFileRaw(requestParameters);
        return await response.value();
    }

}
