/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SftpConnection,
    SftpConnectionFromJSON,
    SftpConnectionToJSON,
    SftpConnectionDto,
    SftpConnectionDtoFromJSON,
    SftpConnectionDtoToJSON,
    SftpConnectionListDto,
    SftpConnectionListDtoFromJSON,
    SftpConnectionListDtoToJSON,
    SftpConnectionScheduledReports,
    SftpConnectionScheduledReportsFromJSON,
    SftpConnectionScheduledReportsToJSON,
    SftpPublicKeyDto,
    SftpPublicKeyDtoFromJSON,
    SftpPublicKeyDtoToJSON,
    TestSftpConnectionDto,
    TestSftpConnectionDtoFromJSON,
    TestSftpConnectionDtoToJSON,
    TestSftpConnectionResponseDto,
    TestSftpConnectionResponseDtoFromJSON,
    TestSftpConnectionResponseDtoToJSON,
} from '../models';

export interface CustomerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRequest {
    id: number;
    customerId: number;
}

export interface CustomerSftpConnectionsControllerCreateSftpConnectionRequest {
    customerId: number;
    sftpConnectionDto: SftpConnectionDto;
}

export interface CustomerSftpConnectionsControllerDeleteSftpConnectionRequest {
    id: number;
    customerId: number;
}

export interface CustomerSftpConnectionsControllerGetSftpConnectionsRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerSftpConnectionsControllerGetSftpConnectionsExcludeEnum>;
    featureType?: CustomerSftpConnectionsControllerGetSftpConnectionsFeatureTypeEnum;
}

export interface CustomerSftpConnectionsControllerUpdateSftpConnectionRequest {
    id: number;
    customerId: number;
    sftpConnectionDto: SftpConnectionDto;
}

export interface SftpControllerTestSFTPRequest {
    testSftpConnectionDto: TestSftpConnectionDto;
}

/**
 * 
 */
export class SftpApi extends runtime.BaseAPI {

    /**
     * Get the total number of scheduled reports that use this connection for SFTP upload
     */
    async customerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRaw(requestParameters: CustomerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRequest): Promise<runtime.ApiResponse<SftpConnectionScheduledReports>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerSftpConnectionsControllerCountScheduledReportsBySFTPConnection.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerSftpConnectionsControllerCountScheduledReportsBySFTPConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/sftp-connections/{id}/count-scheduled-reports`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SftpConnectionScheduledReportsFromJSON(jsonValue));
    }

    /**
     * Get the total number of scheduled reports that use this connection for SFTP upload
     */
    async customerSftpConnectionsControllerCountScheduledReportsBySFTPConnection(requestParameters: CustomerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRequest): Promise<SftpConnectionScheduledReports> {
        const response = await this.customerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create SFTP Connection
     */
    async customerSftpConnectionsControllerCreateSftpConnectionRaw(requestParameters: CustomerSftpConnectionsControllerCreateSftpConnectionRequest): Promise<runtime.ApiResponse<SftpConnection>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerSftpConnectionsControllerCreateSftpConnection.');
        }

        if (requestParameters.sftpConnectionDto === null || requestParameters.sftpConnectionDto === undefined) {
            throw new runtime.RequiredError('sftpConnectionDto','Required parameter requestParameters.sftpConnectionDto was null or undefined when calling customerSftpConnectionsControllerCreateSftpConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/sftp-connections`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SftpConnectionDtoToJSON(requestParameters.sftpConnectionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SftpConnectionFromJSON(jsonValue));
    }

    /**
     * Create SFTP Connection
     */
    async customerSftpConnectionsControllerCreateSftpConnection(requestParameters: CustomerSftpConnectionsControllerCreateSftpConnectionRequest): Promise<SftpConnection> {
        const response = await this.customerSftpConnectionsControllerCreateSftpConnectionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete SFTP Connection
     */
    async customerSftpConnectionsControllerDeleteSftpConnectionRaw(requestParameters: CustomerSftpConnectionsControllerDeleteSftpConnectionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerSftpConnectionsControllerDeleteSftpConnection.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerSftpConnectionsControllerDeleteSftpConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/sftp-connections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete SFTP Connection
     */
    async customerSftpConnectionsControllerDeleteSftpConnection(requestParameters: CustomerSftpConnectionsControllerDeleteSftpConnectionRequest): Promise<void> {
        await this.customerSftpConnectionsControllerDeleteSftpConnectionRaw(requestParameters);
    }

    /**
     * Get a paginated list of SFTP Connections
     */
    async customerSftpConnectionsControllerGetSftpConnectionsRaw(requestParameters: CustomerSftpConnectionsControllerGetSftpConnectionsRequest): Promise<runtime.ApiResponse<SftpConnectionListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerSftpConnectionsControllerGetSftpConnections.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.featureType !== undefined) {
            queryParameters['featureType'] = requestParameters.featureType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/sftp-connections`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SftpConnectionListDtoFromJSON(jsonValue));
    }

    /**
     * Get a paginated list of SFTP Connections
     */
    async customerSftpConnectionsControllerGetSftpConnections(requestParameters: CustomerSftpConnectionsControllerGetSftpConnectionsRequest): Promise<SftpConnectionListDto> {
        const response = await this.customerSftpConnectionsControllerGetSftpConnectionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update SFTP Connection
     */
    async customerSftpConnectionsControllerUpdateSftpConnectionRaw(requestParameters: CustomerSftpConnectionsControllerUpdateSftpConnectionRequest): Promise<runtime.ApiResponse<SftpConnection>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerSftpConnectionsControllerUpdateSftpConnection.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerSftpConnectionsControllerUpdateSftpConnection.');
        }

        if (requestParameters.sftpConnectionDto === null || requestParameters.sftpConnectionDto === undefined) {
            throw new runtime.RequiredError('sftpConnectionDto','Required parameter requestParameters.sftpConnectionDto was null or undefined when calling customerSftpConnectionsControllerUpdateSftpConnection.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/sftp-connections/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SftpConnectionDtoToJSON(requestParameters.sftpConnectionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SftpConnectionFromJSON(jsonValue));
    }

    /**
     * Update SFTP Connection
     */
    async customerSftpConnectionsControllerUpdateSftpConnection(requestParameters: CustomerSftpConnectionsControllerUpdateSftpConnectionRequest): Promise<SftpConnection> {
        const response = await this.customerSftpConnectionsControllerUpdateSftpConnectionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns SFTP public key
     */
    async sftpControllerGetSFTPSSHPublicKeyRaw(): Promise<runtime.ApiResponse<SftpPublicKeyDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/sftp/sftp-public-key`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SftpPublicKeyDtoFromJSON(jsonValue));
    }

    /**
     * Returns SFTP public key
     */
    async sftpControllerGetSFTPSSHPublicKey(): Promise<SftpPublicKeyDto> {
        const response = await this.sftpControllerGetSFTPSSHPublicKeyRaw();
        return await response.value();
    }

    /**
     * Test SFTP capability of a host, by listing a folder.
     */
    async sftpControllerTestSFTPRaw(requestParameters: SftpControllerTestSFTPRequest): Promise<runtime.ApiResponse<TestSftpConnectionResponseDto>> {
        if (requestParameters.testSftpConnectionDto === null || requestParameters.testSftpConnectionDto === undefined) {
            throw new runtime.RequiredError('testSftpConnectionDto','Required parameter requestParameters.testSftpConnectionDto was null or undefined when calling sftpControllerTestSFTP.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/sftp/test-sftp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TestSftpConnectionDtoToJSON(requestParameters.testSftpConnectionDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TestSftpConnectionResponseDtoFromJSON(jsonValue));
    }

    /**
     * Test SFTP capability of a host, by listing a folder.
     */
    async sftpControllerTestSFTP(requestParameters: SftpControllerTestSFTPRequest): Promise<TestSftpConnectionResponseDto> {
        const response = await this.sftpControllerTestSFTPRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerSftpConnectionsControllerGetSftpConnectionsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerSftpConnectionsControllerGetSftpConnectionsFeatureTypeEnum {
    PaymentFiles = 'paymentFiles',
    Reports = 'reports'
}
