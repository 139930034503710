/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientAccountBillChain
 */
export interface ClientAccountBillChain {
    /**
     * 
     * @type {number}
     * @memberof ClientAccountBillChain
     */
    billChainId: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientAccountBillChain
     */
    billInChain: boolean;
}

export function ClientAccountBillChainFromJSON(json: any): ClientAccountBillChain {
    return ClientAccountBillChainFromJSONTyped(json, false);
}

export function ClientAccountBillChainFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientAccountBillChain {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billChainId': json['billChainId'],
        'billInChain': json['billInChain'],
    };
}

export function ClientAccountBillChainToJSON(value?: ClientAccountBillChain | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billChainId': value.billChainId,
        'billInChain': value.billInChain,
    };
}


