import { h, createApp, VNode } from 'vue'
// to be removed when porting over
import singleSpaVue from 'single-spa-vue'
import { SingleSpaVueLifecycles, AppOptions } from 'single-spa-vue'
import App from '@/AppModule/views/view.index.vue'
import Plugins from '@/AppModule/Plugins/agregator.plugins'
import Maska from 'maska'

// to be changed when porting over
const vueLifecycles: SingleSpaVueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    setup() {
      return {}
    },
    render(): VNode {
      return h(App, {
        singleSpaData: {
          name: this.name,
          mountParcel: (this as AppOptions)._.data.mountParcel,
          singleSpa: (this as AppOptions)._.data.singleSpa,
        },
      })
    },
  },
  handleInstance: (app) => {
    app.use(Plugins)
    app.use(Maska)
  },
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
