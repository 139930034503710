/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountModel
 */
export interface VirtualAccountModel {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountModel
     */
    accountCode: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountModel
     */
    billType: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountModel
     */
    clientAccount: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountModel
     */
    commodity: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountModel
     */
    meterSerial: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountModel
     */
    vendorCode: string;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountModel
     */
    virtualAccountGroupId: number;
}

export function VirtualAccountModelFromJSON(json: any): VirtualAccountModel {
    return VirtualAccountModelFromJSONTyped(json, false);
}

export function VirtualAccountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'accountCode': json['accountCode'],
        'billType': json['billType'],
        'clientAccount': json['clientAccount'],
        'commodity': json['commodity'],
        'meterSerial': json['meterSerial'],
        'vendorCode': json['vendorCode'],
        'virtualAccountGroupId': json['virtualAccountGroupId'],
    };
}

export function VirtualAccountModelToJSON(value?: VirtualAccountModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'accountCode': value.accountCode,
        'billType': value.billType,
        'clientAccount': value.clientAccount,
        'commodity': value.commodity,
        'meterSerial': value.meterSerial,
        'vendorCode': value.vendorCode,
        'virtualAccountGroupId': value.virtualAccountGroupId,
    };
}


