/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillDashboardPreview,
    BillDashboardPreviewFromJSON,
    BillDashboardPreviewFromJSONTyped,
    BillDashboardPreviewToJSON,
    BillsDashboardExtra,
    BillsDashboardExtraFromJSON,
    BillsDashboardExtraFromJSONTyped,
    BillsDashboardExtraToJSON,
    BillsDashboardFiltersMeta,
    BillsDashboardFiltersMetaFromJSON,
    BillsDashboardFiltersMetaFromJSONTyped,
    BillsDashboardFiltersMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillsDashboardListDto
 */
export interface BillsDashboardListDto {
    /**
     * 
     * @type {Array<BillDashboardPreview>}
     * @memberof BillsDashboardListDto
     */
    results: Array<BillDashboardPreview>;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardListDto
     */
    total?: number;
    /**
     * 
     * @type {BillsDashboardFiltersMeta}
     * @memberof BillsDashboardListDto
     */
    filters?: BillsDashboardFiltersMeta;
    /**
     * 
     * @type {BillsDashboardExtra}
     * @memberof BillsDashboardListDto
     */
    extra?: BillsDashboardExtra;
}

export function BillsDashboardListDtoFromJSON(json: any): BillsDashboardListDto {
    return BillsDashboardListDtoFromJSONTyped(json, false);
}

export function BillsDashboardListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillsDashboardListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(BillDashboardPreviewFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : BillsDashboardFiltersMetaFromJSON(json['filters']),
        'extra': !exists(json, 'extra') ? undefined : BillsDashboardExtraFromJSON(json['extra']),
    };
}

export function BillsDashboardListDtoToJSON(value?: BillsDashboardListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(BillDashboardPreviewToJSON)),
        'total': value.total,
        'filters': BillsDashboardFiltersMetaToJSON(value.filters),
        'extra': BillsDashboardExtraToJSON(value.extra),
    };
}


