/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateEnergyStarCustomerDto,
    CreateEnergyStarCustomerDtoFromJSON,
    CreateEnergyStarCustomerDtoToJSON,
    CreateEnergyStarMeterDto,
    CreateEnergyStarMeterDtoFromJSON,
    CreateEnergyStarMeterDtoToJSON,
    CreateEnergyStarPropertyDto,
    CreateEnergyStarPropertyDtoFromJSON,
    CreateEnergyStarPropertyDtoToJSON,
    DateIntervalDto,
    DateIntervalDtoFromJSON,
    DateIntervalDtoToJSON,
    ESPropertyUseDto,
    ESPropertyUseDtoFromJSON,
    ESPropertyUseDtoToJSON,
    EnergyStarCustomerAccountDetailsDto,
    EnergyStarCustomerAccountDetailsDtoFromJSON,
    EnergyStarCustomerAccountDetailsDtoToJSON,
    EnergyStarDatabaseProperty,
    EnergyStarDatabasePropertyFromJSON,
    EnergyStarDatabasePropertyToJSON,
    EnergyStarLocationDto,
    EnergyStarLocationDtoFromJSON,
    EnergyStarLocationDtoToJSON,
    EnergyStarMeterDto,
    EnergyStarMeterDtoFromJSON,
    EnergyStarMeterDtoToJSON,
    EnergyStarMetersDto,
    EnergyStarMetersDtoFromJSON,
    EnergyStarMetersDtoToJSON,
    EnergyStarNoScoreReasonsDto,
    EnergyStarNoScoreReasonsDtoFromJSON,
    EnergyStarNoScoreReasonsDtoToJSON,
    EnergyStarPropertyListDto,
    EnergyStarPropertyListDtoFromJSON,
    EnergyStarPropertyListDtoToJSON,
    EnergyStarPropertyModel,
    EnergyStarPropertyModelFromJSON,
    EnergyStarPropertyModelToJSON,
    EnergyStarPropertyUseModel,
    EnergyStarPropertyUseModelFromJSON,
    EnergyStarPropertyUseModelToJSON,
    EnergyStarPropertyUseTypes,
    EnergyStarPropertyUseTypesFromJSON,
    EnergyStarPropertyUseTypesToJSON,
    EnergyStarSaveScoreTotalsDto,
    EnergyStarSaveScoreTotalsDtoFromJSON,
    EnergyStarSaveScoreTotalsDtoToJSON,
    EnergyStarScoreDto,
    EnergyStarScoreDtoFromJSON,
    EnergyStarScoreDtoToJSON,
    EnergyStarScoresDto,
    EnergyStarScoresDtoFromJSON,
    EnergyStarScoresDtoToJSON,
    EnergyStarSuccessDto,
    EnergyStarSuccessDtoFromJSON,
    EnergyStarSuccessDtoToJSON,
    EnergyStarSynchedCustomerDto,
    EnergyStarSynchedCustomerDtoFromJSON,
    EnergyStarSynchedCustomerDtoToJSON,
    EnergyStarUnmappedAccount,
    EnergyStarUnmappedAccountFromJSON,
    EnergyStarUnmappedAccountToJSON,
    EnergyStarUnmappedCustomerDto,
    EnergyStarUnmappedCustomerDtoFromJSON,
    EnergyStarUnmappedCustomerDtoToJSON,
    ResetPasswordDto,
    ResetPasswordDtoFromJSON,
    ResetPasswordDtoToJSON,
    SaveScoreDto,
    SaveScoreDtoFromJSON,
    SaveScoreDtoToJSON,
    UpdateEnergyStarMeterDto,
    UpdateEnergyStarMeterDtoFromJSON,
    UpdateEnergyStarMeterDtoToJSON,
    UpdateEnergyStarPropertyDto,
    UpdateEnergyStarPropertyDtoFromJSON,
    UpdateEnergyStarPropertyDtoToJSON,
} from '../models';

export interface EnergyStarControllerAutoCreateMeterRequest {
    customerId: number;
    propertyId: number;
    energyStarLocationDto: EnergyStarLocationDto;
}

export interface EnergyStarControllerConfirmCustomerDetailsRequest {
    customerId: number;
    energyStarCustomerId: number;
    energyStarUnmappedCustomerDto: EnergyStarUnmappedCustomerDto;
}

export interface EnergyStarControllerCreateCustomerAccountRequest {
    customerId: number;
    createEnergyStarCustomerDto: CreateEnergyStarCustomerDto;
}

export interface EnergyStarControllerCreateMeterRequest {
    customerId: number;
    propertyId: number;
    createEnergyStarMeterDto: CreateEnergyStarMeterDto;
}

export interface EnergyStarControllerCreatePropertyRequest {
    customerId: number;
    createEnergyStarPropertyDto: CreateEnergyStarPropertyDto;
}

export interface EnergyStarControllerCreatePropertyUseRequest {
    customerId: number;
    propertyId: number;
    body: object;
}

export interface EnergyStarControllerCreatePropertyUseNewRequest {
    customerId: number;
    propertyId: number;
    eSPropertyUseDto: ESPropertyUseDto;
}

export interface EnergyStarControllerDeleteMeterRequest {
    customerId: number;
    meterId: number;
    propertyId: number;
}

export interface EnergyStarControllerDeletePropertyUseRequest {
    customerId: number;
    propertyId: number;
    propertyUseId: number;
}

export interface EnergyStarControllerGetAvailablePropertyUseDetailsRequest {
    customerId: number;
    propertyUseType: string;
}

export interface EnergyStarControllerGetAvailablePropertyUsesRequest {
    customerId: number;
}

export interface EnergyStarControllerGetCustomerAccountRequest {
    customerId: number;
}

export interface EnergyStarControllerGetCustomerDetailsByIdRequest {
    customerId: number;
    energyStarCustomerId: number;
}

export interface EnergyStarControllerGetCustomerPasswordRequest {
    customerId: number;
}

export interface EnergyStarControllerGetMeterRequest {
    customerId: number;
    meterId: number;
    propertyId: number;
}

export interface EnergyStarControllerGetMetersRequest {
    customerId: number;
    propertyId: number;
    locationId: number;
}

export interface EnergyStarControllerGetNoScoreReasonsRequest {
    customerId: number;
    propertyId: number;
    year: string;
    month: string;
}

export interface EnergyStarControllerGetPropertiesRequest {
    customerId: number;
}

export interface EnergyStarControllerGetPropertyRequest {
    customerId: number;
    propertyId: number;
}

export interface EnergyStarControllerGetPropertyListRequest {
    customerId: number;
    energyStarCustomerId: number;
}

export interface EnergyStarControllerGetPropertyMonthlyMetricsRequest {
    customerId: number;
    propertyId: number;
    year: string;
}

export interface EnergyStarControllerGetPropertyScoreRequest {
    customerId: number;
    propertyId: number;
}

export interface EnergyStarControllerGetPropertyUseRequest {
    customerId: number;
    propertyId: number;
    propertyUseId: number;
}

export interface EnergyStarControllerGetPropertyUseNewRequest {
    customerId: number;
    propertyId: number;
    propertyUseId: number;
}

export interface EnergyStarControllerGetPropertyUsesRequest {
    customerId: number;
    propertyId: number;
}

export interface EnergyStarControllerGetPropertyUsesNewRequest {
    customerId: number;
    propertyId: number;
}

export interface EnergyStarControllerGetUnmappedCustomersRequest {
    customerId: number;
}

export interface EnergyStarControllerMapLocationRequest {
    customerId: number;
    propertyId: number;
    energyStarLocationDto: EnergyStarLocationDto;
}

export interface EnergyStarControllerMapVirtualAccountToMeterRequest {
    customerId: number;
    propertyId: number;
    meterId: number;
    virtualAccountId: number;
}

export interface EnergyStarControllerSavePropertyScoresRequest {
    customerId: number;
    saveScoreDto: SaveScoreDto;
}

export interface EnergyStarControllerSendConsumptionDataRequest {
    customerId: number;
    locationId: number;
    dateIntervalDto: DateIntervalDto;
}

export interface EnergyStarControllerUnmapLocationRequest {
    customerId: number;
    propertyId: number;
    locationId: number;
}

export interface EnergyStarControllerUnmapVirtualAccountFromMeterRequest {
    meterId: number;
    virtualAccountId: number;
    propertyId: number;
    customerId: number;
}

export interface EnergyStarControllerUpdateCustomerAccountRequest {
    customerId: number;
    energyStarCustomerAccountDetailsDto: EnergyStarCustomerAccountDetailsDto;
}

export interface EnergyStarControllerUpdateMeterRequest {
    customerId: number;
    meterId: number;
    propertyId: number;
    updateEnergyStarMeterDto: UpdateEnergyStarMeterDto;
}

export interface EnergyStarControllerUpdatePropertyRequest {
    customerId: number;
    propertyId: number;
    updateEnergyStarPropertyDto: UpdateEnergyStarPropertyDto;
}

export interface EnergyStarControllerUpdatePropertyUseRequest {
    customerId: number;
    propertyId: number;
    propertyUseId: number;
    body: object;
}

export interface EnergyStarControllerUpdatePropertyUseNewRequest {
    customerId: number;
    propertyId: number;
    propertyUseId: number;
    eSPropertyUseDto: ESPropertyUseDto;
}

/**
 * 
 */
export class EnergyStarApi extends runtime.BaseAPI {

    /**
     * Automatically create meters for an Energy Star property
     */
    async energyStarControllerAutoCreateMeterRaw(requestParameters: EnergyStarControllerAutoCreateMeterRequest): Promise<runtime.ApiResponse<EnergyStarMetersDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerAutoCreateMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerAutoCreateMeter.');
        }

        if (requestParameters.energyStarLocationDto === null || requestParameters.energyStarLocationDto === undefined) {
            throw new runtime.RequiredError('energyStarLocationDto','Required parameter requestParameters.energyStarLocationDto was null or undefined when calling energyStarControllerAutoCreateMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/auto-meters`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnergyStarLocationDtoToJSON(requestParameters.energyStarLocationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarMetersDtoFromJSON(jsonValue));
    }

    /**
     * Automatically create meters for an Energy Star property
     */
    async energyStarControllerAutoCreateMeter(requestParameters: EnergyStarControllerAutoCreateMeterRequest): Promise<EnergyStarMetersDto> {
        const response = await this.energyStarControllerAutoCreateMeterRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async energyStarControllerConfirmCustomerDetailsRaw(requestParameters: EnergyStarControllerConfirmCustomerDetailsRequest): Promise<runtime.ApiResponse<EnergyStarSynchedCustomerDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerConfirmCustomerDetails.');
        }

        if (requestParameters.energyStarCustomerId === null || requestParameters.energyStarCustomerId === undefined) {
            throw new runtime.RequiredError('energyStarCustomerId','Required parameter requestParameters.energyStarCustomerId was null or undefined when calling energyStarControllerConfirmCustomerDetails.');
        }

        if (requestParameters.energyStarUnmappedCustomerDto === null || requestParameters.energyStarUnmappedCustomerDto === undefined) {
            throw new runtime.RequiredError('energyStarUnmappedCustomerDto','Required parameter requestParameters.energyStarUnmappedCustomerDto was null or undefined when calling energyStarControllerConfirmCustomerDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/confirm-customer/{energyStarCustomerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"energyStarCustomerId"}}`, encodeURIComponent(String(requestParameters.energyStarCustomerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnergyStarUnmappedCustomerDtoToJSON(requestParameters.energyStarUnmappedCustomerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarSynchedCustomerDtoFromJSON(jsonValue));
    }

    /**
     */
    async energyStarControllerConfirmCustomerDetails(requestParameters: EnergyStarControllerConfirmCustomerDetailsRequest): Promise<EnergyStarSynchedCustomerDto> {
        const response = await this.energyStarControllerConfirmCustomerDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create Energy star customer account
     */
    async energyStarControllerCreateCustomerAccountRaw(requestParameters: EnergyStarControllerCreateCustomerAccountRequest): Promise<runtime.ApiResponse<EnergyStarCustomerAccountDetailsDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerCreateCustomerAccount.');
        }

        if (requestParameters.createEnergyStarCustomerDto === null || requestParameters.createEnergyStarCustomerDto === undefined) {
            throw new runtime.RequiredError('createEnergyStarCustomerDto','Required parameter requestParameters.createEnergyStarCustomerDto was null or undefined when calling energyStarControllerCreateCustomerAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEnergyStarCustomerDtoToJSON(requestParameters.createEnergyStarCustomerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarCustomerAccountDetailsDtoFromJSON(jsonValue));
    }

    /**
     * Create Energy star customer account
     */
    async energyStarControllerCreateCustomerAccount(requestParameters: EnergyStarControllerCreateCustomerAccountRequest): Promise<EnergyStarCustomerAccountDetailsDto> {
        const response = await this.energyStarControllerCreateCustomerAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send meters data for a given customer and property
     */
    async energyStarControllerCreateMeterRaw(requestParameters: EnergyStarControllerCreateMeterRequest): Promise<runtime.ApiResponse<EnergyStarMeterDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerCreateMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerCreateMeter.');
        }

        if (requestParameters.createEnergyStarMeterDto === null || requestParameters.createEnergyStarMeterDto === undefined) {
            throw new runtime.RequiredError('createEnergyStarMeterDto','Required parameter requestParameters.createEnergyStarMeterDto was null or undefined when calling energyStarControllerCreateMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEnergyStarMeterDtoToJSON(requestParameters.createEnergyStarMeterDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarMeterDtoFromJSON(jsonValue));
    }

    /**
     * Send meters data for a given customer and property
     */
    async energyStarControllerCreateMeter(requestParameters: EnergyStarControllerCreateMeterRequest): Promise<EnergyStarMeterDto> {
        const response = await this.energyStarControllerCreateMeterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new Energy Star property
     */
    async energyStarControllerCreatePropertyRaw(requestParameters: EnergyStarControllerCreatePropertyRequest): Promise<runtime.ApiResponse<EnergyStarPropertyModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerCreateProperty.');
        }

        if (requestParameters.createEnergyStarPropertyDto === null || requestParameters.createEnergyStarPropertyDto === undefined) {
            throw new runtime.RequiredError('createEnergyStarPropertyDto','Required parameter requestParameters.createEnergyStarPropertyDto was null or undefined when calling energyStarControllerCreateProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEnergyStarPropertyDtoToJSON(requestParameters.createEnergyStarPropertyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarPropertyModelFromJSON(jsonValue));
    }

    /**
     * Create a new Energy Star property
     */
    async energyStarControllerCreateProperty(requestParameters: EnergyStarControllerCreatePropertyRequest): Promise<EnergyStarPropertyModel> {
        const response = await this.energyStarControllerCreatePropertyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a new property use for a given property
     */
    async energyStarControllerCreatePropertyUseRaw(requestParameters: EnergyStarControllerCreatePropertyUseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerCreatePropertyUse.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerCreatePropertyUse.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling energyStarControllerCreatePropertyUse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new property use for a given property
     */
    async energyStarControllerCreatePropertyUse(requestParameters: EnergyStarControllerCreatePropertyUseRequest): Promise<void> {
        await this.energyStarControllerCreatePropertyUseRaw(requestParameters);
    }

    /**
     * Create a new property use for a given property
     */
    async energyStarControllerCreatePropertyUseNewRaw(requestParameters: EnergyStarControllerCreatePropertyUseNewRequest): Promise<runtime.ApiResponse<ESPropertyUseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerCreatePropertyUseNew.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerCreatePropertyUseNew.');
        }

        if (requestParameters.eSPropertyUseDto === null || requestParameters.eSPropertyUseDto === undefined) {
            throw new runtime.RequiredError('eSPropertyUseDto','Required parameter requestParameters.eSPropertyUseDto was null or undefined when calling energyStarControllerCreatePropertyUseNew.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses-new`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ESPropertyUseDtoToJSON(requestParameters.eSPropertyUseDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ESPropertyUseDtoFromJSON(jsonValue));
    }

    /**
     * Create a new property use for a given property
     */
    async energyStarControllerCreatePropertyUseNew(requestParameters: EnergyStarControllerCreatePropertyUseNewRequest): Promise<ESPropertyUseDto> {
        const response = await this.energyStarControllerCreatePropertyUseNewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes meter data for a given customer and property
     */
    async energyStarControllerDeleteMeterRaw(requestParameters: EnergyStarControllerDeleteMeterRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerDeleteMeter.');
        }

        if (requestParameters.meterId === null || requestParameters.meterId === undefined) {
            throw new runtime.RequiredError('meterId','Required parameter requestParameters.meterId was null or undefined when calling energyStarControllerDeleteMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerDeleteMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters/{meterId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meterId"}}`, encodeURIComponent(String(requestParameters.meterId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes meter data for a given customer and property
     */
    async energyStarControllerDeleteMeter(requestParameters: EnergyStarControllerDeleteMeterRequest): Promise<void> {
        await this.energyStarControllerDeleteMeterRaw(requestParameters);
    }

    /**
     * Removes a specific property use, for a given property
     */
    async energyStarControllerDeletePropertyUseRaw(requestParameters: EnergyStarControllerDeletePropertyUseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerDeletePropertyUse.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerDeletePropertyUse.');
        }

        if (requestParameters.propertyUseId === null || requestParameters.propertyUseId === undefined) {
            throw new runtime.RequiredError('propertyUseId','Required parameter requestParameters.propertyUseId was null or undefined when calling energyStarControllerDeletePropertyUse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses/{propertyUseId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"propertyUseId"}}`, encodeURIComponent(String(requestParameters.propertyUseId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a specific property use, for a given property
     */
    async energyStarControllerDeletePropertyUse(requestParameters: EnergyStarControllerDeletePropertyUseRequest): Promise<void> {
        await this.energyStarControllerDeletePropertyUseRaw(requestParameters);
    }

    /**
     * Get the property use details by property use type
     */
    async energyStarControllerGetAvailablePropertyUseDetailsRaw(requestParameters: EnergyStarControllerGetAvailablePropertyUseDetailsRequest): Promise<runtime.ApiResponse<EnergyStarPropertyUseModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetAvailablePropertyUseDetails.');
        }

        if (requestParameters.propertyUseType === null || requestParameters.propertyUseType === undefined) {
            throw new runtime.RequiredError('propertyUseType','Required parameter requestParameters.propertyUseType was null or undefined when calling energyStarControllerGetAvailablePropertyUseDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/property-uses-details/{propertyUseType}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyUseType"}}`, encodeURIComponent(String(requestParameters.propertyUseType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarPropertyUseModelFromJSON(jsonValue));
    }

    /**
     * Get the property use details by property use type
     */
    async energyStarControllerGetAvailablePropertyUseDetails(requestParameters: EnergyStarControllerGetAvailablePropertyUseDetailsRequest): Promise<EnergyStarPropertyUseModel> {
        const response = await this.energyStarControllerGetAvailablePropertyUseDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of Property Use Types
     */
    async energyStarControllerGetAvailablePropertyUsesRaw(requestParameters: EnergyStarControllerGetAvailablePropertyUsesRequest): Promise<runtime.ApiResponse<Array<EnergyStarPropertyUseTypes>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetAvailablePropertyUses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/available-property-uses`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergyStarPropertyUseTypesFromJSON));
    }

    /**
     * Returns the list of Property Use Types
     */
    async energyStarControllerGetAvailablePropertyUses(requestParameters: EnergyStarControllerGetAvailablePropertyUsesRequest): Promise<Array<EnergyStarPropertyUseTypes>> {
        const response = await this.energyStarControllerGetAvailablePropertyUsesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Energy star customer account
     */
    async energyStarControllerGetCustomerAccountRaw(requestParameters: EnergyStarControllerGetCustomerAccountRequest): Promise<runtime.ApiResponse<EnergyStarCustomerAccountDetailsDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetCustomerAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarCustomerAccountDetailsDtoFromJSON(jsonValue));
    }

    /**
     * Get Energy star customer account
     */
    async energyStarControllerGetCustomerAccount(requestParameters: EnergyStarControllerGetCustomerAccountRequest): Promise<EnergyStarCustomerAccountDetailsDto> {
        const response = await this.energyStarControllerGetCustomerAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async energyStarControllerGetCustomerDetailsByIdRaw(requestParameters: EnergyStarControllerGetCustomerDetailsByIdRequest): Promise<runtime.ApiResponse<EnergyStarUnmappedCustomerDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetCustomerDetailsById.');
        }

        if (requestParameters.energyStarCustomerId === null || requestParameters.energyStarCustomerId === undefined) {
            throw new runtime.RequiredError('energyStarCustomerId','Required parameter requestParameters.energyStarCustomerId was null or undefined when calling energyStarControllerGetCustomerDetailsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/customer-details/{energyStarCustomerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"energyStarCustomerId"}}`, encodeURIComponent(String(requestParameters.energyStarCustomerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarUnmappedCustomerDtoFromJSON(jsonValue));
    }

    /**
     */
    async energyStarControllerGetCustomerDetailsById(requestParameters: EnergyStarControllerGetCustomerDetailsByIdRequest): Promise<EnergyStarUnmappedCustomerDto> {
        const response = await this.energyStarControllerGetCustomerDetailsByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the ES password for an account
     */
    async energyStarControllerGetCustomerPasswordRaw(requestParameters: EnergyStarControllerGetCustomerPasswordRequest): Promise<runtime.ApiResponse<ResetPasswordDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetCustomerPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/password`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordDtoFromJSON(jsonValue));
    }

    /**
     * Returns the ES password for an account
     */
    async energyStarControllerGetCustomerPassword(requestParameters: EnergyStarControllerGetCustomerPasswordRequest): Promise<ResetPasswordDto> {
        const response = await this.energyStarControllerGetCustomerPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get meter data for a given customer and property
     */
    async energyStarControllerGetMeterRaw(requestParameters: EnergyStarControllerGetMeterRequest): Promise<runtime.ApiResponse<EnergyStarMeterDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetMeter.');
        }

        if (requestParameters.meterId === null || requestParameters.meterId === undefined) {
            throw new runtime.RequiredError('meterId','Required parameter requestParameters.meterId was null or undefined when calling energyStarControllerGetMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters/{meterId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meterId"}}`, encodeURIComponent(String(requestParameters.meterId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarMeterDtoFromJSON(jsonValue));
    }

    /**
     * Get meter data for a given customer and property
     */
    async energyStarControllerGetMeter(requestParameters: EnergyStarControllerGetMeterRequest): Promise<EnergyStarMeterDto> {
        const response = await this.energyStarControllerGetMeterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get meters data for a given customer and property
     */
    async energyStarControllerGetMetersRaw(requestParameters: EnergyStarControllerGetMetersRequest): Promise<runtime.ApiResponse<EnergyStarMetersDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetMeters.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetMeters.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling energyStarControllerGetMeters.');
        }

        const queryParameters: any = {};

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarMetersDtoFromJSON(jsonValue));
    }

    /**
     * Get meters data for a given customer and property
     */
    async energyStarControllerGetMeters(requestParameters: EnergyStarControllerGetMetersRequest): Promise<EnergyStarMetersDto> {
        const response = await this.energyStarControllerGetMetersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of alert information that explains why a specific property cannot receive an ENERGY STAR score for a specific period ending date.
     */
    async energyStarControllerGetNoScoreReasonsRaw(requestParameters: EnergyStarControllerGetNoScoreReasonsRequest): Promise<runtime.ApiResponse<Array<EnergyStarNoScoreReasonsDto>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetNoScoreReasons.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetNoScoreReasons.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling energyStarControllerGetNoScoreReasons.');
        }

        if (requestParameters.month === null || requestParameters.month === undefined) {
            throw new runtime.RequiredError('month','Required parameter requestParameters.month was null or undefined when calling energyStarControllerGetNoScoreReasons.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        if (requestParameters.month !== undefined) {
            queryParameters['month'] = requestParameters.month;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/noScoreReasons`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergyStarNoScoreReasonsDtoFromJSON));
    }

    /**
     * Returns a list of alert information that explains why a specific property cannot receive an ENERGY STAR score for a specific period ending date.
     */
    async energyStarControllerGetNoScoreReasons(requestParameters: EnergyStarControllerGetNoScoreReasonsRequest): Promise<Array<EnergyStarNoScoreReasonsDto>> {
        const response = await this.energyStarControllerGetNoScoreReasonsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async energyStarControllerGetPropertiesRaw(requestParameters: EnergyStarControllerGetPropertiesRequest): Promise<runtime.ApiResponse<Array<EnergyStarDatabaseProperty>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetProperties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergyStarDatabasePropertyFromJSON));
    }

    /**
     */
    async energyStarControllerGetProperties(requestParameters: EnergyStarControllerGetPropertiesRequest): Promise<Array<EnergyStarDatabaseProperty>> {
        const response = await this.energyStarControllerGetPropertiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get Energy Star property details
     */
    async energyStarControllerGetPropertyRaw(requestParameters: EnergyStarControllerGetPropertyRequest): Promise<runtime.ApiResponse<EnergyStarPropertyModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetProperty.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarPropertyModelFromJSON(jsonValue));
    }

    /**
     * Get Energy Star property details
     */
    async energyStarControllerGetProperty(requestParameters: EnergyStarControllerGetPropertyRequest): Promise<EnergyStarPropertyModel> {
        const response = await this.energyStarControllerGetPropertyRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async energyStarControllerGetPropertyListRaw(requestParameters: EnergyStarControllerGetPropertyListRequest): Promise<runtime.ApiResponse<EnergyStarPropertyListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyList.');
        }

        if (requestParameters.energyStarCustomerId === null || requestParameters.energyStarCustomerId === undefined) {
            throw new runtime.RequiredError('energyStarCustomerId','Required parameter requestParameters.energyStarCustomerId was null or undefined when calling energyStarControllerGetPropertyList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/property-list/{energyStarCustomerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"energyStarCustomerId"}}`, encodeURIComponent(String(requestParameters.energyStarCustomerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarPropertyListDtoFromJSON(jsonValue));
    }

    /**
     */
    async energyStarControllerGetPropertyList(requestParameters: EnergyStarControllerGetPropertyListRequest): Promise<EnergyStarPropertyListDto> {
        const response = await this.energyStarControllerGetPropertyListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of monthly scores for a specific property and a given year
     */
    async energyStarControllerGetPropertyMonthlyMetricsRaw(requestParameters: EnergyStarControllerGetPropertyMonthlyMetricsRequest): Promise<runtime.ApiResponse<Array<EnergyStarScoreDto>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyMonthlyMetrics.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetPropertyMonthlyMetrics.');
        }

        if (requestParameters.year === null || requestParameters.year === undefined) {
            throw new runtime.RequiredError('year','Required parameter requestParameters.year was null or undefined when calling energyStarControllerGetPropertyMonthlyMetrics.');
        }

        const queryParameters: any = {};

        if (requestParameters.year !== undefined) {
            queryParameters['year'] = requestParameters.year;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/monthlyScores`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergyStarScoreDtoFromJSON));
    }

    /**
     * Returns a list of monthly scores for a specific property and a given year
     */
    async energyStarControllerGetPropertyMonthlyMetrics(requestParameters: EnergyStarControllerGetPropertyMonthlyMetricsRequest): Promise<Array<EnergyStarScoreDto>> {
        const response = await this.energyStarControllerGetPropertyMonthlyMetricsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the current score and the baseline score for a specific property
     */
    async energyStarControllerGetPropertyScoreRaw(requestParameters: EnergyStarControllerGetPropertyScoreRequest): Promise<runtime.ApiResponse<EnergyStarScoresDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyScore.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetPropertyScore.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/scores`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarScoresDtoFromJSON(jsonValue));
    }

    /**
     * Returns the current score and the baseline score for a specific property
     */
    async energyStarControllerGetPropertyScore(requestParameters: EnergyStarControllerGetPropertyScoreRequest): Promise<EnergyStarScoresDto> {
        const response = await this.energyStarControllerGetPropertyScoreRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get details about a specific property use, for a given property
     */
    async energyStarControllerGetPropertyUseRaw(requestParameters: EnergyStarControllerGetPropertyUseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyUse.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetPropertyUse.');
        }

        if (requestParameters.propertyUseId === null || requestParameters.propertyUseId === undefined) {
            throw new runtime.RequiredError('propertyUseId','Required parameter requestParameters.propertyUseId was null or undefined when calling energyStarControllerGetPropertyUse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses/{propertyUseId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"propertyUseId"}}`, encodeURIComponent(String(requestParameters.propertyUseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get details about a specific property use, for a given property
     */
    async energyStarControllerGetPropertyUse(requestParameters: EnergyStarControllerGetPropertyUseRequest): Promise<void> {
        await this.energyStarControllerGetPropertyUseRaw(requestParameters);
    }

    /**
     * Get details about a specific property use, for a given property
     */
    async energyStarControllerGetPropertyUseNewRaw(requestParameters: EnergyStarControllerGetPropertyUseNewRequest): Promise<runtime.ApiResponse<ESPropertyUseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyUseNew.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetPropertyUseNew.');
        }

        if (requestParameters.propertyUseId === null || requestParameters.propertyUseId === undefined) {
            throw new runtime.RequiredError('propertyUseId','Required parameter requestParameters.propertyUseId was null or undefined when calling energyStarControllerGetPropertyUseNew.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses-new/{propertyUseId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"propertyUseId"}}`, encodeURIComponent(String(requestParameters.propertyUseId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ESPropertyUseDtoFromJSON(jsonValue));
    }

    /**
     * Get details about a specific property use, for a given property
     */
    async energyStarControllerGetPropertyUseNew(requestParameters: EnergyStarControllerGetPropertyUseNewRequest): Promise<ESPropertyUseDto> {
        const response = await this.energyStarControllerGetPropertyUseNewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of property uses for a given property
     */
    async energyStarControllerGetPropertyUsesRaw(requestParameters: EnergyStarControllerGetPropertyUsesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyUses.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetPropertyUses.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get the list of property uses for a given property
     */
    async energyStarControllerGetPropertyUses(requestParameters: EnergyStarControllerGetPropertyUsesRequest): Promise<void> {
        await this.energyStarControllerGetPropertyUsesRaw(requestParameters);
    }

    /**
     * Get the list of property uses for a given property
     */
    async energyStarControllerGetPropertyUsesNewRaw(requestParameters: EnergyStarControllerGetPropertyUsesNewRequest): Promise<runtime.ApiResponse<Array<ESPropertyUseDto>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetPropertyUsesNew.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerGetPropertyUsesNew.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses-new`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ESPropertyUseDtoFromJSON));
    }

    /**
     * Get the list of property uses for a given property
     */
    async energyStarControllerGetPropertyUsesNew(requestParameters: EnergyStarControllerGetPropertyUsesNewRequest): Promise<Array<ESPropertyUseDto>> {
        const response = await this.energyStarControllerGetPropertyUsesNewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of unmapped Energy Star Accounts
     */
    async energyStarControllerGetUnmappedCustomersRaw(requestParameters: EnergyStarControllerGetUnmappedCustomersRequest): Promise<runtime.ApiResponse<Array<EnergyStarUnmappedAccount>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerGetUnmappedCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/unmapped-customers`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EnergyStarUnmappedAccountFromJSON));
    }

    /**
     * Returns the list of unmapped Energy Star Accounts
     */
    async energyStarControllerGetUnmappedCustomers(requestParameters: EnergyStarControllerGetUnmappedCustomersRequest): Promise<Array<EnergyStarUnmappedAccount>> {
        const response = await this.energyStarControllerGetUnmappedCustomersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Map an Energy Star property to a location
     */
    async energyStarControllerMapLocationRaw(requestParameters: EnergyStarControllerMapLocationRequest): Promise<runtime.ApiResponse<EnergyStarPropertyModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerMapLocation.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerMapLocation.');
        }

        if (requestParameters.energyStarLocationDto === null || requestParameters.energyStarLocationDto === undefined) {
            throw new runtime.RequiredError('energyStarLocationDto','Required parameter requestParameters.energyStarLocationDto was null or undefined when calling energyStarControllerMapLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/map`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnergyStarLocationDtoToJSON(requestParameters.energyStarLocationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarPropertyModelFromJSON(jsonValue));
    }

    /**
     * Map an Energy Star property to a location
     */
    async energyStarControllerMapLocation(requestParameters: EnergyStarControllerMapLocationRequest): Promise<EnergyStarPropertyModel> {
        const response = await this.energyStarControllerMapLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Map meter data for a given customer and property to a virtual account.
     */
    async energyStarControllerMapVirtualAccountToMeterRaw(requestParameters: EnergyStarControllerMapVirtualAccountToMeterRequest): Promise<runtime.ApiResponse<EnergyStarSuccessDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerMapVirtualAccountToMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerMapVirtualAccountToMeter.');
        }

        if (requestParameters.meterId === null || requestParameters.meterId === undefined) {
            throw new runtime.RequiredError('meterId','Required parameter requestParameters.meterId was null or undefined when calling energyStarControllerMapVirtualAccountToMeter.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling energyStarControllerMapVirtualAccountToMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters/{meterId}/virtual-accounts/{virtualAccountId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"meterId"}}`, encodeURIComponent(String(requestParameters.meterId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarSuccessDtoFromJSON(jsonValue));
    }

    /**
     * Map meter data for a given customer and property to a virtual account.
     */
    async energyStarControllerMapVirtualAccountToMeter(requestParameters: EnergyStarControllerMapVirtualAccountToMeterRequest): Promise<EnergyStarSuccessDto> {
        const response = await this.energyStarControllerMapVirtualAccountToMeterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Save the scores for all of the customer\'s EnergyStar properties to the DB
     */
    async energyStarControllerSavePropertyScoresRaw(requestParameters: EnergyStarControllerSavePropertyScoresRequest): Promise<runtime.ApiResponse<EnergyStarSaveScoreTotalsDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerSavePropertyScores.');
        }

        if (requestParameters.saveScoreDto === null || requestParameters.saveScoreDto === undefined) {
            throw new runtime.RequiredError('saveScoreDto','Required parameter requestParameters.saveScoreDto was null or undefined when calling energyStarControllerSavePropertyScores.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/save-scores`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SaveScoreDtoToJSON(requestParameters.saveScoreDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarSaveScoreTotalsDtoFromJSON(jsonValue));
    }

    /**
     * Save the scores for all of the customer\'s EnergyStar properties to the DB
     */
    async energyStarControllerSavePropertyScores(requestParameters: EnergyStarControllerSavePropertyScoresRequest): Promise<EnergyStarSaveScoreTotalsDto> {
        const response = await this.energyStarControllerSavePropertyScoresRaw(requestParameters);
        return await response.value();
    }

    /**
     * Send the consumption data for a customers given data interval 
     */
    async energyStarControllerSendConsumptionDataRaw(requestParameters: EnergyStarControllerSendConsumptionDataRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerSendConsumptionData.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling energyStarControllerSendConsumptionData.');
        }

        if (requestParameters.dateIntervalDto === null || requestParameters.dateIntervalDto === undefined) {
            throw new runtime.RequiredError('dateIntervalDto','Required parameter requestParameters.dateIntervalDto was null or undefined when calling energyStarControllerSendConsumptionData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/locations/{locationId}/send-consumption-data`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DateIntervalDtoToJSON(requestParameters.dateIntervalDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send the consumption data for a customers given data interval 
     */
    async energyStarControllerSendConsumptionData(requestParameters: EnergyStarControllerSendConsumptionDataRequest): Promise<void> {
        await this.energyStarControllerSendConsumptionDataRaw(requestParameters);
    }

    /**
     * Unmap a location from an Energy Star property
     */
    async energyStarControllerUnmapLocationRaw(requestParameters: EnergyStarControllerUnmapLocationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUnmapLocation.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerUnmapLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling energyStarControllerUnmapLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/map/{locationId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unmap a location from an Energy Star property
     */
    async energyStarControllerUnmapLocation(requestParameters: EnergyStarControllerUnmapLocationRequest): Promise<void> {
        await this.energyStarControllerUnmapLocationRaw(requestParameters);
    }

    /**
     * Unmap meter data for a given customer and property from a virtual account.
     */
    async energyStarControllerUnmapVirtualAccountFromMeterRaw(requestParameters: EnergyStarControllerUnmapVirtualAccountFromMeterRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.meterId === null || requestParameters.meterId === undefined) {
            throw new runtime.RequiredError('meterId','Required parameter requestParameters.meterId was null or undefined when calling energyStarControllerUnmapVirtualAccountFromMeter.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling energyStarControllerUnmapVirtualAccountFromMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerUnmapVirtualAccountFromMeter.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUnmapVirtualAccountFromMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters/{meterId}/virtual-accounts/{virtualAccountId}`.replace(`{${"meterId"}}`, encodeURIComponent(String(requestParameters.meterId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unmap meter data for a given customer and property from a virtual account.
     */
    async energyStarControllerUnmapVirtualAccountFromMeter(requestParameters: EnergyStarControllerUnmapVirtualAccountFromMeterRequest): Promise<void> {
        await this.energyStarControllerUnmapVirtualAccountFromMeterRaw(requestParameters);
    }

    /**
     * Update Energy star customer account
     */
    async energyStarControllerUpdateCustomerAccountRaw(requestParameters: EnergyStarControllerUpdateCustomerAccountRequest): Promise<runtime.ApiResponse<EnergyStarCustomerAccountDetailsDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUpdateCustomerAccount.');
        }

        if (requestParameters.energyStarCustomerAccountDetailsDto === null || requestParameters.energyStarCustomerAccountDetailsDto === undefined) {
            throw new runtime.RequiredError('energyStarCustomerAccountDetailsDto','Required parameter requestParameters.energyStarCustomerAccountDetailsDto was null or undefined when calling energyStarControllerUpdateCustomerAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/energy-star-customer`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EnergyStarCustomerAccountDetailsDtoToJSON(requestParameters.energyStarCustomerAccountDetailsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarCustomerAccountDetailsDtoFromJSON(jsonValue));
    }

    /**
     * Update Energy star customer account
     */
    async energyStarControllerUpdateCustomerAccount(requestParameters: EnergyStarControllerUpdateCustomerAccountRequest): Promise<EnergyStarCustomerAccountDetailsDto> {
        const response = await this.energyStarControllerUpdateCustomerAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates meters data for a given customer and property
     */
    async energyStarControllerUpdateMeterRaw(requestParameters: EnergyStarControllerUpdateMeterRequest): Promise<runtime.ApiResponse<EnergyStarMeterDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUpdateMeter.');
        }

        if (requestParameters.meterId === null || requestParameters.meterId === undefined) {
            throw new runtime.RequiredError('meterId','Required parameter requestParameters.meterId was null or undefined when calling energyStarControllerUpdateMeter.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerUpdateMeter.');
        }

        if (requestParameters.updateEnergyStarMeterDto === null || requestParameters.updateEnergyStarMeterDto === undefined) {
            throw new runtime.RequiredError('updateEnergyStarMeterDto','Required parameter requestParameters.updateEnergyStarMeterDto was null or undefined when calling energyStarControllerUpdateMeter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/meters/{meterId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"meterId"}}`, encodeURIComponent(String(requestParameters.meterId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEnergyStarMeterDtoToJSON(requestParameters.updateEnergyStarMeterDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarMeterDtoFromJSON(jsonValue));
    }

    /**
     * Updates meters data for a given customer and property
     */
    async energyStarControllerUpdateMeter(requestParameters: EnergyStarControllerUpdateMeterRequest): Promise<EnergyStarMeterDto> {
        const response = await this.energyStarControllerUpdateMeterRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an existing ES property
     */
    async energyStarControllerUpdatePropertyRaw(requestParameters: EnergyStarControllerUpdatePropertyRequest): Promise<runtime.ApiResponse<EnergyStarPropertyModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUpdateProperty.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerUpdateProperty.');
        }

        if (requestParameters.updateEnergyStarPropertyDto === null || requestParameters.updateEnergyStarPropertyDto === undefined) {
            throw new runtime.RequiredError('updateEnergyStarPropertyDto','Required parameter requestParameters.updateEnergyStarPropertyDto was null or undefined when calling energyStarControllerUpdateProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEnergyStarPropertyDtoToJSON(requestParameters.updateEnergyStarPropertyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EnergyStarPropertyModelFromJSON(jsonValue));
    }

    /**
     * Updates an existing ES property
     */
    async energyStarControllerUpdateProperty(requestParameters: EnergyStarControllerUpdatePropertyRequest): Promise<EnergyStarPropertyModel> {
        const response = await this.energyStarControllerUpdatePropertyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an existing property use for a given property
     */
    async energyStarControllerUpdatePropertyUseRaw(requestParameters: EnergyStarControllerUpdatePropertyUseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUpdatePropertyUse.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerUpdatePropertyUse.');
        }

        if (requestParameters.propertyUseId === null || requestParameters.propertyUseId === undefined) {
            throw new runtime.RequiredError('propertyUseId','Required parameter requestParameters.propertyUseId was null or undefined when calling energyStarControllerUpdatePropertyUse.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling energyStarControllerUpdatePropertyUse.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses/{propertyUseId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"propertyUseId"}}`, encodeURIComponent(String(requestParameters.propertyUseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates an existing property use for a given property
     */
    async energyStarControllerUpdatePropertyUse(requestParameters: EnergyStarControllerUpdatePropertyUseRequest): Promise<void> {
        await this.energyStarControllerUpdatePropertyUseRaw(requestParameters);
    }

    /**
     * Update a property use for a given property
     */
    async energyStarControllerUpdatePropertyUseNewRaw(requestParameters: EnergyStarControllerUpdatePropertyUseNewRequest): Promise<runtime.ApiResponse<ESPropertyUseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling energyStarControllerUpdatePropertyUseNew.');
        }

        if (requestParameters.propertyId === null || requestParameters.propertyId === undefined) {
            throw new runtime.RequiredError('propertyId','Required parameter requestParameters.propertyId was null or undefined when calling energyStarControllerUpdatePropertyUseNew.');
        }

        if (requestParameters.propertyUseId === null || requestParameters.propertyUseId === undefined) {
            throw new runtime.RequiredError('propertyUseId','Required parameter requestParameters.propertyUseId was null or undefined when calling energyStarControllerUpdatePropertyUseNew.');
        }

        if (requestParameters.eSPropertyUseDto === null || requestParameters.eSPropertyUseDto === undefined) {
            throw new runtime.RequiredError('eSPropertyUseDto','Required parameter requestParameters.eSPropertyUseDto was null or undefined when calling energyStarControllerUpdatePropertyUseNew.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/energy-star/properties/{propertyId}/uses-new/{propertyUseId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"propertyId"}}`, encodeURIComponent(String(requestParameters.propertyId))).replace(`{${"propertyUseId"}}`, encodeURIComponent(String(requestParameters.propertyUseId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ESPropertyUseDtoToJSON(requestParameters.eSPropertyUseDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ESPropertyUseDtoFromJSON(jsonValue));
    }

    /**
     * Update a property use for a given property
     */
    async energyStarControllerUpdatePropertyUseNew(requestParameters: EnergyStarControllerUpdatePropertyUseNewRequest): Promise<ESPropertyUseDto> {
        const response = await this.energyStarControllerUpdatePropertyUseNewRaw(requestParameters);
        return await response.value();
    }

}
