
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Vue2Parcel',
  props: ['vue2Data', 'parcelName'],
  components: {},
  mounted() {
    this.loadParcel()
  },
  methods: {
    async loadParcel() {
      const domElement: HTMLElement = document.getElementById(
        'single-spa-parcel',
      ) as HTMLElement
      this.vue2Data.mountParcel(() => this.$System.import(this.parcelName), {
        props: this.vue2Data,
        domElement,
      })
    },
  },
})
