/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TestSftpConnectionResponseDto
 */
export interface TestSftpConnectionResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof TestSftpConnectionResponseDto
     */
    isValid: boolean;
}

export function TestSftpConnectionResponseDtoFromJSON(json: any): TestSftpConnectionResponseDto {
    return TestSftpConnectionResponseDtoFromJSONTyped(json, false);
}

export function TestSftpConnectionResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestSftpConnectionResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isValid': json['isValid'],
    };
}

export function TestSftpConnectionResponseDtoToJSON(value?: TestSftpConnectionResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isValid': value.isValid,
    };
}


