import {
  CustomerVendorsApi,
  CustomerVendorsControllerCreateVendorByCustomerIdRequest,
  ProviderMetadataAttribute,
  CustomerVendorsControllerGetVendorCustomAttributesListRequest,
  CustomerVendorsControllerDeleteMetadataAttributeRequest,
  ApiResponse,
  CustomerVendorsControllerUpdateMetadataAttributeRequest,
  CustomerVendorsControllerSortMetadataAttributesRequest,
} from '@/SharedModule/Api'
import { CustomAttributesValuesList } from '@/SharedModule/Api/models/CustomAttributesValuesList'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomerVendorService extends ApiService {
  private customerVendorsApi = new CustomerVendorsApi(this.getBaseOptions())

  public getVendorCustomAttributeList = async (
    request: CustomerVendorsControllerGetVendorCustomAttributesListRequest,
  ): Promise<CustomAttributesValuesList> => {
    return await this.customerVendorsApi.customerVendorsControllerGetVendorCustomAttributesList(
      request,
    )
  }

  public createCustomerCustomAttribute = async (
    request: CustomerVendorsControllerCreateVendorByCustomerIdRequest,
  ): Promise<ProviderMetadataAttribute> => {
    return await this.customerVendorsApi.customerVendorsControllerCreateVendorByCustomerId(
      request,
    )
  }

  public updateCustomerVendorCustomAttribute = async (
    request: CustomerVendorsControllerUpdateMetadataAttributeRequest,
  ): Promise<ApiResponse<ProviderMetadataAttribute>> => {
    return await this.customerVendorsApi.customerVendorsControllerUpdateMetadataAttributeRaw(
      request,
    )
  }

  public deleteCustomerVendorCustomAttribute = async (
    request: CustomerVendorsControllerDeleteMetadataAttributeRequest,
  ): Promise<ApiResponse<void>> => {
    return await this.customerVendorsApi.customerVendorsControllerDeleteMetadataAttributeRaw(
      request,
    )
  }
  public sortVendorCustomAttributes = async (request :CustomerVendorsControllerSortMetadataAttributesRequest) : Promise<ApiResponse<void>> => {
    return await this.customerVendorsApi.customerVendorsControllerSortMetadataAttributesRaw(
      request,
    )
  }
}
