import { CustomerPermissionsService } from '@/Modules/Customers/services/service.customer-permissions'
import { isNil } from 'lodash'
import { ElMessage } from 'element-plus'
import { handleEditUserRoles } from '@/SharedModule/Helpers/helper.user-role-selection'
import {
  CreateUserDtoUserRoleRadioEnum,
  CustomersControllerGetUsersByCustomerIdRequest,
  User,
  UsersControllerGetUsersRequest,
  UsersControllerUpdateUserProfileRequest,
} from '@/SharedModule/Api'
import { CustomersService } from '@/Modules/Customers/services/service.customers'
import { UsersService } from '@/Modules/Users/services/service.users'

const customerPermissionsService = new CustomerPermissionsService()
const customersService = new CustomersService()
const usersService = new UsersService()
type DialogState = '' | 'root' | 'addNew' | 'addExisting'
const userLoaders: { [userId: number]: Promise<User> } = {}
interface NewUser {
  email: string
  profile: {
    firstName: string
    lastName: string
    chatSettings: {
      chatTag: boolean
      groupTag: boolean
    }
  }
}
interface CreateUserAndAssignPayload {
  customerId: number
  userRoleRadio: CreateUserDtoUserRoleRadioEnum
  editUserRole: boolean
  selectedLocations: Record<string, unknown>
  notificationEmail: boolean
  userId?: number
  fullName?: string
}
interface TreeData {
  userRolesRadioSelection: any
  hierarchies: any
  totalLocations: number
}
interface IState {
  usersList: User[]
  usersByCustId: User[]
  newUser: NewUser
  dialogState: DialogState
  treeData: TreeData
  usersData: UsersData
}
interface UsersData {
  [id: number]: User | null
}
const getDefaultState = (): IState => ({
  usersList: [],
  usersByCustId: [],
  newUser: {
    email: '',
    profile: {
      firstName: '',
      lastName: '',
      chatSettings: {
        chatTag: false,
        groupTag: false,
      },
    },
  },
  dialogState: '',
  treeData: {
    userRolesRadioSelection: {},
    hierarchies: {},
    totalLocations: 0,
  },
  usersData: {},
})
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    usersList: (state: IState): User[] => state.usersList,
    usersByCustId: (state: IState): User[] => state.usersByCustId,
    dialogState: (state: IState): DialogState => state.dialogState,
    newUser: (state: IState): NewUser => state.newUser,
    firstName: (state: IState): string => state.newUser.profile.firstName,
    lastName: (state: IState): string => state.newUser.profile.lastName,
    getUser(state: IState): (userId: number) => User | null {
      return (userId: number): User | null => {
        if (!state.usersData[userId]) {
          state.usersData[userId] = null
        }
        return state.usersData[userId]
      }
    },
  },
  mutations: {
    appendUsersData(state: IState, data: { userId: number; user: User }): void {
      state.usersData[data.userId] = data.user
      // eslint-disable-next-line no-prototype-builtins
      if (userLoaders.hasOwnProperty(data.userId)) {
        delete userLoaders[data.userId]
      }
    },
    setUsers(state: IState, usersList: User[]): void {
      state.usersList = [...usersList]
    },
    setChatTag(state: IState, chatTag: boolean): void {
      state.newUser.profile.chatSettings.chatTag = chatTag
    },
    setGroupTag(state: IState, groupTag: boolean): void {
      state.newUser.profile.chatSettings.groupTag = groupTag
    },
    setUsersByCustId(state: IState, usersByCustId: User[]): void {
      state.usersByCustId = [...usersByCustId]
    },
    setFirstName(state: IState, firstName: string): void {
      state.newUser.profile.firstName = firstName
    },
    setLastName(state: IState, lastName: string): void {
      state.newUser.profile.lastName = lastName
    },
    setDialogState(state: IState, dialogState: DialogState): void {
      state.dialogState = dialogState
    },
    setEmail(state: IState, email: string): void {
      state.newUser.email = email
    },
    setTreeData(state: IState, payload: TreeData): void {
      state.treeData = { ...payload }
    },
    resetState(state: IState): void {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async loadUser(
      { commit, state }: any,
      data: { userId: number; forceReload: boolean },
    ): Promise<void> {
      try {
        // eslint-disable-next-line no-prototype-builtins
        if (
          (!state.usersData[data.userId] &&
            // eslint-disable-next-line no-prototype-builtins
            !userLoaders.hasOwnProperty(data.userId)) ||
          data.forceReload
        ) {
          userLoaders[data.userId] = usersService.getUser({
            userId: data.userId,
          })
          const user: User = await userLoaders[data.userId]
          commit('appendUsersData', { userId: data.userId, user })
          // eslint-disable-next-line no-prototype-builtins
        } else if (userLoaders.hasOwnProperty(data.userId)) {
          return userLoaders[data.userId].then()
        }
      } catch (e) {
        console.log(e)
      }
    },
    async createUserAndAssign(
      { dispatch, state }: any,
      {
        customerId,
        userRoleRadio,
        editUserRole,
        userId,
        selectedLocations,
        notificationEmail,
      }: CreateUserAndAssignPayload,
    ): Promise<void> {
      const requestParams = {
        customerId,
        createUserDto: {
          userRoleRadio,
          email: state.newUser.email,
          profile: {
            ...state.newUser.profile,
          },
        },
      }
      const {
        newUser: {
          profile: { firstName, lastName },
        },
      } = state
      const fullName = `${firstName} ${lastName}`
      try {
        const response = await usersService.createUserAndAssign(requestParams)
        dispatch(
          'users/assignLocations',
          {
            customerId,
            userId: response.id,
            selectedLocations,
          },
          { root: true },
        )
        dispatch(
          'users/assignPermissions',
          {
            customerId,
            userId: response.id,
            editUserRole,
            userRoleRadio,
            selectedLocations,
            notificationEmail,
          },
          { root: true },
        )
        ElMessage({
          message: `User ${fullName} was added.`,
          type: 'success',
        })
      } catch (e) {
        ElMessage({
          message: 'The user assignment failed. Please try again.',
          type: 'error',
        })
      }
    },
    async getUsers(
      { commit }: any,
      requestParams: UsersControllerGetUsersRequest,
    ): Promise<void> {
      try {
        const users = await usersService.getUsers(requestParams)
        commit('setUsers', users.results)
      } catch (e) {
        console.log(e)
      }
    },
    async getUsersByCustomerId(
      { commit }: any,
      requestParams: CustomersControllerGetUsersByCustomerIdRequest,
    ): Promise<void> {
      try {
        const usersByCustId = await customersService.getUsersByCustomerId(
          requestParams,
        )
        commit('setUsersByCustId', usersByCustId.results)
      } catch (e) {
        console.log(e)
      }
    },
    async assignLocations(
      context: any,
      {
        customerId,
        userId,
        selectedLocations,
      }: { customerId: number; userId: number; selectedLocations: any },
    ): Promise<void> {
      const { locationsIds, event } = selectedLocations
      const locIds =
        locationsIds &&
        locationsIds.filter((selectedLocation: any) => !isNil(selectedLocation))
      const body = {
        locationsIds: locIds,
      }
      if (event) {
        const body = {
          customerId,
          userId,
          setLocationsDto: {
            locationsIds: selectedLocations.locationsIds,
          },
        }
        await customerPermissionsService.assignLocations(body)
      }
    },
    async assignUsersToCustomer(
      { dispatch, state }: any,
      {
        customerId,
        userId,
        editUserRole,
        userRoleRadio,
        selectedLocations,
        notificationEmail,
      }: CreateUserAndAssignPayload,
    ): Promise<void> {
      const requestParams = {
        userId: userId as number,
        customerId,
        assignUserDto: {
          notificationEmail: notificationEmail,
        },
      }
      const [firstUser] = state.usersList
      const fullName = `${firstUser.profile.firstName} ${firstUser.profile.lastName}`
      try {
        await usersService.assignUserToCustomer(requestParams)
        dispatch(
          'users/assignLocations',
          {
            customerId,
            userId,
            selectedLocations,
          },
          { root: true },
        )
        dispatch(
          'users/assignPermissions',
          {
            customerId,
            userId,
            editUserRole,
            userRoleRadio,
            selectedLocations,
            notificationEmail,
          },
          { root: true },
        )
        ElMessage({
          message: `User ${fullName} was added.`,
          type: 'success',
        })
      } catch (e) {
        ElMessage({
          message: 'The user assignment failed. Please try again.',
          type: 'error',
        })
      }
    },
    async updateProfile(
      { state }: { state: IState },
      user: User,
    ): Promise<void> {
      const requestParams = {
        userId: user.id,
        userProfileDto: {
          ...user.profile,
          chatSettings: {
            ...state.newUser.profile.chatSettings,
          },
        },
      }
      await usersService.updateUserProfile(requestParams)
    },
    async assignPermissions(context: any, payload: any): Promise<void> {
      const userRoles = handleEditUserRoles(payload)
      const { permissions, userId, fullName, readableUserRole, customerId } =
        userRoles
      const setPermissionsDto = {
        permissions,
        userId,
      }
      try {
        await customerPermissionsService.assignPermissions({
          customerId,
          setPermissionsDto,
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
