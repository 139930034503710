/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountBulkUpdateDto
 */
export interface VirtualAccountBulkUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    vendor: string;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountBulkUpdateDto
     */
    vendorProviderId?: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    vendorPrimaryBillingId?: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    serviceAccount: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    meterId: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    vendorType: VirtualAccountBulkUpdateDtoVendorTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    utilityType: VirtualAccountBulkUpdateDtoUtilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    serviceAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    serviceZip?: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    serviceDateMethod?: VirtualAccountBulkUpdateDtoServiceDateMethodEnum;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    ratePlan?: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkUpdateDto
     */
    comment?: string;
}

/**
* @export
* @enum {string}
*/
export enum VirtualAccountBulkUpdateDtoVendorTypeEnum {
    FullService = 'Full Service',
    SupplyOnly = 'Supply Only',
    DistributionOnly = 'Distribution Only'
}/**
* @export
* @enum {string}
*/
export enum VirtualAccountBulkUpdateDtoUtilityTypeEnum {
    Electric = 'ELECTRIC',
    Naturalgas = 'NATURALGAS',
    Chilledwater = 'CHILLEDWATER',
    Fireprotection = 'FIREPROTECTION',
    Internet = 'INTERNET',
    Telephone = 'TELEPHONE',
    Hotwater = 'HOTWATER',
    Stormdrain = 'STORMDRAIN',
    Stormwater = 'STORMWATER',
    Irrigation = 'IRRIGATION',
    Propane = 'PROPANE',
    Steam = 'STEAM',
    Refuse = 'REFUSE',
    Sewer = 'SEWER',
    Water = 'WATER',
    Lighting = 'LIGHTING',
    Solarpv = 'SOLARPV',
    Oil2 = 'OIL2',
    Oil4 = 'OIL4',
    Oil6 = 'OIL6',
    Nitrogen = 'NITROGEN',
    Oxygen = 'OXYGEN',
    Diesel = 'DIESEL',
    ElectricMwh = 'ELECTRIC_MWH',
    SewerMcf = 'SEWER_MCF',
    WaterMcf = 'WATER_MCF',
    Fuelcell = 'FUELCELL'
}/**
* @export
* @enum {string}
*/
export enum VirtualAccountBulkUpdateDtoServiceDateMethodEnum {
    S = 'E-S',
    S1 = 'E-S+1'
}

export function VirtualAccountBulkUpdateDtoFromJSON(json: any): VirtualAccountBulkUpdateDto {
    return VirtualAccountBulkUpdateDtoFromJSONTyped(json, false);
}

export function VirtualAccountBulkUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountBulkUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vendor': json['vendor'],
        'vendorProviderId': !exists(json, 'vendorProviderId') ? undefined : json['vendorProviderId'],
        'vendorPrimaryBillingId': !exists(json, 'vendorPrimaryBillingId') ? undefined : json['vendorPrimaryBillingId'],
        'serviceAccount': json['serviceAccount'],
        'meterId': json['meterId'],
        'vendorType': json['vendorType'],
        'utilityType': json['utilityType'],
        'serviceAddress': !exists(json, 'serviceAddress') ? undefined : json['serviceAddress'],
        'serviceZip': !exists(json, 'serviceZip') ? undefined : json['serviceZip'],
        'serviceDateMethod': !exists(json, 'serviceDateMethod') ? undefined : json['serviceDateMethod'],
        'ratePlan': !exists(json, 'ratePlan') ? undefined : json['ratePlan'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function VirtualAccountBulkUpdateDtoToJSON(value?: VirtualAccountBulkUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor': value.vendor,
        'vendorProviderId': value.vendorProviderId,
        'vendorPrimaryBillingId': value.vendorPrimaryBillingId,
        'serviceAccount': value.serviceAccount,
        'meterId': value.meterId,
        'vendorType': value.vendorType,
        'utilityType': value.utilityType,
        'serviceAddress': value.serviceAddress,
        'serviceZip': value.serviceZip,
        'serviceDateMethod': value.serviceDateMethod,
        'ratePlan': value.ratePlan,
        'comment': value.comment,
    };
}


