import {
  AuthApi,
  AuthControllerRefreshTokenRequest,
  Configuration,
  ResponseContext,
} from '@/SharedModule/Api'
import { loadedRequests } from '@/SharedModule/Services/service.request-interceptors'
import { API_V3_URL } from '@/AppModule/Configs/env'

class ApiService {
  static shouldRefresh = false
  public getBaseOptions(): Configuration {
    return new Configuration({
      basePath: API_V3_URL,
      middleware: [
        {
          post: ApiService.postStatusMiddleware,
          pre: ApiService.preStatusMiddleware,
        },
      ],
    })
  }
  static getStaticOptions(): Configuration {
    return new Configuration({
      basePath: API_V3_URL,
      middleware: [
        {
          post: ApiService.postStatusMiddleware,
          pre: ApiService.preStatusMiddleware,
        },
      ],
    })
  }
  static async refreshToken(): Promise<void> {
    ApiService.shouldRefresh = true
    try {
      const requestParams: AuthControllerRefreshTokenRequest = {
        refreshTokenDto: {
          refreshToken: localStorage.getItem('refreshToken') as string,
        },
      }
      const response = await new AuthApi(
        ApiService.getStaticOptions(),
      ).authControllerRefreshToken(requestParams)
      localStorage.setItem('authToken', response.token)
      localStorage.setItem('refreshToken', response.refreshToken)
    } catch (e) {
      await ApiService.logout()
    }
  }
  static async postStatusMiddleware(context: ResponseContext): Promise<any> {
    loadedRequests.value.pop()
    if (
      context.response.status === 401 &&
      !context.response.url.includes('refresh-token') &&
      localStorage.getItem('authToken')
    ) {
      if (!ApiService.shouldRefresh) {
        await ApiService.refreshToken()
      }
      if (context?.init?.headers) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.init.headers['Authorization'] = localStorage.getItem(
          'authToken',
        ) as any
        ApiService.shouldRefresh = false
        return context.fetch(context.url, context.init)
      }
    } else if (context.response.status === 403) {
      // logout
      window.location.href = '/error/403'
    }
  }
  static async preStatusMiddleware(context: any): Promise<void> {
    context.init.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'authToken',
    )}`
    loadedRequests.value.push(true)
  }

  static async logout(): Promise<void> {
    localStorage.removeItem('authToken')
    localStorage.removeItem('refreshToken')
    window.location.href = '/auth/login'
  }
}

export { ApiService }
