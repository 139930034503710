/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FreezeVirtualAccountItemDto,
    FreezeVirtualAccountItemDtoFromJSON,
    FreezeVirtualAccountItemDtoFromJSONTyped,
    FreezeVirtualAccountItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface FreezeVirtualAccountDto
 */
export interface FreezeVirtualAccountDto {
    /**
     * 
     * @type {Array<FreezeVirtualAccountItemDto>}
     * @memberof FreezeVirtualAccountDto
     */
    items: Array<FreezeVirtualAccountItemDto>;
    /**
     * 
     * @type {boolean}
     * @memberof FreezeVirtualAccountDto
     */
    selectedAll: boolean;
}

export function FreezeVirtualAccountDtoFromJSON(json: any): FreezeVirtualAccountDto {
    return FreezeVirtualAccountDtoFromJSONTyped(json, false);
}

export function FreezeVirtualAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreezeVirtualAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(FreezeVirtualAccountItemDtoFromJSON)),
        'selectedAll': json['selectedAll'],
    };
}

export function FreezeVirtualAccountDtoToJSON(value?: FreezeVirtualAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(FreezeVirtualAccountItemDtoToJSON)),
        'selectedAll': value.selectedAll,
    };
}


