/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ObservationModel
 */
export interface ObservationModel {
    /**
     * 
     * @type {number}
     * @memberof ObservationModel
     */
    block: number;
    /**
     * 
     * @type {string}
     * @memberof ObservationModel
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationModel
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationModel
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ObservationModel
     */
    value: string;
    /**
     * 
     * @type {number}
     * @memberof ObservationModel
     */
    usage: number;
    /**
     * 
     * @type {string}
     * @memberof ObservationModel
     */
    uom: ObservationModelUomEnum;
    /**
     * 
     * @type {number}
     * @memberof ObservationModel
     */
    charge: number;
    /**
     * 
     * @type {string}
     * @memberof ObservationModel
     */
    currency: string;
}

/**
* @export
* @enum {string}
*/
export enum ObservationModelUomEnum {
    Kw = 'KW',
    Kwh = 'KWH',
    Kwhday = 'KWHDAY',
    Usd = 'USD',
    Cad = 'CAD',
    Kvarh = 'KVARH',
    Ccf = 'CCF',
    Gal = 'GAL',
    Kgal = 'KGAL',
    Lamps = 'LAMPS',
    Therm = 'THERM',
    Cf = 'CF',
    Percent = 'PERCENT',
    Usdday = 'USDDAY',
    Units = 'UNITS',
    Kvar = 'KVAR',
    Usdkwh = 'USDKWH',
    Usdkwhday = 'USDKWHDAY',
    Ton = 'TON',
    Tonhr = 'TONHR',
    Block = 'BLOCK',
    Kva = 'KVA',
    Tonday = 'TONDAY',
    Ccfday = 'CCFDAY',
    Thermday = 'THERMDAY',
    Min = 'MIN',
    Minday = 'MINDAY',
    Calls = 'CALLS',
    Mb = 'MB',
    Mbday = 'MBDAY',
    Cubicm = 'CUBICM',
    Mcf = 'MCF',
    Dkthm = 'DKTHM',
    Sqfeet = 'SQFEET',
    Sqfeetday = 'SQFEETDAY',
    Hgal = 'HGAL',
    Btu = 'BTU',
    Mmbtu = 'MMBTU',
    Galday = 'GALDAY',
    Usdccf = 'USDCCF',
    Usdtherm = 'USDTHERM',
    Usdton = 'USDTON',
    Usdmin = 'USDMIN',
    Usdmb = 'USDMB',
    Usdsqfeet = 'USDSQFEET',
    Usdgal = 'USDGAL',
    Usdmmbtu = 'USDMMBTU',
    Mmbtuday = 'MMBTUDAY',
    Mwh = 'MWH',
    Hdd = 'HDD',
    Cdd = 'CDD',
    Tdd = 'TDD',
    Kwhdd = 'KWHDD',
    _10Cf = '10CF',
    Cgal = 'CGAL',
    Dgal = 'DGAL',
    Dcf = 'DCF',
    Gj = 'GJ',
    _10Gal = '10GAL',
    Mbtu = 'MBTU',
    NA = 'N/A',
    Cubicyard = 'CUBICYARD',
    Eru = 'ERU',
    Edu = 'EDU',
    Lbs = 'LBS'
}

export function ObservationModelFromJSON(json: any): ObservationModel {
    return ObservationModelFromJSONTyped(json, false);
}

export function ObservationModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ObservationModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': json['block'],
        'description': json['description'],
        'code': json['code'],
        'type': json['type'],
        'value': json['value'],
        'usage': json['usage'],
        'uom': json['uom'],
        'charge': json['charge'],
        'currency': json['currency'],
    };
}

export function ObservationModelToJSON(value?: ObservationModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'description': value.description,
        'code': value.code,
        'type': value.type,
        'value': value.value,
        'usage': value.usage,
        'uom': value.uom,
        'charge': value.charge,
        'currency': value.currency,
    };
}


