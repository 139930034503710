/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TargetHours
 */
export interface TargetHours {
    /**
     * 
     * @type {number}
     * @memberof TargetHours
     */
    setup: number;
    /**
     * 
     * @type {number}
     * @memberof TargetHours
     */
    live: number;
    /**
     * 
     * @type {number}
     * @memberof TargetHours
     */
    historical: number;
    /**
     * 
     * @type {number}
     * @memberof TargetHours
     */
    demo: number;
}

export function TargetHoursFromJSON(json: any): TargetHours {
    return TargetHoursFromJSONTyped(json, false);
}

export function TargetHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetHours {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setup': json['setup'],
        'live': json['live'],
        'historical': json['historical'],
        'demo': json['demo'],
    };
}

export function TargetHoursToJSON(value?: TargetHours | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setup': value.setup,
        'live': value.live,
        'historical': value.historical,
        'demo': value.demo,
    };
}


