import {
  TableRepository,
  TableOptions,
  TableQuery,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import {
  CustomerBillsPreviewListDto,
  CustomerBillsControllerGetBillsPreviewsByCustomerIdBillTypesEnum,
  CustomerBillsControllerGetBillsPreviewsByCustomerIdExcludeEnum,
  CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest,
  CustomerBillsControllerGetBillsPreviewsByCustomerIdWorkflowStatesEnum,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import { CustomersService } from '@/Modules/Customers/services/service.customers'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { CustomerBillsService } from '@/Modules/Customers/services/service.customer-bills'

const serviceCustomers = new CustomersService()
const serviceCustomerBills = new CustomerBillsService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      statementDateMin: null,
      statementDateMax: null,
      loadedDateMin: null,
      loadedDateMax: null,
      searchQuery: null,
      vendorCodes: null,
      locationIds: null,
      commodities: null,
      workflowStates: null,
      billTypes: null,
      errorTypes: null,
      amountDueMin: null,
      amountDueMax: null,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('Billing ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('clientAccount'),
      },
      {
        prettyName: ref('Vendor'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('vendor'),
      },
      {
        prettyName: ref('Invoice Date'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('statementDate'),
      },
      {
        prettyName: ref('Amount Due'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('amountDue'),
      },
      {
        prettyName: ref('Service Account Id'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('200'),
        modelKey: ref('accountCodes'),
      },
      {
        prettyName: ref('Status'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('workflowState'),
      },
      {
        prettyName: ref('Date Due'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('dueDate'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'customer.bills',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest,
  meta: any,
): CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest => {
  if (filterData.statementDateMin) {
    filterData.statementDateMin = new Date(filterData.statementDateMin)
  }
  if (filterData.statementDateMax) {
    filterData.statementDateMax = new Date(filterData.statementDateMax)
  }
  if (filterData.loadedDateMin) {
    filterData.loadedDateMin = new Date(filterData.loadedDateMin)
  }
  if (filterData.loadedDateMax) {
    filterData.loadedDateMax = new Date(filterData.loadedDateMax)
  }
  if (filterData.amountDueMin) {
    filterData.amountDueMin = +filterData.amountDueMin
  }
  if (filterData.amountDueMax) {
    filterData.amountDueMax = +filterData.amountDueMax
  }
  if (filterData.vendorCodes) {
    filterData.vendorCodes = filterData.vendorCodes.toString().split(',')
  }
  if (filterData.locationIds) {
    filterData.locationIds = filterData.locationIds
      .toString()
      .split(',')
      .map((currentStringLocationId) => +currentStringLocationId)
  }
  if (filterData.commodities) {
    filterData.commodities = filterData.commodities.toString().split(',')
  }
  if (filterData.workflowStates) {
    filterData.workflowStates = filterData.workflowStates
      .toString()
      .split(
        ',',
      ) as CustomerBillsControllerGetBillsPreviewsByCustomerIdWorkflowStatesEnum[]
  }
  if (filterData.billTypes) {
    filterData.billTypes = filterData.billTypes
      .toString()
      .split(
        ',',
      ) as CustomerBillsControllerGetBillsPreviewsByCustomerIdBillTypesEnum[]
  }
  if (filterData.errorTypes) {
    filterData.errorTypes = filterData.errorTypes.toString().split(',')
  }

  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(
        CustomerBillsControllerGetBillsPreviewsByCustomerIdExcludeEnum.Filters,
      )
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        CustomerBillsControllerGetBillsPreviewsByCustomerIdExcludeEnum.Total,
        CustomerBillsControllerGetBillsPreviewsByCustomerIdExcludeEnum.Extra,
      )
    }
  }

  filterData.customerId = meta.customerId

  return filterData
}

const useListRef = (): Ref<CustomerBillsPreviewListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  }) as Ref<CustomerBillsPreviewListDto>

export default class RepositoryCustomerBills
  implements TableRepository<CustomerBillsPreviewListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  constructor(customerId: number | null = null) {
    this.options.meta.value.customerId = customerId
  }

  async retrieveCustomerName(customerId: number): Promise<string> {
    const customer = await serviceCustomers.getCustomer({
      customerId,
    })
    return customer.name
  }

  getList = async (): Promise<void> => {
    const listValue = await serviceCustomerBills.getCustomerBillsPreviews(
      adaptFilterDataToApiTypesAndExclude(
        useQueryAsRequest<CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest>(
          this.options.query,
        ),
        this.options.meta.value,
      ),
    )
    if (this.options.meta.value.excludePaginationData) {
      listValue.extra = this.list.value.extra
      listValue.total = this.list.value.total
      this.options.meta.value.excludePaginationData = false
    }
    if (this.options.meta.value.apiFilters === null) {
      this.options.meta.value.apiFilters = listValue.filters

      if (!this.options.query.filters.value.amountDueMax) {
        this.options.query.filters.value.amountDueMax =
          this.options.meta.value.apiFilters.amountDue.max
        this.options.query.filters.value.amountDueMin =
          this.options.meta.value.apiFilters.amountDue.min
      }
    }
    this.list.value = listValue
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
