
import { defineComponent } from 'vue'

export default defineComponent({
  inheritAttrs: false,
  props: {
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    labelWidth: {
      type: String,
    },
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
})
