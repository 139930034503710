/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationToJSON,
    SetLocationsDto,
    SetLocationsDtoFromJSON,
    SetLocationsDtoToJSON,
    SetPermissionsDto,
    SetPermissionsDtoFromJSON,
    SetPermissionsDtoToJSON,
    UserCustomerPermissionListDto,
    UserCustomerPermissionListDtoFromJSON,
    UserCustomerPermissionListDtoToJSON,
} from '../models';

export interface CustomerPermissionsControllerSetLocationsRequest {
    customerId: number;
    userId: number;
    setLocationsDto: SetLocationsDto;
}

export interface CustomerPermissionsControllerSetPermissionsRequest {
    customerId: number;
    setPermissionsDto: SetPermissionsDto;
}

/**
 * 
 */
export class CustomerPermissionsApi extends runtime.BaseAPI {

    /**
     * Set user locations
     */
    async customerPermissionsControllerSetLocationsRaw(requestParameters: CustomerPermissionsControllerSetLocationsRequest): Promise<runtime.ApiResponse<Array<Location>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerPermissionsControllerSetLocations.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling customerPermissionsControllerSetLocations.');
        }

        if (requestParameters.setLocationsDto === null || requestParameters.setLocationsDto === undefined) {
            throw new runtime.RequiredError('setLocationsDto','Required parameter requestParameters.setLocationsDto was null or undefined when calling customerPermissionsControllerSetLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/users/{userId}/locations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetLocationsDtoToJSON(requestParameters.setLocationsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     * Set user locations
     */
    async customerPermissionsControllerSetLocations(requestParameters: CustomerPermissionsControllerSetLocationsRequest): Promise<Array<Location>> {
        const response = await this.customerPermissionsControllerSetLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set user permissions
     */
    async customerPermissionsControllerSetPermissionsRaw(requestParameters: CustomerPermissionsControllerSetPermissionsRequest): Promise<runtime.ApiResponse<UserCustomerPermissionListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerPermissionsControllerSetPermissions.');
        }

        if (requestParameters.setPermissionsDto === null || requestParameters.setPermissionsDto === undefined) {
            throw new runtime.RequiredError('setPermissionsDto','Required parameter requestParameters.setPermissionsDto was null or undefined when calling customerPermissionsControllerSetPermissions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/permissions`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetPermissionsDtoToJSON(requestParameters.setPermissionsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCustomerPermissionListDtoFromJSON(jsonValue));
    }

    /**
     * Set user permissions
     */
    async customerPermissionsControllerSetPermissions(requestParameters: CustomerPermissionsControllerSetPermissionsRequest): Promise<UserCustomerPermissionListDto> {
        const response = await this.customerPermissionsControllerSetPermissionsRaw(requestParameters);
        return await response.value();
    }

}
