import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { App } from 'vue'

const routesContext = require.context('@/Modules/', true, /routes.index.ts$/i)

const routes: Array<RouteRecordRaw> = routesContext
  .keys()
  .reduce((total, contextKey) => {
    total.push(...routesContext(contextKey).default)
    return total
  }, [] as Array<RouteRecordRaw>)

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default {
  install: (app: App): void => {
    app.use(router)
  },
}
