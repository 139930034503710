/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateIntervalDto
 */
export interface DateIntervalDto {
    /**
     * 
     * @type {Date}
     * @memberof DateIntervalDto
     */
    startDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateIntervalDto
     */
    endDate?: Date;
}

export function DateIntervalDtoFromJSON(json: any): DateIntervalDto {
    return DateIntervalDtoFromJSONTyped(json, false);
}

export function DateIntervalDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateIntervalDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startDate': !exists(json, 'startDate') ? undefined : (new Date(json['startDate'])),
        'endDate': !exists(json, 'endDate') ? undefined : (new Date(json['endDate'])),
    };
}

export function DateIntervalDtoToJSON(value?: DateIntervalDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startDate': value.startDate === undefined ? undefined : (value.startDate.toISOString()),
        'endDate': value.endDate === undefined ? undefined : (value.endDate.toISOString()),
    };
}


