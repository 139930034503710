import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/en'
import { App } from 'vue'

import './scss/element-theme.scss'

export default {
  install: (app: App): void => {
    app.use(ElementPlus)
  },
}
