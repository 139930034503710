/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReparseBillsDto
 */
export interface ReparseBillsDto {
    /**
     * 
     * @type {number}
     * @memberof ReparseBillsDto
     */
    billId: number;
    /**
     * 
     * @type {number}
     * @memberof ReparseBillsDto
     */
    customerId: number;
}

export function ReparseBillsDtoFromJSON(json: any): ReparseBillsDto {
    return ReparseBillsDtoFromJSONTyped(json, false);
}

export function ReparseBillsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReparseBillsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'customerId': json['customerId'],
    };
}

export function ReparseBillsDtoToJSON(value?: ReparseBillsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'customerId': value.customerId,
    };
}


