/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BatchErrorsDto,
    BatchErrorsDtoFromJSON,
    BatchErrorsDtoToJSON,
    BatchesListDto,
    BatchesListDtoFromJSON,
    BatchesListDtoToJSON,
    VirtualAccountMapStatus,
    VirtualAccountMapStatusFromJSON,
    VirtualAccountMapStatusToJSON,
} from '../models';

export interface BatchesControllerAutoResolveBatchErrorsRequest {
    batchId: number;
}

export interface BatchesControllerGetBatchErrorsRequest {
    batchId: number;
}

export interface BatchesControllerGetBatchStatusRequest {
    trackingId: string;
}

export interface BatchesControllerGetListRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<BatchesControllerGetListExcludeEnum>;
    customerIds?: Array<number>;
    batchId?: string;
    customerActive?: boolean;
    startDate?: Date;
    endDate?: Date;
    status?: BatchesControllerGetListStatusEnum;
}

/**
 * 
 */
export class BatchesApi extends runtime.BaseAPI {

    /**
     * Resolve all auto-resolvable errors for the bills from a specific batch
     */
    async batchesControllerAutoResolveBatchErrorsRaw(requestParameters: BatchesControllerAutoResolveBatchErrorsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling batchesControllerAutoResolveBatchErrors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/batches/{batchId}/auto-resolve`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resolve all auto-resolvable errors for the bills from a specific batch
     */
    async batchesControllerAutoResolveBatchErrors(requestParameters: BatchesControllerAutoResolveBatchErrorsRequest): Promise<void> {
        await this.batchesControllerAutoResolveBatchErrorsRaw(requestParameters);
    }

    /**
     * Get list of errors (total & auto-resolvable) for batch
     */
    async batchesControllerGetBatchErrorsRaw(requestParameters: BatchesControllerGetBatchErrorsRequest): Promise<runtime.ApiResponse<BatchErrorsDto>> {
        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling batchesControllerGetBatchErrors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/batches/{batchId}/errors`.replace(`{${"batchId"}}`, encodeURIComponent(String(requestParameters.batchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchErrorsDtoFromJSON(jsonValue));
    }

    /**
     * Get list of errors (total & auto-resolvable) for batch
     */
    async batchesControllerGetBatchErrors(requestParameters: BatchesControllerGetBatchErrorsRequest): Promise<BatchErrorsDto> {
        const response = await this.batchesControllerGetBatchErrorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the reparse status for bills from a batch, after mapping a virtual account to a location
     */
    async batchesControllerGetBatchStatusRaw(requestParameters: BatchesControllerGetBatchStatusRequest): Promise<runtime.ApiResponse<VirtualAccountMapStatus>> {
        if (requestParameters.trackingId === null || requestParameters.trackingId === undefined) {
            throw new runtime.RequiredError('trackingId','Required parameter requestParameters.trackingId was null or undefined when calling batchesControllerGetBatchStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/batches/batch-status/{trackingId}`.replace(`{${"trackingId"}}`, encodeURIComponent(String(requestParameters.trackingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountMapStatusFromJSON(jsonValue));
    }

    /**
     * Get the reparse status for bills from a batch, after mapping a virtual account to a location
     */
    async batchesControllerGetBatchStatus(requestParameters: BatchesControllerGetBatchStatusRequest): Promise<VirtualAccountMapStatus> {
        const response = await this.batchesControllerGetBatchStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of batches
     */
    async batchesControllerGetListRaw(requestParameters: BatchesControllerGetListRequest): Promise<runtime.ApiResponse<BatchesListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.customerIds) {
            queryParameters['customerIds'] = requestParameters.customerIds;
        }

        if (requestParameters.batchId !== undefined) {
            queryParameters['batchId'] = requestParameters.batchId;
        }

        if (requestParameters.customerActive !== undefined) {
            queryParameters['customerActive'] = requestParameters.customerActive;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/batches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BatchesListDtoFromJSON(jsonValue));
    }

    /**
     * Returns the list of batches
     */
    async batchesControllerGetList(requestParameters: BatchesControllerGetListRequest): Promise<BatchesListDto> {
        const response = await this.batchesControllerGetListRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum BatchesControllerGetListExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum BatchesControllerGetListStatusEnum {
    Processed = 'Processed',
    External = 'External'
}
