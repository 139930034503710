/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CubeFilter,
    CubeFilterFromJSON,
    CubeFilterFromJSONTyped,
    CubeFilterToJSON,
    CubeTimeDimension,
    CubeTimeDimensionFromJSON,
    CubeTimeDimensionFromJSONTyped,
    CubeTimeDimensionToJSON,
} from './';

/**
 * 
 * @export
 * @interface CubeQuery
 */
export interface CubeQuery {
    /**
     * 
     * @type {Array<string>}
     * @memberof CubeQuery
     */
    dimensions: Array<string>;
    /**
     * 
     * @type {Array<CubeTimeDimension>}
     * @memberof CubeQuery
     */
    timeDimensions: Array<CubeTimeDimension>;
    /**
     * 
     * @type {Array<CubeFilter>}
     * @memberof CubeQuery
     */
    filters: Array<CubeFilter>;
    /**
     * 
     * @type {number}
     * @memberof CubeQuery
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof CubeQuery
     */
    offset: number;
}

export function CubeQueryFromJSON(json: any): CubeQuery {
    return CubeQueryFromJSONTyped(json, false);
}

export function CubeQueryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CubeQuery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimensions': json['dimensions'],
        'timeDimensions': ((json['timeDimensions'] as Array<any>).map(CubeTimeDimensionFromJSON)),
        'filters': ((json['filters'] as Array<any>).map(CubeFilterFromJSON)),
        'limit': json['limit'],
        'offset': json['offset'],
    };
}

export function CubeQueryToJSON(value?: CubeQuery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimensions': value.dimensions,
        'timeDimensions': ((value.timeDimensions as Array<any>).map(CubeTimeDimensionToJSON)),
        'filters': ((value.filters as Array<any>).map(CubeFilterToJSON)),
        'limit': value.limit,
        'offset': value.offset,
    };
}


