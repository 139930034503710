import {
    TableOptions,
    TableQuery,
    TableRepository,
  } from '@/SharedModule/Components/Tables/interfaces/repository.table'
  import { ref, Ref } from 'vue'
  import { CustomersService } from '@/Modules/Customers/services/service.customers'
  import { CustomerVendorService } from '@/Modules/Customers/services/service.customers.vendors'
  import {
    useQueryAsRequest,
    useTablePaginationMeta,
  } from '@/SharedModule/Components/Tables/composables/composables.repository.table'
  import {
    CustomerVendorsControllerGetVendorCustomAttributesListExcludeEnum,
    CustomerVendorsControllerGetVendorCustomAttributesListRequest,
    CustomAttributesValuesList,
    ProviderMetadataAttribute
  } from '@/SharedModule/Api'
  
  const serviceCustomers = new CustomersService()
  const customerVendorService = new CustomerVendorService()
  
  const useQuery = (): TableQuery => {
    return {
      filters: ref({}),
      sort: ref({
        limit: 20,
        offset: 0,
      }),
      sortBy: ref({
        sortOrder: null,
        attributeName: null,
        attributeType: null,
        attributeUom: null,
      }),
    }
  }
  
  const useOptions = (): TableOptions => {
    return {
      columns: [
        {
          prettyName: ref('Sort Order'),
          labelClassName: null,
          name: null,
          width: null,
          type: null,
          modelKey: ref('sortOrder'),
        },
        {
          prettyName: ref('Name'),
          labelClassName: null,
          name: null,
          width: null,
          type: null,
          modelKey: ref('attributeName'),
        },
        {
          prettyName: ref('Type'),
          labelClassName: null,
          name: null,
          width: null,
          type: null,
          modelKey: ref('attributeType'),
        },
        {
          prettyName: ref('UoM'),
          labelClassName: null,
          name: null,
          width: null,
          type: null,
          modelKey: ref('attributeUom'),
        },
        {
          prettyName: ref(''),
          labelClassName: null,
          name: null,
          width: null,
          type: null,
          modelKey: ref('actions'),
        },
      ],
      query: useQuery(),
      tablePaginationMeta: useTablePaginationMeta(),
      meta: ref({
        route: {
          name: 'customer.custom_attributes_vendor',
        },
        apiFilters: null,
        sortByTimeout: 500,
      }),
    }
  }
  
  const adaptFilterDataToApiTypesAndExclude = (
    filterData: CustomerVendorsControllerGetVendorCustomAttributesListRequest,
    sortBy: any,
    meta: any,
  ): CustomerVendorsControllerGetVendorCustomAttributesListRequest => {
    if (sortBy) {
      filterData.orderBy = filterData.orderBy || []
      Object.keys(sortBy).forEach((currentSortKey: string) => {
        const destructuredFilterData: any = filterData
        if (sortBy[currentSortKey] != null) {
          const filterOrderBy: Record<string, any> | undefined =
            filterData.orderBy || []
          filterOrderBy.push(
            currentSortKey + (sortBy[currentSortKey] ? '.asc' : '.desc'),
          )
        }
        delete destructuredFilterData[currentSortKey]
      })
  
      if (!filterData.orderBy?.length) {
        delete filterData.orderBy
      }
    }
  
    if (
      meta.apiFilters ||
      (meta.excludePaginationData && !meta.excludePaginationDataOverride)
    ) {
      filterData.exclude = []
  
      if (meta.apiFilters) {
        filterData.exclude.push(
          CustomerVendorsControllerGetVendorCustomAttributesListExcludeEnum.Filters,
        )
      }
  
      if (meta.excludePaginationData && !meta.excludePaginationDataOverride) {
        filterData.exclude.push(
          CustomerVendorsControllerGetVendorCustomAttributesListExcludeEnum.Total,
          CustomerVendorsControllerGetVendorCustomAttributesListExcludeEnum.Extra,
        )
      } else if (meta.excludePaginationDataOverride) {
        meta.excludePaginationDataOverride = false
      }
    }
  
    filterData.customerId = meta.customerId
  
    return filterData
  }
  
  const useListRef = (): Ref<CustomAttributesValuesList> =>
    ref({
      results: [] as ProviderMetadataAttribute[],
      total: 0,
      filters: undefined,
      extra: undefined,
    }) as Ref<CustomAttributesValuesList>
  export default class RepositoryCustomerVendorsCustomAttributesMetadata
    implements TableRepository<CustomAttributesValuesList>
  {
    busyLoading = ref(false)
    options = useOptions()
    list = useListRef()
  
    constructor(customerId: number | null = null) {
      this.options.meta.value.customerId = customerId
    }
  
    async retrieveCustomerName(customerId: number): Promise<string> {
      const customer = await serviceCustomers.getCustomer({
        customerId,
      })
      return customer.name
    }
  
    getList = async (): Promise<void> => {
      const listValue =
        await customerVendorService.getVendorCustomAttributeList(
          adaptFilterDataToApiTypesAndExclude(
            useQueryAsRequest<CustomerVendorsControllerGetVendorCustomAttributesListRequest>(
              this.options.query,
            ),
            this.options.query.sortBy?.value,
            this.options.meta.value,
          ),
        )
      if (this.options.meta.value.excludePaginationData) {
        listValue.extra = this.list.value.extra
        listValue.total = this.list.value.total
        this.options.meta.value.excludePaginationData = false
      }
      if (this.options.meta.value.apiFilters === null) {
        this.options.meta.value.apiFilters = listValue.filters
      }
      this.list.value = listValue
    }
  
    setFilter = async (key: string, value: any): Promise<void> => {
      this.options.query.filters.value[key] = value
    }
  
    resetQuery = async (): Promise<void> => {
      const newQuery = useQuery()
  
      Object.assign(this.options.query.filters.value, newQuery.filters.value)
      Object.assign(this.options.query.sort.value, newQuery.sort.value)
    }
  }