/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionMeta,
    PermissionMetaFromJSON,
    PermissionMetaFromJSONTyped,
    PermissionMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserCustomerPermission
 */
export interface UserCustomerPermission {
    /**
     * 
     * @type {number}
     * @memberof UserCustomerPermission
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof UserCustomerPermission
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof UserCustomerPermission
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof UserCustomerPermission
     */
    permissionMetaId: number;
    /**
     * 
     * @type {number}
     * @memberof UserCustomerPermission
     */
    customerId: number;
    /**
     * 
     * @type {PermissionMeta}
     * @memberof UserCustomerPermission
     */
    permissionMeta: PermissionMeta;
}

export function UserCustomerPermissionFromJSON(json: any): UserCustomerPermission {
    return UserCustomerPermissionFromJSONTyped(json, false);
}

export function UserCustomerPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCustomerPermission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'userId': json['userId'],
        'permissionMetaId': json['permissionMetaId'],
        'customerId': json['customerId'],
        'permissionMeta': PermissionMetaFromJSON(json['permissionMeta']),
    };
}

export function UserCustomerPermissionToJSON(value?: UserCustomerPermission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'userId': value.userId,
        'permissionMetaId': value.permissionMetaId,
        'customerId': value.customerId,
        'permissionMeta': PermissionMetaToJSON(value.permissionMeta),
    };
}


