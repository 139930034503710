/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserProfile,
    UserProfileFromJSON,
    UserProfileFromJSONTyped,
    UserProfileToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUserDto
 */
export interface CreateUserDto {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    userRoleRadio: CreateUserDtoUserRoleRadioEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDto
     */
    email: string;
    /**
     * 
     * @type {UserProfile}
     * @memberof CreateUserDto
     */
    profile: UserProfile;
}

/**
* @export
* @enum {string}
*/
export enum CreateUserDtoUserRoleRadioEnum {
    CustomerFullAccess = 'customerFullAccess',
    StandardUser = 'standardUser'
}

export function CreateUserDtoFromJSON(json: any): CreateUserDto {
    return CreateUserDtoFromJSONTyped(json, false);
}

export function CreateUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userRoleRadio': json['userRoleRadio'],
        'email': json['email'],
        'profile': UserProfileFromJSON(json['profile']),
    };
}

export function CreateUserDtoToJSON(value?: CreateUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userRoleRadio': value.userRoleRadio,
        'email': value.email,
        'profile': UserProfileToJSON(value.profile),
    };
}


