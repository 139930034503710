export enum InputSizesEnum {
  Mini = 'mini',
  Small = 'small',
  Medium = 'medium',
}

export enum DialogWidthsEnum {
  ExtraLargeScreen = '1180px',
  MediumScreen = '620px',
  SmallScreen = '500px',
  VerySmallScreen = '460px',
}
