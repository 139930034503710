/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccountGroup,
    VirtualAccountGroupFromJSON,
    VirtualAccountGroupFromJSONTyped,
    VirtualAccountGroupToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountGroupsListDto
 */
export interface VirtualAccountGroupsListDto {
    /**
     * 
     * @type {Array<VirtualAccountGroup>}
     * @memberof VirtualAccountGroupsListDto
     */
    results: Array<VirtualAccountGroup>;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountGroupsListDto
     */
    total: number;
}

export function VirtualAccountGroupsListDtoFromJSON(json: any): VirtualAccountGroupsListDto {
    return VirtualAccountGroupsListDtoFromJSONTyped(json, false);
}

export function VirtualAccountGroupsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountGroupsListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(VirtualAccountGroupFromJSON)),
        'total': json['total'],
    };
}

export function VirtualAccountGroupsListDtoToJSON(value?: VirtualAccountGroupsListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(VirtualAccountGroupToJSON)),
        'total': value.total,
    };
}


