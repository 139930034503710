import { IMessageOptions } from 'element-plus/es/el-message/src/types'

export enum SftpAuthMethodsEnum {
  KEY = 'Key based',
  LOGIN = 'Login',
}
export enum DialogStates {
  edit = 'edit',
  root = 'root',
}
export enum DialogWidths {
  edit = '480px',
  root = '668px',
}
export enum DialogTitles {
  edit = 'Configure SFTP Location',
  create = 'Add new SFTP Location',
  listing = 'SFTP Locations',
  delete = 'Delete SFTP Confirmation',
}

export const DialogToasts: Record<string, IMessageOptions> = {
  addEditSuccess: {
    message: 'Reports Delivery Settings Configured Successfully',
    type: 'success',
  },
  addEditError: {
    message: 'Something went wrong.',
    type: 'error',
  },
  addEditInvalidForm: {
    message: 'Please correctly fill out all fields!',
    type: 'error',
  },
}
