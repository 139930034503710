/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarMeterDto,
    EnergyStarMeterDtoFromJSON,
    EnergyStarMeterDtoFromJSONTyped,
    EnergyStarMeterDtoToJSON,
    EnergyStarVirtualAccount,
    EnergyStarVirtualAccountFromJSON,
    EnergyStarVirtualAccountFromJSONTyped,
    EnergyStarVirtualAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarMetersDto
 */
export interface EnergyStarMetersDto {
    /**
     * 
     * @type {Array<EnergyStarMeterDto>}
     * @memberof EnergyStarMetersDto
     */
    meters: Array<EnergyStarMeterDto>;
    /**
     * 
     * @type {Array<EnergyStarVirtualAccount>}
     * @memberof EnergyStarMetersDto
     */
    unassignedVirtualAccounts: Array<EnergyStarVirtualAccount>;
}

export function EnergyStarMetersDtoFromJSON(json: any): EnergyStarMetersDto {
    return EnergyStarMetersDtoFromJSONTyped(json, false);
}

export function EnergyStarMetersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarMetersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meters': ((json['meters'] as Array<any>).map(EnergyStarMeterDtoFromJSON)),
        'unassignedVirtualAccounts': ((json['unassignedVirtualAccounts'] as Array<any>).map(EnergyStarVirtualAccountFromJSON)),
    };
}

export function EnergyStarMetersDtoToJSON(value?: EnergyStarMetersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meters': ((value.meters as Array<any>).map(EnergyStarMeterDtoToJSON)),
        'unassignedVirtualAccounts': ((value.unassignedVirtualAccounts as Array<any>).map(EnergyStarVirtualAccountToJSON)),
    };
}


