/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Hierarchy,
    HierarchyFromJSON,
    HierarchyFromJSONTyped,
    HierarchyToJSON,
    KeyValuePair,
    KeyValuePairFromJSON,
    KeyValuePairFromJSONTyped,
    KeyValuePairToJSON,
} from './';

/**
 * 
 * @export
 * @interface LocationHierarchiesFiltersMeta
 */
export interface LocationHierarchiesFiltersMeta {
    /**
     * 
     * @type {Array<Hierarchy>}
     * @memberof LocationHierarchiesFiltersMeta
     */
    hierarchies: Array<Hierarchy>;
    /**
     * 
     * @type {Array<KeyValuePair>}
     * @memberof LocationHierarchiesFiltersMeta
     */
    unmatchedLocations: Array<KeyValuePair>;
}

export function LocationHierarchiesFiltersMetaFromJSON(json: any): LocationHierarchiesFiltersMeta {
    return LocationHierarchiesFiltersMetaFromJSONTyped(json, false);
}

export function LocationHierarchiesFiltersMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationHierarchiesFiltersMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hierarchies': ((json['hierarchies'] as Array<any>).map(HierarchyFromJSON)),
        'unmatchedLocations': ((json['unmatchedLocations'] as Array<any>).map(KeyValuePairFromJSON)),
    };
}

export function LocationHierarchiesFiltersMetaToJSON(value?: LocationHierarchiesFiltersMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'hierarchies': ((value.hierarchies as Array<any>).map(HierarchyToJSON)),
        'unmatchedLocations': ((value.unmatchedLocations as Array<any>).map(KeyValuePairToJSON)),
    };
}


