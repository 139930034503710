/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccountMetadataAttribute,
    VirtualAccountMetadataAttributeFromJSON,
    VirtualAccountMetadataAttributeFromJSONTyped,
    VirtualAccountMetadataAttributeToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountsAttributeMapping
 */
export interface VirtualAccountsAttributeMapping {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountsAttributeMapping
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof VirtualAccountsAttributeMapping
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VirtualAccountsAttributeMapping
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountsAttributeMapping
     */
    virtualAccountsAttributesMetadataId: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountsAttributeMapping
     */
    attributeValue: string;
    /**
     * 
     * @type {VirtualAccountMetadataAttribute}
     * @memberof VirtualAccountsAttributeMapping
     */
    virtualAccountsAttributesMetadata: VirtualAccountMetadataAttribute;
}

export function VirtualAccountsAttributeMappingFromJSON(json: any): VirtualAccountsAttributeMapping {
    return VirtualAccountsAttributeMappingFromJSONTyped(json, false);
}

export function VirtualAccountsAttributeMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountsAttributeMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'virtualAccountsAttributesMetadataId': json['virtualAccountsAttributesMetadataId'],
        'attributeValue': json['attributeValue'],
        'virtualAccountsAttributesMetadata': VirtualAccountMetadataAttributeFromJSON(json['virtualAccountsAttributesMetadata']),
    };
}

export function VirtualAccountsAttributeMappingToJSON(value?: VirtualAccountsAttributeMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'virtualAccountsAttributesMetadataId': value.virtualAccountsAttributesMetadataId,
        'attributeValue': value.attributeValue,
        'virtualAccountsAttributesMetadata': VirtualAccountMetadataAttributeToJSON(value.virtualAccountsAttributesMetadata),
    };
}


