/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TargetDate
 */
export interface TargetDate {
    /**
     * 
     * @type {Date}
     * @memberof TargetDate
     */
    originalTargetDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof TargetDate
     */
    targetDate: Date;
}

export function TargetDateFromJSON(json: any): TargetDate {
    return TargetDateFromJSONTyped(json, false);
}

export function TargetDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): TargetDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'originalTargetDate': (new Date(json['originalTargetDate'])),
        'targetDate': (new Date(json['targetDate'])),
    };
}

export function TargetDateToJSON(value?: TargetDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'originalTargetDate': (value.originalTargetDate.toISOString()),
        'targetDate': (value.targetDate.toISOString()),
    };
}


