/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SftpConnectionDto
 */
export interface SftpConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof SftpConnectionDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnectionDto
     */
    host: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnectionDto
     */
    folder: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnectionDto
     */
    featureType: SftpConnectionDtoFeatureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SftpConnectionDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnectionDto
     */
    password: string;
}

/**
* @export
* @enum {string}
*/
export enum SftpConnectionDtoFeatureTypeEnum {
    PaymentFiles = 'paymentFiles',
    Reports = 'reports'
}

export function SftpConnectionDtoFromJSON(json: any): SftpConnectionDto {
    return SftpConnectionDtoFromJSONTyped(json, false);
}

export function SftpConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SftpConnectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'host': json['host'],
        'folder': json['folder'],
        'featureType': json['featureType'],
        'username': json['username'],
        'password': json['password'],
    };
}

export function SftpConnectionDtoToJSON(value?: SftpConnectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'host': value.host,
        'folder': value.folder,
        'featureType': value.featureType,
        'username': value.username,
        'password': value.password,
    };
}


