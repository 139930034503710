import {
  CustomerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRequest,
  CustomerSftpConnectionsControllerCreateSftpConnectionRequest,
  CustomerSftpConnectionsControllerDeleteSftpConnectionRequest,
  CustomerSftpConnectionsControllerGetSftpConnectionsRequest,
  CustomerSftpConnectionsControllerUpdateSftpConnectionRequest,
  SftpApi,
  SftpConnection,
  SftpConnectionListDto,
  SftpConnectionScheduledReports,
  SftpControllerTestSFTPRequest,
  SftpPublicKeyDto,
  TestSftpConnectionResponseDto,
} from '@/SharedModule/Api/index'
import { ApiService } from '@/SharedModule/Services/service.api'

export class SftpService extends ApiService {
  private sftpApi = new SftpApi(this.getBaseOptions())

  public getSftpConnections = (
    requestParameters: CustomerSftpConnectionsControllerGetSftpConnectionsRequest,
  ): Promise<SftpConnectionListDto> => {
    return this.sftpApi.customerSftpConnectionsControllerGetSftpConnections(
      requestParameters,
    )
  }
  deleteSftpConnection = (
    requestParameters: CustomerSftpConnectionsControllerDeleteSftpConnectionRequest,
  ): Promise<void> => {
    return this.sftpApi.customerSftpConnectionsControllerDeleteSftpConnection(
      requestParameters,
    )
  }
  createSftpConnection = (
    requestParameters: CustomerSftpConnectionsControllerCreateSftpConnectionRequest,
  ): Promise<SftpConnection> => {
    return this.sftpApi.customerSftpConnectionsControllerCreateSftpConnection(
      requestParameters,
    )
  }
  testSftpConnection = (
    requestParameters: SftpControllerTestSFTPRequest,
  ): Promise<TestSftpConnectionResponseDto> => {
    return this.sftpApi.sftpControllerTestSFTP(requestParameters)
  }
  getSftpConnectionReportsCount = (
    requestParameters: CustomerSftpConnectionsControllerCountScheduledReportsBySFTPConnectionRequest,
  ): Promise<SftpConnectionScheduledReports> => {
    return this.sftpApi.customerSftpConnectionsControllerCountScheduledReportsBySFTPConnection(
      requestParameters,
    )
  }
  editSftpConnection = (
    requestParameters: CustomerSftpConnectionsControllerUpdateSftpConnectionRequest,
  ): Promise<SftpConnection> => {
    return this.sftpApi.customerSftpConnectionsControllerUpdateSftpConnection(
      requestParameters,
    )
  }
  getSSHKey = (): Promise<SftpPublicKeyDto> => {
    return this.sftpApi.sftpControllerGetSFTPSSHPublicKey()
  }
}
