/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    authType: LoginDtoAuthTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    token?: string;
}

/**
* @export
* @enum {string}
*/
export enum LoginDtoAuthTypeEnum {
    Basic = 'basic',
    AzureToken = 'azureToken'
}

export function LoginDtoFromJSON(json: any): LoginDto {
    return LoginDtoFromJSONTyped(json, false);
}

export function LoginDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authType': json['authType'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function LoginDtoToJSON(value?: LoginDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authType': value.authType,
        'email': value.email,
        'password': value.password,
        'token': value.token,
    };
}


