/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationCustomAttributesValueModel,
    LocationCustomAttributesValueModelFromJSON,
    LocationCustomAttributesValueModelFromJSONTyped,
    LocationCustomAttributesValueModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface LocationPayloadModel
 */
export interface LocationPayloadModel {
    /**
     * 
     * @type {string}
     * @memberof LocationPayloadModel
     */
    siteDivision: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPayloadModel
     */
    siteFax: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPayloadModel
     */
    siteNumber: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPayloadModel
     */
    sitePhone: string;
    /**
     * 
     * @type {string}
     * @memberof LocationPayloadModel
     */
    siteStatus: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationPayloadModel
     */
    siteUnderConstruction: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LocationPayloadModel
     */
    taxExempt: boolean;
    /**
     * 
     * @type {Array<LocationCustomAttributesValueModel>}
     * @memberof LocationPayloadModel
     */
    customAttributes?: Array<LocationCustomAttributesValueModel>;
}

export function LocationPayloadModelFromJSON(json: any): LocationPayloadModel {
    return LocationPayloadModelFromJSONTyped(json, false);
}

export function LocationPayloadModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationPayloadModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'siteDivision': json['siteDivision'],
        'siteFax': json['siteFax'],
        'siteNumber': json['siteNumber'],
        'sitePhone': json['sitePhone'],
        'siteStatus': json['siteStatus'],
        'siteUnderConstruction': json['siteUnderConstruction'],
        'taxExempt': json['taxExempt'],
        'customAttributes': !exists(json, 'customAttributes') ? undefined : ((json['customAttributes'] as Array<any>).map(LocationCustomAttributesValueModelFromJSON)),
    };
}

export function LocationPayloadModelToJSON(value?: LocationPayloadModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'siteDivision': value.siteDivision,
        'siteFax': value.siteFax,
        'siteNumber': value.siteNumber,
        'sitePhone': value.sitePhone,
        'siteStatus': value.siteStatus,
        'siteUnderConstruction': value.siteUnderConstruction,
        'taxExempt': value.taxExempt,
        'customAttributes': value.customAttributes === undefined ? undefined : ((value.customAttributes as Array<any>).map(LocationCustomAttributesValueModelToJSON)),
    };
}


