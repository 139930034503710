/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetCreateDto
 */
export interface WidgetCreateDto {
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateDto
     */
    chartType: string;
    /**
     * 
     * @type {object}
     * @memberof WidgetCreateDto
     */
    filters: object;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WidgetCreateDto
     */
    sortOrder: number;
    /**
     * 
     * @type {string}
     * @memberof WidgetCreateDto
     */
    type: string;
}

export function WidgetCreateDtoFromJSON(json: any): WidgetCreateDto {
    return WidgetCreateDtoFromJSONTyped(json, false);
}

export function WidgetCreateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetCreateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chartType': json['chartType'],
        'filters': json['filters'],
        'name': json['name'],
        'sortOrder': json['sortOrder'],
        'type': json['type'],
    };
}

export function WidgetCreateDtoToJSON(value?: WidgetCreateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chartType': value.chartType,
        'filters': value.filters,
        'name': value.name,
        'sortOrder': value.sortOrder,
        'type': value.type,
    };
}


