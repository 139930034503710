/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationProductionData
 */
export interface LocationProductionData {
    /**
     * 
     * @type {number}
     * @memberof LocationProductionData
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LocationProductionData
     */
    attributeName: string;
    /**
     * 
     * @type {string}
     * @memberof LocationProductionData
     */
    attributeType: string;
    /**
     * 
     * @type {string}
     * @memberof LocationProductionData
     */
    attributeUom: string;
    /**
     * 
     * @type {string}
     * @memberof LocationProductionData
     */
    dateMonth: string;
    /**
     * 
     * @type {number}
     * @memberof LocationProductionData
     */
    sortOrder: number;
    /**
     * 
     * @type {string}
     * @memberof LocationProductionData
     */
    attributeValue: string;
}

export function LocationProductionDataFromJSON(json: any): LocationProductionData {
    return LocationProductionDataFromJSONTyped(json, false);
}

export function LocationProductionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationProductionData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attributeName': json['attributeName'],
        'attributeType': json['attributeType'],
        'attributeUom': json['attributeUom'],
        'dateMonth': json['dateMonth'],
        'sortOrder': json['sortOrder'],
        'attributeValue': json['attributeValue'],
    };
}

export function LocationProductionDataToJSON(value?: LocationProductionData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attributeName': value.attributeName,
        'attributeType': value.attributeType,
        'attributeUom': value.attributeUom,
        'dateMonth': value.dateMonth,
        'sortOrder': value.sortOrder,
        'attributeValue': value.attributeValue,
    };
}


