import {
  TableOptions,
  TableQuery,
  TableRepository,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import {
  CustomersControllerGetListExcludeEnum,
  PaymentEventsListDto,
  PaymentsControllerGetEventsRequest,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { ErrorsService } from '@/Modules/Errors/service/service.errors'
import { subDays } from 'date-fns'
import { isEmpty, isNil } from 'lodash'

const errorsService = new ErrorsService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      customerIds: null,
      eventTypes: ['Warning', 'Error'],
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      createdAtStart: null,
      createdAtEnd: null,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({
      paymentDate: null,
      createdAt: null,
      customerId: null,
      paymentFileFormat: null,
      type: null,
      billMetaId: null,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('Payment Date'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('paymentDate'),
      },
      {
        prettyName: ref('Creation Date'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('createdAt'),
      },
      {
        prettyName: ref('Customer'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('customerId'),
      },
      {
        prettyName: ref('Format'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('paymentFileFormat'),
      },
      {
        prettyName: ref('Event'),
        labelClassName: null,
        name: null,
        type: null,
        width: null,
        modelKey: ref('event'),
      },
      {
        prettyName: ref('Type'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('type'),
      },
      {
        prettyName: ref('Bill ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('billMetaId'),
      },
      {
        prettyName: ref('Description'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('description'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'errorsTab.paymenterrors',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: any,
  sortBy: any,
  meta: any,
): any => {
  if (sortBy) {
    filterData.orderBy = filterData.orderBy || []
    Object.keys(sortBy).forEach((currentSortKey: string) => {
      const destructuredFilterData: any = filterData
      if (sortBy[currentSortKey] != null) {
        filterData.orderBy?.push(
          currentSortKey + (sortBy[currentSortKey] ? '.asc' : '.desc'),
        )
      }
      delete destructuredFilterData[currentSortKey]
    })

    if (!filterData.orderBy?.length) {
      delete filterData.orderBy
    }
  }
  if (filterData.startDate) {
    filterData.startDate = new Date(filterData.startDate)
  }
  if (filterData.endDate) {
    filterData.endDate = new Date(filterData.endDate)
  }
  if (filterData.createdAtStart) {
    filterData.createdAtStart = new Date(filterData.createdAtStart)
  }
  if (filterData.createdAtEnd) {
    filterData.createdAtEnd = new Date(filterData.createdAtEnd)
  }
  if (filterData.eventTypes) {
    filterData.eventTypes = filterData.eventTypes.toString().split(',')
  }
  if (filterData.customerIds) {
    filterData.customerIds = filterData.customerIds
      .toString()
      .split(',')
      .map((currentId: string) => +currentId)
  }
  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(CustomersControllerGetListExcludeEnum.Filters)
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        CustomersControllerGetListExcludeEnum.Total,
        CustomersControllerGetListExcludeEnum.Extra,
      )
    }
  }
  return filterData
}

const useListRef = (): Ref<PaymentEventsListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  }) as Ref<PaymentEventsListDto>

export default class RepositoryPaymentsErrors
  implements TableRepository<PaymentEventsListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  constructor(customerId: number | null = null) {
    this.options.meta.value.customerId = customerId
  }
  transformList(listValue: PaymentEventsListDto): PaymentEventsListDto {
    listValue.results.forEach((error: any) => {
      const errTypesByEvent = this.options.meta.value.apiFilters.events.find(
        (errType: any) => +errType.type === +error.event,
      )
      if (isNil(errTypesByEvent)) {
        error.event = '-'
        return
      }
      error.event = errTypesByEvent.description
    })
    listValue.results.forEach((error: any) => {
      const errTypesByCustomer =
        this.options.meta.value.apiFilters.customers.find(
          (errType: any) => +errType.id === +error.customerId,
        )
      if (isNil(errTypesByCustomer)) {
        error.name = '-'
        return
      }
      error.name = errTypesByCustomer.name
    })
    return listValue
  }
  getList = async (): Promise<void> => {
    try {
      const listValue = (await errorsService.getEvents(
        adaptFilterDataToApiTypesAndExclude(
          useQueryAsRequest<PaymentsControllerGetEventsRequest>(
            this.options.query,
          ),
          this.options.query.sortBy?.value,
          this.options.meta.value,
        ),
      )) as any
      if (this.options.meta.value.apiFilters === null) {
        this.options.meta.value.apiFilters = listValue.filters
      }
      if (this.options.meta.value.excludePaginationData) {
        listValue.extra = this.list.value.extra
        listValue.total = this.list.value.total
        this.options.meta.value.excludePaginationData = false
      }
      this.list.value = this.transformList(listValue)
    } catch (e) {
      console.log(e)
    }
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
