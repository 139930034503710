import {
  BatchErrorsDto,
  BatchesApi,
  BatchesControllerAutoResolveBatchErrorsRequest,
  BatchesControllerGetBatchErrorsRequest,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class BatchesService extends ApiService {
  private readonly batchesApi = new BatchesApi(this.getBaseOptions())

  public getBatchErrors(
    requestParameters: BatchesControllerGetBatchErrorsRequest,
  ): Promise<BatchErrorsDto> {
    return this.batchesApi.batchesControllerGetBatchErrors(requestParameters)
  }

  public autoResolveBatchErrors(
    requestParameters: BatchesControllerAutoResolveBatchErrorsRequest,
  ): Promise<void> {
    return this.batchesApi.batchesControllerAutoResolveBatchErrors(
      requestParameters,
    )
  }
}
