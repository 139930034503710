/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerException
 */
export interface CustomerException {
    /**
     * 
     * @type {number}
     * @memberof CustomerException
     */
    itemsCount: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerException
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerException
     */
    serviceAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerException
     */
    accountCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerException
     */
    prettyName: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerException
     */
    vendorCode: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerException
     */
    serviceZip: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerException
     */
    virtualAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerException
     */
    meterSerial: number;
}

export function CustomerExceptionFromJSON(json: any): CustomerException {
    return CustomerExceptionFromJSONTyped(json, false);
}

export function CustomerExceptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerException {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemsCount': json['itemsCount'],
        'customerId': json['customerId'],
        'serviceAddress': json['serviceAddress'],
        'accountCode': json['accountCode'],
        'prettyName': json['prettyName'],
        'vendorCode': json['vendorCode'],
        'serviceZip': json['serviceZip'],
        'virtualAccountId': json['virtualAccountId'],
        'meterSerial': json['meterSerial'],
    };
}

export function CustomerExceptionToJSON(value?: CustomerException | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'itemsCount': value.itemsCount,
        'customerId': value.customerId,
        'serviceAddress': value.serviceAddress,
        'accountCode': value.accountCode,
        'prettyName': value.prettyName,
        'vendorCode': value.vendorCode,
        'serviceZip': value.serviceZip,
        'virtualAccountId': value.virtualAccountId,
        'meterSerial': value.meterSerial,
    };
}


