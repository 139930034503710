/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Vendor,
    VendorFromJSON,
    VendorFromJSONTyped,
    VendorToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorListDto
 */
export interface VendorListDto {
    /**
     * 
     * @type {Array<Vendor>}
     * @memberof VendorListDto
     */
    results: Array<Vendor>;
    /**
     * 
     * @type {number}
     * @memberof VendorListDto
     */
    total?: number;
    /**
     * 
     * @type {object}
     * @memberof VendorListDto
     */
    filters?: object;
    /**
     * 
     * @type {object}
     * @memberof VendorListDto
     */
    extra?: object;
}

export function VendorListDtoFromJSON(json: any): VendorListDto {
    return VendorListDtoFromJSONTyped(json, false);
}

export function VendorListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(VendorFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : json['filters'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function VendorListDtoToJSON(value?: VendorListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(VendorToJSON)),
        'total': value.total,
        'filters': value.filters,
        'extra': value.extra,
    };
}


