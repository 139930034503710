/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchPayloadModel
 */
export interface BatchPayloadModel {
    /**
     * 
     * @type {number}
     * @memberof BatchPayloadModel
     */
    billId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchPayloadModel
     */
    comment: string;
    /**
     * 
     * @type {number}
     * @memberof BatchPayloadModel
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof BatchPayloadModel
     */
    batchId: number;
    /**
     * 
     * @type {string}
     * @memberof BatchPayloadModel
     */
    batchName: string;
    /**
     * 
     * @type {number}
     * @memberof BatchPayloadModel
     */
    customerId: number;
}

export function BatchPayloadModelFromJSON(json: any): BatchPayloadModel {
    return BatchPayloadModelFromJSONTyped(json, false);
}

export function BatchPayloadModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchPayloadModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'comment': json['comment'],
        'userId': json['userId'],
        'batchId': json['batchId'],
        'batchName': json['batchName'],
        'customerId': json['customerId'],
    };
}

export function BatchPayloadModelToJSON(value?: BatchPayloadModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'comment': value.comment,
        'userId': value.userId,
        'batchId': value.batchId,
        'batchName': value.batchName,
        'customerId': value.customerId,
    };
}


