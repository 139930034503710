import {
  TableOptions,
  TableQuery,
  TableRepository,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import {
  BillsDashboardListDto,
  CustomersControllerGetListExcludeEnum,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { BillsService } from '@/Modules/Bills/service/service.bills'

const billsService = new BillsService()
const sortByDefault = {
  integrityCheck: null,
  dataVerification1: null,
  dataVerification2: null,
  dataAudit1: null,
  dataAudit2: null,
  error: null,
  deleted: null,
  processed: null,
}
const useQuery = (): TableQuery => {
  return {
    filters: ref({
      customerIds: null,
      batchId: null,
      customerActive: true,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({
      error: null,
      integrityCheck: null,
      dataVerification1: null,
      dataVerification2: null,
      dataAudit1: null,
      dataAudit2: null,
      deleted: null,
      processed: null,
    }),
    isSingleSort: ref(true),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('Customer'),
        labelClassName: null,
        name: null,
        width: ref('250'),
        type: null,
        modelKey: ref('customerName'),
      },
      {
        prettyName: ref('Error'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('error'),
      },
      {
        prettyName: ref('Integrity Check'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('integrityCheck'),
      },
      {
        prettyName: ref('Data Verification I'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('dataVerification1'),
      },
      {
        prettyName: ref('Data Verification II'),
        labelClassName: null,
        name: null,
        type: null,
        width: null,
        modelKey: ref('dataVerification2'),
      },
      {
        prettyName: ref('Data Audit I'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('dataAudit1'),
      },
      {
        prettyName: ref('Data Audit II'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('dataAudit2'),
      },
      {
        prettyName: ref('Deleted'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('deleted'),
      },
      {
        prettyName: ref('Processed'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('processed'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'bills.dashboard',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: any,
  sortBy: any,
  meta: any,
): any => {
  if (sortBy) {
    filterData.orderBy = filterData.orderBy || ''
    Object.keys(sortBy).forEach((currentSortKey: string) => {
      const destructuredFilterData: any = filterData
      if (sortBy[currentSortKey] != null) {
        filterData.orderBy =
          currentSortKey + (sortBy[currentSortKey] ? '.asc' : '.desc')
        sortBy = { ...sortByDefault, [currentSortKey]: sortBy[currentSortKey] }
      }
      delete destructuredFilterData[currentSortKey]
    })

    if (!filterData.orderBy?.length) {
      delete filterData.orderBy
    }
  }
  if (filterData.customerIds) {
    filterData.customerIds = filterData.customerIds
      .toString()
      .split(',')
      .map((currentId: string) => +currentId)
  }
  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(CustomersControllerGetListExcludeEnum.Filters)
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        CustomersControllerGetListExcludeEnum.Total,
        CustomersControllerGetListExcludeEnum.Extra,
      )
    }
  }
  return filterData
}

const useListRef = (): Ref<BillsDashboardListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  }) as Ref<BillsDashboardListDto>

export default class RepositoryBillsDashboard
  implements TableRepository<BillsDashboardListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  constructor(customerId: number | null = null) {
    this.options.meta.value.customerId = customerId
  }
  getList = async (): Promise<void> => {
    try {
      const listValue = (await billsService.getBillsDashboard(
        adaptFilterDataToApiTypesAndExclude(
          useQueryAsRequest<BillsDashboardListDto>(this.options.query),
          this.options.query.sortBy?.value,
          this.options.meta.value,
        ),
      )) as any
      if (this.options.meta.value.apiFilters === null) {
        this.options.meta.value.apiFilters = listValue.filters
      }
      if (this.options.meta.value.excludePaginationData) {
        listValue.extra = this.list.value.extra
        listValue.total = this.list.value.total
        this.options.meta.value.excludePaginationData = false
      }
      listValue.extra.customerName = 'Overall total:'
      listValue.results.unshift(listValue.extra)
      this.list.value = listValue
    } catch (e) {
      console.log(e)
    }
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()
    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
