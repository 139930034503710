/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillErrorModel,
    BillErrorModelFromJSON,
    BillErrorModelFromJSONTyped,
    BillErrorModelToJSON,
    BillingLineItem,
    BillingLineItemFromJSON,
    BillingLineItemFromJSONTyped,
    BillingLineItemToJSON,
    CalculationModel,
    CalculationModelFromJSON,
    CalculationModelFromJSONTyped,
    CalculationModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationAttribute,
    LocationAttributeFromJSON,
    LocationAttributeFromJSONTyped,
    LocationAttributeToJSON,
    VirtualAccount,
    VirtualAccountFromJSON,
    VirtualAccountFromJSONTyped,
    VirtualAccountToJSON,
    VirtualAccountsAttributeMapping,
    VirtualAccountsAttributeMappingFromJSON,
    VirtualAccountsAttributeMappingFromJSONTyped,
    VirtualAccountsAttributeMappingToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillItem
 */
export interface BillItem {
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    virtualAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    virtualAccountGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    priorBillId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    billMetaId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    billRecordId: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    daysOfService: number;
    /**
     * 
     * @type {number}
     * @memberof BillItem
     */
    unitCost: number;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    commodity: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    meterSerial: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    accountCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    serviceAddress: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    vendorCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    rateCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    controlCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    serviceZip: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    serviceLocation: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    billType: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    serviceDateMethod: string;
    /**
     * 
     * @type {Date}
     * @memberof BillItem
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    startDate: string;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    endDate: string;
    /**
     * 
     * @type {Date}
     * @memberof BillItem
     */
    adjustedEndDate: Date;
    /**
     * 
     * @type {Location}
     * @memberof BillItem
     */
    location: Location;
    /**
     * 
     * @type {VirtualAccount}
     * @memberof BillItem
     */
    virtualAccount: VirtualAccount;
    /**
     * 
     * @type {Array<BillErrorModel>}
     * @memberof BillItem
     */
    errors: Array<BillErrorModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof BillItem
     */
    files: Array<FileModel>;
    /**
     * 
     * @type {Array<BillingLineItem>}
     * @memberof BillItem
     */
    billingLineItems: Array<BillingLineItem>;
    /**
     * 
     * @type {Array<CalculationModel>}
     * @memberof BillItem
     */
    calculations: Array<CalculationModel>;
    /**
     * 
     * @type {Array<CalculationModel>}
     * @memberof BillItem
     */
    weatherCalculations: Array<CalculationModel>;
    /**
     * 
     * @type {Array<LocationAttribute>}
     * @memberof BillItem
     */
    customLocationAttributes: Array<LocationAttribute>;
    /**
     * 
     * @type {boolean}
     * @memberof BillItem
     */
    estimated: boolean;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    spid: string;
    /**
     * 
     * @type {object}
     * @memberof BillItem
     */
    payload: object;
    /**
     * 
     * @type {Array<VirtualAccountsAttributeMapping>}
     * @memberof BillItem
     */
    customVirtualAccountAttributes?: Array<VirtualAccountsAttributeMapping>;
    /**
     * 
     * @type {Date}
     * @memberof BillItem
     */
    vaFrozenAt: Date;
    /**
     * 
     * @type {string}
     * @memberof BillItem
     */
    vaFrozenReason: string;
}

export function BillItemFromJSON(json: any): BillItem {
    return BillItemFromJSONTyped(json, false);
}

export function BillItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'locationId': json['locationId'],
        'virtualAccountId': json['virtualAccountId'],
        'virtualAccountGroupId': json['virtualAccountGroupId'],
        'priorBillId': json['priorBillId'],
        'billMetaId': json['billMetaId'],
        'billRecordId': json['billRecordId'],
        'daysOfService': json['daysOfService'],
        'unitCost': json['unitCost'],
        'commodity': json['commodity'],
        'meterSerial': json['meterSerial'],
        'accountCode': json['accountCode'],
        'serviceAddress': json['serviceAddress'],
        'vendorCode': json['vendorCode'],
        'rateCode': json['rateCode'],
        'controlCode': json['controlCode'],
        'serviceZip': json['serviceZip'],
        'serviceLocation': json['serviceLocation'],
        'billType': json['billType'],
        'serviceDateMethod': json['serviceDateMethod'],
        'createdAt': (new Date(json['createdAt'])),
        'startDate': json['startDate'],
        'endDate': json['endDate'],
        'adjustedEndDate': (new Date(json['adjustedEndDate'])),
        'location': LocationFromJSON(json['location']),
        'virtualAccount': VirtualAccountFromJSON(json['virtualAccount']),
        'errors': ((json['errors'] as Array<any>).map(BillErrorModelFromJSON)),
        'files': ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'billingLineItems': ((json['billingLineItems'] as Array<any>).map(BillingLineItemFromJSON)),
        'calculations': ((json['calculations'] as Array<any>).map(CalculationModelFromJSON)),
        'weatherCalculations': ((json['weatherCalculations'] as Array<any>).map(CalculationModelFromJSON)),
        'customLocationAttributes': ((json['customLocationAttributes'] as Array<any>).map(LocationAttributeFromJSON)),
        'estimated': json['estimated'],
        'spid': json['spid'],
        'payload': json['payload'],
        'customVirtualAccountAttributes': !exists(json, 'customVirtualAccountAttributes') ? undefined : ((json['customVirtualAccountAttributes'] as Array<any>).map(VirtualAccountsAttributeMappingFromJSON)),
        'vaFrozenAt': (new Date(json['vaFrozenAt'])),
        'vaFrozenReason': json['vaFrozenReason'],
    };
}

export function BillItemToJSON(value?: BillItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'locationId': value.locationId,
        'virtualAccountId': value.virtualAccountId,
        'virtualAccountGroupId': value.virtualAccountGroupId,
        'priorBillId': value.priorBillId,
        'billMetaId': value.billMetaId,
        'billRecordId': value.billRecordId,
        'daysOfService': value.daysOfService,
        'unitCost': value.unitCost,
        'commodity': value.commodity,
        'meterSerial': value.meterSerial,
        'accountCode': value.accountCode,
        'serviceAddress': value.serviceAddress,
        'vendorCode': value.vendorCode,
        'rateCode': value.rateCode,
        'controlCode': value.controlCode,
        'serviceZip': value.serviceZip,
        'serviceLocation': value.serviceLocation,
        'billType': value.billType,
        'serviceDateMethod': value.serviceDateMethod,
        'createdAt': (value.createdAt.toISOString()),
        'startDate': value.startDate,
        'endDate': value.endDate,
        'adjustedEndDate': (value.adjustedEndDate.toISOString()),
        'location': LocationToJSON(value.location),
        'virtualAccount': VirtualAccountToJSON(value.virtualAccount),
        'errors': ((value.errors as Array<any>).map(BillErrorModelToJSON)),
        'files': ((value.files as Array<any>).map(FileModelToJSON)),
        'billingLineItems': ((value.billingLineItems as Array<any>).map(BillingLineItemToJSON)),
        'calculations': ((value.calculations as Array<any>).map(CalculationModelToJSON)),
        'weatherCalculations': ((value.weatherCalculations as Array<any>).map(CalculationModelToJSON)),
        'customLocationAttributes': ((value.customLocationAttributes as Array<any>).map(LocationAttributeToJSON)),
        'estimated': value.estimated,
        'spid': value.spid,
        'payload': value.payload,
        'customVirtualAccountAttributes': value.customVirtualAccountAttributes === undefined ? undefined : ((value.customVirtualAccountAttributes as Array<any>).map(VirtualAccountsAttributeMappingToJSON)),
        'vaFrozenAt': (value.vaFrozenAt.toISOString()),
        'vaFrozenReason': value.vaFrozenReason,
    };
}


