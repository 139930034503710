/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentEntry
 */
export interface PaymentEntry {
    /**
     * 
     * @type {number}
     * @memberof PaymentEntry
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentEntry
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentEntry
     */
    sum: number;
    /**
     * 
     * @type {Date}
     * @memberof PaymentEntry
     */
    paymentFileDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PaymentEntry
     */
    batchStatus: string;
}

export function PaymentEntryFromJSON(json: any): PaymentEntry {
    return PaymentEntryFromJSONTyped(json, false);
}

export function PaymentEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'count': json['count'],
        'sum': json['sum'],
        'paymentFileDate': (new Date(json['paymentFileDate'])),
        'batchStatus': json['batchStatus'],
    };
}

export function PaymentEntryToJSON(value?: PaymentEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'count': value.count,
        'sum': value.sum,
        'paymentFileDate': (value.paymentFileDate.toISOString()),
        'batchStatus': value.batchStatus,
    };
}


