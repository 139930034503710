import {
  AffectedBillsAndBillBlocksModel,
  BulkBillDetailsWorkflowStateModel,
  CustomerVirtualAccountsApi,
  CustomerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRequest,
  CustomerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRequest,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomerVirtualAccountsService extends ApiService {
  private readonly customerVirtualAccountsApi = new CustomerVirtualAccountsApi(
    this.getBaseOptions(),
  )
  public getTotalAffectedBillsAndBillBlocks(
    requestParameters: CustomerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRequest,
  ): Promise<AffectedBillsAndBillBlocksModel> {
    return this.customerVirtualAccountsApi.customerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocks(
      requestParameters,
    )
  }
  public bulkUpdateVirtualAccount(
    requestParameters: CustomerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRequest,
  ): Promise<BulkBillDetailsWorkflowStateModel> {
    return this.customerVirtualAccountsApi.customerVirtualAccountsBillsControllerBulkUpdateVirtualAccount(
      requestParameters,
    )
  }
}
