/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarNoScoreError
 */
export interface EnergyStarNoScoreError {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarNoScoreError
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarNoScoreError
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarNoScoreError
     */
    reason: string;
}

export function EnergyStarNoScoreErrorFromJSON(json: any): EnergyStarNoScoreError {
    return EnergyStarNoScoreErrorFromJSONTyped(json, false);
}

export function EnergyStarNoScoreErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarNoScoreError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'location': json['location'],
        'reason': json['reason'],
    };
}

export function EnergyStarNoScoreErrorToJSON(value?: EnergyStarNoScoreError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'location': value.location,
        'reason': value.reason,
    };
}


