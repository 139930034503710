import { ApiService } from '@/SharedModule/Services/service.api'
import {
  CustomerPermissionsApi,
  CustomerPermissionsControllerSetLocationsRequest,
  CustomerPermissionsControllerSetPermissionsRequest,
  UserCustomerPermissionListDto,
} from '@/SharedModule/Api'

export class CustomerPermissionsService extends ApiService {
  private readonly customerPermissionsApi = new CustomerPermissionsApi(
    this.getBaseOptions(),
  )

  public assignLocations(
    requestParameters: CustomerPermissionsControllerSetLocationsRequest,
  ): Promise<Array<any>> {
    return this.customerPermissionsApi.customerPermissionsControllerSetLocations(
      requestParameters,
    )
  }
  public assignPermissions(
    requestParameters: CustomerPermissionsControllerSetPermissionsRequest,
  ): Promise<UserCustomerPermissionListDto> {
    return this.customerPermissionsApi.customerPermissionsControllerSetPermissions(
      requestParameters,
    )
  }
}
