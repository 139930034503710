/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableFilter,
    AvailableFilterFromJSON,
    AvailableFilterFromJSONTyped,
    AvailableFilterToJSON,
    ColumnFormatRule,
    ColumnFormatRuleFromJSON,
    ColumnFormatRuleFromJSONTyped,
    ColumnFormatRuleToJSON,
    CubeQuery,
    CubeQueryFromJSON,
    CubeQueryFromJSONTyped,
    CubeQueryToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportTemplate
 */
export interface ReportTemplate {
    /**
     * 
     * @type {number}
     * @memberof ReportTemplate
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ReportTemplate
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplate
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplate
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof ReportTemplate
     */
    readableId: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReportTemplate
     */
    previewable: boolean;
    /**
     * 
     * @type {Array<Array<AvailableFilter>>}
     * @memberof ReportTemplate
     */
    availableFilters: Array<Array<AvailableFilter>>;
    /**
     * 
     * @type {CubeQuery}
     * @memberof ReportTemplate
     */
    templateBody: CubeQuery;
    /**
     * 
     * @type {Array<ColumnFormatRule>}
     * @memberof ReportTemplate
     */
    fieldMap: Array<ColumnFormatRule>;
    /**
     * 
     * @type {object}
     * @memberof ReportTemplate
     */
    configPayload: object;
    /**
     * 
     * @type {boolean}
     * @memberof ReportTemplate
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportTemplate
     */
    exportFormats: Array<string>;
}

export function ReportTemplateFromJSON(json: any): ReportTemplate {
    return ReportTemplateFromJSONTyped(json, false);
}

export function ReportTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTemplate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'title': json['title'],
        'description': json['description'],
        'readableId': json['readableId'],
        'previewable': json['previewable'],
        'availableFilters': json['availableFilters'],
        'templateBody': CubeQueryFromJSON(json['templateBody']),
        'fieldMap': ((json['fieldMap'] as Array<any>).map(ColumnFormatRuleFromJSON)),
        'configPayload': json['configPayload'],
        'isEnabled': json['isEnabled'],
        'exportFormats': json['exportFormats'],
    };
}

export function ReportTemplateToJSON(value?: ReportTemplate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'title': value.title,
        'description': value.description,
        'readableId': value.readableId,
        'previewable': value.previewable,
        'availableFilters': value.availableFilters,
        'templateBody': CubeQueryToJSON(value.templateBody),
        'fieldMap': ((value.fieldMap as Array<any>).map(ColumnFormatRuleToJSON)),
        'configPayload': value.configPayload,
        'isEnabled': value.isEnabled,
        'exportFormats': value.exportFormats,
    };
}


