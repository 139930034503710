import {
  CustomerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRequest,
  CustomerVirtualAccountAttributesControllerDeleteMetadataAttributeRequest,
  CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest,
  CustomerVirtualAccountAttributesControllerSortMetadataAttributesRequest,
  CustomerVirtualAccountAttributesControllerUpdateMetadataAttributeRequest,
  VirtualAccountAttributesApi,
  VirtualAccountMetadataAttribute,
  VirtualAccountMetadataAttributesListDto,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomerVirtualAccountMetadataService extends ApiService {
  private virtualAccountAttributesApi = new VirtualAccountAttributesApi(
    this.getBaseOptions(),
  )

  public getVirtualAccountMetadata = async (
    request: CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest,
  ): Promise<VirtualAccountMetadataAttributesListDto> => {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerGetMetadataAttributes(
      request,
    )
  }

  public updateVirtualAccountMetadata = async (
    request: CustomerVirtualAccountAttributesControllerUpdateMetadataAttributeRequest,
  ): Promise<VirtualAccountMetadataAttribute> => {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerUpdateMetadataAttribute(
      request,
    )
  }

  public createVirtualAccountMetadata = async (
    request: CustomerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRequest,
  ): Promise<VirtualAccountMetadataAttribute> => {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttribute(
      request,
    )
  }

  public sortVirtualAccountMetadata = async (
    request: CustomerVirtualAccountAttributesControllerSortMetadataAttributesRequest,
  ): Promise<void> => {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerSortMetadataAttributes(
      request,
    )
  }

  public deleteVirtualAccountMetadata = async (
    request: CustomerVirtualAccountAttributesControllerDeleteMetadataAttributeRequest,
  ): Promise<void> => {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerDeleteMetadataAttribute(
      request,
    )
  }
}
