/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillErrorModel
 */
export interface BillErrorModel {
    /**
     * 
     * @type {number}
     * @memberof BillErrorModel
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillErrorModel
     */
    billItemId: number;
    /**
     * 
     * @type {number}
     * @memberof BillErrorModel
     */
    severity: number;
    /**
     * 
     * @type {number}
     * @memberof BillErrorModel
     */
    validationCheckId: number;
    /**
     * 
     * @type {number}
     * @memberof BillErrorModel
     */
    billRecordId: number;
    /**
     * 
     * @type {Date}
     * @memberof BillErrorModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof BillErrorModel
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof BillErrorModel
     */
    message: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillErrorModel
     */
    resolved: boolean;
    /**
     * 
     * @type {object}
     * @memberof BillErrorModel
     */
    data: object;
    /**
     * 
     * @type {object}
     * @memberof BillErrorModel
     */
    payload: object;
}

export function BillErrorModelFromJSON(json: any): BillErrorModel {
    return BillErrorModelFromJSONTyped(json, false);
}

export function BillErrorModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillErrorModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'billItemId': json['billItemId'],
        'severity': json['severity'],
        'validationCheckId': json['validationCheckId'],
        'billRecordId': json['billRecordId'],
        'createdAt': (new Date(json['createdAt'])),
        'category': json['category'],
        'message': json['message'],
        'resolved': json['resolved'],
        'data': json['data'],
        'payload': json['payload'],
    };
}

export function BillErrorModelToJSON(value?: BillErrorModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'billItemId': value.billItemId,
        'severity': value.severity,
        'validationCheckId': value.validationCheckId,
        'billRecordId': value.billRecordId,
        'createdAt': (value.createdAt.toISOString()),
        'category': value.category,
        'message': value.message,
        'resolved': value.resolved,
        'data': value.data,
        'payload': value.payload,
    };
}


