/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccount,
    VirtualAccountFromJSON,
    VirtualAccountFromJSONTyped,
    VirtualAccountToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountGroup
 */
export interface VirtualAccountGroup {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountGroup
     */
    virtualAccountGroupId: number;
    /**
     * 
     * @type {Array<VirtualAccount>}
     * @memberof VirtualAccountGroup
     */
    virtualAccounts: Array<VirtualAccount>;
}

export function VirtualAccountGroupFromJSON(json: any): VirtualAccountGroup {
    return VirtualAccountGroupFromJSONTyped(json, false);
}

export function VirtualAccountGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'virtualAccountGroupId': json['virtualAccountGroupId'],
        'virtualAccounts': ((json['virtualAccounts'] as Array<any>).map(VirtualAccountFromJSON)),
    };
}

export function VirtualAccountGroupToJSON(value?: VirtualAccountGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'virtualAccountGroupId': value.virtualAccountGroupId,
        'virtualAccounts': ((value.virtualAccounts as Array<any>).map(VirtualAccountToJSON)),
    };
}


