/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentErrorsEmailDelivery
 */
export interface PaymentErrorsEmailDelivery {
    /**
     * 
     * @type {number}
     * @memberof PaymentErrorsEmailDelivery
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentErrorsEmailDelivery
     */
    customerId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentErrorsEmailDelivery
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentErrorsEmailDelivery
     */
    errors: Array<string>;
}

export function PaymentErrorsEmailDeliveryFromJSON(json: any): PaymentErrorsEmailDelivery {
    return PaymentErrorsEmailDeliveryFromJSONTyped(json, false);
}

export function PaymentErrorsEmailDeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentErrorsEmailDelivery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'emails': json['emails'],
        'errors': json['errors'],
    };
}

export function PaymentErrorsEmailDeliveryToJSON(value?: PaymentErrorsEmailDelivery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'emails': value.emails,
        'errors': value.errors,
    };
}


