/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ErrorReasonDto,
    ErrorReasonDtoFromJSON,
    ErrorReasonDtoFromJSONTyped,
    ErrorReasonDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillResolveErrorsDto
 */
export interface BillResolveErrorsDto {
    /**
     * 
     * @type {number}
     * @memberof BillResolveErrorsDto
     */
    billMetaId: number;
    /**
     * 
     * @type {Array<ErrorReasonDto>}
     * @memberof BillResolveErrorsDto
     */
    errors: Array<ErrorReasonDto>;
    /**
     * 
     * @type {string}
     * @memberof BillResolveErrorsDto
     */
    comment: string;
}

export function BillResolveErrorsDtoFromJSON(json: any): BillResolveErrorsDto {
    return BillResolveErrorsDtoFromJSONTyped(json, false);
}

export function BillResolveErrorsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillResolveErrorsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billMetaId': json['billMetaId'],
        'errors': ((json['errors'] as Array<any>).map(ErrorReasonDtoFromJSON)),
        'comment': json['comment'],
    };
}

export function BillResolveErrorsDtoToJSON(value?: BillResolveErrorsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billMetaId': value.billMetaId,
        'errors': ((value.errors as Array<any>).map(ErrorReasonDtoToJSON)),
        'comment': value.comment,
    };
}


