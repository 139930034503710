/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportTemplate,
    ReportTemplateFromJSON,
    ReportTemplateFromJSONTyped,
    ReportTemplateToJSON,
    SftpConnection,
    SftpConnectionFromJSON,
    SftpConnectionFromJSONTyped,
    SftpConnectionToJSON,
} from './';

/**
 * 
 * @export
 * @interface ScheduledReport
 */
export interface ScheduledReport {
    /**
     * 
     * @type {number}
     * @memberof ScheduledReport
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledReport
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ScheduledReport
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledReport
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledReport
     */
    reportTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReport
     */
    customReportName: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReport
     */
    saveFormat: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReport
     */
    recurringType: string;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledReport
     */
    startDate: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReport
     */
    time: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledReport
     */
    emailRecipients: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReport
     */
    timezone: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduledReport
     */
    appliedFilters: string;
    /**
     * 
     * @type {ReportTemplate}
     * @memberof ScheduledReport
     */
    reportTemplate: ReportTemplate;
    /**
     * 
     * @type {SftpConnection}
     * @memberof ScheduledReport
     */
    sftpConnection: SftpConnection;
}

export function ScheduledReportFromJSON(json: any): ScheduledReport {
    return ScheduledReportFromJSONTyped(json, false);
}

export function ScheduledReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'customerId': json['customerId'],
        'userId': json['userId'],
        'reportTemplateId': json['reportTemplateId'],
        'customReportName': json['customReportName'],
        'saveFormat': json['saveFormat'],
        'recurringType': json['recurringType'],
        'startDate': (new Date(json['startDate'])),
        'time': json['time'],
        'emailRecipients': json['emailRecipients'],
        'timezone': json['timezone'],
        'appliedFilters': json['appliedFilters'],
        'reportTemplate': ReportTemplateFromJSON(json['reportTemplate']),
        'sftpConnection': SftpConnectionFromJSON(json['sftpConnection']),
    };
}

export function ScheduledReportToJSON(value?: ScheduledReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'customerId': value.customerId,
        'userId': value.userId,
        'reportTemplateId': value.reportTemplateId,
        'customReportName': value.customReportName,
        'saveFormat': value.saveFormat,
        'recurringType': value.recurringType,
        'startDate': (value.startDate.toISOString()),
        'time': value.time,
        'emailRecipients': value.emailRecipients,
        'timezone': value.timezone,
        'appliedFilters': value.appliedFilters,
        'reportTemplate': ReportTemplateToJSON(value.reportTemplate),
        'sftpConnection': SftpConnectionToJSON(value.sftpConnection),
    };
}


