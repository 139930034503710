/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssigneeDto,
    AssigneeDtoFromJSON,
    AssigneeDtoFromJSONTyped,
    AssigneeDtoToJSON,
    AssigneeToMeDto,
    AssigneeToMeDtoFromJSON,
    AssigneeToMeDtoFromJSONTyped,
    AssigneeToMeDtoToJSON,
    ChangeInvoiceDateDto,
    ChangeInvoiceDateDtoFromJSON,
    ChangeInvoiceDateDtoFromJSONTyped,
    ChangeInvoiceDateDtoToJSON,
    ChangePriorityDto,
    ChangePriorityDtoFromJSON,
    ChangePriorityDtoFromJSONTyped,
    ChangePriorityDtoToJSON,
    ChangeTargetDateDto,
    ChangeTargetDateDtoFromJSON,
    ChangeTargetDateDtoFromJSONTyped,
    ChangeTargetDateDtoToJSON,
    ParkBillsDto,
    ParkBillsDtoFromJSON,
    ParkBillsDtoFromJSONTyped,
    ParkBillsDtoToJSON,
    PreviewBillsQueryDto,
    PreviewBillsQueryDtoFromJSON,
    PreviewBillsQueryDtoFromJSONTyped,
    PreviewBillsQueryDtoToJSON,
    ReparseBillsDto,
    ReparseBillsDtoFromJSON,
    ReparseBillsDtoFromJSONTyped,
    ReparseBillsDtoToJSON,
    ResolveErrorsDto,
    ResolveErrorsDtoFromJSON,
    ResolveErrorsDtoFromJSONTyped,
    ResolveErrorsDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BulkActionDto
 */
export interface BulkActionDto {
    /**
     * 
     * @type {AssigneeToMeDto}
     * @memberof BulkActionDto
     */
    assignToMe?: AssigneeToMeDto;
    /**
     * 
     * @type {AssigneeDto}
     * @memberof BulkActionDto
     */
    assign?: AssigneeDto;
    /**
     * 
     * @type {ChangePriorityDto}
     * @memberof BulkActionDto
     */
    changePriority?: ChangePriorityDto;
    /**
     * 
     * @type {ChangeTargetDateDto}
     * @memberof BulkActionDto
     */
    changeTargetDate?: ChangeTargetDateDto;
    /**
     * 
     * @type {ChangeInvoiceDateDto}
     * @memberof BulkActionDto
     */
    changeInvoiceDate?: ChangeInvoiceDateDto;
    /**
     * 
     * @type {ParkBillsDto}
     * @memberof BulkActionDto
     */
    park?: ParkBillsDto;
    /**
     * 
     * @type {ResolveErrorsDto}
     * @memberof BulkActionDto
     */
    resolveErrors?: ResolveErrorsDto;
    /**
     * 
     * @type {Array<ReparseBillsDto>}
     * @memberof BulkActionDto
     */
    reparse?: Array<ReparseBillsDto>;
    /**
     * 
     * @type {PreviewBillsQueryDto}
     * @memberof BulkActionDto
     */
    filter?: PreviewBillsQueryDto;
}

export function BulkActionDtoFromJSON(json: any): BulkActionDto {
    return BulkActionDtoFromJSONTyped(json, false);
}

export function BulkActionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkActionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignToMe': !exists(json, 'assignToMe') ? undefined : AssigneeToMeDtoFromJSON(json['assignToMe']),
        'assign': !exists(json, 'assign') ? undefined : AssigneeDtoFromJSON(json['assign']),
        'changePriority': !exists(json, 'changePriority') ? undefined : ChangePriorityDtoFromJSON(json['changePriority']),
        'changeTargetDate': !exists(json, 'changeTargetDate') ? undefined : ChangeTargetDateDtoFromJSON(json['changeTargetDate']),
        'changeInvoiceDate': !exists(json, 'changeInvoiceDate') ? undefined : ChangeInvoiceDateDtoFromJSON(json['changeInvoiceDate']),
        'park': !exists(json, 'park') ? undefined : ParkBillsDtoFromJSON(json['park']),
        'resolveErrors': !exists(json, 'resolveErrors') ? undefined : ResolveErrorsDtoFromJSON(json['resolveErrors']),
        'reparse': !exists(json, 'reparse') ? undefined : ((json['reparse'] as Array<any>).map(ReparseBillsDtoFromJSON)),
        'filter': !exists(json, 'filter') ? undefined : PreviewBillsQueryDtoFromJSON(json['filter']),
    };
}

export function BulkActionDtoToJSON(value?: BulkActionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignToMe': AssigneeToMeDtoToJSON(value.assignToMe),
        'assign': AssigneeDtoToJSON(value.assign),
        'changePriority': ChangePriorityDtoToJSON(value.changePriority),
        'changeTargetDate': ChangeTargetDateDtoToJSON(value.changeTargetDate),
        'changeInvoiceDate': ChangeInvoiceDateDtoToJSON(value.changeInvoiceDate),
        'park': ParkBillsDtoToJSON(value.park),
        'resolveErrors': ResolveErrorsDtoToJSON(value.resolveErrors),
        'reparse': value.reparse === undefined ? undefined : ((value.reparse as Array<any>).map(ReparseBillsDtoToJSON)),
        'filter': PreviewBillsQueryDtoToJSON(value.filter),
    };
}


