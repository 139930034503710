const criticalErrorsQuery = [
  'integrity_check',
  'data_verification_1',
  'data_verification_2',
]
const auditErrorsQuery = ['data_audit_1', 'data_audit_2']

const billErrorIds = {
  frozenVirtualAccount: '3030',
}

export { criticalErrorsQuery, auditErrorsQuery, billErrorIds }
