/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillDto,
    BillDtoFromJSON,
    BillDtoToJSON,
    UnprocessedBillsPreviewListDto,
    UnprocessedBillsPreviewListDtoFromJSON,
    UnprocessedBillsPreviewListDtoToJSON,
} from '../models';

export interface CustomerUnprocessedBillsControllerDeleteUnprocessedBillRequest {
    recordId: number;
    customerId: number;
}

export interface CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerUnprocessedBillsControllerGetUnprocessedBillsExcludeEnum>;
}

export interface CustomerUnprocessedBillsControllerMatchUnprocessedBillRequest {
    recordId: number;
    customerId: number;
    billDto: BillDto;
}

/**
 * 
 */
export class CustomerUnprocessedBillsApi extends runtime.BaseAPI {

    /**
     * Deletes an unprocessed bill record
     */
    async customerUnprocessedBillsControllerDeleteUnprocessedBillRaw(requestParameters: CustomerUnprocessedBillsControllerDeleteUnprocessedBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling customerUnprocessedBillsControllerDeleteUnprocessedBill.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerUnprocessedBillsControllerDeleteUnprocessedBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/unprocessed-bills/{recordId}`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an unprocessed bill record
     */
    async customerUnprocessedBillsControllerDeleteUnprocessedBill(requestParameters: CustomerUnprocessedBillsControllerDeleteUnprocessedBillRequest): Promise<void> {
        await this.customerUnprocessedBillsControllerDeleteUnprocessedBillRaw(requestParameters);
    }

    /**
     * Returns a list of bills - with meta filters included.
     */
    async customerUnprocessedBillsControllerGetUnprocessedBillsRaw(requestParameters: CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest): Promise<runtime.ApiResponse<UnprocessedBillsPreviewListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerUnprocessedBillsControllerGetUnprocessedBills.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/unprocessed-bills/list`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UnprocessedBillsPreviewListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of bills - with meta filters included.
     */
    async customerUnprocessedBillsControllerGetUnprocessedBills(requestParameters: CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest): Promise<UnprocessedBillsPreviewListDto> {
        const response = await this.customerUnprocessedBillsControllerGetUnprocessedBillsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Maps an unprocessed bill record to an existing bill
     */
    async customerUnprocessedBillsControllerMatchUnprocessedBillRaw(requestParameters: CustomerUnprocessedBillsControllerMatchUnprocessedBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.recordId === null || requestParameters.recordId === undefined) {
            throw new runtime.RequiredError('recordId','Required parameter requestParameters.recordId was null or undefined when calling customerUnprocessedBillsControllerMatchUnprocessedBill.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerUnprocessedBillsControllerMatchUnprocessedBill.');
        }

        if (requestParameters.billDto === null || requestParameters.billDto === undefined) {
            throw new runtime.RequiredError('billDto','Required parameter requestParameters.billDto was null or undefined when calling customerUnprocessedBillsControllerMatchUnprocessedBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/unprocessed-bills/{recordId}/match`.replace(`{${"recordId"}}`, encodeURIComponent(String(requestParameters.recordId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillDtoToJSON(requestParameters.billDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Maps an unprocessed bill record to an existing bill
     */
    async customerUnprocessedBillsControllerMatchUnprocessedBill(requestParameters: CustomerUnprocessedBillsControllerMatchUnprocessedBillRequest): Promise<void> {
        await this.customerUnprocessedBillsControllerMatchUnprocessedBillRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerUnprocessedBillsControllerGetUnprocessedBillsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
