/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentEmailDeliveryDto
 */
export interface PaymentEmailDeliveryDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentEmailDeliveryDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PaymentEmailDeliveryDto
     */
    paymentFileFormat: PaymentEmailDeliveryDtoPaymentFileFormatEnum;
}

/**
* @export
* @enum {string}
*/
export enum PaymentEmailDeliveryDtoPaymentFileFormatEnum {
    Summary = 'summary',
    Wesleyan = 'wesleyan',
    Sonbyrne = 'sonbyrne',
    SheetzPrimary = 'sheetz_primary',
    SheetzCli = 'sheetz_cli',
    SheetzSds = 'sheetz_sds',
    SheetzConstruction = 'sheetz_construction',
    RoyalfarmsAutopay = 'royalfarms_autopay',
    RoyalfarmsManualpay = 'royalfarms_manualpay',
    RoyalfarmsPcbillpay = 'royalfarms_pcbillpay',
    RoyalfarmsConstruction = 'royalfarms_construction',
    Countrymeadows = 'countrymeadows'
}

export function PaymentEmailDeliveryDtoFromJSON(json: any): PaymentEmailDeliveryDto {
    return PaymentEmailDeliveryDtoFromJSONTyped(json, false);
}

export function PaymentEmailDeliveryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEmailDeliveryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': json['emails'],
        'paymentFileFormat': json['paymentFileFormat'],
    };
}

export function PaymentEmailDeliveryDtoToJSON(value?: PaymentEmailDeliveryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': value.emails,
        'paymentFileFormat': value.paymentFileFormat,
    };
}


