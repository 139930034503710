/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AffectedBillsAndBillBlocksModel
 */
export interface AffectedBillsAndBillBlocksModel {
    /**
     * 
     * @type {number}
     * @memberof AffectedBillsAndBillBlocksModel
     */
    totalBills: number;
    /**
     * 
     * @type {number}
     * @memberof AffectedBillsAndBillBlocksModel
     */
    totalBillBlocks: number;
}

export function AffectedBillsAndBillBlocksModelFromJSON(json: any): AffectedBillsAndBillBlocksModel {
    return AffectedBillsAndBillBlocksModelFromJSONTyped(json, false);
}

export function AffectedBillsAndBillBlocksModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AffectedBillsAndBillBlocksModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalBills': json['totalBills'],
        'totalBillBlocks': json['totalBillBlocks'],
    };
}

export function AffectedBillsAndBillBlocksModelToJSON(value?: AffectedBillsAndBillBlocksModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalBills': value.totalBills,
        'totalBillBlocks': value.totalBillBlocks,
    };
}


