/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivateAccountDto
 */
export interface ActivateAccountDto {
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountDto
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountDto
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountDto
     */
    azureToken?: string;
}

export function ActivateAccountDtoFromJSON(json: any): ActivateAccountDto {
    return ActivateAccountDtoFromJSONTyped(json, false);
}

export function ActivateAccountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivateAccountDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': !exists(json, 'password') ? undefined : json['password'],
        'token': json['token'],
        'azureToken': !exists(json, 'azureToken') ? undefined : json['azureToken'],
    };
}

export function ActivateAccountDtoToJSON(value?: ActivateAccountDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'token': value.token,
        'azureToken': value.azureToken,
    };
}


