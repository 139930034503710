/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentErrorsEmailDeliveryDto
 */
export interface PaymentErrorsEmailDeliveryDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentErrorsEmailDeliveryDto
     */
    emails: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentErrorsEmailDeliveryDto
     */
    errors: Array<PaymentErrorsEmailDeliveryDtoErrorsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum PaymentErrorsEmailDeliveryDtoErrorsEnum {
    PaymentFileCreated = 'paymentFileCreated',
    PaymentFileEmailed = 'paymentFileEmailed',
    PaymentFileNotCreated = 'paymentFileNotCreated',
    PaymentFileNotEmailed = 'paymentFileNotEmailed',
    PaymentFileNotUploadedToSftp = 'paymentFileNotUploadedToSFTP',
    PaymentFileVendorNotFound = 'paymentFileVendorNotFound',
    PaymentFileUtilityNotFound = 'paymentFileUtilityNotFound',
    PaymentFileAccountingCodeNotFound = 'paymentFileAccountingCodeNotFound',
    PaymentFileZeroCharges = 'paymentFileZeroCharges',
    PaymentFileZeroConsumption = 'paymentFileZeroConsumption',
    PaymentFileZeroTotalAmountDue = 'paymentFileZeroTotalAmountDue',
    PaymentFileNegativeTotalAmountDue = 'paymentFileNegativeTotalAmountDue',
    PaymentFileZeroBillBlockTotalCharges = 'paymentFileZeroBillBlockTotalCharges',
    PaymentFileGeneralError = 'paymentFileGeneralError',
    PaymentFileFacilityNotFound = 'paymentFileFacilityNotFound',
    PaymentFileBillLevelAttributeMismatchError = 'paymentFileBillLevelAttributeMismatchError',
    PaymentFileBillLevelAttributeMismatchWarning = 'paymentFileBillLevelAttributeMismatchWarning',
    PaymentFileCommodityLevelAttributeMismatchError = 'paymentFileCommodityLevelAttributeMismatchError',
    PaymentFileCommodityLevelZeroTotalSubcharges = 'paymentFileCommodityLevelZeroTotalSubcharges',
    PaymentFileCommodityLevelNegativeTotalSubcharges = 'paymentFileCommodityLevelNegativeTotalSubcharges',
    PaymentFileCommodityLevelMissingPaymentMode = 'paymentFileCommodityLevelMissingPaymentMode'
}

export function PaymentErrorsEmailDeliveryDtoFromJSON(json: any): PaymentErrorsEmailDeliveryDto {
    return PaymentErrorsEmailDeliveryDtoFromJSONTyped(json, false);
}

export function PaymentErrorsEmailDeliveryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentErrorsEmailDeliveryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emails': json['emails'],
        'errors': json['errors'],
    };
}

export function PaymentErrorsEmailDeliveryDtoToJSON(value?: PaymentErrorsEmailDeliveryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emails': value.emails,
        'errors': value.errors,
    };
}


