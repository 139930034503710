/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationPayloadModel,
    LocationPayloadModelFromJSON,
    LocationPayloadModelFromJSONTyped,
    LocationPayloadModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface LocationDto
 */
export interface LocationDto {
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    buildingType: LocationDtoBuildingTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    longitude: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    postcode: string;
    /**
     * 
     * @type {number}
     * @memberof LocationDto
     */
    squareFeet: number;
    /**
     * 
     * @type {string}
     * @memberof LocationDto
     */
    state: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationDto
     */
    weatherActive: boolean;
    /**
     * 
     * @type {LocationPayloadModel}
     * @memberof LocationDto
     */
    payload: LocationPayloadModel;
}

/**
* @export
* @enum {string}
*/
export enum LocationDtoBuildingTypeEnum {
    Building = 'BUILDING',
    Campus = 'CAMPUS',
    Storefront = 'STOREFRONT'
}

export function LocationDtoFromJSON(json: any): LocationDto {
    return LocationDtoFromJSONTyped(json, false);
}

export function LocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'buildingType': json['buildingType'],
        'city': json['city'],
        'country': json['country'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'name': json['name'],
        'postcode': json['postcode'],
        'squareFeet': json['squareFeet'],
        'state': json['state'],
        'weatherActive': json['weatherActive'],
        'payload': LocationPayloadModelFromJSON(json['payload']),
    };
}

export function LocationDtoToJSON(value?: LocationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'buildingType': value.buildingType,
        'city': value.city,
        'country': value.country,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'name': value.name,
        'postcode': value.postcode,
        'squareFeet': value.squareFeet,
        'state': value.state,
        'weatherActive': value.weatherActive,
        'payload': LocationPayloadModelToJSON(value.payload),
    };
}


