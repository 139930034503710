/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentEvent
 */
export interface PaymentEvent {
    /**
     * 
     * @type {number}
     * @memberof PaymentEvent
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentEvent
     */
    event: PaymentEventEventEnum;
    /**
     * 
     * @type {number}
     * @memberof PaymentEvent
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentEvent
     */
    customerName: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentEvent
     */
    billMetaId: number;
    /**
     * 
     * @type {Date}
     * @memberof PaymentEvent
     */
    paymentDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentEvent
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof PaymentEvent
     */
    fileFormat: PaymentEventFileFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof PaymentEvent
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentEvent
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentEvent
     */
    paymentFileFormat: PaymentEventPaymentFileFormatEnum;
}

/**
* @export
* @enum {string}
*/
export enum PaymentEventEventEnum {
    Information = 'Information',
    Error = 'Error',
    Warning = 'Warning'
}/**
* @export
* @enum {string}
*/
export enum PaymentEventFileFormatEnum {
    Summary = 'summary',
    Wesleyan = 'wesleyan',
    Sonbyrne = 'sonbyrne',
    SheetzPrimary = 'sheetz_primary',
    SheetzCli = 'sheetz_cli',
    SheetzSds = 'sheetz_sds',
    SheetzConstruction = 'sheetz_construction',
    RoyalfarmsAutopay = 'royalfarms_autopay',
    RoyalfarmsManualpay = 'royalfarms_manualpay',
    RoyalfarmsPcbillpay = 'royalfarms_pcbillpay',
    RoyalfarmsConstruction = 'royalfarms_construction',
    Countrymeadows = 'countrymeadows'
}/**
* @export
* @enum {string}
*/
export enum PaymentEventPaymentFileFormatEnum {
    Summary = 'summary',
    Wesleyan = 'wesleyan',
    Sonbyrne = 'sonbyrne',
    SheetzPrimary = 'sheetz_primary',
    SheetzCli = 'sheetz_cli',
    SheetzSds = 'sheetz_sds',
    SheetzConstruction = 'sheetz_construction',
    RoyalfarmsAutopay = 'royalfarms_autopay',
    RoyalfarmsManualpay = 'royalfarms_manualpay',
    RoyalfarmsPcbillpay = 'royalfarms_pcbillpay',
    RoyalfarmsConstruction = 'royalfarms_construction',
    Countrymeadows = 'countrymeadows'
}

export function PaymentEventFromJSON(json: any): PaymentEvent {
    return PaymentEventFromJSONTyped(json, false);
}

export function PaymentEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'event': json['event'],
        'customerId': json['customerId'],
        'customerName': json['customerName'],
        'billMetaId': json['billMetaId'],
        'paymentDate': (new Date(json['paymentDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'fileFormat': json['fileFormat'],
        'type': json['type'],
        'description': json['description'],
        'paymentFileFormat': json['paymentFileFormat'],
    };
}

export function PaymentEventToJSON(value?: PaymentEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'event': value.event,
        'customerId': value.customerId,
        'customerName': value.customerName,
        'billMetaId': value.billMetaId,
        'paymentDate': (value.paymentDate.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'fileFormat': value.fileFormat,
        'type': value.type,
        'description': value.description,
        'paymentFileFormat': value.paymentFileFormat,
    };
}


