/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetadataAttributeDto,
    MetadataAttributeDtoFromJSON,
    MetadataAttributeDtoFromJSONTyped,
    MetadataAttributeDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LocationMetadataBulkDto
 */
export interface LocationMetadataBulkDto {
    /**
     * 
     * @type {Array<MetadataAttributeDto>}
     * @memberof LocationMetadataBulkDto
     */
    locationMetadata: Array<MetadataAttributeDto>;
}

export function LocationMetadataBulkDtoFromJSON(json: any): LocationMetadataBulkDto {
    return LocationMetadataBulkDtoFromJSONTyped(json, false);
}

export function LocationMetadataBulkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationMetadataBulkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationMetadata': ((json['locationMetadata'] as Array<any>).map(MetadataAttributeDtoFromJSON)),
    };
}

export function LocationMetadataBulkDtoToJSON(value?: LocationMetadataBulkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationMetadata': ((value.locationMetadata as Array<any>).map(MetadataAttributeDtoToJSON)),
    };
}


