/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillDashboardPreview
 */
export interface BillDashboardPreview {
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof BillDashboardPreview
     */
    customerName: string;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    totalRowsCount: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    integrityCheck: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    dataVerification1: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    dataVerification2: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    dataAudit1: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    dataAudit2: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    error: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof BillDashboardPreview
     */
    processed: number;
}

export function BillDashboardPreviewFromJSON(json: any): BillDashboardPreview {
    return BillDashboardPreviewFromJSONTyped(json, false);
}

export function BillDashboardPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillDashboardPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'customerName': json['customerName'],
        'totalRowsCount': json['totalRowsCount'],
        'integrityCheck': json['integrityCheck'],
        'dataVerification1': json['dataVerification1'],
        'dataVerification2': json['dataVerification2'],
        'dataAudit1': json['dataAudit1'],
        'dataAudit2': json['dataAudit2'],
        'error': json['error'],
        'deleted': json['deleted'],
        'processed': json['processed'],
    };
}

export function BillDashboardPreviewToJSON(value?: BillDashboardPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customerName': value.customerName,
        'totalRowsCount': value.totalRowsCount,
        'integrityCheck': value.integrityCheck,
        'dataVerification1': value.dataVerification1,
        'dataVerification2': value.dataVerification2,
        'dataAudit1': value.dataAudit1,
        'dataAudit2': value.dataAudit2,
        'error': value.error,
        'deleted': value.deleted,
        'processed': value.processed,
    };
}


