/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountMapResult
 */
export interface VirtualAccountMapResult {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountMapResult
     */
    billItemsUpdated: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof VirtualAccountMapResult
     */
    billsToReparse: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountMapResult
     */
    trackingId: string;
}

export function VirtualAccountMapResultFromJSON(json: any): VirtualAccountMapResult {
    return VirtualAccountMapResultFromJSONTyped(json, false);
}

export function VirtualAccountMapResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountMapResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billItemsUpdated': json['billItemsUpdated'],
        'billsToReparse': json['billsToReparse'],
        'trackingId': json['trackingId'],
    };
}

export function VirtualAccountMapResultToJSON(value?: VirtualAccountMapResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billItemsUpdated': value.billItemsUpdated,
        'billsToReparse': value.billsToReparse,
        'trackingId': value.trackingId,
    };
}


