/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchCompleteDto
 */
export interface BatchCompleteDto {
    /**
     * 
     * @type {number}
     * @memberof BatchCompleteDto
     */
    retryCount: number;
    /**
     * 
     * @type {number}
     * @memberof BatchCompleteDto
     */
    batchId: number;
    /**
     * 
     * @type {number}
     * @memberof BatchCompleteDto
     */
    totalBills: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BatchCompleteDto
     */
    errors: Array<string>;
}

export function BatchCompleteDtoFromJSON(json: any): BatchCompleteDto {
    return BatchCompleteDtoFromJSONTyped(json, false);
}

export function BatchCompleteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchCompleteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retryCount': json['retryCount'],
        'batchId': json['batchId'],
        'totalBills': json['totalBills'],
        'errors': json['errors'],
    };
}

export function BatchCompleteDtoToJSON(value?: BatchCompleteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retryCount': value.retryCount,
        'batchId': value.batchId,
        'totalBills': value.totalBills,
        'errors': value.errors,
    };
}


