
export default {
  inheritAttrs: false,
  props: {
    rules: {
      type: [Array, Object],
      default: (): any => [],
    },
    label: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: String,
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: '',
    },
    requiredStar: {
      type: Boolean,
      default: false,
    },
  },
}
