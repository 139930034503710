import { format } from 'date-fns'
import { isNil } from 'lodash'

export const dateStringToUsDateString = (
  value: string,
  timeZone: string | null = null,
): string | null => {
  if (!value) {
    return null
  }
  const options: Record<string, any> = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  if (timeZone != null) {
    options.timeZone = timeZone
  }

  return new Intl.DateTimeFormat('en-US', options).format(new Date(value))
}

export const changeDateFormat = (date: string, dateFormat: string): string => {
  if (isNil(date)) {
    return ''
  }
  return format(new Date(date), dateFormat)
}
