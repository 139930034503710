import { ApiService } from '@/SharedModule/Services/service.api'
import {
  EmailDeliveryStatusListDto,
  EmailerApi,
  EmailerControllerGetEmailsDeliveryStatusRequest,
  PaymentEventsListDto,
  PaymentsApi,
  PaymentsControllerGetEventsRequest,
} from '@/SharedModule/Api'

export class ErrorsService extends ApiService {
  private errorsApi = new PaymentsApi(this.getBaseOptions())
  private emailerApi = new EmailerApi(this.getBaseOptions())

  public getEvents(
    requestParameters: PaymentsControllerGetEventsRequest,
  ): Promise<PaymentEventsListDto> {
    return this.errorsApi.paymentsControllerGetEvents(requestParameters)
  }
  public getCommunicationErrors(
    requestParameters: EmailerControllerGetEmailsDeliveryStatusRequest,
  ): Promise<EmailDeliveryStatusListDto> {
    return this.emailerApi.emailerControllerGetEmailsDeliveryStatus(
      requestParameters,
    )
  }
}
