/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    line1: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    line2: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    line3: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    line4: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    postCode: string;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    country: string;
}

export function AddressModelFromJSON(json: any): AddressModel {
    return AddressModelFromJSONTyped(json, false);
}

export function AddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'line1': json['line1'],
        'line2': json['line2'],
        'line3': json['line3'],
        'line4': json['line4'],
        'city': json['city'],
        'state': json['state'],
        'postCode': json['postCode'],
        'country': json['country'],
    };
}

export function AddressModelToJSON(value?: AddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'line1': value.line1,
        'line2': value.line2,
        'line3': value.line3,
        'line4': value.line4,
        'city': value.city,
        'state': value.state,
        'postCode': value.postCode,
        'country': value.country,
    };
}


