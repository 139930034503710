/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillReference,
    BillReferenceFromJSON,
    BillReferenceFromJSONTyped,
    BillReferenceToJSON,
} from './';

/**
 * 
 * @export
 * @interface Navigation
 */
export interface Navigation {
    /**
     * 
     * @type {BillReference}
     * @memberof Navigation
     */
    prior: BillReference;
    /**
     * 
     * @type {BillReference}
     * @memberof Navigation
     */
    next: BillReference;
    /**
     * 
     * @type {number}
     * @memberof Navigation
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof Navigation
     */
    index: number;
}

export function NavigationFromJSON(json: any): Navigation {
    return NavigationFromJSONTyped(json, false);
}

export function NavigationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Navigation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prior': BillReferenceFromJSON(json['prior']),
        'next': BillReferenceFromJSON(json['next']),
        'totalCount': json['totalCount'],
        'index': json['index'],
    };
}

export function NavigationToJSON(value?: Navigation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prior': BillReferenceToJSON(value.prior),
        'next': BillReferenceToJSON(value.next),
        'totalCount': value.totalCount,
        'index': value.index,
    };
}


