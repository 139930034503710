/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignUserDto,
    AssignUserDtoFromJSON,
    AssignUserDtoToJSON,
    CreateUserDto,
    CreateUserDtoFromJSON,
    CreateUserDtoToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface UserCustomersControllerAssignUserRequest {
    userId: number;
    customerId: number;
    assignUserDto: AssignUserDto;
}

export interface UserCustomersControllerCreateUserRequest {
    customerId: number;
    createUserDto: CreateUserDto;
}

export interface UserCustomersControllerUnassignUserRequest {
    userId: number;
    customerId: number;
    assignUserDto: AssignUserDto;
}

/**
 * 
 */
export class UserCustomersApi extends runtime.BaseAPI {

    /**
     * Grants customer access to a given user
     */
    async userCustomersControllerAssignUserRaw(requestParameters: UserCustomersControllerAssignUserRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userCustomersControllerAssignUser.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling userCustomersControllerAssignUser.');
        }

        if (requestParameters.assignUserDto === null || requestParameters.assignUserDto === undefined) {
            throw new runtime.RequiredError('assignUserDto','Required parameter requestParameters.assignUserDto was null or undefined when calling userCustomersControllerAssignUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/user-customers/user/{userId}/customer/{customerId}/assign`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignUserDtoToJSON(requestParameters.assignUserDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Grants customer access to a given user
     */
    async userCustomersControllerAssignUser(requestParameters: UserCustomersControllerAssignUserRequest): Promise<Array<number>> {
        const response = await this.userCustomersControllerAssignUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new user and assigns it to a customer
     */
    async userCustomersControllerCreateUserRaw(requestParameters: UserCustomersControllerCreateUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling userCustomersControllerCreateUser.');
        }

        if (requestParameters.createUserDto === null || requestParameters.createUserDto === undefined) {
            throw new runtime.RequiredError('createUserDto','Required parameter requestParameters.createUserDto was null or undefined when calling userCustomersControllerCreateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/user-customers/customers/{customerId}/users/create`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserDtoToJSON(requestParameters.createUserDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Creates a new user and assigns it to a customer
     */
    async userCustomersControllerCreateUser(requestParameters: UserCustomersControllerCreateUserRequest): Promise<User> {
        const response = await this.userCustomersControllerCreateUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Grants customer access to a given user
     */
    async userCustomersControllerUnassignUserRaw(requestParameters: UserCustomersControllerUnassignUserRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling userCustomersControllerUnassignUser.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling userCustomersControllerUnassignUser.');
        }

        if (requestParameters.assignUserDto === null || requestParameters.assignUserDto === undefined) {
            throw new runtime.RequiredError('assignUserDto','Required parameter requestParameters.assignUserDto was null or undefined when calling userCustomersControllerUnassignUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/user-customers/user/{userId}/customer/{customerId}/unassign`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignUserDtoToJSON(requestParameters.assignUserDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Grants customer access to a given user
     */
    async userCustomersControllerUnassignUser(requestParameters: UserCustomersControllerUnassignUserRequest): Promise<Array<number>> {
        const response = await this.userCustomersControllerUnassignUserRaw(requestParameters);
        return await response.value();
    }

}
