/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignLocationsDto,
    AssignLocationsDtoFromJSON,
    AssignLocationsDtoToJSON,
    AssignLocationsResponseDto,
    AssignLocationsResponseDtoFromJSON,
    AssignLocationsResponseDtoToJSON,
    CreateHierarchyDto,
    CreateHierarchyDtoFromJSON,
    CreateHierarchyDtoToJSON,
    CreateHierarchyItemDto,
    CreateHierarchyItemDtoFromJSON,
    CreateHierarchyItemDtoToJSON,
    CreateHierarchyItemResponseDto,
    CreateHierarchyItemResponseDtoFromJSON,
    CreateHierarchyItemResponseDtoToJSON,
    HierarchiesListDto,
    HierarchiesListDtoFromJSON,
    HierarchiesListDtoToJSON,
    Hierarchy,
    HierarchyFromJSON,
    HierarchyToJSON,
    HierarchyItemsDto,
    HierarchyItemsDtoFromJSON,
    HierarchyItemsDtoToJSON,
    HierarchyListDto,
    HierarchyListDtoFromJSON,
    HierarchyListDtoToJSON,
    HierarchyModel,
    HierarchyModelFromJSON,
    HierarchyModelToJSON,
    LocationListDto,
    LocationListDtoFromJSON,
    LocationListDtoToJSON,
    UpdateHierarchyItemDto,
    UpdateHierarchyItemDtoFromJSON,
    UpdateHierarchyItemDtoToJSON,
} from '../models';

export interface HierarchiesControllerAssignLocationsRequest {
    customerId: number;
    assignLocationsDto: AssignLocationsDto;
}

export interface HierarchiesControllerCreateHierarchyRequest {
    customerId: number;
    createHierarchyDto: CreateHierarchyDto;
}

export interface HierarchiesControllerCreateHierarchyItemRequest {
    customerId: number;
    createHierarchyItemDto: CreateHierarchyItemDto;
}

export interface HierarchiesControllerDeleteLocationRequest {
    customerId: number;
    hierarchyId: number;
    itemId: number;
}

export interface HierarchiesControllerGetDefaultHierarchyRequest {
    customerId: number;
}

export interface HierarchiesControllerGetHierarchiesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<HierarchiesControllerGetHierarchiesExcludeEnum>;
    orderBy?: Array<HierarchiesControllerGetHierarchiesOrderByEnum>;
    searchQuery?: string;
}

export interface HierarchiesControllerGetHierarchyItemsRequest {
    customerId: number;
    hierarchyId: number;
}

export interface HierarchiesControllerGetHierarchyUnmatchedLocationsRequest {
    customerId: number;
    hierarchyId: number;
}

export interface HierarchiesControllerUpdateHierarchyRequest {
    customerId: number;
    itemId: number;
    updateHierarchyItemDto: UpdateHierarchyItemDto;
}

/**
 * 
 */
export class HierarchiesApi extends runtime.BaseAPI {

    /**
     * Assign locations to a hierarchy
     */
    async hierarchiesControllerAssignLocationsRaw(requestParameters: HierarchiesControllerAssignLocationsRequest): Promise<runtime.ApiResponse<AssignLocationsResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerAssignLocations.');
        }

        if (requestParameters.assignLocationsDto === null || requestParameters.assignLocationsDto === undefined) {
            throw new runtime.RequiredError('assignLocationsDto','Required parameter requestParameters.assignLocationsDto was null or undefined when calling hierarchiesControllerAssignLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/{hierarchyId}/assign-locations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignLocationsDtoToJSON(requestParameters.assignLocationsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AssignLocationsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Assign locations to a hierarchy
     */
    async hierarchiesControllerAssignLocations(requestParameters: HierarchiesControllerAssignLocationsRequest): Promise<AssignLocationsResponseDto> {
        const response = await this.hierarchiesControllerAssignLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create a hierarchy
     */
    async hierarchiesControllerCreateHierarchyRaw(requestParameters: HierarchiesControllerCreateHierarchyRequest): Promise<runtime.ApiResponse<HierarchyModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerCreateHierarchy.');
        }

        if (requestParameters.createHierarchyDto === null || requestParameters.createHierarchyDto === undefined) {
            throw new runtime.RequiredError('createHierarchyDto','Required parameter requestParameters.createHierarchyDto was null or undefined when calling hierarchiesControllerCreateHierarchy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHierarchyDtoToJSON(requestParameters.createHierarchyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HierarchyModelFromJSON(jsonValue));
    }

    /**
     * Create a hierarchy
     */
    async hierarchiesControllerCreateHierarchy(requestParameters: HierarchiesControllerCreateHierarchyRequest): Promise<HierarchyModel> {
        const response = await this.hierarchiesControllerCreateHierarchyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create hierarchy item
     */
    async hierarchiesControllerCreateHierarchyItemRaw(requestParameters: HierarchiesControllerCreateHierarchyItemRequest): Promise<runtime.ApiResponse<CreateHierarchyItemResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerCreateHierarchyItem.');
        }

        if (requestParameters.createHierarchyItemDto === null || requestParameters.createHierarchyItemDto === undefined) {
            throw new runtime.RequiredError('createHierarchyItemDto','Required parameter requestParameters.createHierarchyItemDto was null or undefined when calling hierarchiesControllerCreateHierarchyItem.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/{hierarchyId}/items`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHierarchyItemDtoToJSON(requestParameters.createHierarchyItemDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateHierarchyItemResponseDtoFromJSON(jsonValue));
    }

    /**
     * Create hierarchy item
     */
    async hierarchiesControllerCreateHierarchyItem(requestParameters: HierarchiesControllerCreateHierarchyItemRequest): Promise<CreateHierarchyItemResponseDto> {
        const response = await this.hierarchiesControllerCreateHierarchyItemRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete hierarchy item
     */
    async hierarchiesControllerDeleteLocationRaw(requestParameters: HierarchiesControllerDeleteLocationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerDeleteLocation.');
        }

        if (requestParameters.hierarchyId === null || requestParameters.hierarchyId === undefined) {
            throw new runtime.RequiredError('hierarchyId','Required parameter requestParameters.hierarchyId was null or undefined when calling hierarchiesControllerDeleteLocation.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling hierarchiesControllerDeleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/{hierarchyId}/items/{itemId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"hierarchyId"}}`, encodeURIComponent(String(requestParameters.hierarchyId))).replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete hierarchy item
     */
    async hierarchiesControllerDeleteLocation(requestParameters: HierarchiesControllerDeleteLocationRequest): Promise<void> {
        await this.hierarchiesControllerDeleteLocationRaw(requestParameters);
    }

    /**
     * List the default location hierarchy for a given customer
     */
    async hierarchiesControllerGetDefaultHierarchyRaw(requestParameters: HierarchiesControllerGetDefaultHierarchyRequest): Promise<runtime.ApiResponse<HierarchyListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerGetDefaultHierarchy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/default`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HierarchyListDtoFromJSON(jsonValue));
    }

    /**
     * List the default location hierarchy for a given customer
     */
    async hierarchiesControllerGetDefaultHierarchy(requestParameters: HierarchiesControllerGetDefaultHierarchyRequest): Promise<HierarchyListDto> {
        const response = await this.hierarchiesControllerGetDefaultHierarchyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of hierarchies for a given customer
     */
    async hierarchiesControllerGetHierarchiesRaw(requestParameters: HierarchiesControllerGetHierarchiesRequest): Promise<runtime.ApiResponse<HierarchiesListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerGetHierarchies.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HierarchiesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of hierarchies for a given customer
     */
    async hierarchiesControllerGetHierarchies(requestParameters: HierarchiesControllerGetHierarchiesRequest): Promise<HierarchiesListDto> {
        const response = await this.hierarchiesControllerGetHierarchiesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of items for a hierarchy for a given customer
     */
    async hierarchiesControllerGetHierarchyItemsRaw(requestParameters: HierarchiesControllerGetHierarchyItemsRequest): Promise<runtime.ApiResponse<HierarchyItemsDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerGetHierarchyItems.');
        }

        if (requestParameters.hierarchyId === null || requestParameters.hierarchyId === undefined) {
            throw new runtime.RequiredError('hierarchyId','Required parameter requestParameters.hierarchyId was null or undefined when calling hierarchiesControllerGetHierarchyItems.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/{hierarchyId}/items`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"hierarchyId"}}`, encodeURIComponent(String(requestParameters.hierarchyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HierarchyItemsDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of items for a hierarchy for a given customer
     */
    async hierarchiesControllerGetHierarchyItems(requestParameters: HierarchiesControllerGetHierarchyItemsRequest): Promise<HierarchyItemsDto> {
        const response = await this.hierarchiesControllerGetHierarchyItemsRaw(requestParameters);
        return await response.value();
    }

    /**
     * List hierarchy unmatched locations
     */
    async hierarchiesControllerGetHierarchyUnmatchedLocationsRaw(requestParameters: HierarchiesControllerGetHierarchyUnmatchedLocationsRequest): Promise<runtime.ApiResponse<LocationListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerGetHierarchyUnmatchedLocations.');
        }

        if (requestParameters.hierarchyId === null || requestParameters.hierarchyId === undefined) {
            throw new runtime.RequiredError('hierarchyId','Required parameter requestParameters.hierarchyId was null or undefined when calling hierarchiesControllerGetHierarchyUnmatchedLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/{hierarchyId}/unmatched-locations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"hierarchyId"}}`, encodeURIComponent(String(requestParameters.hierarchyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationListDtoFromJSON(jsonValue));
    }

    /**
     * List hierarchy unmatched locations
     */
    async hierarchiesControllerGetHierarchyUnmatchedLocations(requestParameters: HierarchiesControllerGetHierarchyUnmatchedLocationsRequest): Promise<LocationListDto> {
        const response = await this.hierarchiesControllerGetHierarchyUnmatchedLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a hierarchy item.
     */
    async hierarchiesControllerUpdateHierarchyRaw(requestParameters: HierarchiesControllerUpdateHierarchyRequest): Promise<runtime.ApiResponse<Hierarchy>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling hierarchiesControllerUpdateHierarchy.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling hierarchiesControllerUpdateHierarchy.');
        }

        if (requestParameters.updateHierarchyItemDto === null || requestParameters.updateHierarchyItemDto === undefined) {
            throw new runtime.RequiredError('updateHierarchyItemDto','Required parameter requestParameters.updateHierarchyItemDto was null or undefined when calling hierarchiesControllerUpdateHierarchy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/hierarchies/{hierarchyId}/items/{itemId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateHierarchyItemDtoToJSON(requestParameters.updateHierarchyItemDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HierarchyFromJSON(jsonValue));
    }

    /**
     * Update a hierarchy item.
     */
    async hierarchiesControllerUpdateHierarchy(requestParameters: HierarchiesControllerUpdateHierarchyRequest): Promise<Hierarchy> {
        const response = await this.hierarchiesControllerUpdateHierarchyRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum HierarchiesControllerGetHierarchiesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum HierarchiesControllerGetHierarchiesOrderByEnum {
    IdAsc = 'id.asc',
    CreatedAtAsc = 'createdAt.asc',
    NameAsc = 'name.asc',
    IdDesc = 'id.desc',
    CreatedAtDesc = 'createdAt.desc',
    NameDesc = 'name.desc'
}
