/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccountsAttributeMappingDto,
    VirtualAccountsAttributeMappingDtoFromJSON,
    VirtualAccountsAttributeMappingDtoFromJSONTyped,
    VirtualAccountsAttributeMappingDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LinkVirtualAccountsDto
 */
export interface LinkVirtualAccountsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof LinkVirtualAccountsDto
     */
    virtualAccountsIds: Array<number>;
    /**
     * 
     * @type {Array<VirtualAccountsAttributeMappingDto>}
     * @memberof LinkVirtualAccountsDto
     */
    linkedAttributes: Array<VirtualAccountsAttributeMappingDto>;
}

export function LinkVirtualAccountsDtoFromJSON(json: any): LinkVirtualAccountsDto {
    return LinkVirtualAccountsDtoFromJSONTyped(json, false);
}

export function LinkVirtualAccountsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkVirtualAccountsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'virtualAccountsIds': json['virtualAccountsIds'],
        'linkedAttributes': ((json['linkedAttributes'] as Array<any>).map(VirtualAccountsAttributeMappingDtoFromJSON)),
    };
}

export function LinkVirtualAccountsDtoToJSON(value?: LinkVirtualAccountsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'virtualAccountsIds': value.virtualAccountsIds,
        'linkedAttributes': ((value.linkedAttributes as Array<any>).map(VirtualAccountsAttributeMappingDtoToJSON)),
    };
}


