/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportTemplate,
    ReportTemplateFromJSON,
    ReportTemplateFromJSONTyped,
    ReportTemplateToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReportTemplatesListDto
 */
export interface ReportTemplatesListDto {
    /**
     * 
     * @type {Array<ReportTemplate>}
     * @memberof ReportTemplatesListDto
     */
    results: Array<ReportTemplate>;
    /**
     * 
     * @type {number}
     * @memberof ReportTemplatesListDto
     */
    total?: number;
    /**
     * 
     * @type {object}
     * @memberof ReportTemplatesListDto
     */
    filters?: object;
    /**
     * 
     * @type {object}
     * @memberof ReportTemplatesListDto
     */
    extra?: object;
}

export function ReportTemplatesListDtoFromJSON(json: any): ReportTemplatesListDto {
    return ReportTemplatesListDtoFromJSONTyped(json, false);
}

export function ReportTemplatesListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportTemplatesListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ReportTemplateFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : json['filters'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function ReportTemplatesListDtoToJSON(value?: ReportTemplatesListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ReportTemplateToJSON)),
        'total': value.total,
        'filters': value.filters,
        'extra': value.extra,
    };
}


