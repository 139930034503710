/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationProductionDataGrouped,
    LocationProductionDataGroupedFromJSON,
    LocationProductionDataGroupedFromJSONTyped,
    LocationProductionDataGroupedToJSON,
} from './';

/**
 * 
 * @export
 * @interface ProductionDataListDto
 */
export interface ProductionDataListDto {
    /**
     * 
     * @type {Array<LocationProductionDataGrouped>}
     * @memberof ProductionDataListDto
     */
    results?: Array<LocationProductionDataGrouped>;
    /**
     * 
     * @type {number}
     * @memberof ProductionDataListDto
     */
    total?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDataListDto
     */
    hasMonthlyAttributes: boolean;
}

export function ProductionDataListDtoFromJSON(json: any): ProductionDataListDto {
    return ProductionDataListDtoFromJSONTyped(json, false);
}

export function ProductionDataListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductionDataListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': !exists(json, 'results') ? undefined : ((json['results'] as Array<any>).map(LocationProductionDataGroupedFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'hasMonthlyAttributes': json['hasMonthlyAttributes'],
    };
}

export function ProductionDataListDtoToJSON(value?: ProductionDataListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': value.results === undefined ? undefined : ((value.results as Array<any>).map(LocationProductionDataGroupedToJSON)),
        'total': value.total,
        'hasMonthlyAttributes': value.hasMonthlyAttributes,
    };
}


