/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentDetail
 */
export interface PaymentDetail {
    /**
     * 
     * @type {number}
     * @memberof PaymentDetail
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetail
     */
    billId: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetail
     */
    customerId: number;
    /**
     * 
     * @type {Date}
     * @memberof PaymentDetail
     */
    dueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentDetail
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentDetail
     */
    invoiceDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentDetail
     */
    paymentFileDate: Date;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetail
     */
    totalAmountDue: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetail
     */
    vendorBillingId: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetail
     */
    vendorName: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetail
     */
    fileType: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetail
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetail
     */
    apRequest: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentDetail
     */
    amountPaid: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDetail
     */
    paymentStatus: string;
}

export function PaymentDetailFromJSON(json: any): PaymentDetail {
    return PaymentDetailFromJSONTyped(json, false);
}

export function PaymentDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'billId': json['billId'],
        'customerId': json['customerId'],
        'dueDate': (new Date(json['dueDate'])),
        'createdAt': (new Date(json['createdAt'])),
        'invoiceDate': (new Date(json['invoiceDate'])),
        'paymentFileDate': (new Date(json['paymentFileDate'])),
        'totalAmountDue': json['totalAmountDue'],
        'vendorBillingId': json['vendorBillingId'],
        'vendorName': json['vendorName'],
        'fileType': json['fileType'],
        'url': json['url'],
        'apRequest': json['apRequest'],
        'amountPaid': json['amountPaid'],
        'paymentStatus': json['paymentStatus'],
    };
}

export function PaymentDetailToJSON(value?: PaymentDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'billId': value.billId,
        'customerId': value.customerId,
        'dueDate': (value.dueDate.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'invoiceDate': (value.invoiceDate.toISOString()),
        'paymentFileDate': (value.paymentFileDate.toISOString()),
        'totalAmountDue': value.totalAmountDue,
        'vendorBillingId': value.vendorBillingId,
        'vendorName': value.vendorName,
        'fileType': value.fileType,
        'url': value.url,
        'apRequest': value.apRequest,
        'amountPaid': value.amountPaid,
        'paymentStatus': value.paymentStatus,
    };
}


