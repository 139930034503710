/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarAddressDto,
    EnergyStarAddressDtoFromJSON,
    EnergyStarAddressDtoFromJSONTyped,
    EnergyStarAddressDtoToJSON,
    GrossFloorAreaDto,
    GrossFloorAreaDtoFromJSON,
    GrossFloorAreaDtoFromJSONTyped,
    GrossFloorAreaDtoToJSON,
    IrrigatedAreaDto,
    IrrigatedAreaDtoFromJSON,
    IrrigatedAreaDtoFromJSONTyped,
    IrrigatedAreaDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateEnergyStarPropertyDto
 */
export interface UpdateEnergyStarPropertyDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEnergyStarPropertyDto
     */
    name: string;
    /**
     * 
     * @type {EnergyStarAddressDto}
     * @memberof UpdateEnergyStarPropertyDto
     */
    address: EnergyStarAddressDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateEnergyStarPropertyDto
     */
    yearBuilt: number;
    /**
     * 
     * @type {GrossFloorAreaDto}
     * @memberof UpdateEnergyStarPropertyDto
     */
    grossFloorArea: GrossFloorAreaDto;
    /**
     * 
     * @type {number}
     * @memberof UpdateEnergyStarPropertyDto
     */
    occupancyPercentage: number;
    /**
     * 
     * @type {IrrigatedAreaDto}
     * @memberof UpdateEnergyStarPropertyDto
     */
    irrigatedArea?: IrrigatedAreaDto;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnergyStarPropertyDto
     */
    primaryFunction: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnergyStarPropertyDto
     */
    constructionStatus: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateEnergyStarPropertyDto
     */
    numberOfBuildings?: number;
}

export function UpdateEnergyStarPropertyDtoFromJSON(json: any): UpdateEnergyStarPropertyDto {
    return UpdateEnergyStarPropertyDtoFromJSONTyped(json, false);
}

export function UpdateEnergyStarPropertyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEnergyStarPropertyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'address': EnergyStarAddressDtoFromJSON(json['address']),
        'yearBuilt': json['yearBuilt'],
        'grossFloorArea': GrossFloorAreaDtoFromJSON(json['grossFloorArea']),
        'occupancyPercentage': json['occupancyPercentage'],
        'irrigatedArea': !exists(json, 'irrigatedArea') ? undefined : IrrigatedAreaDtoFromJSON(json['irrigatedArea']),
        'primaryFunction': json['primaryFunction'],
        'constructionStatus': json['constructionStatus'],
        'numberOfBuildings': !exists(json, 'numberOfBuildings') ? undefined : json['numberOfBuildings'],
    };
}

export function UpdateEnergyStarPropertyDtoToJSON(value?: UpdateEnergyStarPropertyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'address': EnergyStarAddressDtoToJSON(value.address),
        'yearBuilt': value.yearBuilt,
        'grossFloorArea': GrossFloorAreaDtoToJSON(value.grossFloorArea),
        'occupancyPercentage': value.occupancyPercentage,
        'irrigatedArea': IrrigatedAreaDtoToJSON(value.irrigatedArea),
        'primaryFunction': value.primaryFunction,
        'constructionStatus': value.constructionStatus,
        'numberOfBuildings': value.numberOfBuildings,
    };
}


