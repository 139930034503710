import { CustomersService } from '@/Modules/Customers/services/service.customers'
import {
  TableOptions,
  TableQuery,
  TableRepository,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import { Ref, ref } from 'vue'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import {
  CustomerListDto,
  CustomersControllerGetListExcludeEnum,
  CustomersControllerGetListRequest,
} from '@/SharedModule/Api'

const customersService = new CustomersService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      searchQuery: null,
      active: true,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({
      id: null,
      name: true,
      createdAt: null,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('ID'),
        labelClassName: null,
        name: null,
        width: ref('80'),
        type: null,
        modelKey: ref('id'),
      },
      {
        prettyName: ref('Customers Name'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('name'),
      },
      {
        prettyName: ref('Date Created'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('createdAt'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'customers',
      },
      apiFilters: null,
      inputTimeout: 500,
      sortByTimeout: 10,
    }),
  }
}

const useListRef = (): Ref<CustomerListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  })

const adaptFilterDataToApiTypesAndExclude = (
  filterData: CustomersControllerGetListRequest | any,
  sortBy: any,
  meta: any,
): CustomersControllerGetListRequest => {
  if (sortBy) {
    filterData.orderBy = filterData.orderBy || []
    Object.keys(sortBy).forEach((currentSortKey: string) => {
      const destructuredFilterData: any = filterData
      if (sortBy[currentSortKey] != null) {
        filterData.orderBy?.push(
          currentSortKey + (sortBy[currentSortKey] ? '.asc' : '.desc'),
        )
      }
      delete destructuredFilterData[currentSortKey]
    })

    if (!filterData.orderBy?.length) {
      delete filterData.orderBy
    }
  }
  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(CustomersControllerGetListExcludeEnum.Filters)
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        CustomersControllerGetListExcludeEnum.Total,
        CustomersControllerGetListExcludeEnum.Extra,
      )
    }
  }
  return filterData
}

export default class RepositoryCustomersList
  implements TableRepository<CustomerListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  getList = async (): Promise<void> => {
    try {
      const response = await customersService.getCustomers(
        adaptFilterDataToApiTypesAndExclude(
          useQueryAsRequest<CustomersControllerGetListRequest>(
            this.options.query,
          ),
          this.options.query.sortBy?.value,
          this.options.meta.value,
        ),
      )
      if (this.options.meta.value.excludePaginationData) {
        response.extra = this.list.value.extra
        response.total = this.list.value.total
        this.options.meta.value.excludePaginationData = false
      }
      this.list.value = response
    } catch (e) {
      console.log(e)
    }
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
