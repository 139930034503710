/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationMetadata,
    LocationMetadataFromJSON,
    LocationMetadataFromJSONTyped,
    LocationMetadataToJSON,
    LocationPayloadModel,
    LocationPayloadModelFromJSON,
    LocationPayloadModelFromJSONTyped,
    LocationPayloadModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    postcode: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    country: string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    longitude: number;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    weatherActive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Location
     */
    weatherSetup: boolean;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    squareFeet: number;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Location
     */
    deletedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    template: string;
    /**
     * 
     * @type {object}
     * @memberof Location
     */
    billSources: object;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    buildingType: LocationBuildingTypeEnum;
    /**
     * 
     * @type {LocationPayloadModel}
     * @memberof Location
     */
    payload: LocationPayloadModel;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    energyStarPropertyId: number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    paymentCode: string;
    /**
     * 
     * @type {Array<LocationMetadata>}
     * @memberof Location
     */
    metadata?: Array<LocationMetadata>;
}

/**
* @export
* @enum {string}
*/
export enum LocationBuildingTypeEnum {
    Building = 'BUILDING',
    Campus = 'CAMPUS',
    Storefront = 'STOREFRONT'
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'name': json['name'],
        'address': json['address'],
        'postcode': json['postcode'],
        'city': json['city'],
        'state': json['state'],
        'country': json['country'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'weatherActive': json['weatherActive'],
        'weatherSetup': json['weatherSetup'],
        'squareFeet': json['squareFeet'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deletedAt': (new Date(json['deletedAt'])),
        'template': json['template'],
        'billSources': json['billSources'],
        'buildingType': json['buildingType'],
        'payload': LocationPayloadModelFromJSON(json['payload']),
        'energyStarPropertyId': json['energyStarPropertyId'],
        'paymentCode': json['paymentCode'],
        'metadata': !exists(json, 'metadata') ? undefined : ((json['metadata'] as Array<any>).map(LocationMetadataFromJSON)),
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'name': value.name,
        'address': value.address,
        'postcode': value.postcode,
        'city': value.city,
        'state': value.state,
        'country': value.country,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'weatherActive': value.weatherActive,
        'weatherSetup': value.weatherSetup,
        'squareFeet': value.squareFeet,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'deletedAt': (value.deletedAt.toISOString()),
        'template': value.template,
        'billSources': value.billSources,
        'buildingType': value.buildingType,
        'payload': LocationPayloadModelToJSON(value.payload),
        'energyStarPropertyId': value.energyStarPropertyId,
        'paymentCode': value.paymentCode,
        'metadata': value.metadata === undefined ? undefined : ((value.metadata as Array<any>).map(LocationMetadataToJSON)),
    };
}


