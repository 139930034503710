import {
  PowerBiApi,
  PowerBIControllerCreateReportRequest,
  PowerBIControllerDeleteReportRequest,
  PowerBIControllerGetReportsRequest,
  PowerBIControllerUpdateReportRequest,
  PowerBIReportModel,
  PowerBIReportsListDto,
} from '@/SharedModule/Api/index'
import { ApiService } from '@/SharedModule/Services/service.api'

export class PowerBiService extends ApiService {
  private powerBiApi = new PowerBiApi(this.getBaseOptions())

  public getPowerBiReports = (
    requestParameters: PowerBIControllerGetReportsRequest,
  ): Promise<PowerBIReportsListDto> => {
    return this.powerBiApi.powerBIControllerGetReports(requestParameters)
  }
  public deletePowerBiReport = async (
    requestParameters: PowerBIControllerDeleteReportRequest,
  ): Promise<void> => {
    return this.powerBiApi.powerBIControllerDeleteReport(requestParameters)
  }
  public updatePowerBiReport = (
    requestParameters: PowerBIControllerUpdateReportRequest,
  ): Promise<PowerBIReportModel> => {
    return this.powerBiApi.powerBIControllerUpdateReport(requestParameters)
  }
  public createPowerBiReport = (
    requestParameters: PowerBIControllerCreateReportRequest,
  ): Promise<PowerBIReportModel> => {
    return this.powerBiApi.powerBIControllerCreateReport(requestParameters)
  }
}
