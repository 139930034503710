/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RunCustomerReportDto
 */
export interface RunCustomerReportDto {
    /**
     * 
     * @type {number}
     * @memberof RunCustomerReportDto
     */
    reportTemplateId: number;
    /**
     * 
     * @type {string}
     * @memberof RunCustomerReportDto
     */
    customReportName: string;
    /**
     * 
     * @type {object}
     * @memberof RunCustomerReportDto
     */
    appliedFilters: object;
    /**
     * 
     * @type {string}
     * @memberof RunCustomerReportDto
     */
    saveFormat: string;
    /**
     * 
     * @type {string}
     * @memberof RunCustomerReportDto
     */
    timezone: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RunCustomerReportDto
     */
    emailRecipients: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RunCustomerReportDto
     */
    sftpConnectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof RunCustomerReportDto
     */
    id?: string;
    /**
     * 
     * @type {object}
     * @memberof RunCustomerReportDto
     */
    configPayload: object;
}

export function RunCustomerReportDtoFromJSON(json: any): RunCustomerReportDto {
    return RunCustomerReportDtoFromJSONTyped(json, false);
}

export function RunCustomerReportDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RunCustomerReportDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportTemplateId': json['reportTemplateId'],
        'customReportName': json['customReportName'],
        'appliedFilters': json['appliedFilters'],
        'saveFormat': json['saveFormat'],
        'timezone': json['timezone'],
        'emailRecipients': json['emailRecipients'],
        'sftpConnectionId': !exists(json, 'sftpConnectionId') ? undefined : json['sftpConnectionId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'configPayload': json['configPayload'],
    };
}

export function RunCustomerReportDtoToJSON(value?: RunCustomerReportDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportTemplateId': value.reportTemplateId,
        'customReportName': value.customReportName,
        'appliedFilters': value.appliedFilters,
        'saveFormat': value.saveFormat,
        'timezone': value.timezone,
        'emailRecipients': value.emailRecipients,
        'sftpConnectionId': value.sftpConnectionId,
        'id': value.id,
        'configPayload': value.configPayload,
    };
}


