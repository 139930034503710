import {
  TablePaginationMeta,
  TableQuery,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import { ref } from 'vue'

export const useTablePaginationMeta = (
  options: TablePaginationMeta | null = null,
): TablePaginationMeta => {
  return Object.assign(
    {
      showPagination: true,
      pageSize: ref(10),
      pageSizes: ref([10, 20, 30, 40, 50, 100]),
    },
    options || {},
  )
}
export const useQueryAsRequest = <T>(query: TableQuery): T => {
  const result = {
    ...query.filters.value,
    ...query.sort.value,
  }
  Object.keys(result).forEach((key) => {
    if (result[key] === '') {
      delete result[key]
    }
  })
  return result as T
}
