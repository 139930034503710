/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResolveErrorsDto
 */
export interface ResolveErrorsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ResolveErrorsDto
     */
    billsIds: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ResolveErrorsDto
     */
    validationCheckIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ResolveErrorsDto
     */
    comment: string;
}

export function ResolveErrorsDtoFromJSON(json: any): ResolveErrorsDto {
    return ResolveErrorsDtoFromJSONTyped(json, false);
}

export function ResolveErrorsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResolveErrorsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billsIds': json['billsIds'],
        'validationCheckIds': json['validationCheckIds'],
        'comment': json['comment'],
    };
}

export function ResolveErrorsDtoToJSON(value?: ResolveErrorsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billsIds': value.billsIds,
        'validationCheckIds': value.validationCheckIds,
        'comment': value.comment,
    };
}


