/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarbonFootprint
 */
export interface CarbonFootprint {
    /**
     * 
     * @type {number}
     * @memberof CarbonFootprint
     */
    customerId: number;
    /**
     * 
     * @type {boolean}
     * @memberof CarbonFootprint
     */
    customReportEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarbonFootprint
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof CarbonFootprint
     */
    reportId: string;
    /**
     * 
     * @type {string}
     * @memberof CarbonFootprint
     */
    datasetId: string;
}

export function CarbonFootprintFromJSON(json: any): CarbonFootprint {
    return CarbonFootprintFromJSONTyped(json, false);
}

export function CarbonFootprintFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarbonFootprint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'customReportEnabled': json['customReportEnabled'],
        'groupId': json['groupId'],
        'reportId': json['reportId'],
        'datasetId': json['datasetId'],
    };
}

export function CarbonFootprintToJSON(value?: CarbonFootprint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'customReportEnabled': value.customReportEnabled,
        'groupId': value.groupId,
        'reportId': value.reportId,
        'datasetId': value.datasetId,
    };
}


