/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PreviewReportDto,
    PreviewReportDtoFromJSON,
    PreviewReportDtoToJSON,
    ReportPreviewListDto,
    ReportPreviewListDtoFromJSON,
    ReportPreviewListDtoToJSON,
    ReportTemplate,
    ReportTemplateFromJSON,
    ReportTemplateToJSON,
    ReportTemplatesListDto,
    ReportTemplatesListDtoFromJSON,
    ReportTemplatesListDtoToJSON,
    ScheduledReport,
    ScheduledReportFromJSON,
    ScheduledReportToJSON,
    ScheduledReportDto,
    ScheduledReportDtoFromJSON,
    ScheduledReportDtoToJSON,
    ScheduledReportsListDto,
    ScheduledReportsListDtoFromJSON,
    ScheduledReportsListDtoToJSON,
} from '../models';

export interface ReportsControllerDeleteScheduledReportRequest {
    scheduledReportId: number;
    customerId: number;
}

export interface ReportsControllerGetAnyReportPreviewRequest {
    customerId: number;
    previewReportDto: PreviewReportDto;
}

export interface ReportsControllerGetReportFiltersRequest {
    customerId: number;
    reportId: number;
}

export interface ReportsControllerGetReportPreviewRequest {
    customerId: number;
}

export interface ReportsControllerGetReportTemplatesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<ReportsControllerGetReportTemplatesExcludeEnum>;
    orderBy?: Array<ReportsControllerGetReportTemplatesOrderByEnum>;
    search?: string;
    featureType?: ReportsControllerGetReportTemplatesFeatureTypeEnum;
}

export interface ReportsControllerGetScheduledReportsRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<ReportsControllerGetScheduledReportsExcludeEnum>;
    orderBy?: ReportsControllerGetScheduledReportsOrderByEnum;
    type?: Array<string>;
    format?: Array<string>;
    name?: string;
}

export interface ReportsControllerRunScheduleReportRequest {
    scheduledReportId: number;
    customerId: number;
}

export interface ReportsControllerSaveScheduledReportRequest {
    customerId: number;
    scheduledReportDto: ScheduledReportDto;
}

export interface ReportsControllerUpdateScheduledReportRequest {
    scheduledReportId: number;
    customerId: number;
    scheduledReportDto: ScheduledReportDto;
}

/**
 * 
 */
export class ReportsApi extends runtime.BaseAPI {

    /**
     * Delete a scheduled report for a given customer
     */
    async reportsControllerDeleteScheduledReportRaw(requestParameters: ReportsControllerDeleteScheduledReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheduledReportId === null || requestParameters.scheduledReportId === undefined) {
            throw new runtime.RequiredError('scheduledReportId','Required parameter requestParameters.scheduledReportId was null or undefined when calling reportsControllerDeleteScheduledReport.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerDeleteScheduledReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/scheduled/{scheduledReportId}`.replace(`{${"scheduledReportId"}}`, encodeURIComponent(String(requestParameters.scheduledReportId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a scheduled report for a given customer
     */
    async reportsControllerDeleteScheduledReport(requestParameters: ReportsControllerDeleteScheduledReportRequest): Promise<void> {
        await this.reportsControllerDeleteScheduledReportRaw(requestParameters);
    }

    /**
     * Get preview of a given report for a given customer
     */
    async reportsControllerGetAnyReportPreviewRaw(requestParameters: ReportsControllerGetAnyReportPreviewRequest): Promise<runtime.ApiResponse<ReportPreviewListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerGetAnyReportPreview.');
        }

        if (requestParameters.previewReportDto === null || requestParameters.previewReportDto === undefined) {
            throw new runtime.RequiredError('previewReportDto','Required parameter requestParameters.previewReportDto was null or undefined when calling reportsControllerGetAnyReportPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/preview`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PreviewReportDtoToJSON(requestParameters.previewReportDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportPreviewListDtoFromJSON(jsonValue));
    }

    /**
     * Get preview of a given report for a given customer
     */
    async reportsControllerGetAnyReportPreview(requestParameters: ReportsControllerGetAnyReportPreviewRequest): Promise<ReportPreviewListDto> {
        const response = await this.reportsControllerGetAnyReportPreviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of available filters for a given customer and report
     */
    async reportsControllerGetReportFiltersRaw(requestParameters: ReportsControllerGetReportFiltersRequest): Promise<runtime.ApiResponse<ReportTemplate>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerGetReportFilters.');
        }

        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling reportsControllerGetReportFilters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/available-filters/{reportId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTemplateFromJSON(jsonValue));
    }

    /**
     * Get the list of available filters for a given customer and report
     */
    async reportsControllerGetReportFilters(requestParameters: ReportsControllerGetReportFiltersRequest): Promise<ReportTemplate> {
        const response = await this.reportsControllerGetReportFiltersRaw(requestParameters);
        return await response.value();
    }

    /**
     * (Deprecated) Get preview of billing line items report for a given customer
     */
    async reportsControllerGetReportPreviewRaw(requestParameters: ReportsControllerGetReportPreviewRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerGetReportPreview.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/preview`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * (Deprecated) Get preview of billing line items report for a given customer
     */
    async reportsControllerGetReportPreview(requestParameters: ReportsControllerGetReportPreviewRequest): Promise<void> {
        await this.reportsControllerGetReportPreviewRaw(requestParameters);
    }

    /**
     * Get the list of report templates for a given customer
     */
    async reportsControllerGetReportTemplatesRaw(requestParameters: ReportsControllerGetReportTemplatesRequest): Promise<runtime.ApiResponse<ReportTemplatesListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerGetReportTemplates.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.featureType !== undefined) {
            queryParameters['featureType'] = requestParameters.featureType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/templates`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTemplatesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of report templates for a given customer
     */
    async reportsControllerGetReportTemplates(requestParameters: ReportsControllerGetReportTemplatesRequest): Promise<ReportTemplatesListDto> {
        const response = await this.reportsControllerGetReportTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of scheduled reports for a given customer
     */
    async reportsControllerGetScheduledReportsRaw(requestParameters: ReportsControllerGetScheduledReportsRequest): Promise<runtime.ApiResponse<ScheduledReportsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerGetScheduledReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.type) {
            queryParameters['type[]'] = requestParameters.type;
        }

        if (requestParameters.format) {
            queryParameters['format[]'] = requestParameters.format;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/scheduled`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduledReportsListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of scheduled reports for a given customer
     */
    async reportsControllerGetScheduledReports(requestParameters: ReportsControllerGetScheduledReportsRequest): Promise<ScheduledReportsListDto> {
        const response = await this.reportsControllerGetScheduledReportsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Run a scheduled report for a given customer
     */
    async reportsControllerRunScheduleReportRaw(requestParameters: ReportsControllerRunScheduleReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheduledReportId === null || requestParameters.scheduledReportId === undefined) {
            throw new runtime.RequiredError('scheduledReportId','Required parameter requestParameters.scheduledReportId was null or undefined when calling reportsControllerRunScheduleReport.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerRunScheduleReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/scheduled/{scheduledReportId}/run`.replace(`{${"scheduledReportId"}}`, encodeURIComponent(String(requestParameters.scheduledReportId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Run a scheduled report for a given customer
     */
    async reportsControllerRunScheduleReport(requestParameters: ReportsControllerRunScheduleReportRequest): Promise<void> {
        await this.reportsControllerRunScheduleReportRaw(requestParameters);
    }

    /**
     * Save a scheduled report for a given customer
     */
    async reportsControllerSaveScheduledReportRaw(requestParameters: ReportsControllerSaveScheduledReportRequest): Promise<runtime.ApiResponse<ScheduledReport>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerSaveScheduledReport.');
        }

        if (requestParameters.scheduledReportDto === null || requestParameters.scheduledReportDto === undefined) {
            throw new runtime.RequiredError('scheduledReportDto','Required parameter requestParameters.scheduledReportDto was null or undefined when calling reportsControllerSaveScheduledReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/scheduled`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduledReportDtoToJSON(requestParameters.scheduledReportDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduledReportFromJSON(jsonValue));
    }

    /**
     * Save a scheduled report for a given customer
     */
    async reportsControllerSaveScheduledReport(requestParameters: ReportsControllerSaveScheduledReportRequest): Promise<ScheduledReport> {
        const response = await this.reportsControllerSaveScheduledReportRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a scheduled report for a given customer
     */
    async reportsControllerUpdateScheduledReportRaw(requestParameters: ReportsControllerUpdateScheduledReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheduledReportId === null || requestParameters.scheduledReportId === undefined) {
            throw new runtime.RequiredError('scheduledReportId','Required parameter requestParameters.scheduledReportId was null or undefined when calling reportsControllerUpdateScheduledReport.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling reportsControllerUpdateScheduledReport.');
        }

        if (requestParameters.scheduledReportDto === null || requestParameters.scheduledReportDto === undefined) {
            throw new runtime.RequiredError('scheduledReportDto','Required parameter requestParameters.scheduledReportDto was null or undefined when calling reportsControllerUpdateScheduledReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/scheduled/{scheduledReportId}`.replace(`{${"scheduledReportId"}}`, encodeURIComponent(String(requestParameters.scheduledReportId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduledReportDtoToJSON(requestParameters.scheduledReportDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a scheduled report for a given customer
     */
    async reportsControllerUpdateScheduledReport(requestParameters: ReportsControllerUpdateScheduledReportRequest): Promise<void> {
        await this.reportsControllerUpdateScheduledReportRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ReportsControllerGetReportTemplatesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportsControllerGetReportTemplatesOrderByEnum {
    Asc = 'title.asc',
    Desc = 'title.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportsControllerGetReportTemplatesFeatureTypeEnum {
    Reports = 'reports',
    Budgeting = 'budgeting'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportsControllerGetScheduledReportsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum ReportsControllerGetScheduledReportsOrderByEnum {
    CreatedAtAsc = 'createdAt.asc',
    CustomReportNameAsc = 'customReportName.asc',
    TitleAsc = 'title.asc',
    SaveFormatAsc = 'saveFormat.asc',
    RecurringTypeAsc = 'recurringType.asc',
    SftpConnectionNameAsc = 'sftpConnectionName.asc',
    EmailRecipientsFlatAsc = 'emailRecipientsFlat.asc',
    CreatedAtDesc = 'createdAt.desc',
    CustomReportNameDesc = 'customReportName.desc',
    TitleDesc = 'title.desc',
    SaveFormatDesc = 'saveFormat.desc',
    RecurringTypeDesc = 'recurringType.desc',
    SftpConnectionNameDesc = 'sftpConnectionName.desc',
    EmailRecipientsFlatDesc = 'emailRecipientsFlat.desc'
}
