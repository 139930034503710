/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    from: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Email
     */
    to: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    html: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof Email
     */
    attachments: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    type: string;
    /**
     * 
     * @type {object}
     * @memberof Email
     */
    deliveryStatusMetadata: object;
}

export function EmailFromJSON(json: any): Email {
    return EmailFromJSONTyped(json, false);
}

export function EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Email {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'from': json['from'],
        'to': json['to'],
        'subject': json['subject'],
        'html': json['html'],
        'attachments': json['attachments'],
        'type': json['type'],
        'deliveryStatusMetadata': json['deliveryStatusMetadata'],
    };
}

export function EmailToJSON(value?: Email | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'from': value.from,
        'to': value.to,
        'subject': value.subject,
        'html': value.html,
        'attachments': value.attachments,
        'type': value.type,
        'deliveryStatusMetadata': value.deliveryStatusMetadata,
    };
}


