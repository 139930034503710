export enum PaymentsDialogStates {
  root = 'root',
  edit = 'edit',
  addErr = 'addErr',
  editErr = 'editErr',
  EmailOrSFTP = 'EmailOrSFTP',
}

export enum PaymentsDialogWidthsEnum {
  root = '664px',
  edit = '500px',
  addErr = '500px',
  editErr = '500px',
  EmailOrSFTP = '500px',
}

export enum PaymentsDialogTitlesEnum {
  root = 'Payment Settings',
  edit = 'Add new payment delivery',
  addErr = 'Add new error delivery',
  editErr = 'Edit error delivery',
  EmailOrSFTP = 'Edit payment delivery',
}

export enum PaymentsActiveTabEnum {
  PaymentsTab = 'paymentsTab',
  ErrorsTab = 'errorsTab',
}
