/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarGrossFloorArea
 */
export interface EnergyStarGrossFloorArea {
    /**
     * 
     * @type {boolean}
     * @memberof EnergyStarGrossFloorArea
     */
    temporary: boolean;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarGrossFloorArea
     */
    units: string;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarGrossFloorArea
     */
    value: number;
}

export function EnergyStarGrossFloorAreaFromJSON(json: any): EnergyStarGrossFloorArea {
    return EnergyStarGrossFloorAreaFromJSONTyped(json, false);
}

export function EnergyStarGrossFloorAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarGrossFloorArea {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'temporary': json['temporary'],
        'units': json['units'],
        'value': json['value'],
    };
}

export function EnergyStarGrossFloorAreaToJSON(value?: EnergyStarGrossFloorArea | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'temporary': value.temporary,
        'units': value.units,
        'value': value.value,
    };
}


