/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomerFilterDto,
    CustomerFilterDtoFromJSON,
    CustomerFilterDtoFromJSONTyped,
    CustomerFilterDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BatchesFiltersMeta
 */
export interface BatchesFiltersMeta {
    /**
     * 
     * @type {Array<CustomerFilterDto>}
     * @memberof BatchesFiltersMeta
     */
    customers: Array<CustomerFilterDto>;
}

export function BatchesFiltersMetaFromJSON(json: any): BatchesFiltersMeta {
    return BatchesFiltersMetaFromJSONTyped(json, false);
}

export function BatchesFiltersMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchesFiltersMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customers': ((json['customers'] as Array<any>).map(CustomerFilterDtoFromJSON)),
    };
}

export function BatchesFiltersMetaToJSON(value?: BatchesFiltersMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customers': ((value.customers as Array<any>).map(CustomerFilterDtoToJSON)),
    };
}


