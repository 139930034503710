import { RouteRecordRaw } from 'vue-router'
import Component from '@/Modules/PearDashboardParcel/views/view.index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Vue2Parcel.all',
    component: Component,
  },
]

export default routes
