/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ObservationsListResponseDto,
    ObservationsListResponseDtoFromJSON,
    ObservationsListResponseDtoToJSON,
} from '../models';

export interface ObservationsControllerGetPropertyRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<ObservationsControllerGetPropertyExcludeEnum>;
    search?: string;
    orderBy?: Array<string>;
    category?: Array<string>;
    excludedCategory?: Array<string>;
}

/**
 * 
 */
export class ObservationsApi extends runtime.BaseAPI {

    /**
     * Returns the observations
     */
    async observationsControllerGetPropertyRaw(requestParameters: ObservationsControllerGetPropertyRequest): Promise<runtime.ApiResponse<ObservationsListResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.category) {
            queryParameters['category'] = requestParameters.category;
        }

        if (requestParameters.excludedCategory) {
            queryParameters['excludedCategory'] = requestParameters.excludedCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/observations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ObservationsListResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns the observations
     */
    async observationsControllerGetProperty(requestParameters: ObservationsControllerGetPropertyRequest): Promise<ObservationsListResponseDto> {
        const response = await this.observationsControllerGetPropertyRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ObservationsControllerGetPropertyExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
