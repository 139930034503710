import {
  CustomerFilesControllerGetFileRequest,
  FilesApi,
  FileUrlDto,
} from '@/SharedModule/Api/index'
import { ApiService } from '@/SharedModule/Services/service.api'

export class FilesService extends ApiService {
  private filesApi = new FilesApi(this.getBaseOptions())

  public getFile = (
    requestParameters: CustomerFilesControllerGetFileRequest,
  ): Promise<FileUrlDto> => {
    return this.filesApi.customerFilesControllerGetFile(requestParameters)
  }
}
