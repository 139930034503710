/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarContactDto,
    EnergyStarContactDtoFromJSON,
    EnergyStarContactDtoFromJSONTyped,
    EnergyStarContactDtoToJSON,
    EnergyStarOrganizationDto,
    EnergyStarOrganizationDtoFromJSON,
    EnergyStarOrganizationDtoFromJSONTyped,
    EnergyStarOrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateEnergyStarCustomerDto
 */
export interface CreateEnergyStarCustomerDto {
    /**
     * 
     * @type {EnergyStarContactDto}
     * @memberof CreateEnergyStarCustomerDto
     */
    contact: EnergyStarContactDto;
    /**
     * 
     * @type {EnergyStarOrganizationDto}
     * @memberof CreateEnergyStarCustomerDto
     */
    organization: EnergyStarOrganizationDto;
    /**
     * 
     * @type {string}
     * @memberof CreateEnergyStarCustomerDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEnergyStarCustomerDto
     */
    password: string;
}

export function CreateEnergyStarCustomerDtoFromJSON(json: any): CreateEnergyStarCustomerDto {
    return CreateEnergyStarCustomerDtoFromJSONTyped(json, false);
}

export function CreateEnergyStarCustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEnergyStarCustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contact': EnergyStarContactDtoFromJSON(json['contact']),
        'organization': EnergyStarOrganizationDtoFromJSON(json['organization']),
        'username': json['username'],
        'password': json['password'],
    };
}

export function CreateEnergyStarCustomerDtoToJSON(value?: CreateEnergyStarCustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': EnergyStarContactDtoToJSON(value.contact),
        'organization': EnergyStarOrganizationDtoToJSON(value.organization),
        'username': value.username,
        'password': value.password,
    };
}


