import {
  TableOptions,
  TableQuery,
  TableRepository,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import {
  CustomersControllerGetListExcludeEnum,
  EmailDeliveryStatusListDto,
  EmailerControllerGetEmailsDeliveryStatusRequest,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { ErrorsService } from '@/Modules/Errors/service/service.errors'

const errorsService = new ErrorsService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      createdAtMin: null,
      emailStatuses: null,
      emailStatusTypes: 'warning, error',
      statusDateMin: null,
      customerIds: null,
      emailTypes: null,
      emailAddresses: null,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({
      createdAt: null,
      emailStatus: null,
      emailStatusType: null,
      statusDate: null,
      customerName: null,
      emailType: null,
      emailAddress: null,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('Initial Creation Date'),
        labelClassName: null,
        name: null,
        width: ref('200'),
        type: null,
        modelKey: ref('createdAt'),
      },
      {
        prettyName: ref('Status'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('emailStatus'),
      },
      {
        prettyName: ref('Type'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('emailStatusType'),
      },
      {
        prettyName: ref('Status Change Date'),
        labelClassName: null,
        name: null,
        type: null,
        width: null,
        modelKey: ref('statusDate'),
      },
      {
        prettyName: ref('Customer'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('customerName'),
      },
      {
        prettyName: ref('Module'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('emailType'),
      },
      {
        prettyName: ref('Email Address'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('emailAddress'),
      },
      {
        prettyName: ref(''),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('moreDetails'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'errorsTab.communicationerrors',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: any,
  sortBy: any,
  meta: any,
): any => {
  if (sortBy) {
    filterData.orderBy = filterData.orderBy || []
    Object.keys(sortBy).forEach((currentSortKey: string) => {
      const destructuredFilterData: any = filterData
      if (sortBy[currentSortKey] != null) {
        filterData.orderBy?.push(
          currentSortKey + (sortBy[currentSortKey] ? '.asc' : '.desc'),
        )
      }
      delete destructuredFilterData[currentSortKey]
    })
    if (!filterData.orderBy?.length) {
      delete filterData.orderBy
    }
  }
  if (filterData.emailStatuses) {
    filterData.emailStatus = filterData.emailStatuses.toString().split(',')
  }
  if (filterData.emailStatusTypes) {
    filterData.emailStatusType = filterData.emailStatusTypes
      .toString()
      .split(',')
      .map((emailStatusType: string) => emailStatusType.trim())
  }
  if (filterData.emailTypes) {
    filterData.emailType = filterData.emailTypes.toString().split(',')
  }
  if (filterData.emailAddresses) {
    filterData.emailAddress = filterData.emailAddresses.toString().split(',')
  }
  if (filterData.createdAtMin) {
    filterData.createdAtMin = new Date(filterData.createdAtMin)
  }
  if (filterData.statusDateMin) {
    filterData.statusDateMin = new Date(filterData.statusDateMin)
  }
  if (filterData.customerIds) {
    filterData.customerId = filterData.customerIds
      .toString()
      .split(',')
      .map((currentId: string) => +currentId)
  }
  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(CustomersControllerGetListExcludeEnum.Filters)
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        CustomersControllerGetListExcludeEnum.Total,
        CustomersControllerGetListExcludeEnum.Extra,
      )
    }
  }
  return filterData
}

const useListRef = (): Ref<EmailDeliveryStatusListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  }) as Ref<EmailDeliveryStatusListDto>

export default class RepositoryCommunicationsErrors
  implements TableRepository<EmailDeliveryStatusListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  getList = async (): Promise<void> => {
    try {
      const listValue = (await errorsService.getCommunicationErrors(
        adaptFilterDataToApiTypesAndExclude(
          useQueryAsRequest<EmailerControllerGetEmailsDeliveryStatusRequest>(
            this.options.query,
          ),
          this.options.query.sortBy?.value,
          this.options.meta.value,
        ),
      )) as any
      if (this.options.meta.value.apiFilters === null) {
        this.options.meta.value.apiFilters = listValue.filters
      }
      if (this.options.meta.value.excludePaginationData) {
        listValue.extra = this.list.value.extra
        listValue.total = this.list.value.total
        this.options.meta.value.excludePaginationData = false
      }
      this.list.value = listValue
    } catch (e) {
      console.log(e)
    }
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()
    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
