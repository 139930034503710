/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notificationsEmail: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    notificationsSms: boolean;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return NotificationSettingsFromJSONTyped(json, false);
}

export function NotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'notificationsEmail': json['notificationsEmail'],
        'notificationsSms': json['notificationsSms'],
    };
}

export function NotificationSettingsToJSON(value?: NotificationSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'notificationsEmail': value.notificationsEmail,
        'notificationsSms': value.notificationsSms,
    };
}


