/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillDetailsAndBillBlocksModel
 */
export interface BillDetailsAndBillBlocksModel {
    /**
     * 
     * @type {number}
     * @memberof BillDetailsAndBillBlocksModel
     */
    billId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillDetailsAndBillBlocksModel
     */
    billBlockIds: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof BillDetailsAndBillBlocksModel
     */
    invoiceDate: Date;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsAndBillBlocksModel
     */
    vendor: string;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsAndBillBlocksModel
     */
    customerName: string;
    /**
     * 
     * @type {number}
     * @memberof BillDetailsAndBillBlocksModel
     */
    amountDue: number;
    /**
     * 
     * @type {string}
     * @memberof BillDetailsAndBillBlocksModel
     */
    previousWorkflowState: BillDetailsAndBillBlocksModelPreviousWorkflowStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum BillDetailsAndBillBlocksModelPreviousWorkflowStateEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}

export function BillDetailsAndBillBlocksModelFromJSON(json: any): BillDetailsAndBillBlocksModel {
    return BillDetailsAndBillBlocksModelFromJSONTyped(json, false);
}

export function BillDetailsAndBillBlocksModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillDetailsAndBillBlocksModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'billBlockIds': json['billBlockIds'],
        'invoiceDate': (new Date(json['invoiceDate'])),
        'vendor': json['vendor'],
        'customerName': json['customerName'],
        'amountDue': json['amountDue'],
        'previousWorkflowState': json['previousWorkflowState'],
    };
}

export function BillDetailsAndBillBlocksModelToJSON(value?: BillDetailsAndBillBlocksModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'billBlockIds': value.billBlockIds,
        'invoiceDate': (value.invoiceDate.toISOString()),
        'vendor': value.vendor,
        'customerName': value.customerName,
        'amountDue': value.amountDue,
        'previousWorkflowState': value.previousWorkflowState,
    };
}


