/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrefilledDate
 */
export interface PrefilledDate {
    /**
     * 
     * @type {Date}
     * @memberof PrefilledDate
     */
    endDate: Date;
}

export function PrefilledDateFromJSON(json: any): PrefilledDate {
    return PrefilledDateFromJSONTyped(json, false);
}

export function PrefilledDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrefilledDate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'endDate': (new Date(json['endDate'])),
    };
}

export function PrefilledDateToJSON(value?: PrefilledDate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'endDate': (value.endDate.toISOString()),
    };
}


