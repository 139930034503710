/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocationMonthlyValueDto,
    LocationMonthlyValueDtoFromJSON,
    LocationMonthlyValueDtoFromJSONTyped,
    LocationMonthlyValueDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LocationMonthlyAttributesValuesDto
 */
export interface LocationMonthlyAttributesValuesDto {
    /**
     * 
     * @type {Array<LocationMonthlyValueDto>}
     * @memberof LocationMonthlyAttributesValuesDto
     */
    attributeValues: Array<LocationMonthlyValueDto>;
    /**
     * 
     * @type {Date}
     * @memberof LocationMonthlyAttributesValuesDto
     */
    dateMonth: Date;
}

export function LocationMonthlyAttributesValuesDtoFromJSON(json: any): LocationMonthlyAttributesValuesDto {
    return LocationMonthlyAttributesValuesDtoFromJSONTyped(json, false);
}

export function LocationMonthlyAttributesValuesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationMonthlyAttributesValuesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeValues': ((json['attributeValues'] as Array<any>).map(LocationMonthlyValueDtoFromJSON)),
        'dateMonth': (new Date(json['dateMonth'])),
    };
}

export function LocationMonthlyAttributesValuesDtoToJSON(value?: LocationMonthlyAttributesValuesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeValues': ((value.attributeValues as Array<any>).map(LocationMonthlyValueDtoToJSON)),
        'dateMonth': (value.dateMonth.toISOString()),
    };
}


