/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AffectedBillsAndBillBlocksModel,
    AffectedBillsAndBillBlocksModelFromJSON,
    AffectedBillsAndBillBlocksModelToJSON,
    BillDetailsAndBillBlocksModel,
    BillDetailsAndBillBlocksModelFromJSON,
    BillDetailsAndBillBlocksModelToJSON,
    BulkBillDetailsWorkflowStateModel,
    BulkBillDetailsWorkflowStateModelFromJSON,
    BulkBillDetailsWorkflowStateModelToJSON,
    PrefilledDate,
    PrefilledDateFromJSON,
    PrefilledDateToJSON,
    VirtualAccountBulkUpdateDto,
    VirtualAccountBulkUpdateDtoFromJSON,
    VirtualAccountBulkUpdateDtoToJSON,
} from '../models';

export interface CustomerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRequest {
    customerId: number;
    virtualAccountId: number;
    virtualAccountBulkUpdateDto: VirtualAccountBulkUpdateDto;
}

export interface CustomerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocksRequest {
    customerId: number;
    virtualAccountId: number;
}

export interface CustomerVirtualAccountsBillsControllerGetPrefilledDateRequest {
    virtualAccountId: number;
}

export interface CustomerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRequest {
    customerId: number;
    virtualAccountId: number;
}

/**
 * 
 */
export class CustomerVirtualAccountsApi extends runtime.BaseAPI {

    /**
     * Updates a virtual account (bulk bills edit)
     */
    async customerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRaw(requestParameters: CustomerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRequest): Promise<runtime.ApiResponse<BulkBillDetailsWorkflowStateModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountsBillsControllerBulkUpdateVirtualAccount.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountsBillsControllerBulkUpdateVirtualAccount.');
        }

        if (requestParameters.virtualAccountBulkUpdateDto === null || requestParameters.virtualAccountBulkUpdateDto === undefined) {
            throw new runtime.RequiredError('virtualAccountBulkUpdateDto','Required parameter requestParameters.virtualAccountBulkUpdateDto was null or undefined when calling customerVirtualAccountsBillsControllerBulkUpdateVirtualAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-accounts/{virtualAccountId}/bulk-update`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VirtualAccountBulkUpdateDtoToJSON(requestParameters.virtualAccountBulkUpdateDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkBillDetailsWorkflowStateModelFromJSON(jsonValue));
    }

    /**
     * Updates a virtual account (bulk bills edit)
     */
    async customerVirtualAccountsBillsControllerBulkUpdateVirtualAccount(requestParameters: CustomerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRequest): Promise<BulkBillDetailsWorkflowStateModel> {
        const response = await this.customerVirtualAccountsBillsControllerBulkUpdateVirtualAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of bills and bill blocks affected by a virtual account edit
     */
    async customerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocksRaw(requestParameters: CustomerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocksRequest): Promise<runtime.ApiResponse<Array<BillDetailsAndBillBlocksModel>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocks.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-accounts/{virtualAccountId}/get-affected-bills-blocks`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BillDetailsAndBillBlocksModelFromJSON));
    }

    /**
     * Get the list of bills and bill blocks affected by a virtual account edit
     */
    async customerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocks(requestParameters: CustomerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocksRequest): Promise<Array<BillDetailsAndBillBlocksModel>> {
        const response = await this.customerVirtualAccountsBillsControllerGetAffectedBillsAndBillBlocksRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the end date of the last service period of a virtual account
     */
    async customerVirtualAccountsBillsControllerGetPrefilledDateRaw(requestParameters: CustomerVirtualAccountsBillsControllerGetPrefilledDateRequest): Promise<runtime.ApiResponse<PrefilledDate>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountsBillsControllerGetPrefilledDate.');
        }

        const queryParameters: any = {};

        if (requestParameters.virtualAccountId !== undefined) {
            queryParameters['virtualAccountId'] = requestParameters.virtualAccountId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-accounts/{virtualAccountId}/get-prefilled-date`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PrefilledDateFromJSON(jsonValue));
    }

    /**
     * Get the end date of the last service period of a virtual account
     */
    async customerVirtualAccountsBillsControllerGetPrefilledDate(requestParameters: CustomerVirtualAccountsBillsControllerGetPrefilledDateRequest): Promise<PrefilledDate> {
        const response = await this.customerVirtualAccountsBillsControllerGetPrefilledDateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the total number of bills and bill blocks affected by a virtual account edit
     */
    async customerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRaw(requestParameters: CustomerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRequest): Promise<runtime.ApiResponse<AffectedBillsAndBillBlocksModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocks.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-accounts/{virtualAccountId}/get-total-affected-bills-blocks`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AffectedBillsAndBillBlocksModelFromJSON(jsonValue));
    }

    /**
     * Get the total number of bills and bill blocks affected by a virtual account edit
     */
    async customerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocks(requestParameters: CustomerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRequest): Promise<AffectedBillsAndBillBlocksModel> {
        const response = await this.customerVirtualAccountsBillsControllerGetTotalAffectedBillsAndBillBlocksRaw(requestParameters);
        return await response.value();
    }

}
