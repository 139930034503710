/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillChainResponseDto,
    BillChainResponseDtoFromJSON,
    BillChainResponseDtoToJSON,
    ClientAccountBillChainsResponseDto,
    ClientAccountBillChainsResponseDtoFromJSON,
    ClientAccountBillChainsResponseDtoToJSON,
} from '../models';

export interface CustomerBillChainsControllerGetClientAccountBillChainsRequest {
    customerId: number;
    clientAccountId: string;
    billId: number;
}

export interface CustomerBillChainsControllerGetCustomerBillChainRequest {
    customerId: number;
    billChainId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerBillChainsControllerGetCustomerBillChainExcludeEnum>;
    startDate?: Date;
    endDate?: Date;
}

/**
 * 
 */
export class CustomerBillChainsApi extends runtime.BaseAPI {

    /**
     * Returns client account\'s bill chains
     */
    async customerBillChainsControllerGetClientAccountBillChainsRaw(requestParameters: CustomerBillChainsControllerGetClientAccountBillChainsRequest): Promise<runtime.ApiResponse<ClientAccountBillChainsResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillChainsControllerGetClientAccountBillChains.');
        }

        if (requestParameters.clientAccountId === null || requestParameters.clientAccountId === undefined) {
            throw new runtime.RequiredError('clientAccountId','Required parameter requestParameters.clientAccountId was null or undefined when calling customerBillChainsControllerGetClientAccountBillChains.');
        }

        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling customerBillChainsControllerGetClientAccountBillChains.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bill-chains/client-account/{clientAccountId}/bill-id/{billId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"clientAccountId"}}`, encodeURIComponent(String(requestParameters.clientAccountId))).replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientAccountBillChainsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns client account\'s bill chains
     */
    async customerBillChainsControllerGetClientAccountBillChains(requestParameters: CustomerBillChainsControllerGetClientAccountBillChainsRequest): Promise<ClientAccountBillChainsResponseDto> {
        const response = await this.customerBillChainsControllerGetClientAccountBillChainsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns customer\'s bill chain with a given ID
     */
    async customerBillChainsControllerGetCustomerBillChainRaw(requestParameters: CustomerBillChainsControllerGetCustomerBillChainRequest): Promise<runtime.ApiResponse<BillChainResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerBillChainsControllerGetCustomerBillChain.');
        }

        if (requestParameters.billChainId === null || requestParameters.billChainId === undefined) {
            throw new runtime.RequiredError('billChainId','Required parameter requestParameters.billChainId was null or undefined when calling customerBillChainsControllerGetCustomerBillChain.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/bill-chains/{billChainId}/items`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"billChainId"}}`, encodeURIComponent(String(requestParameters.billChainId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillChainResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns customer\'s bill chain with a given ID
     */
    async customerBillChainsControllerGetCustomerBillChain(requestParameters: CustomerBillChainsControllerGetCustomerBillChainRequest): Promise<BillChainResponseDto> {
        const response = await this.customerBillChainsControllerGetCustomerBillChainRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerBillChainsControllerGetCustomerBillChainExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
