import { RouteRecordRaw } from 'vue-router'
import UsersTable from '../views/view.users-table.vue'
import UserInfo from '../views/view.users-info.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/users',
    name: 'users',
    component: UsersTable,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Users',
      },
    },
  },
  {
    path: '/users/:userId',
    name: 'user.info',
    component: UserInfo,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'User Information for',
      },
    },
  },
]

export default routes
