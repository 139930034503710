/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DownloadPaymentFileResponseDto,
    DownloadPaymentFileResponseDtoFromJSON,
    DownloadPaymentFileResponseDtoToJSON,
    EmailDeliveryListDto,
    EmailDeliveryListDtoFromJSON,
    EmailDeliveryListDtoToJSON,
    GeneratePaymentFileConfigDto,
    GeneratePaymentFileConfigDtoFromJSON,
    GeneratePaymentFileConfigDtoToJSON,
    PaymentDatesDTO,
    PaymentDatesDTOFromJSON,
    PaymentDatesDTOToJSON,
    PaymentDetail,
    PaymentDetailFromJSON,
    PaymentDetailToJSON,
    PaymentEmailDelivery,
    PaymentEmailDeliveryFromJSON,
    PaymentEmailDeliveryToJSON,
    PaymentEmailDeliveryDto,
    PaymentEmailDeliveryDtoFromJSON,
    PaymentEmailDeliveryDtoToJSON,
    PaymentEntry,
    PaymentEntryFromJSON,
    PaymentEntryToJSON,
    PaymentErrorsEmailDelivery,
    PaymentErrorsEmailDeliveryFromJSON,
    PaymentErrorsEmailDeliveryToJSON,
    PaymentErrorsEmailDeliveryDto,
    PaymentErrorsEmailDeliveryDtoFromJSON,
    PaymentErrorsEmailDeliveryDtoToJSON,
    PaymentErrorsEmailDeliveryListDto,
    PaymentErrorsEmailDeliveryListDtoFromJSON,
    PaymentErrorsEmailDeliveryListDtoToJSON,
    PaymentEventsListDto,
    PaymentEventsListDtoFromJSON,
    PaymentEventsListDtoToJSON,
    SFTPDeliveryDto,
    SFTPDeliveryDtoFromJSON,
    SFTPDeliveryDtoToJSON,
    SFTPDeliveryDtoResponse,
    SFTPDeliveryDtoResponseFromJSON,
    SFTPDeliveryDtoResponseToJSON,
    SftpDeliveryListDto,
    SftpDeliveryListDtoFromJSON,
    SftpDeliveryListDtoToJSON,
} from '../models';

export interface PaymentsControllerCallGenerateRequest {
    customerId: number;
    markedForPaymentDate: Date;
    generatePaymentFileConfigDto?: GeneratePaymentFileConfigDto;
}

export interface PaymentsControllerDeleteSFTPDeliveryByIdRequest {
    customerId: number;
    sftpDeliveryId: number;
}

export interface PaymentsControllerDownloadPaymentFileRequest {
    customerId: number;
    paymentFileDate: Date;
    fileType?: PaymentsControllerDownloadPaymentFileFileTypeEnum;
}

export interface PaymentsControllerGetEventsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<PaymentsControllerGetEventsExcludeEnum>;
    customerIds?: Array<number>;
    eventTypes?: Array<PaymentsControllerGetEventsEventTypesEnum>;
    startDate?: Date;
    endDate?: Date;
    createdAtStart?: Date;
    createdAtEnd?: Date;
    orderBy?: Array<string>;
}

export interface PaymentsControllerGetPaymentDatesRequest {
    customerId: number;
}

export interface PaymentsControllerGetPaymentForBillIdRequest {
    billId: number;
    customerId: number;
}

export interface PaymentsControllerGetPaymentListRequest {
    customerId: number;
    startDate: Date;
    period: number;
    direction: PaymentsControllerGetPaymentListDirectionEnum;
    batchStatus?: PaymentsControllerGetPaymentListBatchStatusEnum;
}

export interface PaymentsControllerGetPaymentsDetailsRequest {
    customerId: number;
    paymentDate: string;
    sortBy?: string;
}

export interface PaymentsControllerGetSFTPDeliveriesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<PaymentsControllerGetSFTPDeliveriesExcludeEnum>;
}

export interface PaymentsControllerSaveSFTPDeliveryRequest {
    customerId: number;
    sFTPDeliveryDto: SFTPDeliveryDto;
}

export interface PaymentsControllerUpdateSFTPDeliveryRequest {
    customerId: number;
    sftpDeliveryId: number;
    sFTPDeliveryDto: SFTPDeliveryDto;
}

export interface PaymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRequest {
    customerId: number;
    paymentEmailDeliveryDto: PaymentEmailDeliveryDto;
}

export interface PaymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRequest {
    customerId: number;
    emailDeliveryId: number;
}

export interface PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesExcludeEnum>;
}

export interface PaymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRequest {
    customerId: number;
    emailDeliveryId: number;
    paymentEmailDeliveryDto: PaymentEmailDeliveryDto;
}

export interface PaymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRequest {
    customerId: number;
    paymentErrorsEmailDeliveryDto: PaymentErrorsEmailDeliveryDto;
}

export interface PaymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRequest {
    customerId: number;
    paymentErrorsEmailDeliveryId: number;
}

export interface PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesExcludeEnum>;
}

export interface PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryByIdRequest {
    customerId: number;
    paymentErrorsEmailDeliveryId: number;
}

export interface PaymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRequest {
    customerId: number;
    paymentErrorsEmailDeliveryId: number;
    paymentErrorsEmailDeliveryDto: PaymentErrorsEmailDeliveryDto;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Trigger manual payment files generation for a given customer and a date.
     */
    async paymentsControllerCallGenerateRaw(requestParameters: PaymentsControllerCallGenerateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerCallGenerate.');
        }

        if (requestParameters.markedForPaymentDate === null || requestParameters.markedForPaymentDate === undefined) {
            throw new runtime.RequiredError('markedForPaymentDate','Required parameter requestParameters.markedForPaymentDate was null or undefined when calling paymentsControllerCallGenerate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/generate/{customerId}/{markedForPaymentDate}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"markedForPaymentDate"}}`, encodeURIComponent(String(requestParameters.markedForPaymentDate))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GeneratePaymentFileConfigDtoToJSON(requestParameters.generatePaymentFileConfigDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Trigger manual payment files generation for a given customer and a date.
     */
    async paymentsControllerCallGenerate(requestParameters: PaymentsControllerCallGenerateRequest): Promise<void> {
        await this.paymentsControllerCallGenerateRaw(requestParameters);
    }

    /**
     * Soft delete a SFTP delivery by id.
     */
    async paymentsControllerDeleteSFTPDeliveryByIdRaw(requestParameters: PaymentsControllerDeleteSFTPDeliveryByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerDeleteSFTPDeliveryById.');
        }

        if (requestParameters.sftpDeliveryId === null || requestParameters.sftpDeliveryId === undefined) {
            throw new runtime.RequiredError('sftpDeliveryId','Required parameter requestParameters.sftpDeliveryId was null or undefined when calling paymentsControllerDeleteSFTPDeliveryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/{customerId}/sftp-deliveries/{sftpDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"sftpDeliveryId"}}`, encodeURIComponent(String(requestParameters.sftpDeliveryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Soft delete a SFTP delivery by id.
     */
    async paymentsControllerDeleteSFTPDeliveryById(requestParameters: PaymentsControllerDeleteSFTPDeliveryByIdRequest): Promise<void> {
        await this.paymentsControllerDeleteSFTPDeliveryByIdRaw(requestParameters);
    }

    /**
     * Returns a signed Google Bucket URL.
     */
    async paymentsControllerDownloadPaymentFileRaw(requestParameters: PaymentsControllerDownloadPaymentFileRequest): Promise<runtime.ApiResponse<DownloadPaymentFileResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerDownloadPaymentFile.');
        }

        if (requestParameters.paymentFileDate === null || requestParameters.paymentFileDate === undefined) {
            throw new runtime.RequiredError('paymentFileDate','Required parameter requestParameters.paymentFileDate was null or undefined when calling paymentsControllerDownloadPaymentFile.');
        }

        const queryParameters: any = {};

        if (requestParameters.fileType !== undefined) {
            queryParameters['fileType'] = requestParameters.fileType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/download-payment-file/{customerId}/{paymentFileDate}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"paymentFileDate"}}`, encodeURIComponent(String(requestParameters.paymentFileDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DownloadPaymentFileResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns a signed Google Bucket URL.
     */
    async paymentsControllerDownloadPaymentFile(requestParameters: PaymentsControllerDownloadPaymentFileRequest): Promise<DownloadPaymentFileResponseDto> {
        const response = await this.paymentsControllerDownloadPaymentFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the payment errors
     */
    async paymentsControllerGetEventsRaw(requestParameters: PaymentsControllerGetEventsRequest): Promise<runtime.ApiResponse<PaymentEventsListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.customerIds) {
            queryParameters['customerIds[]'] = requestParameters.customerIds;
        }

        if (requestParameters.eventTypes) {
            queryParameters['eventTypes[]'] = requestParameters.eventTypes;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.createdAtStart !== undefined) {
            queryParameters['createdAtStart'] = (requestParameters.createdAtStart as any).toISOString();
        }

        if (requestParameters.createdAtEnd !== undefined) {
            queryParameters['createdAtEnd'] = (requestParameters.createdAtEnd as any).toISOString();
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentEventsListDtoFromJSON(jsonValue));
    }

    /**
     * Returns the payment errors
     */
    async paymentsControllerGetEvents(requestParameters: PaymentsControllerGetEventsRequest): Promise<PaymentEventsListDto> {
        const response = await this.paymentsControllerGetEventsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of YYYY-MM
     */
    async paymentsControllerGetPaymentDatesRaw(requestParameters: PaymentsControllerGetPaymentDatesRequest): Promise<runtime.ApiResponse<PaymentDatesDTO>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerGetPaymentDates.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/payment-dates/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentDatesDTOFromJSON(jsonValue));
    }

    /**
     * Returns a list of YYYY-MM
     */
    async paymentsControllerGetPaymentDates(requestParameters: PaymentsControllerGetPaymentDatesRequest): Promise<PaymentDatesDTO> {
        const response = await this.paymentsControllerGetPaymentDatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a payment file
     */
    async paymentsControllerGetPaymentForBillIdRaw(requestParameters: PaymentsControllerGetPaymentForBillIdRequest): Promise<runtime.ApiResponse<Array<PaymentDetail>>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling paymentsControllerGetPaymentForBillId.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerGetPaymentForBillId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/{customerId}/for-bill/{billId}`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentDetailFromJSON));
    }

    /**
     * Returns a payment file
     */
    async paymentsControllerGetPaymentForBillId(requestParameters: PaymentsControllerGetPaymentForBillIdRequest): Promise<Array<PaymentDetail>> {
        const response = await this.paymentsControllerGetPaymentForBillIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of payments entries
     */
    async paymentsControllerGetPaymentListRaw(requestParameters: PaymentsControllerGetPaymentListRequest): Promise<runtime.ApiResponse<Array<PaymentEntry>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerGetPaymentList.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling paymentsControllerGetPaymentList.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling paymentsControllerGetPaymentList.');
        }

        if (requestParameters.direction === null || requestParameters.direction === undefined) {
            throw new runtime.RequiredError('direction','Required parameter requestParameters.direction was null or undefined when calling paymentsControllerGetPaymentList.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.batchStatus !== undefined) {
            queryParameters['batchStatus'] = requestParameters.batchStatus;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/payments-list/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentEntryFromJSON));
    }

    /**
     * Returns a list of payments entries
     */
    async paymentsControllerGetPaymentList(requestParameters: PaymentsControllerGetPaymentListRequest): Promise<Array<PaymentEntry>> {
        const response = await this.paymentsControllerGetPaymentListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of payment details
     */
    async paymentsControllerGetPaymentsDetailsRaw(requestParameters: PaymentsControllerGetPaymentsDetailsRequest): Promise<runtime.ApiResponse<Array<PaymentDetail>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerGetPaymentsDetails.');
        }

        if (requestParameters.paymentDate === null || requestParameters.paymentDate === undefined) {
            throw new runtime.RequiredError('paymentDate','Required parameter requestParameters.paymentDate was null or undefined when calling paymentsControllerGetPaymentsDetails.');
        }

        const queryParameters: any = {};

        if (requestParameters.sortBy !== undefined) {
            queryParameters['sort_by'] = requestParameters.sortBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/payments-details/{customerId}/{paymentDate}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"paymentDate"}}`, encodeURIComponent(String(requestParameters.paymentDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PaymentDetailFromJSON));
    }

    /**
     * Returns a list of payment details
     */
    async paymentsControllerGetPaymentsDetails(requestParameters: PaymentsControllerGetPaymentsDetailsRequest): Promise<Array<PaymentDetail>> {
        const response = await this.paymentsControllerGetPaymentsDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of SFTP deliveries.
     */
    async paymentsControllerGetSFTPDeliveriesRaw(requestParameters: PaymentsControllerGetSFTPDeliveriesRequest): Promise<runtime.ApiResponse<SftpDeliveryListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerGetSFTPDeliveries.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/{customerId}/sftp-deliveries`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SftpDeliveryListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of SFTP deliveries.
     */
    async paymentsControllerGetSFTPDeliveries(requestParameters: PaymentsControllerGetSFTPDeliveriesRequest): Promise<SftpDeliveryListDto> {
        const response = await this.paymentsControllerGetSFTPDeliveriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a SFTP delivery.
     */
    async paymentsControllerSaveSFTPDeliveryRaw(requestParameters: PaymentsControllerSaveSFTPDeliveryRequest): Promise<runtime.ApiResponse<SFTPDeliveryDtoResponse>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerSaveSFTPDelivery.');
        }

        if (requestParameters.sFTPDeliveryDto === null || requestParameters.sFTPDeliveryDto === undefined) {
            throw new runtime.RequiredError('sFTPDeliveryDto','Required parameter requestParameters.sFTPDeliveryDto was null or undefined when calling paymentsControllerSaveSFTPDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/{customerId}/sftp-deliveries`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SFTPDeliveryDtoToJSON(requestParameters.sFTPDeliveryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SFTPDeliveryDtoResponseFromJSON(jsonValue));
    }

    /**
     * Creates a SFTP delivery.
     */
    async paymentsControllerSaveSFTPDelivery(requestParameters: PaymentsControllerSaveSFTPDeliveryRequest): Promise<SFTPDeliveryDtoResponse> {
        const response = await this.paymentsControllerSaveSFTPDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates SFTP delivery.
     */
    async paymentsControllerUpdateSFTPDeliveryRaw(requestParameters: PaymentsControllerUpdateSFTPDeliveryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsControllerUpdateSFTPDelivery.');
        }

        if (requestParameters.sftpDeliveryId === null || requestParameters.sftpDeliveryId === undefined) {
            throw new runtime.RequiredError('sftpDeliveryId','Required parameter requestParameters.sftpDeliveryId was null or undefined when calling paymentsControllerUpdateSFTPDelivery.');
        }

        if (requestParameters.sFTPDeliveryDto === null || requestParameters.sFTPDeliveryDto === undefined) {
            throw new runtime.RequiredError('sFTPDeliveryDto','Required parameter requestParameters.sFTPDeliveryDto was null or undefined when calling paymentsControllerUpdateSFTPDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/payments/{customerId}/sftp-deliveries/{sftpDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"sftpDeliveryId"}}`, encodeURIComponent(String(requestParameters.sftpDeliveryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SFTPDeliveryDtoToJSON(requestParameters.sFTPDeliveryDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates SFTP delivery.
     */
    async paymentsControllerUpdateSFTPDelivery(requestParameters: PaymentsControllerUpdateSFTPDeliveryRequest): Promise<void> {
        await this.paymentsControllerUpdateSFTPDeliveryRaw(requestParameters);
    }

    /**
     * Creates a new payment email delivery option for a given customer
     */
    async paymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRaw(requestParameters: PaymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRequest): Promise<runtime.ApiResponse<PaymentEmailDelivery>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsEmailDeliveriesControllerCreatePaymentEmailDelivery.');
        }

        if (requestParameters.paymentEmailDeliveryDto === null || requestParameters.paymentEmailDeliveryDto === undefined) {
            throw new runtime.RequiredError('paymentEmailDeliveryDto','Required parameter requestParameters.paymentEmailDeliveryDto was null or undefined when calling paymentsEmailDeliveriesControllerCreatePaymentEmailDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/email-deliveries`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentEmailDeliveryDtoToJSON(requestParameters.paymentEmailDeliveryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentEmailDeliveryFromJSON(jsonValue));
    }

    /**
     * Creates a new payment email delivery option for a given customer
     */
    async paymentsEmailDeliveriesControllerCreatePaymentEmailDelivery(requestParameters: PaymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRequest): Promise<PaymentEmailDelivery> {
        const response = await this.paymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     * (Soft) Deletes an existing payment email delivery option for a given customer
     */
    async paymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRaw(requestParameters: PaymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsEmailDeliveriesControllerDeletePaymentEmailDelivery.');
        }

        if (requestParameters.emailDeliveryId === null || requestParameters.emailDeliveryId === undefined) {
            throw new runtime.RequiredError('emailDeliveryId','Required parameter requestParameters.emailDeliveryId was null or undefined when calling paymentsEmailDeliveriesControllerDeletePaymentEmailDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/email-deliveries/{emailDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"emailDeliveryId"}}`, encodeURIComponent(String(requestParameters.emailDeliveryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * (Soft) Deletes an existing payment email delivery option for a given customer
     */
    async paymentsEmailDeliveriesControllerDeletePaymentEmailDelivery(requestParameters: PaymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRequest): Promise<void> {
        await this.paymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRaw(requestParameters);
    }

    /**
     * Get the list of payment email delivery options for a given customer
     */
    async paymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRaw(requestParameters: PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest): Promise<runtime.ApiResponse<EmailDeliveryListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsEmailDeliveriesControllerGetPaymentEmailDeliveries.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/email-deliveries`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDeliveryListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of payment email delivery options for a given customer
     */
    async paymentsEmailDeliveriesControllerGetPaymentEmailDeliveries(requestParameters: PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest): Promise<EmailDeliveryListDto> {
        const response = await this.paymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an existing payment email delivery option for a given customer
     */
    async paymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRaw(requestParameters: PaymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRequest): Promise<runtime.ApiResponse<PaymentEmailDelivery>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsEmailDeliveriesControllerUpdatePaymentEmailDelivery.');
        }

        if (requestParameters.emailDeliveryId === null || requestParameters.emailDeliveryId === undefined) {
            throw new runtime.RequiredError('emailDeliveryId','Required parameter requestParameters.emailDeliveryId was null or undefined when calling paymentsEmailDeliveriesControllerUpdatePaymentEmailDelivery.');
        }

        if (requestParameters.paymentEmailDeliveryDto === null || requestParameters.paymentEmailDeliveryDto === undefined) {
            throw new runtime.RequiredError('paymentEmailDeliveryDto','Required parameter requestParameters.paymentEmailDeliveryDto was null or undefined when calling paymentsEmailDeliveriesControllerUpdatePaymentEmailDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/email-deliveries/{emailDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"emailDeliveryId"}}`, encodeURIComponent(String(requestParameters.emailDeliveryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentEmailDeliveryDtoToJSON(requestParameters.paymentEmailDeliveryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentEmailDeliveryFromJSON(jsonValue));
    }

    /**
     * Updates an existing payment email delivery option for a given customer
     */
    async paymentsEmailDeliveriesControllerUpdatePaymentEmailDelivery(requestParameters: PaymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRequest): Promise<PaymentEmailDelivery> {
        const response = await this.paymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a new payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRaw(requestParameters: PaymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRequest): Promise<runtime.ApiResponse<PaymentEmailDelivery>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDelivery.');
        }

        if (requestParameters.paymentErrorsEmailDeliveryDto === null || requestParameters.paymentErrorsEmailDeliveryDto === undefined) {
            throw new runtime.RequiredError('paymentErrorsEmailDeliveryDto','Required parameter requestParameters.paymentErrorsEmailDeliveryDto was null or undefined when calling paymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/error-deliveries`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentErrorsEmailDeliveryDtoToJSON(requestParameters.paymentErrorsEmailDeliveryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentEmailDeliveryFromJSON(jsonValue));
    }

    /**
     * Creates a new payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDelivery(requestParameters: PaymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRequest): Promise<PaymentEmailDelivery> {
        const response = await this.paymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an existing payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRaw(requestParameters: PaymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDelivery.');
        }

        if (requestParameters.paymentErrorsEmailDeliveryId === null || requestParameters.paymentErrorsEmailDeliveryId === undefined) {
            throw new runtime.RequiredError('paymentErrorsEmailDeliveryId','Required parameter requestParameters.paymentErrorsEmailDeliveryId was null or undefined when calling paymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/error-deliveries/{paymentErrorsEmailDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"paymentErrorsEmailDeliveryId"}}`, encodeURIComponent(String(requestParameters.paymentErrorsEmailDeliveryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDelivery(requestParameters: PaymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRequest): Promise<void> {
        await this.paymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRaw(requestParameters);
    }

    /**
     * Get the list of payment errors email delivery options for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRaw(requestParameters: PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest): Promise<runtime.ApiResponse<PaymentErrorsEmailDeliveryListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveries.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/error-deliveries`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentErrorsEmailDeliveryListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of payment errors email delivery options for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveries(requestParameters: PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest): Promise<PaymentErrorsEmailDeliveryListDto> {
        const response = await this.paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a given payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryByIdRaw(requestParameters: PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryByIdRequest): Promise<runtime.ApiResponse<PaymentErrorsEmailDelivery>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryById.');
        }

        if (requestParameters.paymentErrorsEmailDeliveryId === null || requestParameters.paymentErrorsEmailDeliveryId === undefined) {
            throw new runtime.RequiredError('paymentErrorsEmailDeliveryId','Required parameter requestParameters.paymentErrorsEmailDeliveryId was null or undefined when calling paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/error-deliveries/{paymentErrorsEmailDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"paymentErrorsEmailDeliveryId"}}`, encodeURIComponent(String(requestParameters.paymentErrorsEmailDeliveryId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentErrorsEmailDeliveryFromJSON(jsonValue));
    }

    /**
     * Get a given payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryById(requestParameters: PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryByIdRequest): Promise<PaymentErrorsEmailDelivery> {
        const response = await this.paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveryByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an existing payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRaw(requestParameters: PaymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRequest): Promise<runtime.ApiResponse<PaymentErrorsEmailDelivery>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDelivery.');
        }

        if (requestParameters.paymentErrorsEmailDeliveryId === null || requestParameters.paymentErrorsEmailDeliveryId === undefined) {
            throw new runtime.RequiredError('paymentErrorsEmailDeliveryId','Required parameter requestParameters.paymentErrorsEmailDeliveryId was null or undefined when calling paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDelivery.');
        }

        if (requestParameters.paymentErrorsEmailDeliveryDto === null || requestParameters.paymentErrorsEmailDeliveryDto === undefined) {
            throw new runtime.RequiredError('paymentErrorsEmailDeliveryDto','Required parameter requestParameters.paymentErrorsEmailDeliveryDto was null or undefined when calling paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDelivery.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/settings/payments/error-deliveries/{paymentErrorsEmailDeliveryId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"paymentErrorsEmailDeliveryId"}}`, encodeURIComponent(String(requestParameters.paymentErrorsEmailDeliveryId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PaymentErrorsEmailDeliveryDtoToJSON(requestParameters.paymentErrorsEmailDeliveryDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaymentErrorsEmailDeliveryFromJSON(jsonValue));
    }

    /**
     * Updates an existing payment errors email delivery option for a given customer
     */
    async paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDelivery(requestParameters: PaymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRequest): Promise<PaymentErrorsEmailDelivery> {
        const response = await this.paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum PaymentsControllerDownloadPaymentFileFileTypeEnum {
    Summary = 'summary',
    Wesleyan = 'wesleyan',
    Sonbyrne = 'sonbyrne',
    SheetzPrimary = 'sheetz_primary',
    SheetzCli = 'sheetz_cli',
    SheetzSds = 'sheetz_sds',
    SheetzConstruction = 'sheetz_construction',
    RoyalfarmsAutopay = 'royalfarms_autopay',
    RoyalfarmsManualpay = 'royalfarms_manualpay',
    RoyalfarmsPcbillpay = 'royalfarms_pcbillpay',
    RoyalfarmsConstruction = 'royalfarms_construction',
    Countrymeadows = 'countrymeadows'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsControllerGetEventsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsControllerGetEventsEventTypesEnum {
    Information = 'Information',
    Error = 'Error',
    Warning = 'Warning'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsControllerGetPaymentListDirectionEnum {
    Minus = '-',
    Plus = '+'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsControllerGetPaymentListBatchStatusEnum {
    Temporary = 'TEMPORARY',
    Submitted = 'SUBMITTED',
    AwaitingApprovals = 'AWAITING_APPROVALS',
    ExceedsThreshold = 'EXCEEDS_THRESHOLD',
    HoldForFunding = 'HOLD_FOR_FUNDING',
    FundingFailed = 'FUNDING_FAILED',
    InsufficientFunds = 'INSUFFICIENT_FUNDS',
    InProcess = 'IN_PROCESS',
    Validating = 'VALIDATING',
    Published = 'PUBLISHED',
    Complete = 'COMPLETE',
    Errored = 'ERRORED',
    FailedFileProcessing = 'FAILED_FILE_PROCESSING',
    Cancelled = 'CANCELLED',
    Pending = 'PENDING'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsControllerGetSFTPDeliveriesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
