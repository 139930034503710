/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEnergyStarMeterDto
 */
export interface CreateEnergyStarMeterDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEnergyStarMeterDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEnergyStarMeterDto
     */
    commodity: CreateEnergyStarMeterDtoCommodityEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEnergyStarMeterDto
     */
    firstBillDate: string;
    /**
     * 
     * @type {number}
     * @memberof CreateEnergyStarMeterDto
     */
    locationId: number;
}

/**
* @export
* @enum {string}
*/
export enum CreateEnergyStarMeterDtoCommodityEnum {
    Electric = 'ELECTRIC',
    Naturalgas = 'NATURALGAS',
    Chilledwater = 'CHILLEDWATER',
    Fireprotection = 'FIREPROTECTION',
    Internet = 'INTERNET',
    Telephone = 'TELEPHONE',
    Hotwater = 'HOTWATER',
    Stormdrain = 'STORMDRAIN',
    Stormwater = 'STORMWATER',
    Irrigation = 'IRRIGATION',
    Propane = 'PROPANE',
    Steam = 'STEAM',
    Refuse = 'REFUSE',
    Sewer = 'SEWER',
    Water = 'WATER',
    Lighting = 'LIGHTING',
    Solarpv = 'SOLARPV',
    Oil2 = 'OIL2',
    Oil4 = 'OIL4',
    Oil6 = 'OIL6',
    Nitrogen = 'NITROGEN',
    Oxygen = 'OXYGEN',
    Diesel = 'DIESEL',
    ElectricMwh = 'ELECTRIC_MWH',
    SewerMcf = 'SEWER_MCF',
    WaterMcf = 'WATER_MCF',
    Fuelcell = 'FUELCELL'
}

export function CreateEnergyStarMeterDtoFromJSON(json: any): CreateEnergyStarMeterDto {
    return CreateEnergyStarMeterDtoFromJSONTyped(json, false);
}

export function CreateEnergyStarMeterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEnergyStarMeterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'commodity': json['commodity'],
        'firstBillDate': json['firstBillDate'],
        'locationId': json['locationId'],
    };
}

export function CreateEnergyStarMeterDtoToJSON(value?: CreateEnergyStarMeterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'commodity': value.commodity,
        'firstBillDate': value.firstBillDate,
        'locationId': value.locationId,
    };
}


