/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationAttribute
 */
export interface LocationAttribute {
    /**
     * 
     * @type {string}
     * @memberof LocationAttribute
     */
    attributeFormat: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAttribute
     */
    attributeName: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAttribute
     */
    attributeUom: string;
    /**
     * 
     * @type {string}
     * @memberof LocationAttribute
     */
    attributeValue: string;
    /**
     * 
     * @type {number}
     * @memberof LocationAttribute
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof LocationAttribute
     */
    locationId: number;
}

export function LocationAttributeFromJSON(json: any): LocationAttribute {
    return LocationAttributeFromJSONTyped(json, false);
}

export function LocationAttributeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationAttribute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeFormat': json['attributeFormat'],
        'attributeName': json['attributeName'],
        'attributeUom': json['attributeUom'],
        'attributeValue': json['attributeValue'],
        'customerId': json['customerId'],
        'locationId': json['locationId'],
    };
}

export function LocationAttributeToJSON(value?: LocationAttribute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeFormat': value.attributeFormat,
        'attributeName': value.attributeName,
        'attributeUom': value.attributeUom,
        'attributeValue': value.attributeValue,
        'customerId': value.customerId,
        'locationId': value.locationId,
    };
}


