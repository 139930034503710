/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssignLocationDto
 */
export interface AssignLocationDto {
    /**
     * 
     * @type {number}
     * @memberof AssignLocationDto
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof AssignLocationDto
     */
    parentId: number;
    /**
     * 
     * @type {number}
     * @memberof AssignLocationDto
     */
    orderValue?: number;
}

export function AssignLocationDtoFromJSON(json: any): AssignLocationDto {
    return AssignLocationDtoFromJSONTyped(json, false);
}

export function AssignLocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignLocationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'parentId': json['parentId'],
        'orderValue': !exists(json, 'orderValue') ? undefined : json['orderValue'],
    };
}

export function AssignLocationDtoToJSON(value?: AssignLocationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'parentId': value.parentId,
        'orderValue': value.orderValue,
    };
}


