/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChatSettings,
    ChatSettingsFromJSON,
    ChatSettingsFromJSONTyped,
    ChatSettingsToJSON,
    NotificationSettings,
    NotificationSettingsFromJSON,
    NotificationSettingsFromJSONTyped,
    NotificationSettingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    lastName: string;
    /**
     * 
     * @type {NotificationSettings}
     * @memberof UserProfile
     */
    notificationsSettings?: NotificationSettings;
    /**
     * 
     * @type {ChatSettings}
     * @memberof UserProfile
     */
    chatSettings?: ChatSettings;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    welcomeSent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    onboardingShown?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProfile
     */
    phoneNumber?: string;
}

export function UserProfileFromJSON(json: any): UserProfile {
    return UserProfileFromJSONTyped(json, false);
}

export function UserProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'notificationsSettings': !exists(json, 'notificationsSettings') ? undefined : NotificationSettingsFromJSON(json['notificationsSettings']),
        'chatSettings': !exists(json, 'chatSettings') ? undefined : ChatSettingsFromJSON(json['chatSettings']),
        'welcomeSent': !exists(json, 'welcomeSent') ? undefined : json['welcomeSent'],
        'onboardingShown': !exists(json, 'onboardingShown') ? undefined : json['onboardingShown'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    };
}

export function UserProfileToJSON(value?: UserProfile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'notificationsSettings': NotificationSettingsToJSON(value.notificationsSettings),
        'chatSettings': ChatSettingsToJSON(value.chatSettings),
        'welcomeSent': value.welcomeSent,
        'onboardingShown': value.onboardingShown,
        'phoneNumber': value.phoneNumber,
    };
}


