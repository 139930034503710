/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorProviderDetails
 */
export interface VendorProviderDetails {
    /**
     * 
     * @type {number}
     * @memberof VendorProviderDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof VendorProviderDetails
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof VendorProviderDetails
     */
    providerId: number;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    remittanceName: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof VendorProviderDetails
     */
    remittanceAddress: AddressModel;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    mainPhone: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    customerServicePhone: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    webAddress: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    emergencyPhone: string;
    /**
     * 
     * @type {string}
     * @memberof VendorProviderDetails
     */
    priorityPhone: string;
}

export function VendorProviderDetailsFromJSON(json: any): VendorProviderDetails {
    return VendorProviderDetailsFromJSONTyped(json, false);
}

export function VendorProviderDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorProviderDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vendorId': json['vendorId'],
        'providerId': json['providerId'],
        'name': json['name'],
        'code': json['code'],
        'remittanceName': json['remittanceName'],
        'remittanceAddress': AddressModelFromJSON(json['remittanceAddress']),
        'mainPhone': json['mainPhone'],
        'customerServicePhone': json['customerServicePhone'],
        'webAddress': json['webAddress'],
        'provider': json['provider'],
        'emergencyPhone': json['emergencyPhone'],
        'priorityPhone': json['priorityPhone'],
    };
}

export function VendorProviderDetailsToJSON(value?: VendorProviderDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vendorId': value.vendorId,
        'providerId': value.providerId,
        'name': value.name,
        'code': value.code,
        'remittanceName': value.remittanceName,
        'remittanceAddress': AddressModelToJSON(value.remittanceAddress),
        'mainPhone': value.mainPhone,
        'customerServicePhone': value.customerServicePhone,
        'webAddress': value.webAddress,
        'provider': value.provider,
        'emergencyPhone': value.emergencyPhone,
        'priorityPhone': value.priorityPhone,
    };
}


