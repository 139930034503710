/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerDashboardModel
 */
export interface CustomerDashboardModel {
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardModel
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDashboardModel
     */
    workflowState: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardModel
     */
    allTime: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardModel
     */
    yesterday: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardModel
     */
    days7Ago: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDashboardModel
     */
    days31Ago: number;
}

export function CustomerDashboardModelFromJSON(json: any): CustomerDashboardModel {
    return CustomerDashboardModelFromJSONTyped(json, false);
}

export function CustomerDashboardModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDashboardModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': json['customerId'],
        'workflowState': json['workflowState'],
        'allTime': json['allTime'],
        'yesterday': json['yesterday'],
        'days7Ago': json['days7Ago'],
        'days31Ago': json['days31Ago'],
    };
}

export function CustomerDashboardModelToJSON(value?: CustomerDashboardModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'workflowState': value.workflowState,
        'allTime': value.allTime,
        'yesterday': value.yesterday,
        'days7Ago': value.days7Ago,
        'days31Ago': value.days31Ago,
    };
}


