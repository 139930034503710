/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatchPayloadModel,
    BatchPayloadModelFromJSON,
    BatchPayloadModelFromJSONTyped,
    BatchPayloadModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface Batch
 */
export interface Batch {
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    deletedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    batchProvider: BatchBatchProviderEnum;
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    batchId: number;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    batchType: BatchBatchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    useSpecifiedFolderId: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    folderId: string;
    /**
     * 
     * @type {string}
     * @memberof Batch
     */
    status: BatchStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    uploadedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Batch
     */
    downloadedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    upFileId: number;
    /**
     * 
     * @type {number}
     * @memberof Batch
     */
    downFileId: number;
    /**
     * 
     * @type {BatchPayloadModel}
     * @memberof Batch
     */
    payload: BatchPayloadModel;
}

/**
* @export
* @enum {string}
*/
export enum BatchBatchProviderEnum {
    Fdg = 'fdg',
    Internal = 'internal'
}/**
* @export
* @enum {string}
*/
export enum BatchBatchTypeEnum {
    Live = 'live',
    Setup = 'setup',
    Maint = 'maint',
    Hist = 'hist',
    Free = 'free'
}/**
* @export
* @enum {string}
*/
export enum BatchStatusEnum {
    Downloaded = 'Downloaded',
    CreationError = 'Creation Error',
    Created = 'Created',
    Processed = 'Processed',
    UploadError = 'Upload Error'
}

export function BatchFromJSON(json: any): Batch {
    return BatchFromJSONTyped(json, false);
}

export function BatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Batch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deletedAt': (new Date(json['deletedAt'])),
        'batchProvider': json['batchProvider'],
        'customerId': json['customerId'],
        'name': json['name'],
        'batchId': json['batchId'],
        'batchType': json['batchType'],
        'useSpecifiedFolderId': json['useSpecifiedFolderId'],
        'folderId': json['folderId'],
        'status': json['status'],
        'uploadedAt': (new Date(json['uploadedAt'])),
        'downloadedAt': (new Date(json['downloadedAt'])),
        'upFileId': json['upFileId'],
        'downFileId': json['downFileId'],
        'payload': BatchPayloadModelFromJSON(json['payload']),
    };
}

export function BatchToJSON(value?: Batch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'deletedAt': (value.deletedAt.toISOString()),
        'batchProvider': value.batchProvider,
        'customerId': value.customerId,
        'name': value.name,
        'batchId': value.batchId,
        'batchType': value.batchType,
        'useSpecifiedFolderId': value.useSpecifiedFolderId,
        'folderId': value.folderId,
        'status': value.status,
        'uploadedAt': (value.uploadedAt.toISOString()),
        'downloadedAt': (value.downloadedAt.toISOString()),
        'upFileId': value.upFileId,
        'downFileId': value.downFileId,
        'payload': BatchPayloadModelToJSON(value.payload),
    };
}


