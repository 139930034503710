/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationBulkResponseDto
 */
export interface LocationBulkResponseDto {
    /**
     * 
     * @type {number}
     * @memberof LocationBulkResponseDto
     */
    locationsCreated: number;
    /**
     * 
     * @type {number}
     * @memberof LocationBulkResponseDto
     */
    locationsGeocoded: number;
}

export function LocationBulkResponseDtoFromJSON(json: any): LocationBulkResponseDto {
    return LocationBulkResponseDtoFromJSONTyped(json, false);
}

export function LocationBulkResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationBulkResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationsCreated': json['locationsCreated'],
        'locationsGeocoded': json['locationsGeocoded'],
    };
}

export function LocationBulkResponseDtoToJSON(value?: LocationBulkResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationsCreated': value.locationsCreated,
        'locationsGeocoded': value.locationsGeocoded,
    };
}


