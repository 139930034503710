/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountsOperatorNotesDto
 */
export interface VirtualAccountsOperatorNotesDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountsOperatorNotesDto
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountsOperatorNotesDto
     */
    priority?: VirtualAccountsOperatorNotesDtoPriorityEnum;
}

/**
* @export
* @enum {string}
*/
export enum VirtualAccountsOperatorNotesDtoPriorityEnum {
    Regular = 'Regular',
    High = 'High'
}

export function VirtualAccountsOperatorNotesDtoFromJSON(json: any): VirtualAccountsOperatorNotesDto {
    return VirtualAccountsOperatorNotesDtoFromJSONTyped(json, false);
}

export function VirtualAccountsOperatorNotesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountsOperatorNotesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': json['note'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
    };
}

export function VirtualAccountsOperatorNotesDtoToJSON(value?: VirtualAccountsOperatorNotesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
        'priority': value.priority,
    };
}


