/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingLineItem
 */
export interface BillingLineItem {
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    block: number;
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    billRecordId: number;
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    billItemId: number;
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    usage: number;
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    charge: number;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    uom: string;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof BillingLineItem
     */
    nativeCurrency: string;
    /**
     * 
     * @type {number}
     * @memberof BillingLineItem
     */
    nativeCharge: number;
}

export function BillingLineItemFromJSON(json: any): BillingLineItem {
    return BillingLineItemFromJSONTyped(json, false);
}

export function BillingLineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingLineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'block': json['block'],
        'id': json['id'],
        'billRecordId': json['billRecordId'],
        'billItemId': json['billItemId'],
        'usage': json['usage'],
        'charge': json['charge'],
        'code': json['code'],
        'type': json['type'],
        'value': json['value'],
        'uom': json['uom'],
        'currency': json['currency'],
        'description': json['description'],
        'nativeCurrency': json['nativeCurrency'],
        'nativeCharge': json['nativeCharge'],
    };
}

export function BillingLineItemToJSON(value?: BillingLineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'block': value.block,
        'id': value.id,
        'billRecordId': value.billRecordId,
        'billItemId': value.billItemId,
        'usage': value.usage,
        'charge': value.charge,
        'code': value.code,
        'type': value.type,
        'value': value.value,
        'uom': value.uom,
        'currency': value.currency,
        'description': value.description,
        'nativeCurrency': value.nativeCurrency,
        'nativeCharge': value.nativeCharge,
    };
}


