import { RouteRecordRaw } from 'vue-router'
import LayoutAuth from '@/Modules/Auth/layouts/layout.auth.vue'
import LoginAuth from '@/Modules/Auth/views/view.index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'auth',
    component: LayoutAuth,
    children: [
      {
        path: '',
        name: 'auth.login',
        component: LoginAuth,
      },
    ],
  },
]

export default routes
