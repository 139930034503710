/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillsSummaryDto
 */
export interface BillsSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof BillsSummaryDto
     */
    billsActive: number;
    /**
     * 
     * @type {number}
     * @memberof BillsSummaryDto
     */
    billsParked: number;
    /**
     * 
     * @type {number}
     * @memberof BillsSummaryDto
     */
    firstBillId: number;
    /**
     * 
     * @type {number}
     * @memberof BillsSummaryDto
     */
    firstCustomerId: number;
    /**
     * 
     * @type {number}
     * @memberof BillsSummaryDto
     */
    billsFrozen: number;
    /**
     * 
     * @type {number}
     * @memberof BillsSummaryDto
     */
    billsNoPayDate: number;
}

export function BillsSummaryDtoFromJSON(json: any): BillsSummaryDto {
    return BillsSummaryDtoFromJSONTyped(json, false);
}

export function BillsSummaryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillsSummaryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billsActive': json['billsActive'],
        'billsParked': json['billsParked'],
        'firstBillId': json['firstBillId'],
        'firstCustomerId': json['firstCustomerId'],
        'billsFrozen': json['billsFrozen'],
        'billsNoPayDate': json['billsNoPayDate'],
    };
}

export function BillsSummaryDtoToJSON(value?: BillsSummaryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billsActive': value.billsActive,
        'billsParked': value.billsParked,
        'firstBillId': value.firstBillId,
        'firstCustomerId': value.firstCustomerId,
        'billsFrozen': value.billsFrozen,
        'billsNoPayDate': value.billsNoPayDate,
    };
}


