/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeatherDaily
 */
export interface WeatherDaily {
    /**
     * 
     * @type {Date}
     * @memberof WeatherDaily
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof WeatherDaily
     */
    locationId: number;
    /**
     * 
     * @type {Date}
     * @memberof WeatherDaily
     */
    date: Date;
    /**
     * 
     * @type {number}
     * @memberof WeatherDaily
     */
    temperatureMax: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherDaily
     */
    temperatureMin: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherDaily
     */
    heating: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherDaily
     */
    cooling: number;
    /**
     * 
     * @type {string}
     * @memberof WeatherDaily
     */
    icon: string;
}

export function WeatherDailyFromJSON(json: any): WeatherDaily {
    return WeatherDailyFromJSONTyped(json, false);
}

export function WeatherDailyFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherDaily {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': (new Date(json['createdAt'])),
        'locationId': json['locationId'],
        'date': (new Date(json['date'])),
        'temperatureMax': json['temperatureMax'],
        'temperatureMin': json['temperatureMin'],
        'heating': json['heating'],
        'cooling': json['cooling'],
        'icon': json['icon'],
    };
}

export function WeatherDailyToJSON(value?: WeatherDaily | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': (value.createdAt.toISOString()),
        'locationId': value.locationId,
        'date': (value.date.toISOString()),
        'temperatureMax': value.temperatureMax,
        'temperatureMin': value.temperatureMin,
        'heating': value.heating,
        'cooling': value.cooling,
        'icon': value.icon,
    };
}


