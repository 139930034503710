/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillMonthlyFeed
 */
export interface BillMonthlyFeed {
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    billId: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    virtualAccountGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    virtualAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    billRecordId: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    blockId: number;
    /**
     * 
     * @type {string}
     * @memberof BillMonthlyFeed
     */
    billType: string;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    daysOfService: number;
    /**
     * 
     * @type {Date}
     * @memberof BillMonthlyFeed
     */
    endDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillMonthlyFeed
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillMonthlyFeed
     */
    statementDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillMonthlyFeed
     */
    timePeriod: Date;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    totalCharges: number;
    /**
     * 
     * @type {number}
     * @memberof BillMonthlyFeed
     */
    totalConsumption: number;
    /**
     * 
     * @type {string}
     * @memberof BillMonthlyFeed
     */
    totalConsumptionUom: BillMonthlyFeedTotalConsumptionUomEnum;
    /**
     * 
     * @type {string}
     * @memberof BillMonthlyFeed
     */
    utilityType: BillMonthlyFeedUtilityTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof BillMonthlyFeed
     */
    vendorCode: string;
}

/**
* @export
* @enum {string}
*/
export enum BillMonthlyFeedTotalConsumptionUomEnum {
    Kw = 'KW',
    Kwh = 'KWH',
    Kwhday = 'KWHDAY',
    Usd = 'USD',
    Cad = 'CAD',
    Kvarh = 'KVARH',
    Ccf = 'CCF',
    Gal = 'GAL',
    Kgal = 'KGAL',
    Lamps = 'LAMPS',
    Therm = 'THERM',
    Cf = 'CF',
    Percent = 'PERCENT',
    Usdday = 'USDDAY',
    Units = 'UNITS',
    Kvar = 'KVAR',
    Usdkwh = 'USDKWH',
    Usdkwhday = 'USDKWHDAY',
    Ton = 'TON',
    Tonhr = 'TONHR',
    Block = 'BLOCK',
    Kva = 'KVA',
    Tonday = 'TONDAY',
    Ccfday = 'CCFDAY',
    Thermday = 'THERMDAY',
    Min = 'MIN',
    Minday = 'MINDAY',
    Calls = 'CALLS',
    Mb = 'MB',
    Mbday = 'MBDAY',
    Cubicm = 'CUBICM',
    Mcf = 'MCF',
    Dkthm = 'DKTHM',
    Sqfeet = 'SQFEET',
    Sqfeetday = 'SQFEETDAY',
    Hgal = 'HGAL',
    Btu = 'BTU',
    Mmbtu = 'MMBTU',
    Galday = 'GALDAY',
    Usdccf = 'USDCCF',
    Usdtherm = 'USDTHERM',
    Usdton = 'USDTON',
    Usdmin = 'USDMIN',
    Usdmb = 'USDMB',
    Usdsqfeet = 'USDSQFEET',
    Usdgal = 'USDGAL',
    Usdmmbtu = 'USDMMBTU',
    Mmbtuday = 'MMBTUDAY',
    Mwh = 'MWH',
    Hdd = 'HDD',
    Cdd = 'CDD',
    Tdd = 'TDD',
    Kwhdd = 'KWHDD',
    _10Cf = '10CF',
    Cgal = 'CGAL',
    Dgal = 'DGAL',
    Dcf = 'DCF',
    Gj = 'GJ',
    _10Gal = '10GAL',
    Mbtu = 'MBTU',
    NA = 'N/A',
    Cubicyard = 'CUBICYARD',
    Eru = 'ERU',
    Edu = 'EDU',
    Lbs = 'LBS'
}/**
* @export
* @enum {string}
*/
export enum BillMonthlyFeedUtilityTypeEnum {
    Electric = 'ELECTRIC',
    Naturalgas = 'NATURALGAS',
    Chilledwater = 'CHILLEDWATER',
    Fireprotection = 'FIREPROTECTION',
    Internet = 'INTERNET',
    Telephone = 'TELEPHONE',
    Hotwater = 'HOTWATER',
    Stormdrain = 'STORMDRAIN',
    Stormwater = 'STORMWATER',
    Irrigation = 'IRRIGATION',
    Propane = 'PROPANE',
    Steam = 'STEAM',
    Refuse = 'REFUSE',
    Sewer = 'SEWER',
    Water = 'WATER',
    Lighting = 'LIGHTING',
    Solarpv = 'SOLARPV',
    Oil2 = 'OIL2',
    Oil4 = 'OIL4',
    Oil6 = 'OIL6',
    Nitrogen = 'NITROGEN',
    Oxygen = 'OXYGEN',
    Diesel = 'DIESEL',
    ElectricMwh = 'ELECTRIC_MWH',
    SewerMcf = 'SEWER_MCF',
    WaterMcf = 'WATER_MCF',
    Fuelcell = 'FUELCELL'
}

export function BillMonthlyFeedFromJSON(json: any): BillMonthlyFeed {
    return BillMonthlyFeedFromJSONTyped(json, false);
}

export function BillMonthlyFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillMonthlyFeed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'customerId': json['customerId'],
        'locationId': json['locationId'],
        'virtualAccountGroupId': json['virtualAccountGroupId'],
        'virtualAccountId': json['virtualAccountId'],
        'billRecordId': json['billRecordId'],
        'blockId': json['blockId'],
        'billType': json['billType'],
        'daysOfService': json['daysOfService'],
        'endDate': (new Date(json['endDate'])),
        'startDate': (new Date(json['startDate'])),
        'statementDate': (new Date(json['statementDate'])),
        'timePeriod': (new Date(json['timePeriod'])),
        'totalCharges': json['totalCharges'],
        'totalConsumption': json['totalConsumption'],
        'totalConsumptionUom': json['totalConsumptionUom'],
        'utilityType': json['utilityType'],
        'vendorCode': json['vendorCode'],
    };
}

export function BillMonthlyFeedToJSON(value?: BillMonthlyFeed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'customerId': value.customerId,
        'locationId': value.locationId,
        'virtualAccountGroupId': value.virtualAccountGroupId,
        'virtualAccountId': value.virtualAccountId,
        'billRecordId': value.billRecordId,
        'blockId': value.blockId,
        'billType': value.billType,
        'daysOfService': value.daysOfService,
        'endDate': (value.endDate.toISOString()),
        'startDate': (value.startDate.toISOString()),
        'statementDate': (value.statementDate.toISOString()),
        'timePeriod': (value.timePeriod.toISOString()),
        'totalCharges': value.totalCharges,
        'totalConsumption': value.totalConsumption,
        'totalConsumptionUom': value.totalConsumptionUom,
        'utilityType': value.utilityType,
        'vendorCode': value.vendorCode,
    };
}


