// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../Assets/icons/arrow-pointing-to-right.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".mt-16{margin-top:16px}.pl-24{padding-left:24px}.text-align-center{text-align:center}.margin-none{margin:0}.icon-arrow-pointing-to-right{background-repeat:no-repeat;background-position:50%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}body,html{width:100%;height:100%;padding:0;margin:0}body{-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#273a58;overflow:hidden}body,button,input,select,textarea{font-family:Open Sans,Helvetica,Arial,sans-serif}", ""]);
// Exports
module.exports = exports;
