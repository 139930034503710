/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccountModel,
    VirtualAccountModelFromJSON,
    VirtualAccountModelFromJSONTyped,
    VirtualAccountModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountsListModel
 */
export interface VirtualAccountsListModel {
    /**
     * 
     * @type {Array<VirtualAccountModel>}
     * @memberof VirtualAccountsListModel
     */
    results: Array<VirtualAccountModel>;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountsListModel
     */
    total: number;
}

export function VirtualAccountsListModelFromJSON(json: any): VirtualAccountsListModel {
    return VirtualAccountsListModelFromJSONTyped(json, false);
}

export function VirtualAccountsListModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountsListModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(VirtualAccountModelFromJSON)),
        'total': json['total'],
    };
}

export function VirtualAccountsListModelToJSON(value?: VirtualAccountsListModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(VirtualAccountModelToJSON)),
        'total': value.total,
    };
}


