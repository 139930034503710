import {
  Bill,
  CustomerBillsApi,
  CustomerBillsControllerGetBillForCustomerByIdRequest,
  CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest,
  CustomerBillsPreviewListDto,
} from '@/SharedModule/Api/index'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomerBillsService extends ApiService {
  private customerBillsApi = new CustomerBillsApi(this.getBaseOptions())

  public getCustomerBillsPreviews(
    requestParameters: CustomerBillsControllerGetBillsPreviewsByCustomerIdRequest,
  ): Promise<CustomerBillsPreviewListDto> {
    return this.customerBillsApi.customerBillsControllerGetBillsPreviewsByCustomerId(
      requestParameters,
    )
  }

  public getCustomerBillByIdPreviews(
    requestParameters: CustomerBillsControllerGetBillForCustomerByIdRequest,
  ): Promise<Partial<Bill>> {
    return this.customerBillsApi.customerBillsControllerGetBillForCustomerById(
      requestParameters,
    )
  }
}
