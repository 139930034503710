/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PowerBIReportModel
 */
export interface PowerBIReportModel {
    /**
     * 
     * @type {number}
     * @memberof PowerBIReportModel
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof PowerBIReportModel
     */
    createdAt: Date;
    /**
     * 
     * @type {number}
     * @memberof PowerBIReportModel
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportModel
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportModel
     */
    reportId: string;
    /**
     * 
     * @type {string}
     * @memberof PowerBIReportModel
     */
    datasetId: string;
    /**
     * 
     * @type {boolean}
     * @memberof PowerBIReportModel
     */
    isDefault: boolean;
}

export function PowerBIReportModelFromJSON(json: any): PowerBIReportModel {
    return PowerBIReportModelFromJSONTyped(json, false);
}

export function PowerBIReportModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerBIReportModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'customerId': json['customerId'],
        'name': json['name'],
        'groupId': json['groupId'],
        'reportId': json['reportId'],
        'datasetId': json['datasetId'],
        'isDefault': json['isDefault'],
    };
}

export function PowerBIReportModelToJSON(value?: PowerBIReportModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'customerId': value.customerId,
        'name': value.name,
        'groupId': value.groupId,
        'reportId': value.reportId,
        'datasetId': value.datasetId,
        'isDefault': value.isDefault,
    };
}


