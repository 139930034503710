/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompletedReportsListDto,
    CompletedReportsListDtoFromJSON,
    CompletedReportsListDtoToJSON,
    ReportStatusListDto,
    ReportStatusListDtoFromJSON,
    ReportStatusListDtoToJSON,
    RunCustomerReportDto,
    RunCustomerReportDtoFromJSON,
    RunCustomerReportDtoToJSON,
} from '../models';

export interface CompletedReportsControllerDeleteReportsRequest {
    customerId: number;
    reportStatusListDto?: ReportStatusListDto;
}

export interface CompletedReportsControllerExecuteNowReportRequest {
    customerId: number;
    runCustomerReportDto: RunCustomerReportDto;
}

export interface CompletedReportsControllerGetReportsRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CompletedReportsControllerGetReportsExcludeEnum>;
    type?: Array<string>;
    featureType?: CompletedReportsControllerGetReportsFeatureTypeEnum;
    format?: Array<string>;
    startDate?: Date;
    endDate?: Date;
    orderBy?: Array<CompletedReportsControllerGetReportsOrderByEnum>;
}

export interface CompletedReportsControllerGetReportsStatusesRequest {
    customerId: number;
    reportIds?: string;
}

export interface CompletedReportsControllerUpdateReportRequest {
    reportId: string;
    customerId: number;
    runCustomerReportDto: RunCustomerReportDto;
}

/**
 * 
 */
export class CompletedReportsApi extends runtime.BaseAPI {

    /**
     */
    async completedReportsControllerDeleteReportsRaw(requestParameters: CompletedReportsControllerDeleteReportsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling completedReportsControllerDeleteReports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/bulk`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ReportStatusListDtoToJSON(requestParameters.reportStatusListDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async completedReportsControllerDeleteReports(requestParameters: CompletedReportsControllerDeleteReportsRequest): Promise<void> {
        await this.completedReportsControllerDeleteReportsRaw(requestParameters);
    }

    /**
     * Create and deliver a new customer report
     */
    async completedReportsControllerExecuteNowReportRaw(requestParameters: CompletedReportsControllerExecuteNowReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling completedReportsControllerExecuteNowReport.');
        }

        if (requestParameters.runCustomerReportDto === null || requestParameters.runCustomerReportDto === undefined) {
            throw new runtime.RequiredError('runCustomerReportDto','Required parameter requestParameters.runCustomerReportDto was null or undefined when calling completedReportsControllerExecuteNowReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/run-now`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RunCustomerReportDtoToJSON(requestParameters.runCustomerReportDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create and deliver a new customer report
     */
    async completedReportsControllerExecuteNowReport(requestParameters: CompletedReportsControllerExecuteNowReportRequest): Promise<void> {
        await this.completedReportsControllerExecuteNowReportRaw(requestParameters);
    }

    /**
     * Get the list of completed reports for a given customer
     */
    async completedReportsControllerGetReportsRaw(requestParameters: CompletedReportsControllerGetReportsRequest): Promise<runtime.ApiResponse<CompletedReportsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling completedReportsControllerGetReports.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.featureType !== undefined) {
            queryParameters['featureType'] = requestParameters.featureType;
        }

        if (requestParameters.format) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/list`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompletedReportsListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of completed reports for a given customer
     */
    async completedReportsControllerGetReports(requestParameters: CompletedReportsControllerGetReportsRequest): Promise<CompletedReportsListDto> {
        const response = await this.completedReportsControllerGetReportsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async completedReportsControllerGetReportsStatusesRaw(requestParameters: CompletedReportsControllerGetReportsStatusesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling completedReportsControllerGetReportsStatuses.');
        }

        const queryParameters: any = {};

        if (requestParameters.reportIds !== undefined) {
            queryParameters['reportIds'] = requestParameters.reportIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/status`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async completedReportsControllerGetReportsStatuses(requestParameters: CompletedReportsControllerGetReportsStatusesRequest): Promise<void> {
        await this.completedReportsControllerGetReportsStatusesRaw(requestParameters);
    }

    /**
     * Update an existing customer report
     */
    async completedReportsControllerUpdateReportRaw(requestParameters: CompletedReportsControllerUpdateReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling completedReportsControllerUpdateReport.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling completedReportsControllerUpdateReport.');
        }

        if (requestParameters.runCustomerReportDto === null || requestParameters.runCustomerReportDto === undefined) {
            throw new runtime.RequiredError('runCustomerReportDto','Required parameter requestParameters.runCustomerReportDto was null or undefined when calling completedReportsControllerUpdateReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RunCustomerReportDtoToJSON(requestParameters.runCustomerReportDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing customer report
     */
    async completedReportsControllerUpdateReport(requestParameters: CompletedReportsControllerUpdateReportRequest): Promise<void> {
        await this.completedReportsControllerUpdateReportRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CompletedReportsControllerGetReportsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CompletedReportsControllerGetReportsFeatureTypeEnum {
    Reports = 'reports',
    Budgeting = 'budgeting'
}
/**
    * @export
    * @enum {string}
    */
export enum CompletedReportsControllerGetReportsOrderByEnum {
    CustomReportNameAsc = 'customReportName.asc',
    CreatedAtAsc = 'createdAt.asc',
    TitleAsc = 'title.asc',
    FormatAsc = 'format.asc',
    StatusAsc = 'status.asc',
    DeliveryAsc = 'delivery.asc',
    SftpConnectionsAsc = 'sftpConnections.asc',
    EmailRecipientsAsc = 'emailRecipients.asc',
    StartDateAsc = 'startDate.asc',
    EndDateAsc = 'endDate.asc',
    UtilityTypeAsc = 'utilityType.asc',
    VendorAsc = 'vendor.asc',
    LocationIdAsc = 'locationId.asc',
    HistoricalDatesAsc = 'historicalDates.asc',
    CustomReportNameDesc = 'customReportName.desc',
    CreatedAtDesc = 'createdAt.desc',
    TitleDesc = 'title.desc',
    FormatDesc = 'format.desc',
    StatusDesc = 'status.desc',
    DeliveryDesc = 'delivery.desc',
    SftpConnectionsDesc = 'sftpConnections.desc',
    EmailRecipientsDesc = 'emailRecipients.desc',
    StartDateDesc = 'startDate.desc',
    EndDateDesc = 'endDate.desc',
    UtilityTypeDesc = 'utilityType.desc',
    VendorDesc = 'vendor.desc',
    LocationIdDesc = 'locationId.desc',
    HistoricalDatesDesc = 'historicalDates.desc'
}
