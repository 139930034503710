// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../Assets/customers/send-email.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../Assets/customers/send-email-disabled.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "[data-v-37884935].view-table .table-info{margin-bottom:0;height:16px}.page-container .view-content[data-v-37884935]{padding:20px 40px}.page-container .page-header[data-v-37884935]{font-size:20px;font-weight:600;margin-bottom:20px}a[data-v-37884935]{color:#4a90e2;cursor:pointer}.btn-send-email[data-v-37884935]{border:none;background:none;cursor:inherit}.btn-send-email[data-v-37884935]:disabled{cursor:not-allowed}.icon-send-email[data-v-37884935]{width:20px;height:14px;display:inline-block;background-repeat:no-repeat;margin:2px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.icon-send-email.disabled[data-v-37884935]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
module.exports = exports;
