import { Ref, ref } from 'vue'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { API_V3_URL } from '@/AppModule/Configs/env'
const loadedRequests: Ref<boolean[]> = ref([])

const registerAxiosRequestListener = (axios: any): void => {
  const originalAxiosCreate = axios.create
  const refreshAuthLogic = (failedRequest: any): any => {
    const refreshToken = localStorage.getItem('refreshToken')
    return axios
      .post(`${API_V3_URL}/api/v3/auth/refresh-token`, {
        refreshToken,
      })
      .then((res: any) => {
        localStorage.setItem('authToken', res.data.token)
        localStorage.setItem('refreshToken', res.data.refreshToken)
        failedRequest.response.config.headers['Authorization'] =
          'Bearer ' + res.data.token
        return Promise.resolve()
      })
      .catch(() => {
        localStorage.removeItem('authToken')
        localStorage.removeItem('refreshToken')
      })
  }

  // eslint-disable-next-line no-global-assign,@typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-global-assign
  axios.create = (...args): any => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const axiosObject: AxiosInstance = originalAxiosCreate(...args)
    createAuthRefreshInterceptor(axiosObject, refreshAuthLogic, {
      statusCodes: [401, 500],
    })
    axiosObject.interceptors.request.use(
      (config: any) => {
        loadedRequests.value.push(true)
        return config
      },
      (error: any) => {
        loadedRequests.value.pop()
        return Promise.reject(error)
      },
    )

    axiosObject.interceptors.response.use(
      (response: any) => {
        loadedRequests.value.pop()
        return response
      },
      (error: any) => {
        loadedRequests.value.pop()
        return Promise.reject(error)
      },
    )

    return axiosObject
  }
}

export { loadedRequests, registerAxiosRequestListener }
