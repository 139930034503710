import { computed, reactive, ref } from 'vue'
import {
  AffectedBillsAndBillBlocksModel,
  BulkBillDetailsWorkflowStateModel,
  VirtualAccount,
  VirtualAccountBulkUpdateDto,
} from '@/SharedModule/Api'
import { VendorsService } from '@/Modules/Vendors/services/service.vendors'
import { DialogStatesVirtualAccount } from '@/Modules/Locations/constants/constants.edit-virtual-acc'
import { CustomerVirtualAccountsService } from '@/Modules/Locations/services/service.customer-virtual-accounts-api'

const customerVirtualAccountsService = new CustomerVirtualAccountsService()

const bulkUpdateFormDefault = {
  vendor: '',
  vendorProviderId: 0,
  vendorPrimaryBillingId: '',
  serviceAccount: '',
  meterId: '',
  vendorType: '',
  vendorCode: '',
  utilityType: '',
  serviceAddress: '',
  serviceZip: '',
  serviceDateMethod: '',
  ratePlan: '',
  comment: '',
}
const vendorsService = new VendorsService()

const totalAffectedBillsAndBillBlocksModelDefault: AffectedBillsAndBillBlocksModel =
  {
    totalBills: 0,
    totalBillBlocks: 0,
  }

export const vendors = ref()

export const openEditVirtualAcc = ref(false)

export const dialogState = ref('')

export const intervalGetBulkBillsWorkflowState: any = ref(null)

export const isOnTabsScreen = computed(
  () => dialogState.value === DialogStatesVirtualAccount.TabsScreen,
)
export const isOnConfirmationScreen = computed(
  () => dialogState.value === DialogStatesVirtualAccount.ConfirmationScreen,
)
export const isOnUpdateTableScreen = computed(
  () => dialogState.value === DialogStatesVirtualAccount.UpdatedBillsScreen,
)
export const isOnCloseScreen = computed(
  () => dialogState.value === DialogStatesVirtualAccount.CloseScreen,
)

export const updatedBillsAndTrackingId: BulkBillDetailsWorkflowStateModel =
  reactive({ bills: [], trackingId: '' })

export const totalAffectedBillsAndBillBlocksModel = reactive(
  totalAffectedBillsAndBillBlocksModelDefault,
)

export const onVendorChange = async (): Promise<void> => {
  const providers =
    (await vendorsService.getProvidersByCode({
      query: bulkUpdateForm.vendor,
    })) || []

  if (!providers.length) {
    throw new Error('Could not update provider code')
  }

  bulkUpdateForm.vendorProviderId = providers[0].providerId
}

export const bulkUpdateForm = reactive({ ...bulkUpdateFormDefault })

export const resetBulkUpdateForm = (): void => {
  Object.assign(bulkUpdateForm, bulkUpdateFormDefault)
}

export const addValuesToBulkUpdateForm = async (
  virtualAccount: VirtualAccount,
  vendorId: number,
): Promise<void> => {
  const vendor = await vendorsService.getVendorById({ vendorId })
  vendors.value = await vendorsService.getListVendors({ limit: 30, offset: 0 })

  const bulkUpdateObj = {
    vendorType: virtualAccount.billType,
    meterId: virtualAccount.meterSerial,
    utilityType: virtualAccount.commodity,
    serviceAccount: virtualAccount.accountCode,
    vendor: vendor.code,
    vendorPrimaryBillingId: virtualAccount.clientAccount,
    vendorProviderId: vendor.vendorProviderDetails[0].providerId,
  }
  Object.assign(bulkUpdateForm, bulkUpdateObj)
}
export const resetTotalAffectedBillsAndBillBlocksModel = (): void => {
  Object.assign(
    totalAffectedBillsAndBillBlocksModel,
    totalAffectedBillsAndBillBlocksModelDefault,
  )
}
export const clearGetBulkBillsInterval = (): void => {
  clearInterval(intervalGetBulkBillsWorkflowState.value)
}
export const handleClose = (): void => {
  dialogState.value = DialogStatesVirtualAccount.CloseScreen
}

export const resetDialogState = (): void => {
  dialogState.value = ''
}

let timeoutQuery: ReturnType<typeof setTimeout>
export const queryForSpecificVendor = async (query: string): Promise<void> => {
  clearTimeout(timeoutQuery)
  if (query !== '') {
    timeoutQuery = setTimeout(async () => {
      const filters = {
        limit: 30,
        offset: 0,
        query,
      }
      vendors.value = await vendorsService.getListVendors(filters)
    }, 500)
  } else {
    vendors.value = await vendorsService.getListVendors({
      limit: 30,
      offset: 0,
    })
  }
}
export const submitVirtualAccountBulkUpdate = async (
  customerId: number,
  virtualAccountId: number,
): Promise<void> => {
  const response =
    await customerVirtualAccountsService.bulkUpdateVirtualAccount({
      customerId,
      virtualAccountId,
      virtualAccountBulkUpdateDto:
        bulkUpdateForm as VirtualAccountBulkUpdateDto,
    })
  Object.assign(updatedBillsAndTrackingId, response)
  dialogState.value = DialogStatesVirtualAccount.UpdatedBillsScreen
}
