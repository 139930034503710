/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarPropertyUseOptionalDefault,
    EnergyStarPropertyUseOptionalDefaultFromJSON,
    EnergyStarPropertyUseOptionalDefaultFromJSONTyped,
    EnergyStarPropertyUseOptionalDefaultToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarPropertyUseAttributes
 */
export interface EnergyStarPropertyUseAttributes {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseAttributes
     */
    attributeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseAttributes
     */
    attributeName: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseAttributes
     */
    attributeValueType: string;
    /**
     * 
     * @type {object}
     * @memberof EnergyStarPropertyUseAttributes
     */
    attributeAllowableValues: object;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseAttributes
     */
    mandatory: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseAttributes
     */
    attributeUomSpecifier: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EnergyStarPropertyUseAttributes
     */
    attributeUomValues: Array<string>;
    /**
     * 
     * @type {EnergyStarPropertyUseOptionalDefault}
     * @memberof EnergyStarPropertyUseAttributes
     */
    optionalDefault: EnergyStarPropertyUseOptionalDefault;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseAttributes
     */
    requiredEsRating: string;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarPropertyUseAttributes
     */
    sortOrder: number;
}

export function EnergyStarPropertyUseAttributesFromJSON(json: any): EnergyStarPropertyUseAttributes {
    return EnergyStarPropertyUseAttributesFromJSONTyped(json, false);
}

export function EnergyStarPropertyUseAttributesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarPropertyUseAttributes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeLabel': json['attributeLabel'],
        'attributeName': json['attributeName'],
        'attributeValueType': json['attributeValueType'],
        'attributeAllowableValues': json['attributeAllowableValues'],
        'mandatory': json['mandatory'],
        'attributeUomSpecifier': json['attributeUomSpecifier'],
        'attributeUomValues': json['attributeUomValues'],
        'optionalDefault': EnergyStarPropertyUseOptionalDefaultFromJSON(json['optionalDefault']),
        'requiredEsRating': json['requiredEsRating'],
        'sortOrder': json['sortOrder'],
    };
}

export function EnergyStarPropertyUseAttributesToJSON(value?: EnergyStarPropertyUseAttributes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeLabel': value.attributeLabel,
        'attributeName': value.attributeName,
        'attributeValueType': value.attributeValueType,
        'attributeAllowableValues': value.attributeAllowableValues,
        'mandatory': value.mandatory,
        'attributeUomSpecifier': value.attributeUomSpecifier,
        'attributeUomValues': value.attributeUomValues,
        'optionalDefault': EnergyStarPropertyUseOptionalDefaultToJSON(value.optionalDefault),
        'requiredEsRating': value.requiredEsRating,
        'sortOrder': value.sortOrder,
    };
}


