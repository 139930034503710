/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationSustainabilityAttributesDto
 */
export interface LocationSustainabilityAttributesDto {
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    recordType: LocationSustainabilityAttributesDtoRecordTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    businessRegion: LocationSustainabilityAttributesDtoBusinessRegionEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    assetType: LocationSustainabilityAttributesDtoAssetTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LocationSustainabilityAttributesDto
     */
    companyOwnedAsset: boolean;
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    leaseId?: string;
    /**
     * 
     * @type {Date}
     * @memberof LocationSustainabilityAttributesDto
     */
    leaseExpirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    facilityId?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationSustainabilityAttributesDto
     */
    totalBuildingArea?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationSustainabilityAttributesDto
     */
    occupiedFloorArea?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    occupiedAreaUnit?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationSustainabilityAttributesDto
     */
    description?: string;
}

/**
* @export
* @enum {string}
*/
export enum LocationSustainabilityAttributesDtoRecordTypeEnum {
    Building = 'Building',
    General = 'General',
    Vehicle = 'Vehicle'
}/**
* @export
* @enum {string}
*/
export enum LocationSustainabilityAttributesDtoBusinessRegionEnum {
    Amer = 'AMER',
    Africa = 'Africa',
    Latam = 'LATAM',
    CentralAmerica = 'Central America',
    NorthAmerica = 'North America',
    MiddleEast = 'Middle East',
    Anz = 'ANZ',
    Europe = 'Europe',
    Apac = 'APAC',
    CentralAsia = 'Central Asia',
    Emea = 'EMEA',
    SouthAmerica = 'South America',
    SouthEastAsia = 'SouthEast Asia'
}/**
* @export
* @enum {string}
*/
export enum LocationSustainabilityAttributesDtoAssetTypeEnum {
    CommercialBuilding = 'Commercial Building',
    DataCenterFacility = 'Data Center Facility',
    AirTravel = 'Air Travel',
    FleetVehicle = 'Fleet Vehicle',
    FreightHauling = 'Freight Hauling',
    GroundTravel = 'Ground Travel',
    HotelStay = 'Hotel Stay',
    PrivateJet = 'Private Jet',
    RentalCar = 'Rental Car'
}

export function LocationSustainabilityAttributesDtoFromJSON(json: any): LocationSustainabilityAttributesDto {
    return LocationSustainabilityAttributesDtoFromJSONTyped(json, false);
}

export function LocationSustainabilityAttributesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationSustainabilityAttributesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recordType': json['recordType'],
        'businessRegion': json['businessRegion'],
        'assetType': json['assetType'],
        'companyOwnedAsset': json['companyOwnedAsset'],
        'leaseId': !exists(json, 'leaseId') ? undefined : json['leaseId'],
        'leaseExpirationDate': !exists(json, 'leaseExpirationDate') ? undefined : (new Date(json['leaseExpirationDate'])),
        'facilityId': !exists(json, 'facilityId') ? undefined : json['facilityId'],
        'totalBuildingArea': !exists(json, 'totalBuildingArea') ? undefined : json['totalBuildingArea'],
        'occupiedFloorArea': !exists(json, 'occupiedFloorArea') ? undefined : json['occupiedFloorArea'],
        'occupiedAreaUnit': !exists(json, 'occupiedAreaUnit') ? undefined : json['occupiedAreaUnit'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function LocationSustainabilityAttributesDtoToJSON(value?: LocationSustainabilityAttributesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recordType': value.recordType,
        'businessRegion': value.businessRegion,
        'assetType': value.assetType,
        'companyOwnedAsset': value.companyOwnedAsset,
        'leaseId': value.leaseId,
        'leaseExpirationDate': value.leaseExpirationDate === undefined ? undefined : (value.leaseExpirationDate.toISOString()),
        'facilityId': value.facilityId,
        'totalBuildingArea': value.totalBuildingArea,
        'occupiedFloorArea': value.occupiedFloorArea,
        'occupiedAreaUnit': value.occupiedAreaUnit,
        'description': value.description,
    };
}


