/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetMetadataResponseDto
 */
export interface WidgetMetadataResponseDto {
    /**
     * 
     * @type {Date}
     * @memberof WidgetMetadataResponseDto
     */
    minDateRange: Date;
    /**
     * 
     * @type {Date}
     * @memberof WidgetMetadataResponseDto
     */
    maxDateRange: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof WidgetMetadataResponseDto
     */
    utilityTypeList: Array<string>;
}

export function WidgetMetadataResponseDtoFromJSON(json: any): WidgetMetadataResponseDto {
    return WidgetMetadataResponseDtoFromJSONTyped(json, false);
}

export function WidgetMetadataResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetMetadataResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minDateRange': (new Date(json['minDateRange'])),
        'maxDateRange': (new Date(json['maxDateRange'])),
        'utilityTypeList': json['utilityTypeList'],
    };
}

export function WidgetMetadataResponseDtoToJSON(value?: WidgetMetadataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minDateRange': (value.minDateRange.toISOString()),
        'maxDateRange': (value.maxDateRange.toISOString()),
        'utilityTypeList': value.utilityTypeList,
    };
}


