export const WorkflowStates = new Map([
  ['integrity_check', 'Integrity Check'],
  ['data_verification_1', 'Data Verification I'],
  ['data_verification_2', 'Data Verification II'],
  ['data_audit_1', 'Data Audit I'],
  ['data_audit_2', 'Data Audit II'],
  ['paid', 'Paid'],
  ['processed', 'Processed'],
  ['bill_loaded', 'Bill Loaded'],
])
