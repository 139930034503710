/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VendorProviderAddressDto,
    VendorProviderAddressDtoFromJSON,
    VendorProviderAddressDtoFromJSONTyped,
    VendorProviderAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorProviderDetailsDto
 */
export interface VendorProviderDetailsDto {
    /**
     * FDG provider id
     * @type {number}
     * @memberof VendorProviderDetailsDto
     */
    providerId: number;
    /**
     * FDG provider code
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    code: string;
    /**
     * Provider name
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    name: string;
    /**
     * Provider remittance name
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    remittanceName: string;
    /**
     * Provider address
     * @type {VendorProviderAddressDto}
     * @memberof VendorProviderDetailsDto
     */
    remittanceAddress?: VendorProviderAddressDto;
    /**
     * Provider main phone
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    mainPhone?: string;
    /**
     * Provider customer service phone
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    customerServicePhone?: string;
    /**
     * Provider web address
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    webAddress?: string;
    /**
     * Provider priority phone
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    priorityPhone?: string;
    /**
     * Provider emergency phone
     * @type {string}
     * @memberof VendorProviderDetailsDto
     */
    emergencyPhone?: string;
}

export function VendorProviderDetailsDtoFromJSON(json: any): VendorProviderDetailsDto {
    return VendorProviderDetailsDtoFromJSONTyped(json, false);
}

export function VendorProviderDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorProviderDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providerId': json['providerId'],
        'code': json['code'],
        'name': json['name'],
        'remittanceName': json['remittanceName'],
        'remittanceAddress': !exists(json, 'remittanceAddress') ? undefined : VendorProviderAddressDtoFromJSON(json['remittanceAddress']),
        'mainPhone': !exists(json, 'mainPhone') ? undefined : json['mainPhone'],
        'customerServicePhone': !exists(json, 'customerServicePhone') ? undefined : json['customerServicePhone'],
        'webAddress': !exists(json, 'webAddress') ? undefined : json['webAddress'],
        'priorityPhone': !exists(json, 'priorityPhone') ? undefined : json['priorityPhone'],
        'emergencyPhone': !exists(json, 'emergencyPhone') ? undefined : json['emergencyPhone'],
    };
}

export function VendorProviderDetailsDtoToJSON(value?: VendorProviderDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providerId': value.providerId,
        'code': value.code,
        'name': value.name,
        'remittanceName': value.remittanceName,
        'remittanceAddress': VendorProviderAddressDtoToJSON(value.remittanceAddress),
        'mainPhone': value.mainPhone,
        'customerServicePhone': value.customerServicePhone,
        'webAddress': value.webAddress,
        'priorityPhone': value.priorityPhone,
        'emergencyPhone': value.emergencyPhone,
    };
}


