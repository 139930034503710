/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CustomAttributesValuesList,
    CustomAttributesValuesListFromJSON,
    CustomAttributesValuesListToJSON,
    MetadataAttributeDto,
    MetadataAttributeDtoFromJSON,
    MetadataAttributeDtoToJSON,
    ProviderMetadataAttribute,
    ProviderMetadataAttributeFromJSON,
    ProviderMetadataAttributeToJSON,
    ProvidersMetadataAndMappingAttribute,
    ProvidersMetadataAndMappingAttributeFromJSON,
    ProvidersMetadataAndMappingAttributeToJSON,
    ProvidersVendorsAttributeMappingDto,
    ProvidersVendorsAttributeMappingDtoFromJSON,
    ProvidersVendorsAttributeMappingDtoToJSON,
    SortMetadataAttributeDto,
    SortMetadataAttributeDtoFromJSON,
    SortMetadataAttributeDtoToJSON,
    UpdateMetadataAttributeDto,
    UpdateMetadataAttributeDtoFromJSON,
    UpdateMetadataAttributeDtoToJSON,
    Vendor,
    VendorFromJSON,
    VendorToJSON,
    VendorCustomPrettyNameDto,
    VendorCustomPrettyNameDtoFromJSON,
    VendorCustomPrettyNameDtoToJSON,
    VendorDetailsByCustomer,
    VendorDetailsByCustomerFromJSON,
    VendorDetailsByCustomerToJSON,
    VendorListDto,
    VendorListDtoFromJSON,
    VendorListDtoToJSON,
    VendorPrettyNameDto,
    VendorPrettyNameDtoFromJSON,
    VendorPrettyNameDtoToJSON,
} from '../models';

export interface CustomerVendorsControllerBulkUpdateVendorPrettyNamesRequest {
    customerId: number;
    vendorPrettyNameDto: Array<VendorPrettyNameDto>;
}

export interface CustomerVendorsControllerCreateVendorByCustomerIdRequest {
    customerId: number;
    metadataAttributeDto: MetadataAttributeDto;
}

export interface CustomerVendorsControllerCreateVendorByCustomerId0Request {
    customerId: number;
    metadataAttributeDto: MetadataAttributeDto;
}

export interface CustomerVendorsControllerDeleteMetadataAttributeRequest {
    id: number;
    customerId: number;
}

export interface CustomerVendorsControllerGetVendorCustomAttributesListRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerVendorsControllerGetVendorCustomAttributesListExcludeEnum>;
    orderBy?: Array<CustomerVendorsControllerGetVendorCustomAttributesListOrderByEnum>;
    searchQuery?: string;
}

export interface CustomerVendorsControllerGetVendorCustomAttributesList0Request {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerVendorsControllerGetVendorCustomAttributesList0ExcludeEnum>;
    orderBy?: Array<CustomerVendorsControllerGetVendorCustomAttributesList0OrderByEnum>;
    searchQuery?: string;
}

export interface CustomerVendorsControllerGetVendorDetailsByCustomerIdRequest {
    customerId: number;
    vendorId: number;
}

export interface CustomerVendorsControllerGetVendorDetailsByCustomerId0Request {
    customerId: number;
    vendorId: number;
}

export interface CustomerVendorsControllerListVendorsByCustomerIdRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerVendorsControllerListVendorsByCustomerIdExcludeEnum>;
    query?: string;
    blankPrettyName?: boolean;
    orderBy?: Array<CustomerVendorsControllerListVendorsByCustomerIdOrderByEnum>;
}

export interface CustomerVendorsControllerSortMetadataAttributesRequest {
    customerId: number;
    sortMetadataAttributeDto: Array<SortMetadataAttributeDto>;
}

export interface CustomerVendorsControllerUpdateMetadataAttributeRequest {
    id: number;
    customerId: number;
    updateMetadataAttributeDto: UpdateMetadataAttributeDto;
}

export interface CustomerVendorsControllerUpdateVendorByCustomerIdRequest {
    customerId: number;
    vendorId: number;
    vendorCustomPrettyNameDto: VendorCustomPrettyNameDto;
}

export interface CustomerVendorsControllerUpdateVendorByCustomerId0Request {
    customerId: number;
    vendorId: number;
    vendorCustomPrettyNameDto: VendorCustomPrettyNameDto;
}

export interface CustomerVendorsControllerUpsertAttributesMappingRequest {
    providerVendorId: number;
    customerId: number;
    providersVendorsAttributeMappingDto: Array<ProvidersVendorsAttributeMappingDto>;
}

/**
 * 
 */
export class CustomerVendorsApi extends runtime.BaseAPI {

    /**
     * Bulk update vendor pretty names
     */
    async customerVendorsControllerBulkUpdateVendorPrettyNamesRaw(requestParameters: CustomerVendorsControllerBulkUpdateVendorPrettyNamesRequest): Promise<runtime.ApiResponse<Array<Vendor>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerBulkUpdateVendorPrettyNames.');
        }

        if (requestParameters.vendorPrettyNameDto === null || requestParameters.vendorPrettyNameDto === undefined) {
            throw new runtime.RequiredError('vendorPrettyNameDto','Required parameter requestParameters.vendorPrettyNameDto was null or undefined when calling customerVendorsControllerBulkUpdateVendorPrettyNames.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/pretty-names`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.vendorPrettyNameDto.map(VendorPrettyNameDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VendorFromJSON));
    }

    /**
     * Bulk update vendor pretty names
     */
    async customerVendorsControllerBulkUpdateVendorPrettyNames(requestParameters: CustomerVendorsControllerBulkUpdateVendorPrettyNamesRequest): Promise<Array<Vendor>> {
        const response = await this.customerVendorsControllerBulkUpdateVendorPrettyNamesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create vendor custom attributes for a given customer
     */
    async customerVendorsControllerCreateVendorByCustomerIdRaw(requestParameters: CustomerVendorsControllerCreateVendorByCustomerIdRequest): Promise<runtime.ApiResponse<ProviderMetadataAttribute>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerCreateVendorByCustomerId.');
        }

        if (requestParameters.metadataAttributeDto === null || requestParameters.metadataAttributeDto === undefined) {
            throw new runtime.RequiredError('metadataAttributeDto','Required parameter requestParameters.metadataAttributeDto was null or undefined when calling customerVendorsControllerCreateVendorByCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/create-custom-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetadataAttributeDtoToJSON(requestParameters.metadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Create vendor custom attributes for a given customer
     */
    async customerVendorsControllerCreateVendorByCustomerId(requestParameters: CustomerVendorsControllerCreateVendorByCustomerIdRequest): Promise<ProviderMetadataAttribute> {
        const response = await this.customerVendorsControllerCreateVendorByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create vendor custom attributes for a given customer
     */
    async customerVendorsControllerCreateVendorByCustomerId_1Raw(requestParameters: CustomerVendorsControllerCreateVendorByCustomerId0Request): Promise<runtime.ApiResponse<ProviderMetadataAttribute>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerCreateVendorByCustomerId_1.');
        }

        if (requestParameters.metadataAttributeDto === null || requestParameters.metadataAttributeDto === undefined) {
            throw new runtime.RequiredError('metadataAttributeDto','Required parameter requestParameters.metadataAttributeDto was null or undefined when calling customerVendorsControllerCreateVendorByCustomerId_1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/create-custom-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetadataAttributeDtoToJSON(requestParameters.metadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Create vendor custom attributes for a given customer
     */
    async customerVendorsControllerCreateVendorByCustomerId_1(requestParameters: CustomerVendorsControllerCreateVendorByCustomerId0Request): Promise<ProviderMetadataAttribute> {
        const response = await this.customerVendorsControllerCreateVendorByCustomerId_1Raw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an existing vendor attribute for a given customer
     */
    async customerVendorsControllerDeleteMetadataAttributeRaw(requestParameters: CustomerVendorsControllerDeleteMetadataAttributeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerVendorsControllerDeleteMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerDeleteMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/attributes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing vendor attribute for a given customer
     */
    async customerVendorsControllerDeleteMetadataAttribute(requestParameters: CustomerVendorsControllerDeleteMetadataAttributeRequest): Promise<void> {
        await this.customerVendorsControllerDeleteMetadataAttributeRaw(requestParameters);
    }

    /**
     * Get vendor custom attributes list
     */
    async customerVendorsControllerGetVendorCustomAttributesListRaw(requestParameters: CustomerVendorsControllerGetVendorCustomAttributesListRequest): Promise<runtime.ApiResponse<CustomAttributesValuesList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerGetVendorCustomAttributesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/custom-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAttributesValuesListFromJSON(jsonValue));
    }

    /**
     * Get vendor custom attributes list
     */
    async customerVendorsControllerGetVendorCustomAttributesList(requestParameters: CustomerVendorsControllerGetVendorCustomAttributesListRequest): Promise<CustomAttributesValuesList> {
        const response = await this.customerVendorsControllerGetVendorCustomAttributesListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vendor custom attributes list
     */
    async customerVendorsControllerGetVendorCustomAttributesList_2Raw(requestParameters: CustomerVendorsControllerGetVendorCustomAttributesList0Request): Promise<runtime.ApiResponse<CustomAttributesValuesList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerGetVendorCustomAttributesList_2.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/custom-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomAttributesValuesListFromJSON(jsonValue));
    }

    /**
     * Get vendor custom attributes list
     */
    async customerVendorsControllerGetVendorCustomAttributesList_2(requestParameters: CustomerVendorsControllerGetVendorCustomAttributesList0Request): Promise<CustomAttributesValuesList> {
        const response = await this.customerVendorsControllerGetVendorCustomAttributesList_2Raw(requestParameters);
        return await response.value();
    }

    /**
     * Get vendor details by customer
     */
    async customerVendorsControllerGetVendorDetailsByCustomerIdRaw(requestParameters: CustomerVendorsControllerGetVendorDetailsByCustomerIdRequest): Promise<runtime.ApiResponse<VendorDetailsByCustomer>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerGetVendorDetailsByCustomerId.');
        }

        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling customerVendorsControllerGetVendorDetailsByCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/{vendorId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorDetailsByCustomerFromJSON(jsonValue));
    }

    /**
     * Get vendor details by customer
     */
    async customerVendorsControllerGetVendorDetailsByCustomerId(requestParameters: CustomerVendorsControllerGetVendorDetailsByCustomerIdRequest): Promise<VendorDetailsByCustomer> {
        const response = await this.customerVendorsControllerGetVendorDetailsByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get vendor details by customer
     */
    async customerVendorsControllerGetVendorDetailsByCustomerId_3Raw(requestParameters: CustomerVendorsControllerGetVendorDetailsByCustomerId0Request): Promise<runtime.ApiResponse<VendorDetailsByCustomer>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerGetVendorDetailsByCustomerId_3.');
        }

        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling customerVendorsControllerGetVendorDetailsByCustomerId_3.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/{vendorId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorDetailsByCustomerFromJSON(jsonValue));
    }

    /**
     * Get vendor details by customer
     */
    async customerVendorsControllerGetVendorDetailsByCustomerId_3(requestParameters: CustomerVendorsControllerGetVendorDetailsByCustomerId0Request): Promise<VendorDetailsByCustomer> {
        const response = await this.customerVendorsControllerGetVendorDetailsByCustomerId_3Raw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of existing vendors by customer id
     */
    async customerVendorsControllerListVendorsByCustomerIdRaw(requestParameters: CustomerVendorsControllerListVendorsByCustomerIdRequest): Promise<runtime.ApiResponse<VendorListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerListVendorsByCustomerId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.blankPrettyName !== undefined) {
            queryParameters['blankPrettyName'] = requestParameters.blankPrettyName;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of existing vendors by customer id
     */
    async customerVendorsControllerListVendorsByCustomerId(requestParameters: CustomerVendorsControllerListVendorsByCustomerIdRequest): Promise<VendorListDto> {
        const response = await this.customerVendorsControllerListVendorsByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sort/reorder provide vendor attributes for a given customer
     */
    async customerVendorsControllerSortMetadataAttributesRaw(requestParameters: CustomerVendorsControllerSortMetadataAttributesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerSortMetadataAttributes.');
        }

        if (requestParameters.sortMetadataAttributeDto === null || requestParameters.sortMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('sortMetadataAttributeDto','Required parameter requestParameters.sortMetadataAttributeDto was null or undefined when calling customerVendorsControllerSortMetadataAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/sort`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.sortMetadataAttributeDto.map(SortMetadataAttributeDtoToJSON),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sort/reorder provide vendor attributes for a given customer
     */
    async customerVendorsControllerSortMetadataAttributes(requestParameters: CustomerVendorsControllerSortMetadataAttributesRequest): Promise<void> {
        await this.customerVendorsControllerSortMetadataAttributesRaw(requestParameters);
    }

    /**
     * Update an existing vendor attribute for a given customer
     */
    async customerVendorsControllerUpdateMetadataAttributeRaw(requestParameters: CustomerVendorsControllerUpdateMetadataAttributeRequest): Promise<runtime.ApiResponse<ProviderMetadataAttribute>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerVendorsControllerUpdateMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerUpdateMetadataAttribute.');
        }

        if (requestParameters.updateMetadataAttributeDto === null || requestParameters.updateMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('updateMetadataAttributeDto','Required parameter requestParameters.updateMetadataAttributeDto was null or undefined when calling customerVendorsControllerUpdateMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/attributes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMetadataAttributeDtoToJSON(requestParameters.updateMetadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Update an existing vendor attribute for a given customer
     */
    async customerVendorsControllerUpdateMetadataAttribute(requestParameters: CustomerVendorsControllerUpdateMetadataAttributeRequest): Promise<ProviderMetadataAttribute> {
        const response = await this.customerVendorsControllerUpdateMetadataAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update vendor pretty name by customerID
     */
    async customerVendorsControllerUpdateVendorByCustomerIdRaw(requestParameters: CustomerVendorsControllerUpdateVendorByCustomerIdRequest): Promise<runtime.ApiResponse<Vendor>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerUpdateVendorByCustomerId.');
        }

        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling customerVendorsControllerUpdateVendorByCustomerId.');
        }

        if (requestParameters.vendorCustomPrettyNameDto === null || requestParameters.vendorCustomPrettyNameDto === undefined) {
            throw new runtime.RequiredError('vendorCustomPrettyNameDto','Required parameter requestParameters.vendorCustomPrettyNameDto was null or undefined when calling customerVendorsControllerUpdateVendorByCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/{vendorId}/vendor-pretty-name`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VendorCustomPrettyNameDtoToJSON(requestParameters.vendorCustomPrettyNameDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorFromJSON(jsonValue));
    }

    /**
     * Update vendor pretty name by customerID
     */
    async customerVendorsControllerUpdateVendorByCustomerId(requestParameters: CustomerVendorsControllerUpdateVendorByCustomerIdRequest): Promise<Vendor> {
        const response = await this.customerVendorsControllerUpdateVendorByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update vendor pretty name by customerID
     */
    async customerVendorsControllerUpdateVendorByCustomerId_4Raw(requestParameters: CustomerVendorsControllerUpdateVendorByCustomerId0Request): Promise<runtime.ApiResponse<Vendor>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerUpdateVendorByCustomerId_4.');
        }

        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling customerVendorsControllerUpdateVendorByCustomerId_4.');
        }

        if (requestParameters.vendorCustomPrettyNameDto === null || requestParameters.vendorCustomPrettyNameDto === undefined) {
            throw new runtime.RequiredError('vendorCustomPrettyNameDto','Required parameter requestParameters.vendorCustomPrettyNameDto was null or undefined when calling customerVendorsControllerUpdateVendorByCustomerId_4.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/{vendorId}/vendor-pretty-name`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: VendorCustomPrettyNameDtoToJSON(requestParameters.vendorCustomPrettyNameDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorFromJSON(jsonValue));
    }

    /**
     * Update vendor pretty name by customerID
     */
    async customerVendorsControllerUpdateVendorByCustomerId_4(requestParameters: CustomerVendorsControllerUpdateVendorByCustomerId0Request): Promise<Vendor> {
        const response = await this.customerVendorsControllerUpdateVendorByCustomerId_4Raw(requestParameters);
        return await response.value();
    }

    /**
     * Store provider vendor attributes mapping values
     */
    async customerVendorsControllerUpsertAttributesMappingRaw(requestParameters: CustomerVendorsControllerUpsertAttributesMappingRequest): Promise<runtime.ApiResponse<Array<ProvidersMetadataAndMappingAttribute>>> {
        if (requestParameters.providerVendorId === null || requestParameters.providerVendorId === undefined) {
            throw new runtime.RequiredError('providerVendorId','Required parameter requestParameters.providerVendorId was null or undefined when calling customerVendorsControllerUpsertAttributesMapping.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVendorsControllerUpsertAttributesMapping.');
        }

        if (requestParameters.providersVendorsAttributeMappingDto === null || requestParameters.providersVendorsAttributeMappingDto === undefined) {
            throw new runtime.RequiredError('providersVendorsAttributeMappingDto','Required parameter requestParameters.providersVendorsAttributeMappingDto was null or undefined when calling customerVendorsControllerUpsertAttributesMapping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/vendors/{providerVendorId}`.replace(`{${"providerVendorId"}}`, encodeURIComponent(String(requestParameters.providerVendorId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.providersVendorsAttributeMappingDto.map(ProvidersVendorsAttributeMappingDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProvidersMetadataAndMappingAttributeFromJSON));
    }

    /**
     * Store provider vendor attributes mapping values
     */
    async customerVendorsControllerUpsertAttributesMapping(requestParameters: CustomerVendorsControllerUpsertAttributesMappingRequest): Promise<Array<ProvidersMetadataAndMappingAttribute>> {
        const response = await this.customerVendorsControllerUpsertAttributesMappingRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerVendorsControllerGetVendorCustomAttributesListExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVendorsControllerGetVendorCustomAttributesListOrderByEnum {
    SortOrderAsc = 'sortOrder.asc',
    AttributeNameAsc = 'attributeName.asc',
    AttributeTypeAsc = 'attributeType.asc',
    AttributeUomAsc = 'attributeUom.asc',
    SortOrderDesc = 'sortOrder.desc',
    AttributeNameDesc = 'attributeName.desc',
    AttributeTypeDesc = 'attributeType.desc',
    AttributeUomDesc = 'attributeUom.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVendorsControllerGetVendorCustomAttributesList0ExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVendorsControllerGetVendorCustomAttributesList0OrderByEnum {
    SortOrderAsc = 'sortOrder.asc',
    AttributeNameAsc = 'attributeName.asc',
    AttributeTypeAsc = 'attributeType.asc',
    AttributeUomAsc = 'attributeUom.asc',
    SortOrderDesc = 'sortOrder.desc',
    AttributeNameDesc = 'attributeName.desc',
    AttributeTypeDesc = 'attributeType.desc',
    AttributeUomDesc = 'attributeUom.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVendorsControllerListVendorsByCustomerIdExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVendorsControllerListVendorsByCustomerIdOrderByEnum {
    IdAsc = 'id.asc',
    CodeAsc = 'code.asc',
    PrettyNameAsc = 'prettyName.asc',
    CustomPrettyNameAsc = 'customPrettyName.asc',
    IdDesc = 'id.desc',
    CodeDesc = 'code.desc',
    PrettyNameDesc = 'prettyName.desc',
    CustomPrettyNameDesc = 'customPrettyName.desc'
}
