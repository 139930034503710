/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Widget,
    WidgetFromJSON,
    WidgetFromJSONTyped,
    WidgetToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetsListDto
 */
export interface WidgetsListDto {
    /**
     * 
     * @type {Array<Widget>}
     * @memberof WidgetsListDto
     */
    results: Array<Widget>;
    /**
     * 
     * @type {number}
     * @memberof WidgetsListDto
     */
    total: number;
}

export function WidgetsListDtoFromJSON(json: any): WidgetsListDto {
    return WidgetsListDtoFromJSONTyped(json, false);
}

export function WidgetsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetsListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(WidgetFromJSON)),
        'total': json['total'],
    };
}

export function WidgetsListDtoToJSON(value?: WidgetsListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(WidgetToJSON)),
        'total': value.total,
    };
}


