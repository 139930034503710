import { API_V3_URL } from '@/AppModule/Configs/env'

import { VirtualAccountsService } from './service.virtual-accounts'
import { VirtualAccountAttribute } from '@/Modules/Locations/interfaces/interface.virtual-account'
import {
  Configuration,
  CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest,
  CustomerVirtualAccountAttributesControllerGetOperatorNotesRequest,
  CustomerVirtualAccountAttributesControllerListAttributesMappingRequest,
  CustomerVirtualAccountAttributesControllerUpsertAttributesMappingRequest,
  CustomerVirtualAccountAttributesControllerUpsertOperatorNotesRequest,
  VirtualAccountAttributesApi,
  VirtualAccountMetadataAttribute,
  VirtualAccountMetadataAttributeAttributeTypeEnum,
  VirtualAccountMetadataAttributesListDto,
  VirtualAccountsAttributeMapping,
  VirtualAccountsOperatorNotes,
} from '@/SharedModule/Api/index'

export class VirtualAccountAttributesService extends VirtualAccountsService {
  private virtualAccountAttributesApi: VirtualAccountAttributesApi
  constructor() {
    super()
    this.virtualAccountAttributesApi = new VirtualAccountAttributesApi(
      this.getBaseOptions(),
    )
  }

  public getVirtualAccountAttributesPaginatedResponse(
    requestParameters: CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest,
  ): Promise<VirtualAccountMetadataAttributesListDto> {
    return this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerGetMetadataAttributes(
      requestParameters,
    )
  }

  public async getVirtualAccountAttributesOperatorNotes(
    requestParameters: CustomerVirtualAccountAttributesControllerGetOperatorNotesRequest,
  ): Promise<VirtualAccountsOperatorNotes> {
    try {
      return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerGetOperatorNotes(
        requestParameters,
      )
    } catch (e) {
      console.log(e)
      return e
    }
  }

  public getVirtualAccountAttributesSpecificItem(
    requestParameters: CustomerVirtualAccountAttributesControllerListAttributesMappingRequest,
  ): Promise<Array<VirtualAccountsAttributeMapping>> {
    return this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerListAttributesMapping(
      requestParameters,
    )
  }
  public async getVirtualAccountAttributesSpecificItemList(
    requestParams: CustomerVirtualAccountAttributesControllerListAttributesMappingRequest,
  ): Promise<VirtualAccountsAttributeMapping[]> {
    return await this.getVirtualAccountAttributesSpecificItem(requestParams)
  }
  public async getVirtualAccountAttributesResponse(
    virtualAccountId: number,
    customerId: number,
  ): Promise<VirtualAccountAttribute[]> {
    const selectedVirtualAccountAttrs =
      await this.getVirtualAccountAttributesSpecificItem({
        virtualAccountId,
        customerId,
      })
    const virtualAccountAttrs = await this.getVirtualAccountAttributesList(
      customerId,
    )
    for (const virtualAccountAttr of virtualAccountAttrs) {
      for (const selectedVirtualAccountAttr of selectedVirtualAccountAttrs) {
        if (
          +virtualAccountAttr.id ===
            +selectedVirtualAccountAttr.virtualAccountsAttributesMetadataId &&
          selectedVirtualAccountAttr.attributeValue
        ) {
          if (
            virtualAccountAttr.attributeType ===
            VirtualAccountMetadataAttributeAttributeTypeEnum.Numeric
          ) {
            virtualAccountAttr.attributeValue =
              +selectedVirtualAccountAttr.attributeValue
          } else {
            virtualAccountAttr.attributeValue =
              selectedVirtualAccountAttr.attributeValue
          }
        }
      }
    }
    return virtualAccountAttrs
  }
  public async getVirtualAccountAttributesList(
    customerId: number,
  ): Promise<VirtualAccountAttribute[]> {
    const response = await this.getVirtualAccountAttributesPaginatedResponse({
      customerId,
    })
    return response.results.map(
      (virtualAttr: VirtualAccountMetadataAttribute) => {
        return { ...virtualAttr, attributeValue: '' }
      },
    )
  }
  public async postCustomAttributesValues(
    requestParameters: CustomerVirtualAccountAttributesControllerUpsertAttributesMappingRequest,
  ): Promise<VirtualAccountsAttributeMapping[]> {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerUpsertAttributesMapping(
      requestParameters,
    )
  }
  public async postOperatorNotes(
    requestParameters: CustomerVirtualAccountAttributesControllerUpsertOperatorNotesRequest,
  ): Promise<VirtualAccountsOperatorNotes> {
    return await this.virtualAccountAttributesApi.customerVirtualAccountAttributesControllerUpsertOperatorNotes(
      requestParameters,
    )
  }
}
