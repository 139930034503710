/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BatchErrors,
    BatchErrorsFromJSON,
    BatchErrorsFromJSONTyped,
    BatchErrorsToJSON,
} from './';

/**
 * 
 * @export
 * @interface BatchErrorsDto
 */
export interface BatchErrorsDto {
    /**
     * 
     * @type {Array<BatchErrors>}
     * @memberof BatchErrorsDto
     */
    batchErrors: Array<BatchErrors>;
}

export function BatchErrorsDtoFromJSON(json: any): BatchErrorsDto {
    return BatchErrorsDtoFromJSONTyped(json, false);
}

export function BatchErrorsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchErrorsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'batchErrors': ((json['batchErrors'] as Array<any>).map(BatchErrorsFromJSON)),
    };
}

export function BatchErrorsDtoToJSON(value?: BatchErrorsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'batchErrors': ((value.batchErrors as Array<any>).map(BatchErrorsToJSON)),
    };
}


