/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorReasoningModel
 */
export interface ErrorReasoningModel {
    /**
     * 
     * @type {number}
     * @memberof ErrorReasoningModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorReasoningModel
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof ErrorReasoningModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ErrorReasoningModel
     */
    updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ErrorReasoningModel
     */
    isDefault: boolean;
}

export function ErrorReasoningModelFromJSON(json: any): ErrorReasoningModel {
    return ErrorReasoningModelFromJSONTyped(json, false);
}

export function ErrorReasoningModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorReasoningModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'isDefault': json['isDefault'],
    };
}

export function ErrorReasoningModelToJSON(value?: ErrorReasoningModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'isDefault': value.isDefault,
    };
}


