/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeatherDates
 */
export interface WeatherDates {
    /**
     * 
     * @type {Date}
     * @memberof WeatherDates
     */
    minDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof WeatherDates
     */
    maxDate: Date;
}

export function WeatherDatesFromJSON(json: any): WeatherDates {
    return WeatherDatesFromJSONTyped(json, false);
}

export function WeatherDatesFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherDates {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minDate': (new Date(json['minDate'])),
        'maxDate': (new Date(json['maxDate'])),
    };
}

export function WeatherDatesToJSON(value?: WeatherDates | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minDate': (value.minDate.toISOString()),
        'maxDate': (value.maxDate.toISOString()),
    };
}


