/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountHistoryModel
 */
export interface AccountHistoryModel {
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    billId: number;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    virtualAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof AccountHistoryModel
     */
    commodity: string;
    /**
     * 
     * @type {Date}
     * @memberof AccountHistoryModel
     */
    startDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof AccountHistoryModel
     */
    endDate: Date;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    daysOfService: number;
    /**
     * 
     * @type {string}
     * @memberof AccountHistoryModel
     */
    serviceDateMethod: string;
    /**
     * 
     * @type {string}
     * @memberof AccountHistoryModel
     */
    rateCode: string;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    subcharges: number;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    consumption: number;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    genConsumption: number;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    demand: number;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    unitCost: number;
    /**
     * 
     * @type {number}
     * @memberof AccountHistoryModel
     */
    loadFactor: number;
}

export function AccountHistoryModelFromJSON(json: any): AccountHistoryModel {
    return AccountHistoryModelFromJSONTyped(json, false);
}

export function AccountHistoryModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountHistoryModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'virtualAccountId': json['virtualAccountId'],
        'commodity': json['commodity'],
        'startDate': (new Date(json['startDate'])),
        'endDate': (new Date(json['endDate'])),
        'daysOfService': json['daysOfService'],
        'serviceDateMethod': json['serviceDateMethod'],
        'rateCode': json['rateCode'],
        'subcharges': json['subcharges'],
        'consumption': json['consumption'],
        'genConsumption': json['genConsumption'],
        'demand': json['demand'],
        'unitCost': json['unitCost'],
        'loadFactor': json['loadFactor'],
    };
}

export function AccountHistoryModelToJSON(value?: AccountHistoryModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'virtualAccountId': value.virtualAccountId,
        'commodity': value.commodity,
        'startDate': (value.startDate.toISOString()),
        'endDate': (value.endDate.toISOString()),
        'daysOfService': value.daysOfService,
        'serviceDateMethod': value.serviceDateMethod,
        'rateCode': value.rateCode,
        'subcharges': value.subcharges,
        'consumption': value.consumption,
        'genConsumption': value.genConsumption,
        'demand': value.demand,
        'unitCost': value.unitCost,
        'loadFactor': value.loadFactor,
    };
}


