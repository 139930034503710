/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
    ContractDetails,
    ContractDetailsFromJSON,
    ContractDetailsFromJSONTyped,
    ContractDetailsToJSON,
    Entitlements,
    EntitlementsFromJSON,
    EntitlementsFromJSONTyped,
    EntitlementsToJSON,
    TargetHours,
    TargetHoursFromJSON,
    TargetHoursFromJSONTyped,
    TargetHoursToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    fdgCode: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerDto
     */
    active: boolean;
    /**
     * 
     * @type {Entitlements}
     * @memberof CustomerDto
     */
    entitlements: Entitlements;
    /**
     * 
     * @type {TargetHours}
     * @memberof CustomerDto
     */
    targetHours: TargetHours;
    /**
     * 
     * @type {ContractDetails}
     * @memberof CustomerDto
     */
    contractDetails: ContractDetails;
    /**
     * 
     * @type {AddressModel}
     * @memberof CustomerDto
     */
    address?: AddressModel;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    tin?: string;
}

export function CustomerDtoFromJSON(json: any): CustomerDto {
    return CustomerDtoFromJSONTyped(json, false);
}

export function CustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'fdgCode': json['fdgCode'],
        'active': json['active'],
        'entitlements': EntitlementsFromJSON(json['entitlements']),
        'targetHours': TargetHoursFromJSON(json['targetHours']),
        'contractDetails': ContractDetailsFromJSON(json['contractDetails']),
        'address': !exists(json, 'address') ? undefined : AddressModelFromJSON(json['address']),
        'tin': !exists(json, 'tin') ? undefined : json['tin'],
    };
}

export function CustomerDtoToJSON(value?: CustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'fdgCode': value.fdgCode,
        'active': value.active,
        'entitlements': EntitlementsToJSON(value.entitlements),
        'targetHours': TargetHoursToJSON(value.targetHours),
        'contractDetails': ContractDetailsToJSON(value.contractDetails),
        'address': AddressModelToJSON(value.address),
        'tin': value.tin,
    };
}


