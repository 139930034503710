/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Hierarchy,
    HierarchyFromJSON,
    HierarchyFromJSONTyped,
    HierarchyToJSON,
} from './';

/**
 * 
 * @export
 * @interface HierarchyListDto
 */
export interface HierarchyListDto {
    /**
     * 
     * @type {Array<Hierarchy>}
     * @memberof HierarchyListDto
     */
    results: Array<Hierarchy>;
    /**
     * 
     * @type {number}
     * @memberof HierarchyListDto
     */
    total?: number;
    /**
     * 
     * @type {object}
     * @memberof HierarchyListDto
     */
    filters?: object;
    /**
     * 
     * @type {object}
     * @memberof HierarchyListDto
     */
    extra?: object;
}

export function HierarchyListDtoFromJSON(json: any): HierarchyListDto {
    return HierarchyListDtoFromJSONTyped(json, false);
}

export function HierarchyListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HierarchyListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(HierarchyFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : json['filters'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function HierarchyListDtoToJSON(value?: HierarchyListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(HierarchyToJSON)),
        'total': value.total,
        'filters': value.filters,
        'extra': value.extra,
    };
}


