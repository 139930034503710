import {
  VirtualAccountBulkUpdateDtoServiceDateMethodEnum,
  VirtualAccountBulkUpdateDtoUtilityTypeEnum,
  VirtualAccountBulkUpdateDtoVendorTypeEnum,
} from '@/SharedModule/Api'

export enum DialogStatesVirtualAccount {
  CloseScreen = 'close',
  TabsScreen = 'tabs',
  ConfirmationScreen = 'confirm',
  UpdatedBillsScreen = 'updatedBills',
}

export enum TabsNames {
  MetadataAttributes = 'metadataAttributes',
  BulkUpdateMetadataAttributes = 'bulkUpdateMetadataAttributes',
}

export enum DialogTitlesEnum {
  EditScreen = 'Edit Virtual Account',
  ConfirmationScreen = 'Confirm Bill Updates',
  UpdatedBillsScreen = 'Updated Bills',
  CloseScreen = '',
}

export const rules = {
  vendor: [
    {
      required: true,
      message: 'Vendor information is required.',
      trigger: 'change',
    },
  ],
  vendorProviderId: [
    { required: true, message: 'Vendor ID information is required.' },
    { type: 'number', message: 'Vendor ID information must be a number.' },
  ],
  vendorPrimaryBillingId: [
    {
      required: true,
      message: 'Vendor Primary Billing ID information is required.',
    },
  ],
  vendorType: [
    {
      required: true,
      message: 'Vendor Type information is required.',
    },
  ],
  serviceAccount: [
    { required: true, message: 'Service Account information is required.' },
  ],
  meterId: [{ required: true, message: 'Meter ID information is required.' }],
  vendorCode: [
    { required: true, message: 'Vendor Code information is required.' },
  ],
  utilityType: [
    {
      required: true,
      message: 'Utility Type information is required.',
    },
  ],
}
export const vendorTypes = Object.values(
  VirtualAccountBulkUpdateDtoVendorTypeEnum,
)
export const utilityTypes = Object.values(
  VirtualAccountBulkUpdateDtoUtilityTypeEnum,
)
export const serviceDateMethods = Object.values(
  VirtualAccountBulkUpdateDtoServiceDateMethodEnum,
)
