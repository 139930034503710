/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VendorProviderDetails,
    VendorProviderDetailsFromJSON,
    VendorProviderDetailsFromJSONTyped,
    VendorProviderDetailsToJSON,
} from './';

/**
 * 
 * @export
 * @interface VendorWithProviderDetails
 */
export interface VendorWithProviderDetails {
    /**
     * 
     * @type {number}
     * @memberof VendorWithProviderDetails
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof VendorWithProviderDetails
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof VendorWithProviderDetails
     */
    prettyName: string;
    /**
     * 
     * @type {string}
     * @memberof VendorWithProviderDetails
     */
    customPrettyName: string;
    /**
     * 
     * @type {Array<VendorProviderDetails>}
     * @memberof VendorWithProviderDetails
     */
    vendorProviderDetails: Array<VendorProviderDetails>;
}

export function VendorWithProviderDetailsFromJSON(json: any): VendorWithProviderDetails {
    return VendorWithProviderDetailsFromJSONTyped(json, false);
}

export function VendorWithProviderDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorWithProviderDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'prettyName': json['prettyName'],
        'customPrettyName': json['customPrettyName'],
        'vendorProviderDetails': ((json['vendorProviderDetails'] as Array<any>).map(VendorProviderDetailsFromJSON)),
    };
}

export function VendorWithProviderDetailsToJSON(value?: VendorWithProviderDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'prettyName': value.prettyName,
        'customPrettyName': value.customPrettyName,
        'vendorProviderDetails': ((value.vendorProviderDetails as Array<any>).map(VendorProviderDetailsToJSON)),
    };
}


