/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetPermissionsDto
 */
export interface SetPermissionsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SetPermissionsDto
     */
    permissions: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SetPermissionsDto
     */
    userId: number;
}

export function SetPermissionsDtoFromJSON(json: any): SetPermissionsDto {
    return SetPermissionsDtoFromJSONTyped(json, false);
}

export function SetPermissionsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPermissionsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'permissions': json['permissions'],
        'userId': json['userId'],
    };
}

export function SetPermissionsDtoToJSON(value?: SetPermissionsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'permissions': value.permissions,
        'userId': value.userId,
    };
}


