import {
  ProviderCodePair,
  Vendor,
  VendorsApi,
  VendorsControllerGetProvidersByCodeRequest,
  VendorsControllerGetVendorByIdRequest,
  VendorsControllerListVendorsRequest,
  VendorWithProviderDetails,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class VendorsService extends ApiService {
  private readonly vendorService = new VendorsApi(this.getBaseOptions())

  public getVendorById(
    requestParameters: VendorsControllerGetVendorByIdRequest,
  ): Promise<VendorWithProviderDetails> {
    return this.vendorService.vendorsControllerGetVendorById(requestParameters)
  }
  public async getProvidersByCode(
    requestParameters: VendorsControllerGetProvidersByCodeRequest,
  ): Promise<ProviderCodePair[] | undefined> {
    try {
      const vendorsList =
        await this.vendorService.vendorsControllerGetProvidersByCode(
          requestParameters,
        )
      return vendorsList.results
    } catch (e) {
      console.log(e)
    }
  }
  public async getListVendors(
    requestParameters: VendorsControllerListVendorsRequest,
  ): Promise<Vendor[] | undefined> {
    try {
      const vendorsList = await this.vendorService.vendorsControllerListVendors(
        requestParameters,
      )
      return vendorsList.results
    } catch (e) {
      console.log(e)
    }
  }
}
