/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CubeTimeDimension
 */
export interface CubeTimeDimension {
    /**
     * 
     * @type {string}
     * @memberof CubeTimeDimension
     */
    dimension: string;
    /**
     * 
     * @type {string}
     * @memberof CubeTimeDimension
     */
    granularity: CubeTimeDimensionGranularityEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CubeTimeDimension
     */
    dateRange: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum CubeTimeDimensionGranularityEnum {
    Second = 'second',
    Minute = 'minute',
    Hour = 'hour',
    Day = 'day',
    Week = 'week',
    Month = 'month',
    Year = 'year'
}

export function CubeTimeDimensionFromJSON(json: any): CubeTimeDimension {
    return CubeTimeDimensionFromJSONTyped(json, false);
}

export function CubeTimeDimensionFromJSONTyped(json: any, ignoreDiscriminator: boolean): CubeTimeDimension {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimension': json['dimension'],
        'granularity': json['granularity'],
        'dateRange': json['dateRange'],
    };
}

export function CubeTimeDimensionToJSON(value?: CubeTimeDimension | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimension': value.dimension,
        'granularity': value.granularity,
        'dateRange': value.dateRange,
    };
}


