import { AuthService } from '@/Modules/Auth/services/service.auth'
import { LoginInterface } from '@/Modules/Auth/interfaces/interface.login'
import { FormActions } from 'vee-validate'
import { ActionContext } from 'vuex'
import { LoginDtoAuthTypeEnum } from '@/SharedModule/Api'

const authServices = new AuthService()

export interface AuthState {
  apiError: string
}

interface AuthParams {
  values: LoginInterface
  actions: FormActions<Record<string, any>> | any
}

export default {
  namespaced: true,
  state: (): AuthState => ({
    apiError: '',
  }),
  getters: {
    apiError: (state: AuthState): string => state.apiError,
  },
  mutations: {
    setApiError(state: AuthState, error: any): void {
      if (error) {
        state.apiError =
          error.response != null
            ? error.response.message && error.response.message.push
              ? error.response.message.join('')
              : error.response.message
            : 'Email or password is invalid'
      }
    },
  },
  actions: {
    async authenticate(
      { commit }: any,
      { values, actions }: AuthParams,
    ): Promise<void> {
      try {
        const response = await authServices.login({
          authType: LoginDtoAuthTypeEnum.Basic,
          email: values.email,
          password: values.password,
        })
        actions.resetForm()
        localStorage.setItem('authToken', response.token)
        localStorage.setItem('refreshToken', response.refreshToken)
        const redirect = localStorage.getItem('redirect')
        localStorage.removeItem('redirect')
        if (redirect) {
          location.href = redirect
        } else {
          location.href = '/'
        }
      } catch (error) {
        console.log(error)
        const err = await error.json()
        commit('setApiError', err)
      }
    },
  },
}
