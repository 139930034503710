/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillChainItem
 */
export interface BillChainItem {
    /**
     * 
     * @type {number}
     * @memberof BillChainItem
     */
    billId: number;
    /**
     * 
     * @type {object}
     * @memberof BillChainItem
     */
    statementDate: object;
    /**
     * 
     * @type {number}
     * @memberof BillChainItem
     */
    currentBillCharges: number;
    /**
     * 
     * @type {number}
     * @memberof BillChainItem
     */
    pastDueAmount: number;
    /**
     * 
     * @type {number}
     * @memberof BillChainItem
     */
    totalBillCharges: number;
    /**
     * 
     * @type {object}
     * @memberof BillChainItem
     */
    dueDate: object;
    /**
     * 
     * @type {number}
     * @memberof BillChainItem
     */
    priorBillDays: number;
}

export function BillChainItemFromJSON(json: any): BillChainItem {
    return BillChainItemFromJSONTyped(json, false);
}

export function BillChainItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillChainItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'statementDate': json['statementDate'],
        'currentBillCharges': json['currentBillCharges'],
        'pastDueAmount': json['pastDueAmount'],
        'totalBillCharges': json['totalBillCharges'],
        'dueDate': json['dueDate'],
        'priorBillDays': json['priorBillDays'],
    };
}

export function BillChainItemToJSON(value?: BillChainItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'statementDate': value.statementDate,
        'currentBillCharges': value.currentBillCharges,
        'pastDueAmount': value.pastDueAmount,
        'totalBillCharges': value.totalBillCharges,
        'dueDate': value.dueDate,
        'priorBillDays': value.priorBillDays,
    };
}


