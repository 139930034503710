import { PaymentsActiveTabEnum } from '@/Modules/Payments/enums/enums.dialog'

interface IState {
  paymentsEntitlement: boolean
  paymentsFileFormats: string[]
  dialogState: string
  activeTab: string
}
interface Columns {
  label: string
  modelKey?: string
  width?: string
}
const getDefaultState = (): IState => ({
  paymentsEntitlement: false,
  paymentsFileFormats: [],
  dialogState: 'root',
  activeTab: PaymentsActiveTabEnum.PaymentsTab,
})
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    dialogState: (state: IState): string => state.dialogState,
    columns:
      () =>
      (type = 'email'): Columns[] =>
        [
          {
            label:
              type === 'sftp' ? 'Destination IP Address:' : 'Email recipients:',
            modelKey: type === 'sftp' ? 'host' : 'emails',
            width: '270px',
          },
          {
            label:
              type === 'error'
                ? 'Payment file errors:'
                : 'Payment file format:',
            modelKey: type === 'error' ? 'errors' : 'paymentFileFormat',
            width: '200px',
          },
          {
            label: 'Actions:',
          },
        ],
    paymentsEntitlement: (state: IState): boolean => state.paymentsEntitlement,
    paymentsFileFormat: (state: IState): string[] => state.paymentsFileFormats,
  },
  mutations: {
    setPaymentsEntitlement(state: IState, paymentsEntitlement: boolean): void {
      state.paymentsEntitlement = paymentsEntitlement
    },
    setPaymentsFileFormat(state: IState, paymentsFileFormats: string[]): void {
      state.paymentsFileFormats = paymentsFileFormats
    },
    setDialogState(state: IState, dialogState: string): void {
      state.dialogState = dialogState
    },
    setActiveTab(state: IState, activeTab: string): void {
      state.activeTab = activeTab
    },
    clearState(state: IState): void {
      Object.assign(state, getDefaultState())
    },
  },
}
