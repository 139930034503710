/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarScoreDto,
    EnergyStarScoreDtoFromJSON,
    EnergyStarScoreDtoFromJSONTyped,
    EnergyStarScoreDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarScoresDto
 */
export interface EnergyStarScoresDto {
    /**
     * 
     * @type {EnergyStarScoreDto}
     * @memberof EnergyStarScoresDto
     */
    currentScore: EnergyStarScoreDto;
    /**
     * 
     * @type {EnergyStarScoreDto}
     * @memberof EnergyStarScoresDto
     */
    baselineScore: EnergyStarScoreDto;
}

export function EnergyStarScoresDtoFromJSON(json: any): EnergyStarScoresDto {
    return EnergyStarScoresDtoFromJSONTyped(json, false);
}

export function EnergyStarScoresDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarScoresDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentScore': EnergyStarScoreDtoFromJSON(json['currentScore']),
        'baselineScore': EnergyStarScoreDtoFromJSON(json['baselineScore']),
    };
}

export function EnergyStarScoresDtoToJSON(value?: EnergyStarScoresDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'currentScore': EnergyStarScoreDtoToJSON(value.currentScore),
        'baselineScore': EnergyStarScoreDtoToJSON(value.baselineScore),
    };
}


