/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmailDeliveryStatusListDto,
    EmailDeliveryStatusListDtoFromJSON,
    EmailDeliveryStatusListDtoToJSON,
} from '../models';

export interface EmailerControllerGetEmailsDeliveryStatusRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<EmailerControllerGetEmailsDeliveryStatusExcludeEnum>;
    orderBy?: Array<EmailerControllerGetEmailsDeliveryStatusOrderByEnum>;
    customerId?: Array<number>;
    emailType?: Array<EmailerControllerGetEmailsDeliveryStatusEmailTypeEnum>;
    emailStatus?: Array<EmailerControllerGetEmailsDeliveryStatusEmailStatusEnum>;
    emailStatusType?: Array<EmailerControllerGetEmailsDeliveryStatusEmailStatusTypeEnum>;
    statusDateMin?: Date;
    statusDateMax?: Date;
    emailAddress?: Array<string>;
    emailSubject?: string;
    createdAtMin?: Date;
    createdAtMax?: Date;
    updatedAtMin?: Date;
    updatedAtMax?: Date;
}

export interface EmailerControllerSendgridCallbackRequest {
    requestBody: Array<string>;
}

/**
 * 
 */
export class EmailerApi extends runtime.BaseAPI {

    /**
     */
    async emailerControllerGetEmailsDeliveryStatusRaw(requestParameters: EmailerControllerGetEmailsDeliveryStatusRequest): Promise<runtime.ApiResponse<EmailDeliveryStatusListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.customerId) {
            queryParameters['customerId[]'] = requestParameters.customerId;
        }

        if (requestParameters.emailType) {
            queryParameters['emailType[]'] = requestParameters.emailType;
        }

        if (requestParameters.emailStatus) {
            queryParameters['emailStatus[]'] = requestParameters.emailStatus;
        }

        if (requestParameters.emailStatusType) {
            queryParameters['emailStatusType[]'] = requestParameters.emailStatusType;
        }

        if (requestParameters.statusDateMin !== undefined) {
            queryParameters['statusDateMin'] = (requestParameters.statusDateMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.statusDateMax !== undefined) {
            queryParameters['statusDateMax'] = (requestParameters.statusDateMax as any).toISOString().substr(0,10);
        }

        if (requestParameters.emailAddress) {
            queryParameters['emailAddress[]'] = requestParameters.emailAddress;
        }

        if (requestParameters.emailSubject !== undefined) {
            queryParameters['emailSubject'] = requestParameters.emailSubject;
        }

        if (requestParameters.createdAtMin !== undefined) {
            queryParameters['createdAtMin'] = (requestParameters.createdAtMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdAtMax !== undefined) {
            queryParameters['createdAtMax'] = (requestParameters.createdAtMax as any).toISOString().substr(0,10);
        }

        if (requestParameters.updatedAtMin !== undefined) {
            queryParameters['updatedAtMin'] = (requestParameters.updatedAtMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.updatedAtMax !== undefined) {
            queryParameters['updatedAtMax'] = (requestParameters.updatedAtMax as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/emailer/delivery-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailDeliveryStatusListDtoFromJSON(jsonValue));
    }

    /**
     */
    async emailerControllerGetEmailsDeliveryStatus(requestParameters: EmailerControllerGetEmailsDeliveryStatusRequest): Promise<EmailDeliveryStatusListDto> {
        const response = await this.emailerControllerGetEmailsDeliveryStatusRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async emailerControllerSendgridCallbackRaw(requestParameters: EmailerControllerSendgridCallbackRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling emailerControllerSendgridCallback.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/emailer/callback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async emailerControllerSendgridCallback(requestParameters: EmailerControllerSendgridCallbackRequest): Promise<void> {
        await this.emailerControllerSendgridCallbackRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum EmailerControllerGetEmailsDeliveryStatusExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum EmailerControllerGetEmailsDeliveryStatusOrderByEnum {
    CustomerIdAsc = 'customerId.asc',
    CustomerNameAsc = 'customerName.asc',
    EmailTypeAsc = 'emailType.asc',
    EmailAddressAsc = 'emailAddress.asc',
    EmailSubjectAsc = 'emailSubject.asc',
    CreatedAtAsc = 'createdAt.asc',
    UpdatedAtAsc = 'updatedAt.asc',
    ProcessedAsc = 'processed.asc',
    DeliveredAsc = 'delivered.asc',
    BounceAsc = 'bounce.asc',
    DeferredAsc = 'deferred.asc',
    DroppedAsc = 'dropped.asc',
    OpenAsc = 'open.asc',
    BlockedAsc = 'blocked.asc',
    EmailStatusAsc = 'emailStatus.asc',
    EmailStatusTypeAsc = 'emailStatusType.asc',
    StatusDateAsc = 'statusDate.asc',
    CustomerIdDesc = 'customerId.desc',
    CustomerNameDesc = 'customerName.desc',
    EmailTypeDesc = 'emailType.desc',
    EmailAddressDesc = 'emailAddress.desc',
    EmailSubjectDesc = 'emailSubject.desc',
    CreatedAtDesc = 'createdAt.desc',
    UpdatedAtDesc = 'updatedAt.desc',
    ProcessedDesc = 'processed.desc',
    DeliveredDesc = 'delivered.desc',
    BounceDesc = 'bounce.desc',
    DeferredDesc = 'deferred.desc',
    DroppedDesc = 'dropped.desc',
    OpenDesc = 'open.desc',
    BlockedDesc = 'blocked.desc',
    EmailStatusDesc = 'emailStatus.desc',
    EmailStatusTypeDesc = 'emailStatusType.desc',
    StatusDateDesc = 'statusDate.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum EmailerControllerGetEmailsDeliveryStatusEmailTypeEnum {
    Information = 'information',
    Reporting = 'reporting',
    Payments = 'payments',
    InternalNotification = 'internal-notification'
}
/**
    * @export
    * @enum {string}
    */
export enum EmailerControllerGetEmailsDeliveryStatusEmailStatusEnum {
    Processed = 'processed',
    Deferred = 'deferred',
    Delivered = 'delivered',
    Open = 'open',
    Dropped = 'dropped',
    Bounce = 'bounce',
    Blocked = 'blocked',
    None = 'none'
}
/**
    * @export
    * @enum {string}
    */
export enum EmailerControllerGetEmailsDeliveryStatusEmailStatusTypeEnum {
    Information = 'information',
    Warning = 'warning',
    Error = 'error'
}
