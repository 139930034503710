/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChatTag,
    ChatTagFromJSON,
    ChatTagFromJSONTyped,
    ChatTagToJSON,
} from './';

/**
 * 
 * @export
 * @interface ChatTagsListDto
 */
export interface ChatTagsListDto {
    /**
     * 
     * @type {Array<ChatTag>}
     * @memberof ChatTagsListDto
     */
    results: Array<ChatTag>;
}

export function ChatTagsListDtoFromJSON(json: any): ChatTagsListDto {
    return ChatTagsListDtoFromJSONTyped(json, false);
}

export function ChatTagsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatTagsListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(ChatTagFromJSON)),
    };
}

export function ChatTagsListDtoToJSON(value?: ChatTagsListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(ChatTagToJSON)),
    };
}


