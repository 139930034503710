import { ComputedRef, Ref } from 'vue'
import { intersection } from 'lodash'
interface FiltersTransformer {
  onDateChange: (date: string, filter: string) => void
  onFilterSearchQuery: (
    timeout: string,
    filter: string,
    filterSearchQuery: string,
  ) => void
  filterGetter: (
    val: any,
    filter: Ref<never[]>,
    apiFilter: string,
    isComplex?: boolean,
    property?: any,
  ) => void
  onFilterChange: (
    val: string[],
    timeout: string,
    filter: string,
    apiFilter: string,
    isComplex?: boolean,
    property?: any,
  ) => void
}
export function useFilterTransformer(
  repository: ComputedRef<any>,
  meta: ComputedRef<any>,
  apiFilters: ComputedRef<any>,
): FiltersTransformer {
  const onFilterSearchQuery = (
    timeout: string,
    filter: string,
    filterSearchQuery: string,
  ): void => {
    clearTimeout(meta.value[timeout])
    meta.value[timeout] = setTimeout(() => {
      meta.value.queryFiltersDirtyFromUserInput = true
      repository.value[filter] = filterSearchQuery
    }, meta.value.inputTimeout)
  }
  const onDateChange = (date: string, filter: string): void => {
    if (!date) {
      meta.value.queryFiltersDirtyFromUserInput = true
      repository.value[filter] = null
    }
    meta.value.queryFiltersDirtyFromUserInput = true
    repository.value[filter] = date
  }
  const filterGetter = (
    val: any,
    filter: Ref<never[]>,
    apiFilter: string,
    isComplex = false,
    property?: any,
  ): void => {
    const [routerFilter = null, filterValues = null] = val
    if (filterValues === null) return
    if (!routerFilter) {
      filter.value = []
      return
    }
    if (apiFilters.value[apiFilter]?.length) {
      filter.value = intersection(
        routerFilter
          .toString()
          .split(',')
          .map((str: string) => (isComplex ? +str : str.trim())),
        apiFilters.value[apiFilter].map((str: string) =>
          isComplex ? str[property] : str.trim(),
        ),
      ) as any
      return
    }
    filter.value = []
  }
  const onFilterChange = (
    val: string[],
    timeout: string,
    filter: string,
    apiFilter: string,
    isComplex = false,
    property?: any,
  ): void => {
    clearTimeout(meta.value[timeout])
    if (!val.length) {
      meta.value.queryFiltersDirtyFromUserInput = true
      repository.value[filter] = ''
      return
    }
    meta.value[timeout] = setTimeout(() => {
      meta.value.queryFiltersDirtyFromUserInput = true
      repository.value[filter] = intersection(
        val.map((str: string) => (isComplex ? +str : str.trim())),
        apiFilters.value[apiFilter].map((str: any) =>
          isComplex ? str[property] : str.trim(),
        ),
      ).join(',')
    }, meta.value.multiSelectTimeout)
  }
  return {
    onDateChange,
    onFilterSearchQuery,
    filterGetter,
    onFilterChange,
  }
}
