/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendgridEvent
 */
export interface SendgridEvent {
    /**
     * 
     * @type {string}
     * @memberof SendgridEvent
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SendgridEvent
     */
    event: SendgridEventEventEnum;
    /**
     * 
     * @type {string}
     * @memberof SendgridEvent
     */
    sgMessageId: string;
    /**
     * 
     * @type {number}
     * @memberof SendgridEvent
     */
    timestamp: number;
    /**
     * 
     * @type {string}
     * @memberof SendgridEvent
     */
    response?: string;
}

/**
* @export
* @enum {string}
*/
export enum SendgridEventEventEnum {
    Processed = 'processed',
    Deferred = 'deferred',
    Delivered = 'delivered',
    Open = 'open',
    Dropped = 'dropped',
    Bounce = 'bounce',
    Blocked = 'blocked',
    None = 'none'
}

export function SendgridEventFromJSON(json: any): SendgridEvent {
    return SendgridEventFromJSONTyped(json, false);
}

export function SendgridEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendgridEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'event': json['event'],
        'sgMessageId': json['sg_message_id'],
        'timestamp': json['timestamp'],
        'response': !exists(json, 'response') ? undefined : json['response'],
    };
}

export function SendgridEventToJSON(value?: SendgridEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'event': value.event,
        'sg_message_id': value.sgMessageId,
        'timestamp': value.timestamp,
        'response': value.response,
    };
}


