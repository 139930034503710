/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FreezeVirtualAccountDto,
    FreezeVirtualAccountDtoFromJSON,
    FreezeVirtualAccountDtoFromJSONTyped,
    FreezeVirtualAccountDtoToJSON,
    ObservationModel,
    ObservationModelFromJSON,
    ObservationModelFromJSONTyped,
    ObservationModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface EditBillDto
 */
export interface EditBillDto {
    /**
     * 
     * @type {Array<ObservationModel>}
     * @memberof EditBillDto
     */
    data: Array<ObservationModel>;
    /**
     * 
     * @type {string}
     * @memberof EditBillDto
     */
    comment?: string;
    /**
     * 
     * @type {Date}
     * @memberof EditBillDto
     */
    markedForPayment?: Date;
    /**
     * 
     * @type {FreezeVirtualAccountDto}
     * @memberof EditBillDto
     */
    freezeVirtualAccounts?: FreezeVirtualAccountDto;
    /**
     * 
     * @type {FreezeVirtualAccountDto}
     * @memberof EditBillDto
     */
    unFreezeVirtualAccounts?: FreezeVirtualAccountDto;
}

export function EditBillDtoFromJSON(json: any): EditBillDto {
    return EditBillDtoFromJSONTyped(json, false);
}

export function EditBillDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EditBillDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ObservationModelFromJSON)),
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'markedForPayment': !exists(json, 'markedForPayment') ? undefined : (new Date(json['markedForPayment'])),
        'freezeVirtualAccounts': !exists(json, 'freezeVirtualAccounts') ? undefined : FreezeVirtualAccountDtoFromJSON(json['freezeVirtualAccounts']),
        'unFreezeVirtualAccounts': !exists(json, 'unFreezeVirtualAccounts') ? undefined : FreezeVirtualAccountDtoFromJSON(json['unFreezeVirtualAccounts']),
    };
}

export function EditBillDtoToJSON(value?: EditBillDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ObservationModelToJSON)),
        'comment': value.comment,
        'markedForPayment': value.markedForPayment === undefined ? undefined : (value.markedForPayment.toISOString()),
        'freezeVirtualAccounts': FreezeVirtualAccountDtoToJSON(value.freezeVirtualAccounts),
        'unFreezeVirtualAccounts': FreezeVirtualAccountDtoToJSON(value.unFreezeVirtualAccounts),
    };
}


