/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarPropertyUseTypes
 */
export interface EnergyStarPropertyUseTypes {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseTypes
     */
    type: EnergyStarPropertyUseTypesTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseTypes
     */
    label: string;
}

/**
* @export
* @enum {string}
*/
export enum EnergyStarPropertyUseTypesTypeEnum {
    CollegeUniversity = 'collegeUniversity',
    DataCenter = 'dataCenter',
    Hospital = 'hospital',
    Hotel = 'hotel',
    K12School = 'k12School',
    MultifamilyHousing = 'multifamilyHousing',
    NonRefrigeratedWarehouse = 'nonRefrigeratedWarehouse',
    Office = 'office',
    Other = 'other',
    Parking = 'parking',
    ResidenceHallDormitory = 'residenceHallDormitory',
    Retail = 'retail',
    Supermarket = 'supermarket',
    WorshipFacility = 'worshipFacility',
    ConvenienceStoreWithGasStation = 'convenienceStoreWithGasStation',
    ConvenienceStoreWithoutGasStation = 'convenienceStoreWithoutGasStation'
}

export function EnergyStarPropertyUseTypesFromJSON(json: any): EnergyStarPropertyUseTypes {
    return EnergyStarPropertyUseTypesFromJSONTyped(json, false);
}

export function EnergyStarPropertyUseTypesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarPropertyUseTypes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'label': json['label'],
    };
}

export function EnergyStarPropertyUseTypesToJSON(value?: EnergyStarPropertyUseTypes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'label': value.label,
    };
}


