import {
  VirtualAccountsApi,
  VirtualAccountsControllerUpdateVirtualAccountRequest,
} from '@/SharedModule/Api/index'
import { ApiService } from '@/SharedModule/Services/service.api'

export class VirtualAccountsService extends ApiService {
  private virtualAccountsApi: VirtualAccountsApi
  constructor() {
    super()
    this.virtualAccountsApi = new VirtualAccountsApi(this.getBaseOptions())
  }
  public async putVirtualAccount(
    requestParameters: VirtualAccountsControllerUpdateVirtualAccountRequest,
  ): Promise<void> {
    return this.virtualAccountsApi.virtualAccountsControllerUpdateVirtualAccount(
      requestParameters,
    )
  }
}
