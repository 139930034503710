/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerBillPreview
 */
export interface CustomerBillPreview {
    /**
     * 
     * @type {number}
     * @memberof CustomerBillPreview
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerBillPreview
     */
    clientAccount: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerBillPreview
     */
    workflowState: string;
    /**
     * 
     * @type {Date}
     * @memberof CustomerBillPreview
     */
    dueDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerBillPreview
     */
    statementDate: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomerBillPreview
     */
    vendor: string;
    /**
     * 
     * @type {number}
     * @memberof CustomerBillPreview
     */
    amountDue: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerBillPreview
     */
    accountCodes: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof CustomerBillPreview
     */
    billingStartDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerBillPreview
     */
    billingEndDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof CustomerBillPreview
     */
    markedForPayment: Date;
    /**
     * 
     * @type {number}
     * @memberof CustomerBillPreview
     */
    currentCharges: number;
}

export function CustomerBillPreviewFromJSON(json: any): CustomerBillPreview {
    return CustomerBillPreviewFromJSONTyped(json, false);
}

export function CustomerBillPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerBillPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'clientAccount': json['clientAccount'],
        'workflowState': json['workflowState'],
        'dueDate': (new Date(json['dueDate'])),
        'statementDate': (new Date(json['statementDate'])),
        'vendor': json['vendor'],
        'amountDue': json['amountDue'],
        'accountCodes': json['accountCodes'],
        'billingStartDate': (new Date(json['billingStartDate'])),
        'billingEndDate': (new Date(json['billingEndDate'])),
        'markedForPayment': (new Date(json['markedForPayment'])),
        'currentCharges': json['currentCharges'],
    };
}

export function CustomerBillPreviewToJSON(value?: CustomerBillPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'clientAccount': value.clientAccount,
        'workflowState': value.workflowState,
        'dueDate': (value.dueDate.toISOString()),
        'statementDate': (value.statementDate.toISOString()),
        'vendor': value.vendor,
        'amountDue': value.amountDue,
        'accountCodes': value.accountCodes,
        'billingStartDate': (value.billingStartDate.toISOString()),
        'billingEndDate': (value.billingEndDate.toISOString()),
        'markedForPayment': (value.markedForPayment.toISOString()),
        'currentCharges': value.currentCharges,
    };
}


