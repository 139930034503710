/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    WeatherActiveDto,
    WeatherActiveDtoFromJSON,
    WeatherActiveDtoToJSON,
} from '../models';

export interface WeatherControllerToggleCustomerWeatherSetupRequest {
    customerId: number;
    weatherActiveDto: WeatherActiveDto;
}

/**
 * 
 */
export class WeatherApi extends runtime.BaseAPI {

    /**
     * Turn on/off weather_active for all locations of a given customer
     */
    async weatherControllerToggleCustomerWeatherSetupRaw(requestParameters: WeatherControllerToggleCustomerWeatherSetupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling weatherControllerToggleCustomerWeatherSetup.');
        }

        if (requestParameters.weatherActiveDto === null || requestParameters.weatherActiveDto === undefined) {
            throw new runtime.RequiredError('weatherActiveDto','Required parameter requestParameters.weatherActiveDto was null or undefined when calling weatherControllerToggleCustomerWeatherSetup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/weather/{customerId}/toggle-bulk-weather-setup`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WeatherActiveDtoToJSON(requestParameters.weatherActiveDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Turn on/off weather_active for all locations of a given customer
     */
    async weatherControllerToggleCustomerWeatherSetup(requestParameters: WeatherControllerToggleCustomerWeatherSetupRequest): Promise<void> {
        await this.weatherControllerToggleCustomerWeatherSetupRaw(requestParameters);
    }

}
