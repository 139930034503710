/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillFiltersDto,
    BillFiltersDtoFromJSON,
    BillFiltersDtoToJSON,
    BillParkDto,
    BillParkDtoFromJSON,
    BillParkDtoToJSON,
    BillResolveErrorsDto,
    BillResolveErrorsDtoFromJSON,
    BillResolveErrorsDtoToJSON,
    BillUnresolveErrorsDto,
    BillUnresolveErrorsDtoFromJSON,
    BillUnresolveErrorsDtoToJSON,
    BillsDashboardListDto,
    BillsDashboardListDtoFromJSON,
    BillsDashboardListDtoToJSON,
    BillsInProgressQueryDto,
    BillsInProgressQueryDtoFromJSON,
    BillsInProgressQueryDtoToJSON,
    BillsListDto,
    BillsListDtoFromJSON,
    BillsListDtoToJSON,
    BillsPreviewListDto,
    BillsPreviewListDtoFromJSON,
    BillsPreviewListDtoToJSON,
    BillsSummaryDto,
    BillsSummaryDtoFromJSON,
    BillsSummaryDtoToJSON,
    BulkActionDto,
    BulkActionDtoFromJSON,
    BulkActionDtoToJSON,
    BulkBillWorkflowStateModel,
    BulkBillWorkflowStateModelFromJSON,
    BulkBillWorkflowStateModelToJSON,
    ErrorReasonDto,
    ErrorReasonDtoFromJSON,
    ErrorReasonDtoToJSON,
    ErrorReasoningListDto,
    ErrorReasoningListDtoFromJSON,
    ErrorReasoningListDtoToJSON,
} from '../models';

export interface BillsControllerBulkActionsRequest {
    bulkActionDto: BulkActionDto;
}

export interface BillsControllerBulkAutoResolveErrorsRequest {
    billsListDto: BillsListDto;
}

export interface BillsControllerBulkResolveErrorsRequest {
    billResolveErrorsDto: BillResolveErrorsDto;
}

export interface BillsControllerBulkUnresolveErrorsRequest {
    billUnresolveErrorsDto: BillUnresolveErrorsDto;
}

export interface BillsControllerCacheGetBulkBillsWorkflowStateRequest {
    trackingId: string;
}

export interface BillsControllerCheckBillsInProcessRequest {
    billsInProgressQueryDto: BillsInProgressQueryDto;
}

export interface BillsControllerGetBillsDashboardPreviewRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<BillsControllerGetBillsDashboardPreviewExcludeEnum>;
    orderBy?: Array<BillsControllerGetBillsDashboardPreviewOrderByEnum>;
    customerIds?: Array<number>;
    batchId?: number;
    customerActive?: boolean;
}

export interface BillsControllerGetBillsErrorReasoningsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<BillsControllerGetBillsErrorReasoningsExcludeEnum>;
    orderBy?: Array<BillsControllerGetBillsErrorReasoningsOrderByEnum>;
    name?: string;
    createdAtMin?: Date;
    createdAtMax?: Date;
    updatedAtMin?: Date;
    updatedAtMax?: Date;
    isDefault?: boolean;
}

export interface BillsControllerGetBillsPreviewsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<BillsControllerGetBillsPreviewsExcludeEnum>;
    orderBy?: Array<string>;
    searchQuery?: string;
    customerIds?: Array<number>;
    userIds?: Array<number>;
    maxDueDate?: Date;
    maxTargetDate?: Date;
    errorTypes?: Array<string>;
    billTypes?: Array<BillsControllerGetBillsPreviewsBillTypesEnum>;
    vendorCodes?: Array<string>;
    workflowStates?: Array<BillsControllerGetBillsPreviewsWorkflowStatesEnum>;
    customerActive?: boolean;
    isParked?: boolean;
    noPayDate?: boolean;
}

export interface BillsControllerGetCustomerIdByBillIdRequest {
    billId: number;
}

export interface BillsControllerGetMyBillsPreviewsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<BillsControllerGetMyBillsPreviewsExcludeEnum>;
    orderBy?: Array<string>;
    searchQuery?: string;
    customerIds?: Array<number>;
    userIds?: Array<number>;
    maxDueDate?: Date;
    maxTargetDate?: Date;
    errorTypes?: Array<string>;
    billTypes?: Array<BillsControllerGetMyBillsPreviewsBillTypesEnum>;
    vendorCodes?: Array<string>;
    workflowStates?: Array<BillsControllerGetMyBillsPreviewsWorkflowStatesEnum>;
    customerActive?: boolean;
    isParked?: boolean;
    noPayDate?: boolean;
}

export interface BillsControllerParkBillRequest {
    billParkDto: BillParkDto;
}

export interface BillsControllerUnparkBillRequest {
    billParkDto: BillParkDto;
}

export interface BillsControllerUpdateErrorReasoningRequest {
    errorReasonDto: ErrorReasonDto;
}

/**
 * 
 */
export class BillsApi extends runtime.BaseAPI {

    /**
     * Bulk actions on bills
     */
    async billsControllerBulkActionsRaw(requestParameters: BillsControllerBulkActionsRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.bulkActionDto === null || requestParameters.bulkActionDto === undefined) {
            throw new runtime.RequiredError('bulkActionDto','Required parameter requestParameters.bulkActionDto was null or undefined when calling billsControllerBulkActions.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/bulk-actions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkActionDtoToJSON(requestParameters.bulkActionDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Bulk actions on bills
     */
    async billsControllerBulkActions(requestParameters: BillsControllerBulkActionsRequest): Promise<Array<number>> {
        const response = await this.billsControllerBulkActionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Resolve auto-resolvable errors for a given list of bills
     */
    async billsControllerBulkAutoResolveErrorsRaw(requestParameters: BillsControllerBulkAutoResolveErrorsRequest): Promise<runtime.ApiResponse<BulkBillWorkflowStateModel>> {
        if (requestParameters.billsListDto === null || requestParameters.billsListDto === undefined) {
            throw new runtime.RequiredError('billsListDto','Required parameter requestParameters.billsListDto was null or undefined when calling billsControllerBulkAutoResolveErrors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/bulk-autoresolve-errors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillsListDtoToJSON(requestParameters.billsListDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkBillWorkflowStateModelFromJSON(jsonValue));
    }

    /**
     * Resolve auto-resolvable errors for a given list of bills
     */
    async billsControllerBulkAutoResolveErrors(requestParameters: BillsControllerBulkAutoResolveErrorsRequest): Promise<BulkBillWorkflowStateModel> {
        const response = await this.billsControllerBulkAutoResolveErrorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Bulk resolve errors for a specific bill
     */
    async billsControllerBulkResolveErrorsRaw(requestParameters: BillsControllerBulkResolveErrorsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billResolveErrorsDto === null || requestParameters.billResolveErrorsDto === undefined) {
            throw new runtime.RequiredError('billResolveErrorsDto','Required parameter requestParameters.billResolveErrorsDto was null or undefined when calling billsControllerBulkResolveErrors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/bulk-resolve-errors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillResolveErrorsDtoToJSON(requestParameters.billResolveErrorsDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk resolve errors for a specific bill
     */
    async billsControllerBulkResolveErrors(requestParameters: BillsControllerBulkResolveErrorsRequest): Promise<void> {
        await this.billsControllerBulkResolveErrorsRaw(requestParameters);
    }

    /**
     * Bulk unresolve errors for a specific bill
     */
    async billsControllerBulkUnresolveErrorsRaw(requestParameters: BillsControllerBulkUnresolveErrorsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billUnresolveErrorsDto === null || requestParameters.billUnresolveErrorsDto === undefined) {
            throw new runtime.RequiredError('billUnresolveErrorsDto','Required parameter requestParameters.billUnresolveErrorsDto was null or undefined when calling billsControllerBulkUnresolveErrors.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/bulk-unresolve-errors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillUnresolveErrorsDtoToJSON(requestParameters.billUnresolveErrorsDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk unresolve errors for a specific bill
     */
    async billsControllerBulkUnresolveErrors(requestParameters: BillsControllerBulkUnresolveErrorsRequest): Promise<void> {
        await this.billsControllerBulkUnresolveErrorsRaw(requestParameters);
    }

    /**
     * Get bulk auto-resolve bill errors status
     */
    async billsControllerCacheGetBulkBillsWorkflowStateRaw(requestParameters: BillsControllerCacheGetBulkBillsWorkflowStateRequest): Promise<runtime.ApiResponse<BulkBillWorkflowStateModel>> {
        if (requestParameters.trackingId === null || requestParameters.trackingId === undefined) {
            throw new runtime.RequiredError('trackingId','Required parameter requestParameters.trackingId was null or undefined when calling billsControllerCacheGetBulkBillsWorkflowState.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/bulk-bills-workflow-state/{trackingId}`.replace(`{${"trackingId"}}`, encodeURIComponent(String(requestParameters.trackingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BulkBillWorkflowStateModelFromJSON(jsonValue));
    }

    /**
     * Get bulk auto-resolve bill errors status
     */
    async billsControllerCacheGetBulkBillsWorkflowState(requestParameters: BillsControllerCacheGetBulkBillsWorkflowStateRequest): Promise<BulkBillWorkflowStateModel> {
        const response = await this.billsControllerCacheGetBulkBillsWorkflowStateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get bill ids in process status
     */
    async billsControllerCheckBillsInProcessRaw(requestParameters: BillsControllerCheckBillsInProcessRequest): Promise<runtime.ApiResponse<Array<number>>> {
        if (requestParameters.billsInProgressQueryDto === null || requestParameters.billsInProgressQueryDto === undefined) {
            throw new runtime.RequiredError('billsInProgressQueryDto','Required parameter requestParameters.billsInProgressQueryDto was null or undefined when calling billsControllerCheckBillsInProcess.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/processing-list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillsInProgressQueryDtoToJSON(requestParameters.billsInProgressQueryDto),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get bill ids in process status
     */
    async billsControllerCheckBillsInProcess(requestParameters: BillsControllerCheckBillsInProcessRequest): Promise<Array<number>> {
        const response = await this.billsControllerCheckBillsInProcessRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get bill filters
     */
    async billsControllerGetBillFiltersRaw(): Promise<runtime.ApiResponse<BillFiltersDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillFiltersDtoFromJSON(jsonValue));
    }

    /**
     * Get bill filters
     */
    async billsControllerGetBillFilters(): Promise<BillFiltersDto> {
        const response = await this.billsControllerGetBillFiltersRaw();
        return await response.value();
    }

    /**
     * Returns a list of bills for the dashboard view - with meta filters and summary details.
     */
    async billsControllerGetBillsDashboardPreviewRaw(requestParameters: BillsControllerGetBillsDashboardPreviewRequest): Promise<runtime.ApiResponse<BillsDashboardListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.customerIds) {
            queryParameters['customerIds'] = requestParameters.customerIds;
        }

        if (requestParameters.batchId !== undefined) {
            queryParameters['batchId'] = requestParameters.batchId;
        }

        if (requestParameters.customerActive !== undefined) {
            queryParameters['customerActive'] = requestParameters.customerActive;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillsDashboardListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of bills for the dashboard view - with meta filters and summary details.
     */
    async billsControllerGetBillsDashboardPreview(requestParameters: BillsControllerGetBillsDashboardPreviewRequest): Promise<BillsDashboardListDto> {
        const response = await this.billsControllerGetBillsDashboardPreviewRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of bills error reasonings
     */
    async billsControllerGetBillsErrorReasoningsRaw(requestParameters: BillsControllerGetBillsErrorReasoningsRequest): Promise<runtime.ApiResponse<ErrorReasoningListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        if (requestParameters.createdAtMin !== undefined) {
            queryParameters['createdAtMin'] = (requestParameters.createdAtMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.createdAtMax !== undefined) {
            queryParameters['createdAtMax'] = (requestParameters.createdAtMax as any).toISOString().substr(0,10);
        }

        if (requestParameters.updatedAtMin !== undefined) {
            queryParameters['updatedAtMin'] = (requestParameters.updatedAtMin as any).toISOString().substr(0,10);
        }

        if (requestParameters.updatedAtMax !== undefined) {
            queryParameters['updatedAtMax'] = (requestParameters.updatedAtMax as any).toISOString().substr(0,10);
        }

        if (requestParameters.isDefault !== undefined) {
            queryParameters['isDefault'] = requestParameters.isDefault;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/error-reasonings`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorReasoningListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of bills error reasonings
     */
    async billsControllerGetBillsErrorReasonings(requestParameters: BillsControllerGetBillsErrorReasoningsRequest): Promise<ErrorReasoningListDto> {
        const response = await this.billsControllerGetBillsErrorReasoningsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of bills - with meta filters included.
     */
    async billsControllerGetBillsPreviewsRaw(requestParameters: BillsControllerGetBillsPreviewsRequest): Promise<runtime.ApiResponse<BillsPreviewListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.customerIds) {
            queryParameters['customerIds'] = requestParameters.customerIds;
        }

        if (requestParameters.userIds) {
            queryParameters['userIds'] = requestParameters.userIds;
        }

        if (requestParameters.maxDueDate !== undefined) {
            queryParameters['maxDueDate'] = (requestParameters.maxDueDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.maxTargetDate !== undefined) {
            queryParameters['maxTargetDate'] = (requestParameters.maxTargetDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.errorTypes) {
            queryParameters['errorTypes'] = requestParameters.errorTypes;
        }

        if (requestParameters.billTypes) {
            queryParameters['billTypes'] = requestParameters.billTypes;
        }

        if (requestParameters.vendorCodes) {
            queryParameters['vendorCodes'] = requestParameters.vendorCodes;
        }

        if (requestParameters.workflowStates) {
            queryParameters['workflowStates'] = requestParameters.workflowStates;
        }

        if (requestParameters.customerActive !== undefined) {
            queryParameters['customerActive'] = requestParameters.customerActive;
        }

        if (requestParameters.isParked !== undefined) {
            queryParameters['isParked'] = requestParameters.isParked;
        }

        if (requestParameters.noPayDate !== undefined) {
            queryParameters['noPayDate'] = requestParameters.noPayDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillsPreviewListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of bills - with meta filters included.
     */
    async billsControllerGetBillsPreviews(requestParameters: BillsControllerGetBillsPreviewsRequest): Promise<BillsPreviewListDto> {
        const response = await this.billsControllerGetBillsPreviewsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get customer id by bill id
     */
    async billsControllerGetCustomerIdByBillIdRaw(requestParameters: BillsControllerGetCustomerIdByBillIdRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling billsControllerGetCustomerIdByBillId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/{billId}/customer-id`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get customer id by bill id
     */
    async billsControllerGetCustomerIdByBillId(requestParameters: BillsControllerGetCustomerIdByBillIdRequest): Promise<number> {
        const response = await this.billsControllerGetCustomerIdByBillIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of bills assigned to the current user - with meta filters included.
     */
    async billsControllerGetMyBillsPreviewsRaw(requestParameters: BillsControllerGetMyBillsPreviewsRequest): Promise<runtime.ApiResponse<BillsPreviewListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.customerIds) {
            queryParameters['customerIds'] = requestParameters.customerIds;
        }

        if (requestParameters.userIds) {
            queryParameters['userIds'] = requestParameters.userIds;
        }

        if (requestParameters.maxDueDate !== undefined) {
            queryParameters['maxDueDate'] = (requestParameters.maxDueDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.maxTargetDate !== undefined) {
            queryParameters['maxTargetDate'] = (requestParameters.maxTargetDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.errorTypes) {
            queryParameters['errorTypes'] = requestParameters.errorTypes;
        }

        if (requestParameters.billTypes) {
            queryParameters['billTypes'] = requestParameters.billTypes;
        }

        if (requestParameters.vendorCodes) {
            queryParameters['vendorCodes'] = requestParameters.vendorCodes;
        }

        if (requestParameters.workflowStates) {
            queryParameters['workflowStates'] = requestParameters.workflowStates;
        }

        if (requestParameters.customerActive !== undefined) {
            queryParameters['customerActive'] = requestParameters.customerActive;
        }

        if (requestParameters.isParked !== undefined) {
            queryParameters['isParked'] = requestParameters.isParked;
        }

        if (requestParameters.noPayDate !== undefined) {
            queryParameters['noPayDate'] = requestParameters.noPayDate;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/my-bills`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillsPreviewListDtoFromJSON(jsonValue));
    }

    /**
     * Returns the list of bills assigned to the current user - with meta filters included.
     */
    async billsControllerGetMyBillsPreviews(requestParameters: BillsControllerGetMyBillsPreviewsRequest): Promise<BillsPreviewListDto> {
        const response = await this.billsControllerGetMyBillsPreviewsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the summary of bills assigned to the current user - assigned, parked, frozen, no pay date.
     */
    async billsControllerGetMyBillsSummaryRaw(): Promise<runtime.ApiResponse<BillsSummaryDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/my-bills/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillsSummaryDtoFromJSON(jsonValue));
    }

    /**
     * Returns the summary of bills assigned to the current user - assigned, parked, frozen, no pay date.
     */
    async billsControllerGetMyBillsSummary(): Promise<BillsSummaryDto> {
        const response = await this.billsControllerGetMyBillsSummaryRaw();
        return await response.value();
    }

    /**
     * Park a bill.
     */
    async billsControllerParkBillRaw(requestParameters: BillsControllerParkBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billParkDto === null || requestParameters.billParkDto === undefined) {
            throw new runtime.RequiredError('billParkDto','Required parameter requestParameters.billParkDto was null or undefined when calling billsControllerParkBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/my-bills/park`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillParkDtoToJSON(requestParameters.billParkDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Park a bill.
     */
    async billsControllerParkBill(requestParameters: BillsControllerParkBillRequest): Promise<void> {
        await this.billsControllerParkBillRaw(requestParameters);
    }

    /**
     * Reprioritize bills for user
     */
    async billsControllerReprioritizeRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/reprioritize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reprioritize bills for user
     */
    async billsControllerReprioritize(): Promise<void> {
        await this.billsControllerReprioritizeRaw();
    }

    /**
     * Unpark a bill.
     */
    async billsControllerUnparkBillRaw(requestParameters: BillsControllerUnparkBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.billParkDto === null || requestParameters.billParkDto === undefined) {
            throw new runtime.RequiredError('billParkDto','Required parameter requestParameters.billParkDto was null or undefined when calling billsControllerUnparkBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/my-bills/unpark`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillParkDtoToJSON(requestParameters.billParkDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unpark a bill.
     */
    async billsControllerUnparkBill(requestParameters: BillsControllerUnparkBillRequest): Promise<void> {
        await this.billsControllerUnparkBillRaw(requestParameters);
    }

    /**
     * Update reasoning for a resolved error
     */
    async billsControllerUpdateErrorReasoningRaw(requestParameters: BillsControllerUpdateErrorReasoningRequest): Promise<runtime.ApiResponse<ErrorReasonDto>> {
        if (requestParameters.errorReasonDto === null || requestParameters.errorReasonDto === undefined) {
            throw new runtime.RequiredError('errorReasonDto','Required parameter requestParameters.errorReasonDto was null or undefined when calling billsControllerUpdateErrorReasoning.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/bills/update-error-reasoning`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ErrorReasonDtoToJSON(requestParameters.errorReasonDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorReasonDtoFromJSON(jsonValue));
    }

    /**
     * Update reasoning for a resolved error
     */
    async billsControllerUpdateErrorReasoning(requestParameters: BillsControllerUpdateErrorReasoningRequest): Promise<ErrorReasonDto> {
        const response = await this.billsControllerUpdateErrorReasoningRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsDashboardPreviewExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsDashboardPreviewOrderByEnum {
    IntegrityCheckAsc = 'integrityCheck.asc',
    DataVerification1Asc = 'dataVerification1.asc',
    DataVerification2Asc = 'dataVerification2.asc',
    DataAudit1Asc = 'dataAudit1.asc',
    DataAudit2Asc = 'dataAudit2.asc',
    ErrorAsc = 'error.asc',
    ErrorsAsc = 'errors.asc',
    NameAsc = 'name.asc',
    DeletedAsc = 'deleted.asc',
    ProcessedAsc = 'processed.asc',
    CustomerAsc = 'customer.asc',
    IntegrityCheckDesc = 'integrityCheck.desc',
    DataVerification1Desc = 'dataVerification1.desc',
    DataVerification2Desc = 'dataVerification2.desc',
    DataAudit1Desc = 'dataAudit1.desc',
    DataAudit2Desc = 'dataAudit2.desc',
    ErrorDesc = 'error.desc',
    ErrorsDesc = 'errors.desc',
    NameDesc = 'name.desc',
    DeletedDesc = 'deleted.desc',
    ProcessedDesc = 'processed.desc',
    CustomerDesc = 'customer.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsErrorReasoningsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsErrorReasoningsOrderByEnum {
    IdAsc = 'id.asc',
    NameAsc = 'name.asc',
    CreatedAtAsc = 'createdAt.asc',
    UpdatedAtAsc = 'updatedAt.asc',
    IsDefaultAsc = 'isDefault.asc',
    IdDesc = 'id.desc',
    NameDesc = 'name.desc',
    CreatedAtDesc = 'createdAt.desc',
    UpdatedAtDesc = 'updatedAt.desc',
    IsDefaultDesc = 'isDefault.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsPreviewsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsPreviewsBillTypesEnum {
    Historical = 'historical',
    Setup = 'setup',
    Live = 'live',
    Free = 'free',
    Maintenance = 'maintenance',
    Special = 'special'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetBillsPreviewsWorkflowStatesEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetMyBillsPreviewsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetMyBillsPreviewsBillTypesEnum {
    Historical = 'historical',
    Setup = 'setup',
    Live = 'live',
    Free = 'free',
    Maintenance = 'maintenance',
    Special = 'special'
}
/**
    * @export
    * @enum {string}
    */
export enum BillsControllerGetMyBillsPreviewsWorkflowStatesEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}
