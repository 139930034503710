import { ElLoading } from 'element-plus'
import { loadedRequests } from '@/SharedModule/Services/service.request-interceptors'
import { ILoadingInstance } from 'element-plus/es/el-loading/src/loading.type'

let showingGeneralLoader: any = null
const jobs: any[] = []
let timeout: number
const showFullscreenLoader = (): ILoadingInstance => {
  return ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(255, 255, 255, 0.6)',
  })
}

const registerJob = (job: string): void => {
  // when navingating the browser history some responses get returned from the browser cache throwing the general loader's middleware logic off track
  // instead of debouncing the middleware's unregister logic we just register a complete job since we can track the progress with async
  jobs.push(job)
}
const unRegisterJob = (job: string): void => {
  jobs.splice(
    jobs.findIndex((currentJob) => currentJob === job),
    1,
  )
}

const showGeneralLoader = (): void => {
  if (!showingGeneralLoader) {
    loadedRequests.value.splice(0)
    clearTimeout(timeout)
    showingGeneralLoader = showFullscreenLoader()
    document
      .getElementsByTagName('body')[0]
      .classList.remove('finishedLoadingData')
    document.getElementsByTagName('body')[0].classList.add('startedLoadedData')
    const interval = setInterval(() => {
      if (!loadedRequests.value.length && !jobs.length) {
        clearInterval(interval)
        showingGeneralLoader.close()
        showingGeneralLoader = null
        timeout = setTimeout(() => {
          document
            .getElementsByTagName('body')[0]
            .classList.remove('startedLoadedData')
          document
            .getElementsByTagName('body')[0]
            .classList.add('finishedLoadingData')
        }, 300)
      }
    }, 50)
  }
}

export { showGeneralLoader, showFullscreenLoader, registerJob, unRegisterJob }
