/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationChangesDto
 */
export interface LocationChangesDto {
    /**
     * 
     * @type {string}
     * @memberof LocationChangesDto
     */
    action: LocationChangesDtoActionEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationChangesDto
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof LocationChangesDto
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof LocationChangesDto
     */
    shouldGeocode: boolean;
}

/**
* @export
* @enum {string}
*/
export enum LocationChangesDtoActionEnum {
    Update = 'Update',
    Create = 'Create'
}

export function LocationChangesDtoFromJSON(json: any): LocationChangesDto {
    return LocationChangesDtoFromJSONTyped(json, false);
}

export function LocationChangesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationChangesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': json['action'],
        'address': json['address'],
        'name': json['name'],
        'shouldGeocode': json['shouldGeocode'],
    };
}

export function LocationChangesDtoToJSON(value?: LocationChangesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': value.action,
        'address': value.address,
        'name': value.name,
        'shouldGeocode': value.shouldGeocode,
    };
}


