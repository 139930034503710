/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarGrossFloorArea,
    EnergyStarGrossFloorAreaFromJSON,
    EnergyStarGrossFloorAreaFromJSONTyped,
    EnergyStarGrossFloorAreaToJSON,
    EnergyStartPropertyAddressModel,
    EnergyStartPropertyAddressModelFromJSON,
    EnergyStartPropertyAddressModelFromJSONTyped,
    EnergyStartPropertyAddressModelToJSON,
    IrrigatedAreaModel,
    IrrigatedAreaModelFromJSON,
    IrrigatedAreaModelFromJSONTyped,
    IrrigatedAreaModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarPropertyModel
 */
export interface EnergyStarPropertyModel {
    /**
     * 
     * @type {number}
     * @memberof EnergyStarPropertyModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyModel
     */
    primaryFunction: string;
    /**
     * 
     * @type {EnergyStartPropertyAddressModel}
     * @memberof EnergyStarPropertyModel
     */
    address: EnergyStartPropertyAddressModel;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarPropertyModel
     */
    yearBuilt: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyModel
     */
    constructionStatus: string;
    /**
     * 
     * @type {EnergyStarGrossFloorArea}
     * @memberof EnergyStarPropertyModel
     */
    grossFloorArea: EnergyStarGrossFloorArea;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarPropertyModel
     */
    occupancyPercentage: number;
    /**
     * 
     * @type {IrrigatedAreaModel}
     * @memberof EnergyStarPropertyModel
     */
    irrigatedArea: IrrigatedAreaModel;
    /**
     * 
     * @type {boolean}
     * @memberof EnergyStarPropertyModel
     */
    isFederalProperty: boolean;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarPropertyModel
     */
    numberOfBuildings: number;
}

export function EnergyStarPropertyModelFromJSON(json: any): EnergyStarPropertyModel {
    return EnergyStarPropertyModelFromJSONTyped(json, false);
}

export function EnergyStarPropertyModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarPropertyModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'primaryFunction': json['primaryFunction'],
        'address': EnergyStartPropertyAddressModelFromJSON(json['address']),
        'yearBuilt': json['yearBuilt'],
        'constructionStatus': json['constructionStatus'],
        'grossFloorArea': EnergyStarGrossFloorAreaFromJSON(json['grossFloorArea']),
        'occupancyPercentage': json['occupancyPercentage'],
        'irrigatedArea': IrrigatedAreaModelFromJSON(json['irrigatedArea']),
        'isFederalProperty': json['isFederalProperty'],
        'numberOfBuildings': json['numberOfBuildings'],
    };
}

export function EnergyStarPropertyModelToJSON(value?: EnergyStarPropertyModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'primaryFunction': value.primaryFunction,
        'address': EnergyStartPropertyAddressModelToJSON(value.address),
        'yearBuilt': value.yearBuilt,
        'constructionStatus': value.constructionStatus,
        'grossFloorArea': EnergyStarGrossFloorAreaToJSON(value.grossFloorArea),
        'occupancyPercentage': value.occupancyPercentage,
        'irrigatedArea': IrrigatedAreaModelToJSON(value.irrigatedArea),
        'isFederalProperty': value.isFederalProperty,
        'numberOfBuildings': value.numberOfBuildings,
    };
}


