import { RouteRecordRaw } from 'vue-router'
import MyBillsView from '@/Modules/Bills/views/view.page.my-bills.vue'
import BillsView from '@/Modules/Bills/views/view.page.bills.vue'
import BillsDashboard from '@/Modules/Bills/views/view.bills-dashboard.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/bills/my',
    name: 'bills.my',
    component: MyBillsView,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'My Bills',
      },
    },
  },
  {
    path: '/bills/find',
    name: 'bills.find',
    component: BillsView,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Find Bills',
      },
    },
  },
  {
    path: '/bills/dashboard',
    name: 'bills.dashboard',
    component: BillsDashboard,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Bills Dashboard',
      },
    },
  },
]

export default routes
