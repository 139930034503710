/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ESPropertyUseDetailsDto,
    ESPropertyUseDetailsDtoFromJSON,
    ESPropertyUseDetailsDtoFromJSONTyped,
    ESPropertyUseDetailsDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ESPropertyUseDto
 */
export interface ESPropertyUseDto {
    /**
     * 
     * @type {number}
     * @memberof ESPropertyUseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDto
     */
    name: string;
    /**
     * 
     * @type {Array<ESPropertyUseDetailsDto>}
     * @memberof ESPropertyUseDto
     */
    useDetails: Array<ESPropertyUseDetailsDto>;
}

export function ESPropertyUseDtoFromJSON(json: any): ESPropertyUseDto {
    return ESPropertyUseDtoFromJSONTyped(json, false);
}

export function ESPropertyUseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESPropertyUseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'name': json['name'],
        'useDetails': ((json['useDetails'] as Array<any>).map(ESPropertyUseDetailsDtoFromJSON)),
    };
}

export function ESPropertyUseDtoToJSON(value?: ESPropertyUseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'label': value.label,
        'name': value.name,
        'useDetails': ((value.useDetails as Array<any>).map(ESPropertyUseDetailsDtoToJSON)),
    };
}


