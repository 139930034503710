/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ErrorReasonDto
 */
export interface ErrorReasonDto {
    /**
     * 
     * @type {number}
     * @memberof ErrorReasonDto
     */
    billErrorId: number;
    /**
     * 
     * @type {number}
     * @memberof ErrorReasonDto
     */
    reasoningId: number;
}

export function ErrorReasonDtoFromJSON(json: any): ErrorReasonDto {
    return ErrorReasonDtoFromJSONTyped(json, false);
}

export function ErrorReasonDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorReasonDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billErrorId': json['billErrorId'],
        'reasoningId': json['reasoningId'],
    };
}

export function ErrorReasonDtoToJSON(value?: ErrorReasonDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billErrorId': value.billErrorId,
        'reasoningId': value.reasoningId,
    };
}


