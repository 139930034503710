import { PaymentsService } from '@/Modules/Payments/services/service.customer-payment-settings'
import {
  PaymentErrorsEmailDelivery,
  PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest,
} from '@/SharedModule/Api'
import _ from 'lodash'
import {
  PaymentsActiveTabEnum,
  PaymentsDialogStates,
} from '@/Modules/Payments/enums/enums.dialog'
import { ElMessage } from 'element-plus'

const paymentsService = new PaymentsService()

interface ErrorDeliveryForm {
  newEmail: string
  emails: string[]
  errors: string[]
  id?: number
}
export interface IState {
  errorDeliveriesArr: PaymentErrorsEmailDelivery[]
  errorDeliveryForm: ErrorDeliveryForm
}
const errorDeliveryFormDefault: ErrorDeliveryForm = {
  newEmail: '',
  emails: [],
  errors: [],
  id: undefined,
}
const getDefaultState = (): IState => ({
  errorDeliveriesArr: [],
  errorDeliveryForm: {
    ...errorDeliveryFormDefault,
  },
})
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    errorDeliveriesArr: (
      state: IState,
      getters: any,
      rootState: any,
      rootGetters: any,
    ): PaymentErrorsEmailDelivery[] => {
      return state.errorDeliveriesArr.map(
        (emailDelivery: PaymentErrorsEmailDelivery): any => ({
          ...emailDelivery,
          emails: emailDelivery.emails.join(', '),
          errors: rootGetters['resources/billErrEvents']
            .map((event: any) =>
              emailDelivery.errors.includes(event.type.toString())
                ? event.description
                : undefined,
            )
            .filter((ev: any) => !_.isNil(ev))
            .join(', '),
        }),
      )
    },
    errorDeliveryForm: (state: IState): ErrorDeliveryForm =>
      state.errorDeliveryForm,
  },
  mutations: {
    setErrorDeliveries(
      state: IState,
      errorDeliveriesArr: PaymentErrorsEmailDelivery[],
    ): void {
      state.errorDeliveriesArr = errorDeliveriesArr
    },
    setNewEmail(state: IState, newEmail: string): void {
      state.errorDeliveryForm.newEmail = newEmail
    },
    setEmails(state: IState): void {
      if (state.errorDeliveryForm.newEmail !== '') {
        state.errorDeliveryForm.emails = [
          ...state.errorDeliveryForm.emails,
          state.errorDeliveryForm.newEmail,
        ]
        state.errorDeliveryForm.newEmail = ''
      }
    },
    deleteEmails(state: IState, tag: string): void {
      state.errorDeliveryForm.emails.splice(
        state.errorDeliveryForm.emails.indexOf(tag),
        1,
      )
    },
    setErrors(state: IState, errors: any): void {
      state.errorDeliveryForm.errors = errors
    },
    setForm(state: IState, id: number): void {
      const errDeliveries = state?.errorDeliveriesArr.find(
        (errorDelivery) => +errorDelivery.id === +id,
      )
      state.errorDeliveryForm = {
        ...state.errorDeliveryForm,
        emails: errDeliveries?.emails || [],
        errors: errDeliveries?.errors || [],
        id,
      }
    },
    clearForm(state: IState): void {
      state.errorDeliveryForm = { ...errorDeliveryFormDefault }
    },
    clearState(state: IState): void {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getErrorDeliveries(
      { commit }: any,
      requestParams: PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest,
    ): Promise<void> {
      try {
        const { results } = await paymentsService.paymentsGetErrorDeliveries(
          requestParams,
        )
        commit('setErrorDeliveries', results)
      } catch (e) {
        console.log(e)
      }
    },
    async postErrorDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          paymentErrorsEmailDeliveryDto: {
            ...state.errorDeliveryForm,
            errors: [
              ...state.errorDeliveryForm.errors.map((error: string) => +error),
            ],
            newEmail: undefined,
          },
        }
        await paymentsService.paymentsPostErrorDeliveries(requestParams)
        dispatch('getErrorDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch('returnToListPage', {
          dialogState: PaymentsDialogStates.root,
          activeTab: PaymentsActiveTabEnum.ErrorsTab,
        })
        commit('clearForm')
        ElMessage({
          message: 'Delivery settings configured successfully.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Delivery settings error.',
          type: 'error',
        })
      }
    },
    async updateErrorDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          paymentErrorsEmailDeliveryId: state.errorDeliveryForm.id,
          paymentErrorsEmailDeliveryDto: {
            ...state.errorDeliveryForm,
            errors: [
              ...state.errorDeliveryForm.errors.map((error: string) => +error),
            ],
            newEmail: undefined,
          },
        }
        await paymentsService.paymentsUpdateErrorDeliveries(requestParams)
        dispatch('getErrorDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch('returnToListPage', {
          dialogState: PaymentsDialogStates.root,
          activeTab: PaymentsActiveTabEnum.ErrorsTab,
        })
        commit('clearForm')
        ElMessage({
          message: 'Error Delivery settings configured successfully.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Error Delivery settings configured error.',
          type: 'error',
        })
      }
    },
    async deleteErrorDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          paymentErrorsEmailDeliveryId: state.errorDeliveryForm.id,
        }
        await paymentsService.paymentsDeleteErrorDeliveries(requestParams)
        dispatch('getErrorDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch('returnToListPage', {
          dialogState: PaymentsDialogStates.root,
          activeTab: PaymentsActiveTabEnum.ErrorsTab,
        })
        commit('clearForm')
        ElMessage({
          message: 'Error Delivery deleted.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Error Delivery deletion failed.',
          type: 'error',
        })
      }
    },
    returnToListPage(
      { commit }: any,
      { dialogState, activeTab }: { dialogState: string; activeTab: string },
    ): void {
      commit('abstract/setDialogState', dialogState, {
        root: true,
      })
      commit('abstract/setActiveTab', activeTab, {
        root: true,
      })
    },
  },
}
