/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnprocessedBillPreview
 */
export interface UnprocessedBillPreview {
    /**
     * 
     * @type {number}
     * @memberof UnprocessedBillPreview
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof UnprocessedBillPreview
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof UnprocessedBillPreview
     */
    fdgId: string;
    /**
     * 
     * @type {string}
     * @memberof UnprocessedBillPreview
     */
    prettyName: string;
    /**
     * 
     * @type {number}
     * @memberof UnprocessedBillPreview
     */
    batchId: number;
    /**
     * 
     * @type {string}
     * @memberof UnprocessedBillPreview
     */
    batchType: UnprocessedBillPreviewBatchTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof UnprocessedBillPreview
     */
    processedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof UnprocessedBillPreview
     */
    batchFileId: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UnprocessedBillPreview
     */
    accountCodes: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum UnprocessedBillPreviewBatchTypeEnum {
    Live = 'live',
    Setup = 'setup',
    Maint = 'maint',
    Hist = 'hist',
    Free = 'free'
}

export function UnprocessedBillPreviewFromJSON(json: any): UnprocessedBillPreview {
    return UnprocessedBillPreviewFromJSONTyped(json, false);
}

export function UnprocessedBillPreviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnprocessedBillPreview {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'fdgId': json['fdgId'],
        'prettyName': json['prettyName'],
        'batchId': json['batchId'],
        'batchType': json['batchType'],
        'processedAt': (new Date(json['processedAt'])),
        'batchFileId': json['batchFileId'],
        'accountCodes': json['accountCodes'],
    };
}

export function UnprocessedBillPreviewToJSON(value?: UnprocessedBillPreview | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'fdgId': value.fdgId,
        'prettyName': value.prettyName,
        'batchId': value.batchId,
        'batchType': value.batchType,
        'processedAt': (value.processedAt.toISOString()),
        'batchFileId': value.batchFileId,
        'accountCodes': value.accountCodes,
    };
}


