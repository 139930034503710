/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnalyzeBillDto
 */
export interface AnalyzeBillDto {
    /**
     * 
     * @type {number}
     * @memberof AnalyzeBillDto
     */
    billMetaId: number;
}

export function AnalyzeBillDtoFromJSON(json: any): AnalyzeBillDto {
    return AnalyzeBillDtoFromJSONTyped(json, false);
}

export function AnalyzeBillDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyzeBillDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billMetaId': json['billMetaId'],
    };
}

export function AnalyzeBillDtoToJSON(value?: AnalyzeBillDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billMetaId': value.billMetaId,
    };
}


