/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MonthlyFeedCalculation
 */
export interface MonthlyFeedCalculation {
    /**
     * 
     * @type {string}
     * @memberof MonthlyFeedCalculation
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyFeedCalculation
     */
    billValue: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyFeedCalculation
     */
    proRatedValue: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyFeedCalculation
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof MonthlyFeedCalculation
     */
    units?: string;
}

export function MonthlyFeedCalculationFromJSON(json: any): MonthlyFeedCalculation {
    return MonthlyFeedCalculationFromJSONTyped(json, false);
}

export function MonthlyFeedCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyFeedCalculation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'billValue': json['billValue'],
        'proRatedValue': json['proRatedValue'],
        'field': !exists(json, 'field') ? undefined : json['field'],
        'units': !exists(json, 'units') ? undefined : json['units'],
    };
}

export function MonthlyFeedCalculationToJSON(value?: MonthlyFeedCalculation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'billValue': value.billValue,
        'proRatedValue': value.proRatedValue,
        'field': value.field,
        'units': value.units,
    };
}


