/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserCustomerPermission,
    UserCustomerPermissionFromJSON,
    UserCustomerPermissionFromJSONTyped,
    UserCustomerPermissionToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserCustomerPermissionListDto
 */
export interface UserCustomerPermissionListDto {
    /**
     * 
     * @type {Array<UserCustomerPermission>}
     * @memberof UserCustomerPermissionListDto
     */
    results: Array<UserCustomerPermission>;
}

export function UserCustomerPermissionListDtoFromJSON(json: any): UserCustomerPermissionListDto {
    return UserCustomerPermissionListDtoFromJSONTyped(json, false);
}

export function UserCustomerPermissionListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCustomerPermissionListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(UserCustomerPermissionFromJSON)),
    };
}

export function UserCustomerPermissionListDtoToJSON(value?: UserCustomerPermissionListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(UserCustomerPermissionToJSON)),
    };
}


