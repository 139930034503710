export const currency = (
  value: number,
  options: Intl.NumberFormatOptions = {},
): string => {
  value = +value === 0 ? 0 : value
  const isNegative = String(value).charAt(0) === '-'

  if (isNegative) {
    value = +String(value).slice(1)
  }

  const result = Intl.NumberFormat(
    'en-US',
    Object.assign(
      {
        style: 'currency',
        currency: 'USD',
      },
      options,
    ),
  ).format(value)
  return isNegative ? `-${result}` : result
}
