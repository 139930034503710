import { PaymentsService } from '@/Modules/Payments/services/service.customer-payment-settings'
import {
  PaymentEmailDelivery,
  PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest,
} from '@/SharedModule/Api'
import {
  PaymentsActiveTabEnum,
  PaymentsDialogStates,
} from '@/Modules/Payments/enums/enums.dialog'
import { ElMessage } from 'element-plus'

const paymentsService = new PaymentsService()
export interface IState {
  emailDeliveriesArr: PaymentEmailDelivery[]
  newEmailDelivery: EmailDelivery
}
interface EmailDelivery {
  deliveryBy: string
  emails: string[]
  paymentFileFormat: string
  email: string
  id?: number
}
const newEmailDelivery: EmailDelivery = {
  email: '',
  deliveryBy: 'email',
  emails: [],
  paymentFileFormat: '',
  id: undefined,
}
const getDefaultState = (): IState => ({
  emailDeliveriesArr: [],
  newEmailDelivery: {
    ...newEmailDelivery,
  },
})
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    emailDeliveriesArr: (state: IState): PaymentEmailDelivery[] =>
      state.emailDeliveriesArr.map((emailDelivery: any) => ({
        ...emailDelivery,
        emails: emailDelivery.emails.join(', '),
      })),
    newEmailDelivery: (state: IState): EmailDelivery => state.newEmailDelivery,
    deliveryBy: (state: IState): string => state.newEmailDelivery.deliveryBy,
  },
  mutations: {
    setEmailDeliveries(
      state: IState,
      emailDeliveries: PaymentEmailDelivery[],
    ): void {
      state.emailDeliveriesArr = emailDeliveries
    },
    setNewEmail(state: IState, email: string): void {
      state.newEmailDelivery.email = email
    },
    setEmails(state: IState): void {
      if (state.newEmailDelivery.email !== '') {
        state.newEmailDelivery.emails = [
          ...state.newEmailDelivery.emails,
          state.newEmailDelivery.email,
        ]
        state.newEmailDelivery.email = ''
      }
    },
    deleteEmails(state: IState, tag: string): void {
      state.newEmailDelivery.emails.splice(
        state.newEmailDelivery.emails.indexOf(tag),
        1,
      )
    },
    setDeliveryBy(state: IState, deliveryBy: string): void {
      state.newEmailDelivery.deliveryBy = deliveryBy
    },
    setPaymentFileFormat(state: IState, paymentFileFormat: string): void {
      state.newEmailDelivery.paymentFileFormat = paymentFileFormat
    },
    setForm(state: IState, id: number): void {
      const emailDeliveries = state?.emailDeliveriesArr.find(
        (emailDelivery) => +emailDelivery.id === +id,
      )
      state.newEmailDelivery = {
        ...state.newEmailDelivery,
        emails: emailDeliveries?.emails || [],
        paymentFileFormat: emailDeliveries?.paymentFileFormat || '',
        id,
      }
    },
    clearDeliveryForm(state: IState): void {
      state.newEmailDelivery = { ...newEmailDelivery }
    },
    clearState(state: IState): void {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getEmailDeliveries(
      { commit }: any,
      requestParams: PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest,
    ): Promise<void> {
      try {
        const { results } = await paymentsService.paymentsGetEmailDeliveries(
          requestParams,
        )
        commit('setEmailDeliveries', results)
      } catch (e) {
        console.log(e)
      }
    },
    async createEmailDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          paymentEmailDeliveryDto: {
            ...state.newEmailDelivery,
            deliveryBy: undefined,
            email: undefined,
          },
        }
        await paymentsService.paymentsCreateEmailDeliveries(requestParams)
        dispatch('getEmailDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch(
          'error-deliveries/returnToListPage',
          {
            dialogState: PaymentsDialogStates.root,
            activeTab: PaymentsActiveTabEnum.PaymentsTab,
          },
          { root: true },
        )
        commit('clearDeliveryForm')
        ElMessage({
          message: 'Email Delivery settings configured successfully.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Email Delivery settings configured error.',
          type: 'error',
        })
      }
    },
    async updateEmailDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          emailDeliveryId: state.newEmailDelivery.id,
          paymentEmailDeliveryDto: {
            ...state.newEmailDelivery,
            deliveryBy: undefined,
            email: undefined,
          },
        }
        await paymentsService.paymentsUpdateEmailDeliveries(requestParams)
        dispatch('getEmailDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch(
          'error-deliveries/returnToListPage',
          {
            dialogState: PaymentsDialogStates.root,
            activeTab: PaymentsActiveTabEnum.PaymentsTab,
          },
          { root: true },
        )
        commit('clearDeliveryForm')
        ElMessage({
          message: 'Email Delivery settings updated successfully.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Email Delivery settings update failed.',
          type: 'success',
        })
      }
    },
    async deleteEmailDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          emailDeliveryId: state.newEmailDelivery.id,
        }
        await paymentsService.paymentsDeleteEmailDeliveries(requestParams)
        dispatch('getEmailDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch(
          'error-deliveries/returnToListPage',
          {
            dialogState: PaymentsDialogStates.root,
            activeTab: PaymentsActiveTabEnum.PaymentsTab,
          },
          { root: true },
        )
        commit('clearDeliveryForm')
        ElMessage({
          message: 'Email Delivery deleted.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'Email Delivery settings deletion failed.',
          type: 'success',
        })
      }
    },
  },
}
