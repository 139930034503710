import { PaymentsService } from '@/Modules/Payments/services/service.customer-payment-settings'
import {
  PaymentsControllerGetSFTPDeliveriesRequest,
  SFTPDeliveryDtoResponse,
} from '@/SharedModule/Api'
import {
  PaymentsActiveTabEnum,
  PaymentsDialogStates,
} from '@/Modules/Payments/enums/enums.dialog'
import { SftpService } from '@/Modules/Customers/services/service.sftp'
import { ElMessage } from 'element-plus'

const paymentsService = new PaymentsService()
const sftpServices = new SftpService()
export interface IState {
  sftpDeliveriesArr: SFTPDeliveryDtoResponse[]
  sftpDeliveriesForm: SFTPDelivery
  sshKey: string
  isValid: boolean
  checkedConnectionOnce: boolean
  loadingConnectionTest: boolean
  formDirty: boolean
}
interface SFTPDelivery {
  host: string
  folder: string
  id?: number
  paymentFileFormat?: string
  paymentDeliveryId?: number
}
const sftpDeliveriesFormDefault: SFTPDelivery = {
  host: '',
  folder: '',
  paymentFileFormat: '',
}
const getDefaultState = (): IState => ({
  sftpDeliveriesArr: [],
  sftpDeliveriesForm: {
    ...sftpDeliveriesFormDefault,
  },
  sshKey: '',
  checkedConnectionOnce: false,
  loadingConnectionTest: false,
  isValid: false,
  formDirty: false,
})
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    sftpDeliveriesArr: (state: IState): SFTPDeliveryDtoResponse[] =>
      state.sftpDeliveriesArr,
    sftpDeliveriesForm: (state: IState): SFTPDelivery =>
      state.sftpDeliveriesForm,
    sshKey: (state: IState): string => state.sshKey,
    checkedConnectionOnce: (state: IState): boolean =>
      state.checkedConnectionOnce,
    loadingConnectionTest: (state: IState): boolean =>
      state.loadingConnectionTest,
    formDirty: (state: IState): boolean => state.formDirty,
    isValid: (state: IState): boolean => state.isValid,
  },
  mutations: {
    setSFTPDeliveries(
      state: IState,
      sftpDeliveriesArr: SFTPDeliveryDtoResponse[],
    ): void {
      state.sftpDeliveriesArr = sftpDeliveriesArr
    },
    setHost(state: IState, host: string): void {
      state.sftpDeliveriesForm.host = host
    },
    setTestValidity(state: IState, isValid: boolean): void {
      state.isValid = isValid
    },
    setFormDirty(state: IState, formDirty: boolean): void {
      state.formDirty = formDirty
    },
    setFolder(state: IState, folder: string): void {
      state.sftpDeliveriesForm.folder = folder
    },
    setForm(state: IState, paymentDeliveryId: number): void {
      const sftpDelivery = state?.sftpDeliveriesArr.find(
        (sftpDv: any) => +sftpDv.paymentDeliveryId === +paymentDeliveryId,
      )
      state.sftpDeliveriesForm = {
        ...state.sftpDeliveriesForm,
        host: sftpDelivery?.host || '',
        folder: sftpDelivery?.folder || '',
        paymentFileFormat: sftpDelivery?.paymentFileFormat || '',
        paymentDeliveryId: sftpDelivery?.paymentDeliveryId || 0,
      }
    },
    setPaymentFileFormat(state: IState, paymentFileFormat: string): void {
      state.sftpDeliveriesForm.paymentFileFormat = paymentFileFormat
    },
    setSSHKey(state: IState, sshKey: string): void {
      state.sshKey = sshKey
    },
    setCheckedConnectionOnce(
      state: IState,
      checkedConnectionOnce: boolean,
    ): void {
      state.checkedConnectionOnce = checkedConnectionOnce
    },
    setLoadingConnectionTest(
      state: IState,
      loadingConnectionTest: boolean,
    ): void {
      state.loadingConnectionTest = loadingConnectionTest
    },
    clearSFTPForm(state: IState): void {
      state.sftpDeliveriesForm = { ...sftpDeliveriesFormDefault }
    },
    clearState(state: IState): void {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async getSFTPDeliveries(
      { commit }: any,
      requestParams: PaymentsControllerGetSFTPDeliveriesRequest,
    ): Promise<void> {
      try {
        const { results } = await paymentsService.paymentsGetSFTPDeliveries(
          requestParams,
        )
        commit('setSFTPDeliveries', results)
      } catch (e) {
        console.log(e)
      }
    },
    async createSFTPDeliveries(
      { dispatch, commit, state, rootState }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          sFTPDeliveryDto: {
            ...state.sftpDeliveriesForm,
            paymentFileFormat: state.sftpDeliveriesForm.paymentFileFormat,
          },
        }
        await paymentsService.paymentsCreateSFTPDeliveries(requestParams)
        dispatch('getSFTPDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch(
          'error-deliveries/returnToListPage',
          {
            dialogState: PaymentsDialogStates.root,
            activeTab: PaymentsActiveTabEnum.PaymentsTab,
          },
          { root: true },
        )
        commit('clearSFTPForm')
        ElMessage({
          message: 'SFTP Delivery settings configured successfully.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'SFTP Delivery settings configured error.',
          type: 'error',
        })
      }
    },
    async updateSFTPDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          sftpDeliveryId: state.sftpDeliveriesForm.paymentDeliveryId,
          sFTPDeliveryDto: {
            ...state.sftpDeliveriesForm,
            paymentFileFormat: state.sftpDeliveriesForm.paymentFileFormat,
          },
        }
        await paymentsService.paymentsUpdateSFTPDeliveries(requestParams)
        dispatch('getSFTPDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch(
          'error-deliveries/returnToListPage',
          {
            dialogState: PaymentsDialogStates.root,
            activeTab: PaymentsActiveTabEnum.PaymentsTab,
          },
          { root: true },
        )
        commit('clearSFTPForm')
        ElMessage({
          message: 'SFTP Delivery settings configured successfully.',
          type: 'success',
        })
      } catch (e) {
        console.log(e)
        ElMessage({
          message: 'SFTP Delivery settings configured error.',
          type: 'error',
        })
      }
    },
    async deleteSFTPDeliveries(
      { dispatch, commit, state }: any,
      customerId: number,
    ): Promise<void> {
      try {
        const requestParams = {
          customerId,
          sftpDeliveryId: state.sftpDeliveriesForm.paymentDeliveryId,
        }
        await paymentsService.paymentsDeleteSFTPDeliveries(requestParams)
        dispatch('getSFTPDeliveries', {
          customerId: requestParams.customerId,
          limit: 100,
        })
        dispatch(
          'error-deliveries/returnToListPage',
          {
            dialogState: PaymentsDialogStates.root,
            activeTab: PaymentsActiveTabEnum.PaymentsTab,
          },
          { root: true },
        )
        commit('clearSFTPForm')
      } catch (e) {
        console.log(e)
      }
    },
    async getSSHKey({ commit }: any): Promise<void> {
      const response = await sftpServices.getSSHKey()
      commit('setSSHKey', response.sshPublicKey)
    },
    async testConnection({ commit, state }: any): Promise<void> {
      const { host, folder }: { host: string; folder: string } =
        state.sftpDeliveriesForm
      commit('setTestValidity', true)
      if (!state.checkedConnectionOnce) {
        commit('setCheckedConnectionOnce', true)
      }
      try {
        const response = await sftpServices.testSftpConnection({
          testSftpConnectionDto: { host, folder },
        })
        commit('setTestValidity', response.isValid)
        if (!response.isValid) {
          commit('setFormDirty', true)
        }
      } catch {
        commit('setFormDirty', true)
      }
    },
  },
}
