/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarPropertyUseOptionalDefault
 */
export interface EnergyStarPropertyUseOptionalDefault {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseOptionalDefault
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseOptionalDefault
     */
    staticValue: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseOptionalDefault
     */
    multiplierValue: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseOptionalDefault
     */
    multiplierAttribute: string;
}

export function EnergyStarPropertyUseOptionalDefaultFromJSON(json: any): EnergyStarPropertyUseOptionalDefault {
    return EnergyStarPropertyUseOptionalDefaultFromJSONTyped(json, false);
}

export function EnergyStarPropertyUseOptionalDefaultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarPropertyUseOptionalDefault {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'staticValue': json['staticValue'],
        'multiplierValue': json['multiplierValue'],
        'multiplierAttribute': json['multiplierAttribute'],
    };
}

export function EnergyStarPropertyUseOptionalDefaultToJSON(value?: EnergyStarPropertyUseOptionalDefault | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'staticValue': value.staticValue,
        'multiplierValue': value.multiplierValue,
        'multiplierAttribute': value.multiplierAttribute,
    };
}


