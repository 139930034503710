import { createStore } from 'vuex'
import { App } from 'vue'

function loadModules(): {
  context: __WebpackModuleApi.RequireContext
  modules: any
} {
  const context = require.context('@/Modules/', true, /v-store.*.ts$/i)
  const modules = context
    .keys()
    .map((key) => {
      const fileName = key.split('/')
      const name = fileName[fileName.length - 1].split('.')
      return { key, name: name[1] }
    })
    .reduce((modules, { key, name }) => {
      return {
        ...modules,
        [name]: context(key).default,
      }
    }, {})

  return { context, modules }
}

const { context, modules } = loadModules()

const store = createStore({
  modules,
})

if (module.hot) {
  module.hot.accept(context.id, () => {
    const { modules } = loadModules()

    store.hotUpdate({
      modules,
    })
  })
}

export default {
  install: (app: App): void => {
    app.use(store)
  },
}
