/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationMonthlyMappingDto
 */
export interface LocationMonthlyMappingDto {
    /**
     * 
     * @type {number}
     * @memberof LocationMonthlyMappingDto
     */
    parentId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LocationMonthlyMappingDto
     */
    locationIds: Array<number>;
}

export function LocationMonthlyMappingDtoFromJSON(json: any): LocationMonthlyMappingDto {
    return LocationMonthlyMappingDtoFromJSONTyped(json, false);
}

export function LocationMonthlyMappingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationMonthlyMappingDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'locationIds': json['locationIds'],
    };
}

export function LocationMonthlyMappingDtoToJSON(value?: LocationMonthlyMappingDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentId': value.parentId,
        'locationIds': value.locationIds,
    };
}


