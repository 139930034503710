/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WidgetMetaDto,
    WidgetMetaDtoFromJSON,
    WidgetMetaDtoFromJSONTyped,
    WidgetMetaDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface WidgetDataDto
 */
export interface WidgetDataDto {
    /**
     * 
     * @type {Array<object>}
     * @memberof WidgetDataDto
     */
    data: Array<object>;
    /**
     * 
     * @type {WidgetMetaDto}
     * @memberof WidgetDataDto
     */
    meta: WidgetMetaDto;
}

export function WidgetDataDtoFromJSON(json: any): WidgetDataDto {
    return WidgetDataDtoFromJSONTyped(json, false);
}

export function WidgetDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': json['data'],
        'meta': WidgetMetaDtoFromJSON(json['meta']),
    };
}

export function WidgetDataDtoToJSON(value?: WidgetDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'meta': WidgetMetaDtoToJSON(value.meta),
    };
}


