/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BatchParseBodyDto,
    BatchParseBodyDtoFromJSON,
    BatchParseBodyDtoToJSON,
    Bill,
    BillFromJSON,
    BillToJSON,
} from '../models';

export interface BatchTestApiControllerTestBatchUnpackRequest {
    batchParseBodyDto: BatchParseBodyDto;
}

/**
 * 
 */
export class BatchesApiApi extends runtime.BaseAPI {

    /**
     * Test batch unpack
     */
    async batchTestApiControllerTestBatchUnpackRaw(requestParameters: BatchTestApiControllerTestBatchUnpackRequest): Promise<runtime.ApiResponse<Array<Bill>>> {
        if (requestParameters.batchParseBodyDto === null || requestParameters.batchParseBodyDto === undefined) {
            throw new runtime.RequiredError('batchParseBodyDto','Required parameter requestParameters.batchParseBodyDto was null or undefined when calling batchTestApiControllerTestBatchUnpack.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/batches-api/unpack-batch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchParseBodyDtoToJSON(requestParameters.batchParseBodyDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BillFromJSON));
    }

    /**
     * Test batch unpack
     */
    async batchTestApiControllerTestBatchUnpack(requestParameters: BatchTestApiControllerTestBatchUnpackRequest): Promise<Array<Bill>> {
        const response = await this.batchTestApiControllerTestBatchUnpackRaw(requestParameters);
        return await response.value();
    }

}
