/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarNoScoreError,
    EnergyStarNoScoreErrorFromJSON,
    EnergyStarNoScoreErrorFromJSONTyped,
    EnergyStarNoScoreErrorToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarSaveScoreTotalsDto
 */
export interface EnergyStarSaveScoreTotalsDto {
    /**
     * 
     * @type {number}
     * @memberof EnergyStarSaveScoreTotalsDto
     */
    totalLocations: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarSaveScoreTotalsDto
     */
    totalProperties: number;
    /**
     * 
     * @type {Array<EnergyStarNoScoreError>}
     * @memberof EnergyStarSaveScoreTotalsDto
     */
    errors: Array<EnergyStarNoScoreError>;
}

export function EnergyStarSaveScoreTotalsDtoFromJSON(json: any): EnergyStarSaveScoreTotalsDto {
    return EnergyStarSaveScoreTotalsDtoFromJSONTyped(json, false);
}

export function EnergyStarSaveScoreTotalsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarSaveScoreTotalsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalLocations': json['totalLocations'],
        'totalProperties': json['totalProperties'],
        'errors': ((json['errors'] as Array<any>).map(EnergyStarNoScoreErrorFromJSON)),
    };
}

export function EnergyStarSaveScoreTotalsDtoToJSON(value?: EnergyStarSaveScoreTotalsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalLocations': value.totalLocations,
        'totalProperties': value.totalProperties,
        'errors': ((value.errors as Array<any>).map(EnergyStarNoScoreErrorToJSON)),
    };
}


