/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivateAccountDto,
    ActivateAccountDtoFromJSON,
    ActivateAccountDtoToJSON,
    ForgotPasswordDto,
    ForgotPasswordDtoFromJSON,
    ForgotPasswordDtoToJSON,
    ResetPasswordDto,
    ResetPasswordDtoFromJSON,
    ResetPasswordDtoToJSON,
    ResetPasswordModel,
    ResetPasswordModelFromJSON,
    ResetPasswordModelToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserActivationModel,
    UserActivationModelFromJSON,
    UserActivationModelToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoToJSON,
    UsersListDto,
    UsersListDtoFromJSON,
    UsersListDtoToJSON,
} from '../models';

export interface UsersControllerActivateAccountRequest {
    activateAccountDto: ActivateAccountDto;
}

export interface UsersControllerActivateAccountByIdRequest {
    userId: number;
}

export interface UsersControllerCheckResetPasswordTokenRequest {
    token: string;
}

export interface UsersControllerCheckUserActivationTokenRequest {
    token: string;
}

export interface UsersControllerDeactivateAccountByIdRequest {
    userId: number;
}

export interface UsersControllerForgotPasswordRequest {
    forgotPasswordDto: ForgotPasswordDto;
}

export interface UsersControllerGetUserRequest {
    userId: number;
}

export interface UsersControllerGetUserActivationLinkRequest {
    userId: number;
}

export interface UsersControllerGetUserPasswordResetLinkRequest {
    userId: number;
}

export interface UsersControllerGetUsersRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<UsersControllerGetUsersExcludeEnum>;
    orderBy?: Array<UsersControllerGetUsersOrderByEnum>;
    email?: string;
    searchQuery?: string;
}

export interface UsersControllerResetUserPasswordRequest {
    token: string;
    resetPasswordDto: ResetPasswordDto;
}

export interface UsersControllerSendActivationRequest {
    userId: number;
}

export interface UsersControllerSoftDeleteUserRequest {
    userId: number;
}

export interface UsersControllerUpdateUserProfileRequest {
    userId: number;
    userProfileDto: UserProfileDto;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Activate the account
     */
    async usersControllerActivateAccountRaw(requestParameters: UsersControllerActivateAccountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.activateAccountDto === null || requestParameters.activateAccountDto === undefined) {
            throw new runtime.RequiredError('activateAccountDto','Required parameter requestParameters.activateAccountDto was null or undefined when calling usersControllerActivateAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivateAccountDtoToJSON(requestParameters.activateAccountDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Activate the account
     */
    async usersControllerActivateAccount(requestParameters: UsersControllerActivateAccountRequest): Promise<void> {
        await this.usersControllerActivateAccountRaw(requestParameters);
    }

    /**
     */
    async usersControllerActivateAccountByIdRaw(requestParameters: UsersControllerActivateAccountByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerActivateAccountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}/activate`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerActivateAccountById(requestParameters: UsersControllerActivateAccountByIdRequest): Promise<void> {
        await this.usersControllerActivateAccountByIdRaw(requestParameters);
    }

    /**
     * Check reset password token
     */
    async usersControllerCheckResetPasswordTokenRaw(requestParameters: UsersControllerCheckResetPasswordTokenRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling usersControllerCheckResetPasswordToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/password-reset/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Check reset password token
     */
    async usersControllerCheckResetPasswordToken(requestParameters: UsersControllerCheckResetPasswordTokenRequest): Promise<User> {
        const response = await this.usersControllerCheckResetPasswordTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Check user activation token
     */
    async usersControllerCheckUserActivationTokenRaw(requestParameters: UsersControllerCheckUserActivationTokenRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling usersControllerCheckUserActivationToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/activate/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Check user activation token
     */
    async usersControllerCheckUserActivationToken(requestParameters: UsersControllerCheckUserActivationTokenRequest): Promise<User> {
        const response = await this.usersControllerCheckUserActivationTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async usersControllerDeactivateAccountByIdRaw(requestParameters: UsersControllerDeactivateAccountByIdRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerDeactivateAccountById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}/deactivate`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async usersControllerDeactivateAccountById(requestParameters: UsersControllerDeactivateAccountByIdRequest): Promise<void> {
        await this.usersControllerDeactivateAccountByIdRaw(requestParameters);
    }

    /**
     * Request user password change
     */
    async usersControllerForgotPasswordRaw(requestParameters: UsersControllerForgotPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.forgotPasswordDto === null || requestParameters.forgotPasswordDto === undefined) {
            throw new runtime.RequiredError('forgotPasswordDto','Required parameter requestParameters.forgotPasswordDto was null or undefined when calling usersControllerForgotPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordDtoToJSON(requestParameters.forgotPasswordDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request user password change
     */
    async usersControllerForgotPassword(requestParameters: UsersControllerForgotPasswordRequest): Promise<void> {
        await this.usersControllerForgotPasswordRaw(requestParameters);
    }

    /**
     * Returns a user.
     */
    async usersControllerGetUserRaw(requestParameters: UsersControllerGetUserRequest): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerGetUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Returns a user.
     */
    async usersControllerGetUser(requestParameters: UsersControllerGetUserRequest): Promise<User> {
        const response = await this.usersControllerGetUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the activation link.
     */
    async usersControllerGetUserActivationLinkRaw(requestParameters: UsersControllerGetUserActivationLinkRequest): Promise<runtime.ApiResponse<UserActivationModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerGetUserActivationLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}/activation-link`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserActivationModelFromJSON(jsonValue));
    }

    /**
     * Returns the activation link.
     */
    async usersControllerGetUserActivationLink(requestParameters: UsersControllerGetUserActivationLinkRequest): Promise<UserActivationModel> {
        const response = await this.usersControllerGetUserActivationLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the password reset link.
     */
    async usersControllerGetUserPasswordResetLinkRaw(requestParameters: UsersControllerGetUserPasswordResetLinkRequest): Promise<runtime.ApiResponse<ResetPasswordModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerGetUserPasswordResetLink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}/reset-password-link`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ResetPasswordModelFromJSON(jsonValue));
    }

    /**
     * Returns the password reset link.
     */
    async usersControllerGetUserPasswordResetLink(requestParameters: UsersControllerGetUserPasswordResetLinkRequest): Promise<ResetPasswordModel> {
        const response = await this.usersControllerGetUserPasswordResetLinkRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of users.
     */
    async usersControllerGetUsersRaw(requestParameters: UsersControllerGetUsersRequest): Promise<runtime.ApiResponse<UsersListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of users.
     */
    async usersControllerGetUsers(requestParameters: UsersControllerGetUsersRequest): Promise<UsersListDto> {
        const response = await this.usersControllerGetUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Reset user\'s password
     */
    async usersControllerResetUserPasswordRaw(requestParameters: UsersControllerResetUserPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.token === null || requestParameters.token === undefined) {
            throw new runtime.RequiredError('token','Required parameter requestParameters.token was null or undefined when calling usersControllerResetUserPassword.');
        }

        if (requestParameters.resetPasswordDto === null || requestParameters.resetPasswordDto === undefined) {
            throw new runtime.RequiredError('resetPasswordDto','Required parameter requestParameters.resetPasswordDto was null or undefined when calling usersControllerResetUserPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/password-reset/{token}`.replace(`{${"token"}}`, encodeURIComponent(String(requestParameters.token))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordDtoToJSON(requestParameters.resetPasswordDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reset user\'s password
     */
    async usersControllerResetUserPassword(requestParameters: UsersControllerResetUserPasswordRequest): Promise<void> {
        await this.usersControllerResetUserPasswordRaw(requestParameters);
    }

    /**
     * Send activation email
     */
    async usersControllerSendActivationRaw(requestParameters: UsersControllerSendActivationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerSendActivation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}/send-activation`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send activation email
     */
    async usersControllerSendActivation(requestParameters: UsersControllerSendActivationRequest): Promise<void> {
        await this.usersControllerSendActivationRaw(requestParameters);
    }

    /**
     * Delete a user.
     */
    async usersControllerSoftDeleteUserRaw(requestParameters: UsersControllerSoftDeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerSoftDeleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a user.
     */
    async usersControllerSoftDeleteUser(requestParameters: UsersControllerSoftDeleteUserRequest): Promise<void> {
        await this.usersControllerSoftDeleteUserRaw(requestParameters);
    }

    /**
     * Update user profile
     */
    async usersControllerUpdateUserProfileRaw(requestParameters: UsersControllerUpdateUserProfileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling usersControllerUpdateUserProfile.');
        }

        if (requestParameters.userProfileDto === null || requestParameters.userProfileDto === undefined) {
            throw new runtime.RequiredError('userProfileDto','Required parameter requestParameters.userProfileDto was null or undefined when calling usersControllerUpdateUserProfile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/users/{userId}/profile`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserProfileDtoToJSON(requestParameters.userProfileDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update user profile
     */
    async usersControllerUpdateUserProfile(requestParameters: UsersControllerUpdateUserProfileRequest): Promise<void> {
        await this.usersControllerUpdateUserProfileRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum UsersControllerGetUsersExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum UsersControllerGetUsersOrderByEnum {
    IdAsc = 'id.asc',
    ActiveAsc = 'active.asc',
    LastLoginAsc = 'lastLogin.asc',
    LastNameAsc = 'lastName.asc',
    FirstNameAsc = 'firstName.asc',
    ChatTagAsc = 'chatTag.asc',
    GroupTagAsc = 'groupTag.asc',
    EmailAsc = 'email.asc',
    IdDesc = 'id.desc',
    ActiveDesc = 'active.desc',
    LastLoginDesc = 'lastLogin.desc',
    LastNameDesc = 'lastName.desc',
    FirstNameDesc = 'firstName.desc',
    ChatTagDesc = 'chatTag.desc',
    GroupTagDesc = 'groupTag.desc',
    EmailDesc = 'email.desc'
}
