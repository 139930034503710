import { envs } from '@/AppModule/Configs/env'
import { App } from 'vue'

export const hosts = {
  v1: envs.v1.host,
  v3: envs.v3.host,
}

export default {
  install: (app: App): void => {
    app.config.globalProperties.$hosts = hosts
    app.config.globalProperties.$window = window
    app.config.globalProperties.$System =
      app.config.globalProperties.$window.System
  },
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $hosts: any
    $System: any
    $window: any
  }
}
