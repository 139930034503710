/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentEmailDelivery
 */
export interface PaymentEmailDelivery {
    /**
     * 
     * @type {number}
     * @memberof PaymentEmailDelivery
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentEmailDelivery
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentEmailDelivery
     */
    paymentFileFormat: PaymentEmailDeliveryPaymentFileFormatEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentEmailDelivery
     */
    emails: Array<string>;
}

/**
* @export
* @enum {string}
*/
export enum PaymentEmailDeliveryPaymentFileFormatEnum {
    Summary = 'summary',
    Wesleyan = 'wesleyan',
    Sonbyrne = 'sonbyrne',
    SheetzPrimary = 'sheetz_primary',
    SheetzCli = 'sheetz_cli',
    SheetzSds = 'sheetz_sds',
    SheetzConstruction = 'sheetz_construction',
    RoyalfarmsAutopay = 'royalfarms_autopay',
    RoyalfarmsManualpay = 'royalfarms_manualpay',
    RoyalfarmsPcbillpay = 'royalfarms_pcbillpay',
    RoyalfarmsConstruction = 'royalfarms_construction',
    Countrymeadows = 'countrymeadows'
}

export function PaymentEmailDeliveryFromJSON(json: any): PaymentEmailDelivery {
    return PaymentEmailDeliveryFromJSONTyped(json, false);
}

export function PaymentEmailDeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEmailDelivery {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'paymentFileFormat': json['paymentFileFormat'],
        'emails': json['emails'],
    };
}

export function PaymentEmailDeliveryToJSON(value?: PaymentEmailDelivery | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'paymentFileFormat': value.paymentFileFormat,
        'emails': value.emails,
    };
}


