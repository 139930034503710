/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WidgetMetaDto
 */
export interface WidgetMetaDto {
    /**
     * 
     * @type {number}
     * @memberof WidgetMetaDto
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetMetaDto
     */
    max: number;
    /**
     * 
     * @type {number}
     * @memberof WidgetMetaDto
     */
    min: number;
}

export function WidgetMetaDtoFromJSON(json: any): WidgetMetaDto {
    return WidgetMetaDtoFromJSONTyped(json, false);
}

export function WidgetMetaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WidgetMetaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalCount': json['totalCount'],
        'max': json['max'],
        'min': json['min'],
    };
}

export function WidgetMetaDtoToJSON(value?: WidgetMetaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalCount': value.totalCount,
        'max': value.max,
        'min': value.min,
    };
}


