/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountUpdateDto
 */
export interface VirtualAccountUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountUpdateDto
     */
    accountOpened: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountUpdateDto
     */
    accountClosed: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountUpdateDto
     */
    accountPausedFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountUpdateDto
     */
    accountPausedTo?: string;
}

export function VirtualAccountUpdateDtoFromJSON(json: any): VirtualAccountUpdateDto {
    return VirtualAccountUpdateDtoFromJSONTyped(json, false);
}

export function VirtualAccountUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accountOpened': json['accountOpened'],
        'accountClosed': json['accountClosed'],
        'accountPausedFrom': !exists(json, 'accountPausedFrom') ? undefined : json['accountPausedFrom'],
        'accountPausedTo': !exists(json, 'accountPausedTo') ? undefined : json['accountPausedTo'],
    };
}

export function VirtualAccountUpdateDtoToJSON(value?: VirtualAccountUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accountOpened': value.accountOpened,
        'accountClosed': value.accountClosed,
        'accountPausedFrom': value.accountPausedFrom,
        'accountPausedTo': value.accountPausedTo,
    };
}


