/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarContactDto,
    EnergyStarContactDtoFromJSON,
    EnergyStarContactDtoFromJSONTyped,
    EnergyStarContactDtoToJSON,
    EnergyStarOrganizationDto,
    EnergyStarOrganizationDtoFromJSON,
    EnergyStarOrganizationDtoFromJSONTyped,
    EnergyStarOrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarCustomerAccountDetailsDto
 */
export interface EnergyStarCustomerAccountDetailsDto {
    /**
     * 
     * @type {EnergyStarContactDto}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    contact: EnergyStarContactDto;
    /**
     * 
     * @type {EnergyStarOrganizationDto}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    organization: EnergyStarOrganizationDto;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    password: string;
    /**
     * 
     * @type {boolean}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    webserviceUser: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EnergyStarCustomerAccountDetailsDto
     */
    searchable: boolean;
}

export function EnergyStarCustomerAccountDetailsDtoFromJSON(json: any): EnergyStarCustomerAccountDetailsDto {
    return EnergyStarCustomerAccountDetailsDtoFromJSONTyped(json, false);
}

export function EnergyStarCustomerAccountDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarCustomerAccountDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contact': EnergyStarContactDtoFromJSON(json['contact']),
        'organization': EnergyStarOrganizationDtoFromJSON(json['organization']),
        'id': json['id'],
        'username': json['username'],
        'password': json['password'],
        'webserviceUser': json['webserviceUser'],
        'searchable': json['searchable'],
    };
}

export function EnergyStarCustomerAccountDetailsDtoToJSON(value?: EnergyStarCustomerAccountDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contact': EnergyStarContactDtoToJSON(value.contact),
        'organization': EnergyStarOrganizationDtoToJSON(value.organization),
        'id': value.id,
        'username': value.username,
        'password': value.password,
        'webserviceUser': value.webserviceUser,
        'searchable': value.searchable,
    };
}


