/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillValidationDefinitionModel
 */
export interface BillValidationDefinitionModel {
    /**
     * 
     * @type {number}
     * @memberof BillValidationDefinitionModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BillValidationDefinitionModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BillValidationDefinitionModel
     */
    workflowState: string;
    /**
     * 
     * @type {string}
     * @memberof BillValidationDefinitionModel
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof BillValidationDefinitionModel
     */
    severity: number;
    /**
     * 
     * @type {boolean}
     * @memberof BillValidationDefinitionModel
     */
    resolvable: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillValidationDefinitionModel
     */
    autoResolvable: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillValidationDefinitionModel
     */
    metadata: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof BillValidationDefinitionModel
     */
    bulkResolvable: boolean;
}

export function BillValidationDefinitionModelFromJSON(json: any): BillValidationDefinitionModel {
    return BillValidationDefinitionModelFromJSONTyped(json, false);
}

export function BillValidationDefinitionModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillValidationDefinitionModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'workflowState': json['workflowState'],
        'category': json['category'],
        'severity': json['severity'],
        'resolvable': json['resolvable'],
        'autoResolvable': json['autoResolvable'],
        'metadata': json['metadata'],
        'bulkResolvable': json['bulkResolvable'],
    };
}

export function BillValidationDefinitionModelToJSON(value?: BillValidationDefinitionModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'workflowState': value.workflowState,
        'category': value.category,
        'severity': value.severity,
        'resolvable': value.resolvable,
        'autoResolvable': value.autoResolvable,
        'metadata': value.metadata,
        'bulkResolvable': value.bulkResolvable,
    };
}


