/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportStatusListDto
 */
export interface ReportStatusListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReportStatusListDto
     */
    reportIds: Array<string>;
}

export function ReportStatusListDtoFromJSON(json: any): ReportStatusListDto {
    return ReportStatusListDtoFromJSONTyped(json, false);
}

export function ReportStatusListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportStatusListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reportIds': json['reportIds'],
    };
}

export function ReportStatusListDtoToJSON(value?: ReportStatusListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reportIds': value.reportIds,
    };
}


