/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangePriorityDto
 */
export interface ChangePriorityDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangePriorityDto
     */
    billsIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ChangePriorityDto
     */
    priority: number;
}

export function ChangePriorityDtoFromJSON(json: any): ChangePriorityDto {
    return ChangePriorityDtoFromJSONTyped(json, false);
}

export function ChangePriorityDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangePriorityDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billsIds': json['billsIds'],
        'priority': json['priority'],
    };
}

export function ChangePriorityDtoToJSON(value?: ChangePriorityDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billsIds': value.billsIds,
        'priority': value.priority,
    };
}


