import { ResourcesService } from '@/Modules/Resources/services/service.resources'
import { PaymentsService } from '@/Modules/Payments/services/service.customer-payment-settings'

const paymentsService = new PaymentsService()
export default {
  namespaced: true,
  state: (): any => ({
    billErrEvents: [],
  }),
  getters: {
    billErrEvents: (state: any): any => state.billErrEvents,
  },
  mutations: {
    setBillErrEvents(state: any, billErrEvents: any): any {
      state.billErrEvents = [
        ...billErrEvents.events.map((event: any) => ({
          ...event,
          type: event.type.toString(),
        })),
      ]
    },
  },
  actions: {
    async getBillValidDefs({ commit }: any): Promise<void> {
      try {
        const { filters } = await paymentsService.paymentsGetErrorEvents({})
        commit('setBillErrEvents', filters)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
