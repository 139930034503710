/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FileModel
 */
export interface FileModel {
    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof FileModel
     */
    customerId: number;
    /**
     * 
     * @type {Date}
     * @memberof FileModel
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof FileModel
     */
    contentType: string;
    /**
     * 
     * @type {object}
     * @memberof FileModel
     */
    payload: object;
}

export function FileModelFromJSON(json: any): FileModel {
    return FileModelFromJSONTyped(json, false);
}

export function FileModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'createdAt': (new Date(json['createdAt'])),
        'hash': json['hash'],
        'path': json['path'],
        'contentType': json['contentType'],
        'payload': json['payload'],
    };
}

export function FileModelToJSON(value?: FileModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'createdAt': (value.createdAt.toISOString()),
        'hash': value.hash,
        'path': value.path,
        'contentType': value.contentType,
        'payload': value.payload,
    };
}


