import { RouteRecordRaw } from 'vue-router'
import BillsView from '@/Modules/Customers/views/view.page.bills.vue'
import LocationsCustomAttributesView from '@/Modules/Customers/views/view.page.locations.custom-attributes.vue'
import VendorsCustomAttributesView from '@/Modules/Customers/views/view.page.vendors.custom-attributes.vue'
import ProxyRouterViewComponent from '@/SharedModule/Components/Router/ProxyRouterViewComponent.vue'
import UnprocessedBillsView from '@/Modules/Customers/views/view.page.unprocessed-bills.vue'
import CustomersTable from '@/Modules/Customers/views/view.customers-table.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/customers',
    name: 'customers',
    component: CustomersTable,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Customers',
      },
    },
  },
  {
    path: '/customers/:customerId/bills',
    name: 'customer.bills',
    component: BillsView,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Bills for',
      },
    },
  },
  {
    path: '/customers/:customerId/custom_attributes_vendor',
    name: 'customer.custom_attributes_vendor',
    component: VendorsCustomAttributesView,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Custom Attributes',
      },
    },
  },
  {
    path: '/customers/:customerId/custom_attributes',
    name: 'customer.custom_attributes',
    component: ProxyRouterViewComponent,
    children: [
      {
        path: 'locationAttributes',
        name: 'customer.custom_attributes_locations',
        component: LocationsCustomAttributesView,
        meta: {
          showBreadcrumbs: true,
          breadcrumb: {
            titleTemplate: 'Custom Attributes',
          },
        },
      },
      {
        path: 'vaAttributes',
        name: 'customer.custom_attributes_virtual_accounts',
        component: LocationsCustomAttributesView,
        meta: {
          showBreadcrumbs: true,
          breadcrumb: {
            titleTemplate: 'Custom Attributes',
          },
        },
      },
    ],
  },
  {
    path: '/customers/:customerId/exceptions/unprocessed',
    name: 'customer.exceptions.unprocessed',
    component: UnprocessedBillsView,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Unmatched Service Addresses',
      },
    },
  },
]

export default routes
