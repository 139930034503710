/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './';

/**
 * 
 * @export
 * @interface SendEmailDto
 */
export interface SendEmailDto {
    /**
     * 
     * @type {number}
     * @memberof SendEmailDto
     */
    retryCount: number;
    /**
     * 
     * @type {Email}
     * @memberof SendEmailDto
     */
    email: Email;
    /**
     * 
     * @type {string}
     * @memberof SendEmailDto
     */
    templateName: string;
    /**
     * 
     * @type {object}
     * @memberof SendEmailDto
     */
    params: object;
    /**
     * 
     * @type {number}
     * @memberof SendEmailDto
     */
    customerId: number;
}

export function SendEmailDtoFromJSON(json: any): SendEmailDto {
    return SendEmailDtoFromJSONTyped(json, false);
}

export function SendEmailDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendEmailDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retryCount': json['retryCount'],
        'email': EmailFromJSON(json['email']),
        'templateName': json['templateName'],
        'params': json['params'],
        'customerId': json['customerId'],
    };
}

export function SendEmailDtoToJSON(value?: SendEmailDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retryCount': value.retryCount,
        'email': EmailToJSON(value.email),
        'templateName': value.templateName,
        'params': value.params,
        'customerId': value.customerId,
    };
}


