import { isNil } from 'lodash'

export const customerRolesPermissions: any = {
  standardUser: [],
  customerFullAccess: [
    { feature: 'customer_full_access', permission: 'enabled', id: 2 },
  ],
}

export const userRolesRadioSelection = [
  { label: 'standardUser', name: 'Standard User' },
  { label: 'customerFullAccess', name: 'Full Access (default)' },
]

export const userRoles = (customers: any, currentCustomerId: any): any => {
  const fullAccess = isFullAccess(customers, currentCustomerId)
  if (!isNil(fullAccess)) {
    if (!fullAccess.isFullAcc && fullAccess.permissionsLength > 0) {
      return 'Custom Role'
    }
    if (fullAccess.isFullAcc) {
      return 'Full Access'
    } else {
      return 'Standard User'
    }
  }
}

export const isFullAccess = (customers: any, currentCustomerId: any): any => {
  if (!isNil(customers)) {
    const currentCustomer = customers.find(
      (customer: any) => +customer.id === +currentCustomerId,
    )
    const isFullAcc =
      currentCustomer &&
      currentCustomer.permissions &&
      !!currentCustomer.permissions.find(
        (p: any) =>
          p.feature ===
            customerRolesPermissions.customerFullAccess[0].feature &&
          p.permission ===
            customerRolesPermissions.customerFullAccess[0].permission,
      )
    const permissionsLength =
      currentCustomer && currentCustomer.permissions.length
    return { isFullAcc, permissionsLength }
  }
}
export const handleEditUserRoles = (payload: any): any => {
  const { userId, userRoleRadio, fullName, editUserRole, customerId } = payload
  const customerAccess = customerRolesPermissions[userRoleRadio]
  const [userRole] = customerAccess
  const permissions = !isNil(userRole) ? [userRole.id] : []
  const readableUserRole =
    !isNil(userRole) &&
    userRole.feature === 'customer_full_access' &&
    userRole.permission === 'enabled'
      ? 'Full Access'
      : 'Standard User'
  return {
    permissions,
    userId,
    fullName,
    readableUserRole,
    editUserRole,
    customerId,
  }
}
