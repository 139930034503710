/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountMapDto
 */
export interface VirtualAccountMapDto {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountMapDto
     */
    virtualAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountMapDto
     */
    serviceAddress: string;
}

export function VirtualAccountMapDtoFromJSON(json: any): VirtualAccountMapDto {
    return VirtualAccountMapDtoFromJSONTyped(json, false);
}

export function VirtualAccountMapDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountMapDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'virtualAccountId': json['virtualAccountId'],
        'serviceAddress': json['serviceAddress'],
    };
}

export function VirtualAccountMapDtoToJSON(value?: VirtualAccountMapDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'virtualAccountId': value.virtualAccountId,
        'serviceAddress': value.serviceAddress,
    };
}


