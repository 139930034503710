/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssigneeToMeDto
 */
export interface AssigneeToMeDto {
    /**
     * Use 0 or null to unassign, otherwise the user id
     * @type {number}
     * @memberof AssigneeToMeDto
     */
    assignee: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof AssigneeToMeDto
     */
    billsIds: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof AssigneeToMeDto
     */
    replace: boolean;
}

export function AssigneeToMeDtoFromJSON(json: any): AssigneeToMeDto {
    return AssigneeToMeDtoFromJSONTyped(json, false);
}

export function AssigneeToMeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssigneeToMeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'assignee': json['assignee'],
        'billsIds': json['billsIds'],
        'replace': json['replace'],
    };
}

export function AssigneeToMeDtoToJSON(value?: AssigneeToMeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'assignee': value.assignee,
        'billsIds': value.billsIds,
        'replace': value.replace,
    };
}


