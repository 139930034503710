import { CustomersService } from '@/Modules/Customers/services/service.customers'
import { ElMessage } from 'element-plus'

export interface IState {
  showAddCustomerDiag: boolean
  customerDto: any
}

const getDefaultState = (): any => ({
  showAddCustomerDiag: false,
  customerDto: {
    active: true,
    name: '',
    fdgCode: '',
    tin: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: '',
      postCode: '',
    },
    entitlements: {
      payments: false,
      powerBi: false,
      billPay: false,
      energyStar: false,
      carbonFootprint: false,
      constellation: false,
      constellation_billing: false,
      activityHistoryChat: false,
      budgeting: false,
      weather: false,
    },
    contractDetails: {
      contractStartDate: undefined,
      contractEndDate: undefined,
      productTier: undefined,
      contractSigned: 'Exelon Generation',
      partner: undefined,
      isPartnerBilling: undefined,
    },
    targetHours: {
      setup: 48,
      live: 48,
      historical: 48,
      demo: 48,
    },
  },
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    showAddCustomerDiag: (state: IState): boolean => state.showAddCustomerDiag,
    getCustomerModel: (state: IState): any => state.customerDto,
    customerCountry: (state: IState): any => state.customerDto.address.country,
    contractStartDate: (state: IState): any =>
      state.customerDto.contractDetails.contractStartDate,
    contractEndDate: (state: IState): any =>
      state.customerDto.contractDetails.contractEndDate,
    // entitlement getters
    payments: (state: IState): any => state.customerDto.entitlements.payments,
    powerBi: (state: IState): any => state.customerDto.entitlements.powerBi,
    budgeting: (state: IState): any => state.customerDto.entitlements.budgeting,
    weather: (state: IState): any => state.customerDto.entitlements.weather,
    energyStar: (state: IState): any =>
      state.customerDto.entitlements.energyStar,
    carbonFootprint: (state: IState): any =>
      state.customerDto.entitlements.carbonFootprint,
    activityHistoryChat: (state: IState): any =>
      state.customerDto.entitlements.activityHistoryChat,
    billPay: (state: IState): any => state.customerDto.entitlements.billPay,
  },
  mutations: {
    // generic mutation
    setCustomerData(state: IState, payload: any): void {
      const { key, itemKey, val } = payload
      if (itemKey) {
        state.customerDto[key][itemKey] = val
      } else {
        state.customerDto[key] = val
      }
    },
    // custom mutations
    setCustomerCountry(state: IState, payload: any): void {
      state.customerDto.address.state = ''
      state.customerDto.address.country = payload
    },
    setPartner(state: IState, payload: any): void {
      state.customerDto.contractDetails.partner =
        payload === '' ? undefined : payload
    },
    setIsPartnerBilling(state: IState, payload: any): void {
      state.customerDto.contractDetails.isPartnerBilling =
        payload === '' ? undefined : payload
    },
    resetEntitlements(state: IState): void {
      for (const [key] of Object.entries(state.customerDto.entitlements)) {
        state.customerDto.entitlements[key] = false
      }
    },
    // other mutations
    setShowAddCustomerDiag(state: IState, showAddCustomerDiag: boolean): void {
      state.showAddCustomerDiag = showAddCustomerDiag
    },
    resetState(state: IState): void {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async createCustomer({ state, commit }: any): Promise<void> {
      const customerDto = { ...state.customerDto }
      try {
        const customerService = new CustomersService()
        await customerService.createCustomer({
          customerDto,
        })

        ElMessage({
          message: `Customer ${customerDto.name} created successfully.`,
          type: 'success',
        })
        commit('resetState')
      } catch (e) {
        ElMessage({
          message: `Customer ${customerDto.name} not created. Please try again.`,
          type: 'error',
        })
      }
    },
  },
}
