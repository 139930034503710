/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarAddressDto,
    EnergyStarAddressDtoFromJSON,
    EnergyStarAddressDtoFromJSONTyped,
    EnergyStarAddressDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarContactDto
 */
export interface EnergyStarContactDto {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarContactDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarContactDto
     */
    lastName: string;
    /**
     * 
     * @type {EnergyStarAddressDto}
     * @memberof EnergyStarContactDto
     */
    address: EnergyStarAddressDto;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarContactDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarContactDto
     */
    jobTitle: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarContactDto
     */
    phone: string;
}

export function EnergyStarContactDtoFromJSON(json: any): EnergyStarContactDto {
    return EnergyStarContactDtoFromJSONTyped(json, false);
}

export function EnergyStarContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'address': EnergyStarAddressDtoFromJSON(json['address']),
        'email': json['email'],
        'jobTitle': json['jobTitle'],
        'phone': json['phone'],
    };
}

export function EnergyStarContactDtoToJSON(value?: EnergyStarContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'address': EnergyStarAddressDtoToJSON(value.address),
        'email': value.email,
        'jobTitle': value.jobTitle,
        'phone': value.phone,
    };
}


