import {
  SftpConnectionDto,
  SftpConnectionDtoFeatureTypeEnum,
} from '@/SharedModule/Api'

export class SftpConnectionModel implements Partial<SftpConnectionDto> {
  public featureType = SftpConnectionDtoFeatureTypeEnum.Reports
  public name = ''
  public password = ''
  public username = ''
  public host = ''
  public folder = ''
}
