import { AuthApi, LoginDto, LoginResponseDto, User } from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class AuthService extends ApiService {
  private readonly authApi = new AuthApi(this.getBaseOptions())

  public login = async (
    requestParameters: LoginDto,
  ): Promise<LoginResponseDto> => {
    return this.authApi.authControllerLogin({ loginDto: requestParameters })
  }

  public currentUser = async (): Promise<User> => {
    return this.authApi.authControllerGetCurrentUser()
  }
}
