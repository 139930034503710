/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PowerBIReportModel,
    PowerBIReportModelFromJSON,
    PowerBIReportModelFromJSONTyped,
    PowerBIReportModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface PowerBIReportsListDto
 */
export interface PowerBIReportsListDto {
    /**
     * 
     * @type {Array<PowerBIReportModel>}
     * @memberof PowerBIReportsListDto
     */
    results: Array<PowerBIReportModel>;
}

export function PowerBIReportsListDtoFromJSON(json: any): PowerBIReportsListDto {
    return PowerBIReportsListDtoFromJSONTyped(json, false);
}

export function PowerBIReportsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PowerBIReportsListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PowerBIReportModelFromJSON)),
    };
}

export function PowerBIReportsListDtoToJSON(value?: PowerBIReportsListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PowerBIReportModelToJSON)),
    };
}


