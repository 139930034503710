/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStartPropertyAddressModel
 */
export interface EnergyStartPropertyAddressModel {
    /**
     * 
     * @type {string}
     * @memberof EnergyStartPropertyAddressModel
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStartPropertyAddressModel
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStartPropertyAddressModel
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStartPropertyAddressModel
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStartPropertyAddressModel
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStartPropertyAddressModel
     */
    county?: string;
}

export function EnergyStartPropertyAddressModelFromJSON(json: any): EnergyStartPropertyAddressModel {
    return EnergyStartPropertyAddressModelFromJSONTyped(json, false);
}

export function EnergyStartPropertyAddressModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStartPropertyAddressModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': json['address1'],
        'city': json['city'],
        'state': json['state'],
        'postalCode': json['postalCode'],
        'country': json['country'],
        'county': !exists(json, 'county') ? undefined : json['county'],
    };
}

export function EnergyStartPropertyAddressModelToJSON(value?: EnergyStartPropertyAddressModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'city': value.city,
        'state': value.state,
        'postalCode': value.postalCode,
        'country': value.country,
        'county': value.county,
    };
}


