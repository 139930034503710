/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChangePasswordDto,
    ChangePasswordDtoFromJSON,
    ChangePasswordDtoToJSON,
    LoginDto,
    LoginDtoFromJSON,
    LoginDtoToJSON,
    LoginResponseDto,
    LoginResponseDtoFromJSON,
    LoginResponseDtoToJSON,
    RefreshTokenDto,
    RefreshTokenDtoFromJSON,
    RefreshTokenDtoToJSON,
    User,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface AuthControllerChangePasswordRequest {
    changePasswordDto: ChangePasswordDto;
}

export interface AuthControllerLoginRequest {
    loginDto: LoginDto;
}

export interface AuthControllerRefreshTokenRequest {
    refreshTokenDto: RefreshTokenDto;
}

/**
 * 
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     * Change user password
     */
    async authControllerChangePasswordRaw(requestParameters: AuthControllerChangePasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.changePasswordDto === null || requestParameters.changePasswordDto === undefined) {
            throw new runtime.RequiredError('changePasswordDto','Required parameter requestParameters.changePasswordDto was null or undefined when calling authControllerChangePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/auth/change-password`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordDtoToJSON(requestParameters.changePasswordDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change user password
     */
    async authControllerChangePassword(requestParameters: AuthControllerChangePasswordRequest): Promise<void> {
        await this.authControllerChangePasswordRaw(requestParameters);
    }

    /**
     * Get details about the current logged in user
     */
    async authControllerGetCurrentUserRaw(): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/auth/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get details about the current logged in user
     */
    async authControllerGetCurrentUser(): Promise<User> {
        const response = await this.authControllerGetCurrentUserRaw();
        return await response.value();
    }

    /**
     * Authenticates a user
     */
    async authControllerLoginRaw(requestParameters: AuthControllerLoginRequest): Promise<runtime.ApiResponse<LoginResponseDto>> {
        if (requestParameters.loginDto === null || requestParameters.loginDto === undefined) {
            throw new runtime.RequiredError('loginDto','Required parameter requestParameters.loginDto was null or undefined when calling authControllerLogin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginDtoToJSON(requestParameters.loginDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseDtoFromJSON(jsonValue));
    }

    /**
     * Authenticates a user
     */
    async authControllerLogin(requestParameters: AuthControllerLoginRequest): Promise<LoginResponseDto> {
        const response = await this.authControllerLoginRaw(requestParameters);
        return await response.value();
    }

    /**
     * Refreshes a session
     */
    async authControllerRefreshTokenRaw(requestParameters: AuthControllerRefreshTokenRequest): Promise<runtime.ApiResponse<LoginResponseDto>> {
        if (requestParameters.refreshTokenDto === null || requestParameters.refreshTokenDto === undefined) {
            throw new runtime.RequiredError('refreshTokenDto','Required parameter requestParameters.refreshTokenDto was null or undefined when calling authControllerRefreshToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/auth/refresh-token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RefreshTokenDtoToJSON(requestParameters.refreshTokenDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseDtoFromJSON(jsonValue));
    }

    /**
     * Refreshes a session
     */
    async authControllerRefreshToken(requestParameters: AuthControllerRefreshTokenRequest): Promise<LoginResponseDto> {
        const response = await this.authControllerRefreshTokenRaw(requestParameters);
        return await response.value();
    }

}
