/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PreviewBillsQueryDto
 */
export interface PreviewBillsQueryDto {
    /**
     * 
     * @type {number}
     * @memberof PreviewBillsQueryDto
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof PreviewBillsQueryDto
     */
    offset?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewBillsQueryDto
     */
    exclude?: Array<PreviewBillsQueryDtoExcludeEnum>;
    /**
     * Allowed values: priority, targetDate, statementDate, prettyName, billType, sortOrder
     * @type {Array<string>}
     * @memberof PreviewBillsQueryDto
     */
    orderBy?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PreviewBillsQueryDto
     */
    searchQuery?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PreviewBillsQueryDto
     */
    customerIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PreviewBillsQueryDto
     */
    userIds?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof PreviewBillsQueryDto
     */
    maxDueDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PreviewBillsQueryDto
     */
    maxTargetDate?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewBillsQueryDto
     */
    errorTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewBillsQueryDto
     */
    billTypes?: Array<PreviewBillsQueryDtoBillTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewBillsQueryDto
     */
    vendorCodes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PreviewBillsQueryDto
     */
    workflowStates?: Array<PreviewBillsQueryDtoWorkflowStatesEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewBillsQueryDto
     */
    customerActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewBillsQueryDto
     */
    isParked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PreviewBillsQueryDto
     */
    noPayDate?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum PreviewBillsQueryDtoExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}/**
* @export
* @enum {string}
*/
export enum PreviewBillsQueryDtoBillTypesEnum {
    Historical = 'historical',
    Setup = 'setup',
    Live = 'live',
    Free = 'free',
    Maintenance = 'maintenance',
    Special = 'special'
}/**
* @export
* @enum {string}
*/
export enum PreviewBillsQueryDtoWorkflowStatesEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}

export function PreviewBillsQueryDtoFromJSON(json: any): PreviewBillsQueryDto {
    return PreviewBillsQueryDtoFromJSONTyped(json, false);
}

export function PreviewBillsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PreviewBillsQueryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'offset': !exists(json, 'offset') ? undefined : json['offset'],
        'exclude': !exists(json, 'exclude') ? undefined : json['exclude'],
        'orderBy': !exists(json, 'orderBy[]') ? undefined : json['orderBy[]'],
        'searchQuery': !exists(json, 'searchQuery') ? undefined : json['searchQuery'],
        'customerIds': !exists(json, 'customerIds') ? undefined : json['customerIds'],
        'userIds': !exists(json, 'userIds') ? undefined : json['userIds'],
        'maxDueDate': !exists(json, 'maxDueDate') ? undefined : (new Date(json['maxDueDate'])),
        'maxTargetDate': !exists(json, 'maxTargetDate') ? undefined : (new Date(json['maxTargetDate'])),
        'errorTypes': !exists(json, 'errorTypes') ? undefined : json['errorTypes'],
        'billTypes': !exists(json, 'billTypes') ? undefined : json['billTypes'],
        'vendorCodes': !exists(json, 'vendorCodes') ? undefined : json['vendorCodes'],
        'workflowStates': !exists(json, 'workflowStates') ? undefined : json['workflowStates'],
        'customerActive': !exists(json, 'customerActive') ? undefined : json['customerActive'],
        'isParked': !exists(json, 'isParked') ? undefined : json['isParked'],
        'noPayDate': !exists(json, 'noPayDate') ? undefined : json['noPayDate'],
    };
}

export function PreviewBillsQueryDtoToJSON(value?: PreviewBillsQueryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'limit': value.limit,
        'offset': value.offset,
        'exclude': value.exclude,
        'orderBy[]': value.orderBy,
        'searchQuery': value.searchQuery,
        'customerIds': value.customerIds,
        'userIds': value.userIds,
        'maxDueDate': value.maxDueDate === undefined ? undefined : (value.maxDueDate.toISOString().substr(0,10)),
        'maxTargetDate': value.maxTargetDate === undefined ? undefined : (value.maxTargetDate.toISOString().substr(0,10)),
        'errorTypes': value.errorTypes,
        'billTypes': value.billTypes,
        'vendorCodes': value.vendorCodes,
        'workflowStates': value.workflowStates,
        'customerActive': value.customerActive,
        'isParked': value.isParked,
        'noPayDate': value.noPayDate,
    };
}


