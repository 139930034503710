/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TestSftpConnectionDto
 */
export interface TestSftpConnectionDto {
    /**
     * 
     * @type {number}
     * @memberof TestSftpConnectionDto
     */
    connectionId?: number;
    /**
     * 
     * @type {string}
     * @memberof TestSftpConnectionDto
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSftpConnectionDto
     */
    folder?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSftpConnectionDto
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof TestSftpConnectionDto
     */
    password?: string;
}

export function TestSftpConnectionDtoFromJSON(json: any): TestSftpConnectionDto {
    return TestSftpConnectionDtoFromJSONTyped(json, false);
}

export function TestSftpConnectionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TestSftpConnectionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'connectionId': !exists(json, 'connectionId') ? undefined : json['connectionId'],
        'host': !exists(json, 'host') ? undefined : json['host'],
        'folder': !exists(json, 'folder') ? undefined : json['folder'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'password': !exists(json, 'password') ? undefined : json['password'],
    };
}

export function TestSftpConnectionDtoToJSON(value?: TestSftpConnectionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'connectionId': value.connectionId,
        'host': value.host,
        'folder': value.folder,
        'username': value.username,
        'password': value.password,
    };
}


