import { ApiService } from '@/SharedModule/Services/service.api'
import {
  EmailDeliveryListDto,
  PaymentEmailDelivery,
  PaymentErrorsEmailDelivery,
  PaymentErrorsEmailDeliveryListDto,
  PaymentEventsListDto,
  PaymentsApi,
  PaymentsControllerDeleteSFTPDeliveryByIdRequest,
  PaymentsControllerGetEventsRequest,
  PaymentsControllerGetSFTPDeliveriesRequest,
  PaymentsControllerSaveSFTPDeliveryRequest,
  PaymentsControllerUpdateSFTPDeliveryRequest,
  PaymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRequest,
  PaymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRequest,
  PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest,
  PaymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRequest,
  PaymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRequest,
  PaymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRequest,
  PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest,
  PaymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRequest,
  SFTPDeliveryDtoResponse,
  SftpDeliveryListDto,
} from '@/SharedModule/Api'

export class PaymentsService extends ApiService {
  private paymentsService = new PaymentsApi(this.getBaseOptions())

  public paymentsGetEmailDeliveries = async (
    requestParams: PaymentsEmailDeliveriesControllerGetPaymentEmailDeliveriesRequest,
  ): Promise<EmailDeliveryListDto> =>
    await this.paymentsService.paymentsEmailDeliveriesControllerGetPaymentEmailDeliveries(
      requestParams,
    )
  public paymentsCreateEmailDeliveries = async (
    requestParams: PaymentsEmailDeliveriesControllerCreatePaymentEmailDeliveryRequest,
  ): Promise<PaymentEmailDelivery> =>
    await this.paymentsService.paymentsEmailDeliveriesControllerCreatePaymentEmailDelivery(
      requestParams,
    )
  public paymentsUpdateEmailDeliveries = async (
    requestParams: PaymentsEmailDeliveriesControllerUpdatePaymentEmailDeliveryRequest,
  ): Promise<PaymentEmailDelivery> =>
    await this.paymentsService.paymentsEmailDeliveriesControllerUpdatePaymentEmailDelivery(
      requestParams,
    )
  public paymentsDeleteEmailDeliveries = async (
    requestParams: PaymentsEmailDeliveriesControllerDeletePaymentEmailDeliveryRequest,
  ): Promise<void> =>
    await this.paymentsService.paymentsEmailDeliveriesControllerDeletePaymentEmailDelivery(
      requestParams,
    )

  public paymentsGetSFTPDeliveries = async (
    requestParams: PaymentsControllerGetSFTPDeliveriesRequest,
  ): Promise<SftpDeliveryListDto> =>
    await this.paymentsService.paymentsControllerGetSFTPDeliveries(
      requestParams,
    )
  public paymentsCreateSFTPDeliveries = async (
    requestParams: PaymentsControllerSaveSFTPDeliveryRequest,
  ): Promise<SFTPDeliveryDtoResponse> =>
    await this.paymentsService.paymentsControllerSaveSFTPDelivery(requestParams)

  public paymentsUpdateSFTPDeliveries = async (
    requestParams: PaymentsControllerUpdateSFTPDeliveryRequest,
  ): Promise<void> =>
    await this.paymentsService.paymentsControllerUpdateSFTPDelivery(
      requestParams,
    )

  public paymentsDeleteSFTPDeliveries = async (
    requestParams: PaymentsControllerDeleteSFTPDeliveryByIdRequest,
  ): Promise<void> =>
    await this.paymentsService.paymentsControllerDeleteSFTPDeliveryById(
      requestParams,
    )
  public paymentsGetErrorDeliveries = async (
    requestParams: PaymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveriesRequest,
  ): Promise<PaymentErrorsEmailDeliveryListDto> =>
    await this.paymentsService.paymentsErrorsEmailDeliveryControllerGetPaymentErrorsEmailDeliveries(
      requestParams,
    )

  public paymentsGetErrorEvents = async (
    requestParams: PaymentsControllerGetEventsRequest,
  ): Promise<PaymentEventsListDto> =>
    await this.paymentsService.paymentsControllerGetEvents(requestParams)

  public paymentsPostErrorDeliveries = async (
    requestParams: PaymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDeliveryRequest,
  ): Promise<PaymentEmailDelivery> =>
    await this.paymentsService.paymentsErrorsEmailDeliveryControllerCreatePaymentErrorsEmailDelivery(
      requestParams,
    )
  public paymentsUpdateErrorDeliveries = async (
    requestParams: PaymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDeliveryRequest,
  ): Promise<PaymentErrorsEmailDelivery> =>
    await this.paymentsService.paymentsErrorsEmailDeliveryControllerUpdatePaymentEmailDelivery(
      requestParams,
    )
  public paymentsDeleteErrorDeliveries = async (
    requestParams: PaymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDeliveryRequest,
  ): Promise<void> =>
    await this.paymentsService.paymentsErrorsEmailDeliveryControllerDeletePaymentErrorsEmailDelivery(
      requestParams,
    )
}
