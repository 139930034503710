/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarAddressDto
 */
export interface EnergyStarAddressDto {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarAddressDto
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarAddressDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarAddressDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarAddressDto
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarAddressDto
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarAddressDto
     */
    county?: string;
}

export function EnergyStarAddressDtoFromJSON(json: any): EnergyStarAddressDto {
    return EnergyStarAddressDtoFromJSONTyped(json, false);
}

export function EnergyStarAddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarAddressDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address1': json['address1'],
        'city': json['city'],
        'state': json['state'],
        'postalCode': json['postalCode'],
        'country': json['country'],
        'county': !exists(json, 'county') ? undefined : json['county'],
    };
}

export function EnergyStarAddressDtoToJSON(value?: EnergyStarAddressDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address1': value.address1,
        'city': value.city,
        'state': value.state,
        'postalCode': value.postalCode,
        'country': value.country,
        'county': value.county,
    };
}


