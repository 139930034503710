/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExchangeRateListDto,
    ExchangeRateListDtoFromJSON,
    ExchangeRateListDtoToJSON,
} from '../models';

export interface ExchangeRatesControllerGetExchangeRatesRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<ExchangeRatesControllerGetExchangeRatesExcludeEnum>;
    startDate?: Date;
    endDate?: Date;
    currency?: Array<string>;
    orderBy?: Array<ExchangeRatesControllerGetExchangeRatesOrderByEnum>;
}

/**
 * 
 */
export class ExchangeRatesApi extends runtime.BaseAPI {

    /**
     * Get date specific exchange rates
     */
    async exchangeRatesControllerGetExchangeRatesRaw(requestParameters: ExchangeRatesControllerGetExchangeRatesRequest): Promise<runtime.ApiResponse<ExchangeRateListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.currency) {
            queryParameters['currency'] = requestParameters.currency;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/exchange-rates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExchangeRateListDtoFromJSON(jsonValue));
    }

    /**
     * Get date specific exchange rates
     */
    async exchangeRatesControllerGetExchangeRates(requestParameters: ExchangeRatesControllerGetExchangeRatesRequest): Promise<ExchangeRateListDto> {
        const response = await this.exchangeRatesControllerGetExchangeRatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get app supported currencies
     */
    async exchangeRatesControllerGetSupportedCurrenciesRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/exchange-rates/supported-currencies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Get app supported currencies
     */
    async exchangeRatesControllerGetSupportedCurrencies(): Promise<void> {
        await this.exchangeRatesControllerGetSupportedCurrenciesRaw();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ExchangeRatesControllerGetExchangeRatesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum ExchangeRatesControllerGetExchangeRatesOrderByEnum {
    DateAsc = 'date.asc',
    CurrencyAsc = 'currency.asc',
    DateDesc = 'date.desc',
    CurrencyDesc = 'currency.desc'
}
