/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarContactDto,
    EnergyStarContactDtoFromJSON,
    EnergyStarContactDtoFromJSONTyped,
    EnergyStarContactDtoToJSON,
    EnergyStarOrganizationDto,
    EnergyStarOrganizationDtoFromJSON,
    EnergyStarOrganizationDtoFromJSONTyped,
    EnergyStarOrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarUnmappedCustomerDto
 */
export interface EnergyStarUnmappedCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarUnmappedCustomerDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarUnmappedCustomerDto
     */
    includeTestPropertiesInGraphics: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarUnmappedCustomerDto
     */
    billboardMetric: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarUnmappedCustomerDto
     */
    languagePreference: string;
    /**
     * 
     * @type {EnergyStarContactDto}
     * @memberof EnergyStarUnmappedCustomerDto
     */
    accountInfo: EnergyStarContactDto;
    /**
     * 
     * @type {EnergyStarOrganizationDto}
     * @memberof EnergyStarUnmappedCustomerDto
     */
    organization: EnergyStarOrganizationDto;
}

export function EnergyStarUnmappedCustomerDtoFromJSON(json: any): EnergyStarUnmappedCustomerDto {
    return EnergyStarUnmappedCustomerDtoFromJSONTyped(json, false);
}

export function EnergyStarUnmappedCustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarUnmappedCustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'includeTestPropertiesInGraphics': json['includeTestPropertiesInGraphics'],
        'billboardMetric': json['billboardMetric'],
        'languagePreference': json['languagePreference'],
        'accountInfo': EnergyStarContactDtoFromJSON(json['accountInfo']),
        'organization': EnergyStarOrganizationDtoFromJSON(json['organization']),
    };
}

export function EnergyStarUnmappedCustomerDtoToJSON(value?: EnergyStarUnmappedCustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'includeTestPropertiesInGraphics': value.includeTestPropertiesInGraphics,
        'billboardMetric': value.billboardMetric,
        'languagePreference': value.languagePreference,
        'accountInfo': EnergyStarContactDtoToJSON(value.accountInfo),
        'organization': EnergyStarOrganizationDtoToJSON(value.organization),
    };
}


