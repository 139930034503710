/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentDatesDTO
 */
export interface PaymentDatesDTO {
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentDatesDTO
     */
    dates: Array<string>;
}

export function PaymentDatesDTOFromJSON(json: any): PaymentDatesDTO {
    return PaymentDatesDTOFromJSONTyped(json, false);
}

export function PaymentDatesDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentDatesDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dates': json['dates'],
    };
}

export function PaymentDatesDTOToJSON(value?: PaymentDatesDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dates': value.dates,
    };
}


