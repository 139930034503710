import { App } from 'vue'

const pluginsContext = require.context('./', true, /plugin.index.ts$/i)
export default {
  install: (app: App): void => {
    for (const key of pluginsContext.keys()) {
      pluginsContext(key).default.install(app)
    }
  },
}

export const loadComponents = (
  app: App,
  context: typeof pluginsContext,
): void => {
  for (const key of context.keys()) {
    app.component(context(key).default.name, context(key).default)
  }
}
