import { RouteRecordRaw } from 'vue-router'
import PaymentErrorsTable from '@/Modules/Errors/views/view.payments-error.vue'
import CommunicationErrorTable from '@/Modules/Errors/views/view.communication-error-log.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/paymenterrors',
    name: 'errorsTab.paymenterrors',
    component: PaymentErrorsTable,
    meta: {
      showBreadcrumbs: true,
      breadcrumb: {
        titleTemplate: 'Payments Error Log',
      },
    },
  },
  {
    path: '/communicationerrors',
    component: CommunicationErrorTable,
    name: 'errorsTab.communicationerrors',
    meta: {
      breadcrumb: {
        titleTemplate: 'Communication Error Log',
      },
      showBreadcrumbs: true,
    },
  },
]
export default routes
