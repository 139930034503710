/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CarbonFootprint,
    CarbonFootprintFromJSON,
    CarbonFootprintToJSON,
    CarbonFootprintDto,
    CarbonFootprintDtoFromJSON,
    CarbonFootprintDtoToJSON,
    ChatTagsListDto,
    ChatTagsListDtoFromJSON,
    ChatTagsListDtoToJSON,
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
    CustomerDto,
    CustomerDtoFromJSON,
    CustomerDtoToJSON,
    CustomerExceptionsResponseDto,
    CustomerExceptionsResponseDtoFromJSON,
    CustomerExceptionsResponseDtoToJSON,
    CustomerListDto,
    CustomerListDtoFromJSON,
    CustomerListDtoToJSON,
    ExceptionsClosestMatchesListDto,
    ExceptionsClosestMatchesListDtoFromJSON,
    ExceptionsClosestMatchesListDtoToJSON,
    Location,
    LocationFromJSON,
    LocationToJSON,
    UsersListDto,
    UsersListDtoFromJSON,
    UsersListDtoToJSON,
} from '../models';

export interface CustomersControllerCreateCarbonFootprintDetailsRequest {
    customerId: number;
    carbonFootprintDto: CarbonFootprintDto;
}

export interface CustomersControllerCreateCustomerRequest {
    customerDto: CustomerDto;
}

export interface CustomersControllerGetCarbonFootprintDetailsRequest {
    customerId: number;
}

export interface CustomersControllerGetChatTagsListRequest {
    customerId: number;
}

export interface CustomersControllerGetCustomerRequest {
    customerId: number;
}

export interface CustomersControllerGetExceptionsByCustomerIdRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomersControllerGetExceptionsByCustomerIdExcludeEnum>;
    virtualAccountId?: number;
    searchQuery?: string;
    orderBy?: Array<CustomersControllerGetExceptionsByCustomerIdOrderByEnum>;
}

export interface CustomersControllerGetExceptionsClosestMatchesRequest {
    customerId: number;
    searchQuery: string;
}

export interface CustomersControllerGetListRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<CustomersControllerGetListExcludeEnum>;
    orderBy?: Array<string>;
    searchQuery?: string;
    active?: boolean;
    ids?: Array<number>;
}

export interface CustomersControllerGetMatchesLocationsRequest {
    customerId: number;
    serviceZip?: number;
}

export interface CustomersControllerGetUsersByCustomerIdRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomersControllerGetUsersByCustomerIdExcludeEnum>;
    orderBy?: Array<CustomersControllerGetUsersByCustomerIdOrderByEnum>;
    email?: string;
    searchQuery?: string;
}

export interface CustomersControllerUpdateCarbonFootprintDetailsRequest {
    customerId: number;
    carbonFootprintDto: CarbonFootprintDto;
}

export interface CustomersControllerUpdateCustomerRequest {
    customerId: number;
    customerDto: CustomerDto;
}

/**
 * 
 */
export class CustomersApi extends runtime.BaseAPI {

    /**
     * Set up customer\'s carbon footprint details - create
     */
    async customersControllerCreateCarbonFootprintDetailsRaw(requestParameters: CustomersControllerCreateCarbonFootprintDetailsRequest): Promise<runtime.ApiResponse<CarbonFootprint>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerCreateCarbonFootprintDetails.');
        }

        if (requestParameters.carbonFootprintDto === null || requestParameters.carbonFootprintDto === undefined) {
            throw new runtime.RequiredError('carbonFootprintDto','Required parameter requestParameters.carbonFootprintDto was null or undefined when calling customersControllerCreateCarbonFootprintDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/carbon-footprint`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CarbonFootprintDtoToJSON(requestParameters.carbonFootprintDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CarbonFootprintFromJSON(jsonValue));
    }

    /**
     * Set up customer\'s carbon footprint details - create
     */
    async customersControllerCreateCarbonFootprintDetails(requestParameters: CustomersControllerCreateCarbonFootprintDetailsRequest): Promise<CarbonFootprint> {
        const response = await this.customersControllerCreateCarbonFootprintDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Creates a customer
     */
    async customersControllerCreateCustomerRaw(requestParameters: CustomersControllerCreateCustomerRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customerDto === null || requestParameters.customerDto === undefined) {
            throw new runtime.RequiredError('customerDto','Required parameter requestParameters.customerDto was null or undefined when calling customersControllerCreateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerDtoToJSON(requestParameters.customerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Creates a customer
     */
    async customersControllerCreateCustomer(requestParameters: CustomersControllerCreateCustomerRequest): Promise<Customer> {
        const response = await this.customersControllerCreateCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns customer\'s carbon footprint setup
     */
    async customersControllerGetCarbonFootprintDetailsRaw(requestParameters: CustomersControllerGetCarbonFootprintDetailsRequest): Promise<runtime.ApiResponse<CarbonFootprint>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetCarbonFootprintDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/carbon-footprint`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CarbonFootprintFromJSON(jsonValue));
    }

    /**
     * Returns customer\'s carbon footprint setup
     */
    async customersControllerGetCarbonFootprintDetails(requestParameters: CustomersControllerGetCarbonFootprintDetailsRequest): Promise<CarbonFootprint> {
        const response = await this.customersControllerGetCarbonFootprintDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the list of available chat tags
     */
    async customersControllerGetChatTagsListRaw(requestParameters: CustomersControllerGetChatTagsListRequest): Promise<runtime.ApiResponse<ChatTagsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetChatTagsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/chat-tags`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ChatTagsListDtoFromJSON(jsonValue));
    }

    /**
     * Returns the list of available chat tags
     */
    async customersControllerGetChatTagsList(requestParameters: CustomersControllerGetChatTagsListRequest): Promise<ChatTagsListDto> {
        const response = await this.customersControllerGetChatTagsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a customer
     */
    async customersControllerGetCustomerRaw(requestParameters: CustomersControllerGetCustomerRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Returns a customer
     */
    async customersControllerGetCustomer(requestParameters: CustomersControllerGetCustomerRequest): Promise<Customer> {
        const response = await this.customersControllerGetCustomerRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of exceptions for a given customer.
     */
    async customersControllerGetExceptionsByCustomerIdRaw(requestParameters: CustomersControllerGetExceptionsByCustomerIdRequest): Promise<runtime.ApiResponse<CustomerExceptionsResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetExceptionsByCustomerId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.virtualAccountId !== undefined) {
            queryParameters['virtualAccountId'] = requestParameters.virtualAccountId;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/exceptions`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerExceptionsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of exceptions for a given customer.
     */
    async customersControllerGetExceptionsByCustomerId(requestParameters: CustomersControllerGetExceptionsByCustomerIdRequest): Promise<CustomerExceptionsResponseDto> {
        const response = await this.customersControllerGetExceptionsByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of exceptions closest matches for a given customer to match to a bill
     */
    async customersControllerGetExceptionsClosestMatchesRaw(requestParameters: CustomersControllerGetExceptionsClosestMatchesRequest): Promise<runtime.ApiResponse<ExceptionsClosestMatchesListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetExceptionsClosestMatches.');
        }

        if (requestParameters.searchQuery === null || requestParameters.searchQuery === undefined) {
            throw new runtime.RequiredError('searchQuery','Required parameter requestParameters.searchQuery was null or undefined when calling customersControllerGetExceptionsClosestMatches.');
        }

        const queryParameters: any = {};

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/exceptions/closest-matches`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ExceptionsClosestMatchesListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of exceptions closest matches for a given customer to match to a bill
     */
    async customersControllerGetExceptionsClosestMatches(requestParameters: CustomersControllerGetExceptionsClosestMatchesRequest): Promise<ExceptionsClosestMatchesListDto> {
        const response = await this.customersControllerGetExceptionsClosestMatchesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of customers
     */
    async customersControllerGetListRaw(requestParameters: CustomersControllerGetListRequest): Promise<runtime.ApiResponse<CustomerListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        if (requestParameters.ids) {
            queryParameters['ids[]'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of customers
     */
    async customersControllerGetList(requestParameters: CustomersControllerGetListRequest): Promise<CustomerListDto> {
        const response = await this.customersControllerGetListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of locations for a given customer to match to a bill
     */
    async customersControllerGetMatchesLocationsRaw(requestParameters: CustomersControllerGetMatchesLocationsRequest): Promise<runtime.ApiResponse<Array<Location>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetMatchesLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.serviceZip !== undefined) {
            queryParameters['serviceZip'] = requestParameters.serviceZip;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/exceptions/matches`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationFromJSON));
    }

    /**
     * Returns a list of locations for a given customer to match to a bill
     */
    async customersControllerGetMatchesLocations(requestParameters: CustomersControllerGetMatchesLocationsRequest): Promise<Array<Location>> {
        const response = await this.customersControllerGetMatchesLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a list of users for a given customer.
     */
    async customersControllerGetUsersByCustomerIdRaw(requestParameters: CustomersControllerGetUsersByCustomerIdRequest): Promise<runtime.ApiResponse<UsersListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerGetUsersByCustomerId.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/users`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UsersListDtoFromJSON(jsonValue));
    }

    /**
     * Returns a list of users for a given customer.
     */
    async customersControllerGetUsersByCustomerId(requestParameters: CustomersControllerGetUsersByCustomerIdRequest): Promise<UsersListDto> {
        const response = await this.customersControllerGetUsersByCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set up customer\'s carbon footprint details - update
     */
    async customersControllerUpdateCarbonFootprintDetailsRaw(requestParameters: CustomersControllerUpdateCarbonFootprintDetailsRequest): Promise<runtime.ApiResponse<CarbonFootprint>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerUpdateCarbonFootprintDetails.');
        }

        if (requestParameters.carbonFootprintDto === null || requestParameters.carbonFootprintDto === undefined) {
            throw new runtime.RequiredError('carbonFootprintDto','Required parameter requestParameters.carbonFootprintDto was null or undefined when calling customersControllerUpdateCarbonFootprintDetails.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/carbon-footprint`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CarbonFootprintDtoToJSON(requestParameters.carbonFootprintDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CarbonFootprintFromJSON(jsonValue));
    }

    /**
     * Set up customer\'s carbon footprint details - update
     */
    async customersControllerUpdateCarbonFootprintDetails(requestParameters: CustomersControllerUpdateCarbonFootprintDetailsRequest): Promise<CarbonFootprint> {
        const response = await this.customersControllerUpdateCarbonFootprintDetailsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a customer
     */
    async customersControllerUpdateCustomerRaw(requestParameters: CustomersControllerUpdateCustomerRequest): Promise<runtime.ApiResponse<Customer>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customersControllerUpdateCustomer.');
        }

        if (requestParameters.customerDto === null || requestParameters.customerDto === undefined) {
            throw new runtime.RequiredError('customerDto','Required parameter requestParameters.customerDto was null or undefined when calling customersControllerUpdateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerDtoToJSON(requestParameters.customerDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Updates a customer
     */
    async customersControllerUpdateCustomer(requestParameters: CustomersControllerUpdateCustomerRequest): Promise<Customer> {
        const response = await this.customersControllerUpdateCustomerRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomersControllerGetExceptionsByCustomerIdExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomersControllerGetExceptionsByCustomerIdOrderByEnum {
    VirtualAccountIdAsc = 'virtualAccountId.asc',
    PrettyNameAsc = 'prettyName.asc',
    MeterSerialAsc = 'meterSerial.asc',
    AccountCodeAsc = 'accountCode.asc',
    ServiceAddressAsc = 'serviceAddress.asc',
    ServiceZipAsc = 'serviceZip.asc',
    ItemsCountAsc = 'itemsCount.asc',
    VirtualAccountIdDesc = 'virtualAccountId.desc',
    PrettyNameDesc = 'prettyName.desc',
    MeterSerialDesc = 'meterSerial.desc',
    AccountCodeDesc = 'accountCode.desc',
    ServiceAddressDesc = 'serviceAddress.desc',
    ServiceZipDesc = 'serviceZip.desc',
    ItemsCountDesc = 'itemsCount.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomersControllerGetListExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomersControllerGetUsersByCustomerIdExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomersControllerGetUsersByCustomerIdOrderByEnum {
    IdAsc = 'id.asc',
    ActiveAsc = 'active.asc',
    LastLoginAsc = 'lastLogin.asc',
    LastNameAsc = 'lastName.asc',
    FirstNameAsc = 'firstName.asc',
    ChatTagAsc = 'chatTag.asc',
    GroupTagAsc = 'groupTag.asc',
    EmailAsc = 'email.asc',
    IdDesc = 'id.desc',
    ActiveDesc = 'active.desc',
    LastLoginDesc = 'lastLogin.desc',
    LastNameDesc = 'lastName.desc',
    FirstNameDesc = 'firstName.desc',
    ChatTagDesc = 'chatTag.desc',
    GroupTagDesc = 'groupTag.desc',
    EmailDesc = 'email.desc'
}
