import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26ccd061"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }
const _hoisted_2 = {
  key: 0,
  class: "required-star"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: $props.label,
    class: _normalizeClass($props.classes),
    prop: $props.prop,
    rules: $props.rules,
    size: $props.size,
    "label-width": $props.labelWidth
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "pre", {}, undefined, true),
        ($props.requiredStar)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
          : _createCommentVNode("", true),
        _createVNode(_component_el_select, _mergeProps({
          placeholder: $props.placeholder,
          "model-value": $props.modelValue
        }, _ctx.$attrs, {
          filterable: "",
          clearable: ""
        }), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 16, ["placeholder", "model-value"])
      ])
    ]),
    _: 3
  }, 8, ["label", "class", "prop", "rules", "size", "label-width"]))
}