/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SftpPublicKeyDto
 */
export interface SftpPublicKeyDto {
    /**
     * 
     * @type {string}
     * @memberof SftpPublicKeyDto
     */
    sshPublicKey: string;
}

export function SftpPublicKeyDtoFromJSON(json: any): SftpPublicKeyDto {
    return SftpPublicKeyDtoFromJSONTyped(json, false);
}

export function SftpPublicKeyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SftpPublicKeyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sshPublicKey': json['sshPublicKey'],
    };
}

export function SftpPublicKeyDtoToJSON(value?: SftpPublicKeyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sshPublicKey': value.sshPublicKey,
    };
}


