/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillErrorsFiltersDto,
    BillErrorsFiltersDtoFromJSON,
    BillErrorsFiltersDtoFromJSONTyped,
    BillErrorsFiltersDtoToJSON,
    BillTypesFiltersDto,
    BillTypesFiltersDtoFromJSON,
    BillTypesFiltersDtoFromJSONTyped,
    BillTypesFiltersDtoToJSON,
    CustomIdMap,
    CustomIdMapFromJSON,
    CustomIdMapFromJSONTyped,
    CustomIdMapToJSON,
    CustomersFiltersDto,
    CustomersFiltersDtoFromJSON,
    CustomersFiltersDtoFromJSONTyped,
    CustomersFiltersDtoToJSON,
    VendorCodesFiltersDto,
    VendorCodesFiltersDtoFromJSON,
    VendorCodesFiltersDtoFromJSONTyped,
    VendorCodesFiltersDtoToJSON,
    WorkflowStatesFiltersDto,
    WorkflowStatesFiltersDtoFromJSON,
    WorkflowStatesFiltersDtoFromJSONTyped,
    WorkflowStatesFiltersDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillFiltersDto
 */
export interface BillFiltersDto {
    /**
     * 
     * @type {Array<CustomersFiltersDto>}
     * @memberof BillFiltersDto
     */
    customers: Array<CustomersFiltersDto>;
    /**
     * 
     * @type {Array<VendorCodesFiltersDto>}
     * @memberof BillFiltersDto
     */
    vendorCodes: Array<VendorCodesFiltersDto>;
    /**
     * 
     * @type {Array<CustomIdMap>}
     * @memberof BillFiltersDto
     */
    users: Array<CustomIdMap>;
    /**
     * 
     * @type {Array<WorkflowStatesFiltersDto>}
     * @memberof BillFiltersDto
     */
    workflowStates: Array<WorkflowStatesFiltersDto>;
    /**
     * 
     * @type {Array<BillTypesFiltersDto>}
     * @memberof BillFiltersDto
     */
    billTypes: Array<BillTypesFiltersDto>;
    /**
     * 
     * @type {Array<BillErrorsFiltersDto>}
     * @memberof BillFiltersDto
     */
    billErrors: Array<BillErrorsFiltersDto>;
}

export function BillFiltersDtoFromJSON(json: any): BillFiltersDto {
    return BillFiltersDtoFromJSONTyped(json, false);
}

export function BillFiltersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillFiltersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customers': ((json['customers'] as Array<any>).map(CustomersFiltersDtoFromJSON)),
        'vendorCodes': ((json['vendorCodes'] as Array<any>).map(VendorCodesFiltersDtoFromJSON)),
        'users': ((json['users'] as Array<any>).map(CustomIdMapFromJSON)),
        'workflowStates': ((json['workflowStates'] as Array<any>).map(WorkflowStatesFiltersDtoFromJSON)),
        'billTypes': ((json['billTypes'] as Array<any>).map(BillTypesFiltersDtoFromJSON)),
        'billErrors': ((json['billErrors'] as Array<any>).map(BillErrorsFiltersDtoFromJSON)),
    };
}

export function BillFiltersDtoToJSON(value?: BillFiltersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customers': ((value.customers as Array<any>).map(CustomersFiltersDtoToJSON)),
        'vendorCodes': ((value.vendorCodes as Array<any>).map(VendorCodesFiltersDtoToJSON)),
        'users': ((value.users as Array<any>).map(CustomIdMapToJSON)),
        'workflowStates': ((value.workflowStates as Array<any>).map(WorkflowStatesFiltersDtoToJSON)),
        'billTypes': ((value.billTypes as Array<any>).map(BillTypesFiltersDtoToJSON)),
        'billErrors': ((value.billErrors as Array<any>).map(BillErrorsFiltersDtoToJSON)),
    };
}


