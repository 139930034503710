/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccount,
    VirtualAccountFromJSON,
    VirtualAccountFromJSONTyped,
    VirtualAccountToJSON,
    VirtualAccountBulkUpdateDto,
    VirtualAccountBulkUpdateDtoFromJSON,
    VirtualAccountBulkUpdateDtoFromJSONTyped,
    VirtualAccountBulkUpdateDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountBulkBillsUpdateDto
 */
export interface VirtualAccountBulkBillsUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    retryCount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    billsIds: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    trackingId: string;
    /**
     * 
     * @type {VirtualAccountBulkUpdateDto}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    virtualAccountUpdateDto: VirtualAccountBulkUpdateDto;
    /**
     * 
     * @type {VirtualAccount}
     * @memberof VirtualAccountBulkBillsUpdateDto
     */
    virtualAccount: VirtualAccount;
}

export function VirtualAccountBulkBillsUpdateDtoFromJSON(json: any): VirtualAccountBulkBillsUpdateDto {
    return VirtualAccountBulkBillsUpdateDtoFromJSONTyped(json, false);
}

export function VirtualAccountBulkBillsUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountBulkBillsUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retryCount': json['retryCount'],
        'billsIds': json['billsIds'],
        'userId': json['userId'],
        'customerId': json['customerId'],
        'trackingId': json['trackingId'],
        'virtualAccountUpdateDto': VirtualAccountBulkUpdateDtoFromJSON(json['virtualAccountUpdateDto']),
        'virtualAccount': VirtualAccountFromJSON(json['virtualAccount']),
    };
}

export function VirtualAccountBulkBillsUpdateDtoToJSON(value?: VirtualAccountBulkBillsUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retryCount': value.retryCount,
        'billsIds': value.billsIds,
        'userId': value.userId,
        'customerId': value.customerId,
        'trackingId': value.trackingId,
        'virtualAccountUpdateDto': VirtualAccountBulkUpdateDtoToJSON(value.virtualAccountUpdateDto),
        'virtualAccount': VirtualAccountToJSON(value.virtualAccount),
    };
}


