/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExportBillDto,
    ExportBillDtoFromJSON,
    ExportBillDtoToJSON,
} from '../models';

export interface ExporterQueueControllerTestExportBillRequest {
    exportBillDto: ExportBillDto;
}

/**
 * 
 */
export class ExporterQueueApi extends runtime.BaseAPI {

    /**
     * Test the export bill async queue
     */
    async exporterQueueControllerTestExportBillRaw(requestParameters: ExporterQueueControllerTestExportBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exportBillDto === null || requestParameters.exportBillDto === undefined) {
            throw new runtime.RequiredError('exportBillDto','Required parameter requestParameters.exportBillDto was null or undefined when calling exporterQueueControllerTestExportBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/export-bill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportBillDtoToJSON(requestParameters.exportBillDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test the export bill async queue
     */
    async exporterQueueControllerTestExportBill(requestParameters: ExporterQueueControllerTestExportBillRequest): Promise<void> {
        await this.exporterQueueControllerTestExportBillRaw(requestParameters);
    }

}
