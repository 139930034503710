/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationMonthlyAttributesMappingModel
 */
export interface LocationMonthlyAttributesMappingModel {
    /**
     * 
     * @type {number}
     * @memberof LocationMonthlyAttributesMappingModel
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMonthlyAttributesMappingModel
     */
    parentId?: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMonthlyAttributesMappingModel
     */
    locationMonthlyAttributesMetadataId: number;
    /**
     * 
     * @type {string}
     * @memberof LocationMonthlyAttributesMappingModel
     */
    locationName?: string;
}

export function LocationMonthlyAttributesMappingModelFromJSON(json: any): LocationMonthlyAttributesMappingModel {
    return LocationMonthlyAttributesMappingModelFromJSONTyped(json, false);
}

export function LocationMonthlyAttributesMappingModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationMonthlyAttributesMappingModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locationId': json['locationId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'locationMonthlyAttributesMetadataId': json['locationMonthlyAttributesMetadataId'],
        'locationName': !exists(json, 'locationName') ? undefined : json['locationName'],
    };
}

export function LocationMonthlyAttributesMappingModelToJSON(value?: LocationMonthlyAttributesMappingModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locationId': value.locationId,
        'parentId': value.parentId,
        'locationMonthlyAttributesMetadataId': value.locationMonthlyAttributesMetadataId,
        'locationName': value.locationName,
    };
}


