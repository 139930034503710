/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BillsMonthlyFeedDto,
    BillsMonthlyFeedDtoFromJSON,
    BillsMonthlyFeedDtoToJSON,
    Location,
    LocationFromJSON,
    LocationToJSON,
    LocationBulkDto,
    LocationBulkDtoFromJSON,
    LocationBulkDtoToJSON,
    LocationBulkResponseDto,
    LocationBulkResponseDtoFromJSON,
    LocationBulkResponseDtoToJSON,
    LocationBulkValidateResponseDto,
    LocationBulkValidateResponseDtoFromJSON,
    LocationBulkValidateResponseDtoToJSON,
    LocationDto,
    LocationDtoFromJSON,
    LocationDtoToJSON,
    LocationListDto,
    LocationListDtoFromJSON,
    LocationListDtoToJSON,
    LocationMetricsListDto,
    LocationMetricsListDtoFromJSON,
    LocationMetricsListDtoToJSON,
    LocationMonthlyAttributesAddMonthsDto,
    LocationMonthlyAttributesAddMonthsDtoFromJSON,
    LocationMonthlyAttributesAddMonthsDtoToJSON,
    LocationMonthlyAttributesDto,
    LocationMonthlyAttributesDtoFromJSON,
    LocationMonthlyAttributesDtoToJSON,
    LocationMonthlyAttributesValues,
    LocationMonthlyAttributesValuesFromJSON,
    LocationMonthlyAttributesValuesToJSON,
    LocationMonthlyAttributesValuesDto,
    LocationMonthlyAttributesValuesDtoFromJSON,
    LocationMonthlyAttributesValuesDtoToJSON,
    LocationMonthlyMetadataAttribute,
    LocationMonthlyMetadataAttributeFromJSON,
    LocationMonthlyMetadataAttributeToJSON,
    LocationMonthlyMetadataAttributeDto,
    LocationMonthlyMetadataAttributeDtoFromJSON,
    LocationMonthlyMetadataAttributeDtoToJSON,
    LocationSustainabilityAttributesDto,
    LocationSustainabilityAttributesDtoFromJSON,
    LocationSustainabilityAttributesDtoToJSON,
    LocationSustainabilityAttributesModel,
    LocationSustainabilityAttributesModelFromJSON,
    LocationSustainabilityAttributesModelToJSON,
    MonthlyFeedCalculation,
    MonthlyFeedCalculationFromJSON,
    MonthlyFeedCalculationToJSON,
    ProductionDataListDto,
    ProductionDataListDtoFromJSON,
    ProductionDataListDtoToJSON,
    VirtualAccountBillHistoryList,
    VirtualAccountBillHistoryListFromJSON,
    VirtualAccountBillHistoryListToJSON,
    VirtualAccountGroupsListDto,
    VirtualAccountGroupsListDtoFromJSON,
    VirtualAccountGroupsListDtoToJSON,
    VirtualAccountMapDto,
    VirtualAccountMapDtoFromJSON,
    VirtualAccountMapDtoToJSON,
    VirtualAccountMapResult,
    VirtualAccountMapResultFromJSON,
    VirtualAccountMapResultToJSON,
    VirtualAccountsListModel,
    VirtualAccountsListModelFromJSON,
    VirtualAccountsListModelToJSON,
    WeatherDailyListDto,
    WeatherDailyListDtoFromJSON,
    WeatherDailyListDtoToJSON,
} from '../models';

export interface CustomerLocationControllerGetBillsMonthlyFeedRequest {
    customerId: number;
    locationId: number;
    startDate: Date;
    direction: CustomerLocationControllerGetBillsMonthlyFeedDirectionEnum;
    period: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerLocationControllerGetBillsMonthlyFeedExcludeEnum>;
    utilityType?: CustomerLocationControllerGetBillsMonthlyFeedUtilityTypeEnum;
}

export interface CustomerLocationControllerGetBillsMonthlyFeedCalculationsRequest {
    customerId: number;
    locationId: number;
    blockId: number;
    startDate: Date;
    endDate: Date;
}

export interface CustomerLocationControllerGetDailyWeatherRequest {
    locationId: number;
    startDate: Date;
    endDate: Date;
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerLocationControllerGetDailyWeatherExcludeEnum>;
}

export interface CustomerLocationsControllerAddMonthsAndYearsForProductionDataRequest {
    customerId: number;
    locationId: number;
    locationMonthlyAttributesAddMonthsDto: LocationMonthlyAttributesAddMonthsDto;
}

export interface CustomerLocationsControllerBulkCreateLocationsRequest {
    customerId: number;
    locationBulkDto: LocationBulkDto;
}

export interface CustomerLocationsControllerBulkValidateLocationsRequest {
    customerId: number;
    locationBulkDto: LocationBulkDto;
}

export interface CustomerLocationsControllerCreateLocationRequest {
    customerId: number;
    locationDto: LocationDto;
}

export interface CustomerLocationsControllerCreateLocationMonthlyForCustomerIdRequest {
    customerId: number;
    locationMonthlyMetadataAttributeDto: LocationMonthlyMetadataAttributeDto;
}

export interface CustomerLocationsControllerDeleteLocationRequest {
    customerId: number;
    locationId: number;
}

export interface CustomerLocationsControllerDeleteLocationMonthlyMetadataAttributeRequest {
    id: number;
    customerId: number;
}

export interface CustomerLocationsControllerGetCustomerLocationRequest {
    customerId: number;
    locationId: number;
}

export interface CustomerLocationsControllerGetCustomerLocationMetricsRequest {
    customerId: number;
    locationId: number;
    limit?: number;
    virtualAccountIds?: Array<number>;
    commodity?: CustomerLocationsControllerGetCustomerLocationMetricsCommodityEnum;
}

export interface CustomerLocationsControllerGetCustomerLocationsRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerLocationsControllerGetCustomerLocationsExcludeEnum>;
    orderBy?: Array<CustomerLocationsControllerGetCustomerLocationsOrderByEnum>;
    buildingTypes?: Array<CustomerLocationsControllerGetCustomerLocationsBuildingTypesEnum>;
    states?: Array<string>;
    cities?: Array<string>;
    squareFeetMin?: number;
    squareFeetMax?: number;
    searchQuery?: string;
}

export interface CustomerLocationsControllerGetLocationMonthlyAttributesListRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerLocationsControllerGetLocationMonthlyAttributesListExcludeEnum>;
    orderBy?: Array<CustomerLocationsControllerGetLocationMonthlyAttributesListOrderByEnum>;
}

export interface CustomerLocationsControllerGetLocationSustainabilityAttributesRequest {
    customerId: number;
    locationId: number;
}

export interface CustomerLocationsControllerGetProductionDataRequest {
    customerId: number;
    locationId: number;
}

export interface CustomerLocationsControllerGetVirtualAccountGroupsRequest {
    customerId: number;
    locationId: number;
    order?: string;
    offset?: number;
    limit?: number;
}

export interface CustomerLocationsControllerGetVirtualAccountsBillHistoryListRequest {
    customerId: number;
    locationId: number;
    virtualAccountId: number;
}

export interface CustomerLocationsControllerGetVirtualAccountsListRequest {
    customerId: number;
    locationId: number;
    virtualAccountId: number;
}

export interface CustomerLocationsControllerMapVirtualAccountRequest {
    customerId: number;
    locationId: number;
    virtualAccountMapDto: VirtualAccountMapDto;
}

export interface CustomerLocationsControllerSetLocationSustainabilityAttributesRequest {
    customerId: number;
    locationId: number;
    locationSustainabilityAttributesDto: LocationSustainabilityAttributesDto;
}

export interface CustomerLocationsControllerUpdateLocationRequest {
    customerId: number;
    locationId: number;
    locationDto: LocationDto;
}

export interface CustomerLocationsControllerUpdateLocationMonthlyAttributesForLocationIdRequest {
    customerId: number;
    locationId: number;
    locationMonthlyAttributesValuesDto: LocationMonthlyAttributesValuesDto;
}

export interface CustomerLocationsControllerUpdateLocationMonthlyMetadataAttributeRequest {
    customerId: number;
    id: number;
    locationMonthlyMetadataAttributeDto: LocationMonthlyMetadataAttributeDto;
}

/**
 * 
 */
export class CustomerLocationsApi extends runtime.BaseAPI {

    /**
     * Get specific bills monthly feed for a location and date interval
     */
    async customerLocationControllerGetBillsMonthlyFeedRaw(requestParameters: CustomerLocationControllerGetBillsMonthlyFeedRequest): Promise<runtime.ApiResponse<BillsMonthlyFeedDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationControllerGetBillsMonthlyFeed.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationControllerGetBillsMonthlyFeed.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling customerLocationControllerGetBillsMonthlyFeed.');
        }

        if (requestParameters.direction === null || requestParameters.direction === undefined) {
            throw new runtime.RequiredError('direction','Required parameter requestParameters.direction was null or undefined when calling customerLocationControllerGetBillsMonthlyFeed.');
        }

        if (requestParameters.period === null || requestParameters.period === undefined) {
            throw new runtime.RequiredError('period','Required parameter requestParameters.period was null or undefined when calling customerLocationControllerGetBillsMonthlyFeed.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.direction !== undefined) {
            queryParameters['direction'] = requestParameters.direction;
        }

        if (requestParameters.period !== undefined) {
            queryParameters['period'] = requestParameters.period;
        }

        if (requestParameters.utilityType !== undefined) {
            queryParameters['utilityType'] = requestParameters.utilityType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/bills-monthly-feed`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillsMonthlyFeedDtoFromJSON(jsonValue));
    }

    /**
     * Get specific bills monthly feed for a location and date interval
     */
    async customerLocationControllerGetBillsMonthlyFeed(requestParameters: CustomerLocationControllerGetBillsMonthlyFeedRequest): Promise<BillsMonthlyFeedDto> {
        const response = await this.customerLocationControllerGetBillsMonthlyFeedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get calculations for bills monthly feed for a location, date interval and block
     */
    async customerLocationControllerGetBillsMonthlyFeedCalculationsRaw(requestParameters: CustomerLocationControllerGetBillsMonthlyFeedCalculationsRequest): Promise<runtime.ApiResponse<Array<MonthlyFeedCalculation>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationControllerGetBillsMonthlyFeedCalculations.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationControllerGetBillsMonthlyFeedCalculations.');
        }

        if (requestParameters.blockId === null || requestParameters.blockId === undefined) {
            throw new runtime.RequiredError('blockId','Required parameter requestParameters.blockId was null or undefined when calling customerLocationControllerGetBillsMonthlyFeedCalculations.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling customerLocationControllerGetBillsMonthlyFeedCalculations.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling customerLocationControllerGetBillsMonthlyFeedCalculations.');
        }

        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/bills-monthly-feed/{blockId}/calculations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"blockId"}}`, encodeURIComponent(String(requestParameters.blockId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MonthlyFeedCalculationFromJSON));
    }

    /**
     * Get calculations for bills monthly feed for a location, date interval and block
     */
    async customerLocationControllerGetBillsMonthlyFeedCalculations(requestParameters: CustomerLocationControllerGetBillsMonthlyFeedCalculationsRequest): Promise<Array<MonthlyFeedCalculation>> {
        const response = await this.customerLocationControllerGetBillsMonthlyFeedCalculationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get specific weather readings for a given location and date interval
     */
    async customerLocationControllerGetDailyWeatherRaw(requestParameters: CustomerLocationControllerGetDailyWeatherRequest): Promise<runtime.ApiResponse<WeatherDailyListDto>> {
        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationControllerGetDailyWeather.');
        }

        if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
            throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling customerLocationControllerGetDailyWeather.');
        }

        if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
            throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling customerLocationControllerGetDailyWeather.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationControllerGetDailyWeather.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/weather`.replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WeatherDailyListDtoFromJSON(jsonValue));
    }

    /**
     * Get specific weather readings for a given location and date interval
     */
    async customerLocationControllerGetDailyWeather(requestParameters: CustomerLocationControllerGetDailyWeatherRequest): Promise<WeatherDailyListDto> {
        const response = await this.customerLocationControllerGetDailyWeatherRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create location monthly attributes values for a given location
     */
    async customerLocationsControllerAddMonthsAndYearsForProductionDataRaw(requestParameters: CustomerLocationsControllerAddMonthsAndYearsForProductionDataRequest): Promise<runtime.ApiResponse<ProductionDataListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerAddMonthsAndYearsForProductionData.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerAddMonthsAndYearsForProductionData.');
        }

        if (requestParameters.locationMonthlyAttributesAddMonthsDto === null || requestParameters.locationMonthlyAttributesAddMonthsDto === undefined) {
            throw new runtime.RequiredError('locationMonthlyAttributesAddMonthsDto','Required parameter requestParameters.locationMonthlyAttributesAddMonthsDto was null or undefined when calling customerLocationsControllerAddMonthsAndYearsForProductionData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/create-monthly-attributes-values/{locationId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationMonthlyAttributesAddMonthsDtoToJSON(requestParameters.locationMonthlyAttributesAddMonthsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductionDataListDtoFromJSON(jsonValue));
    }

    /**
     * Create location monthly attributes values for a given location
     */
    async customerLocationsControllerAddMonthsAndYearsForProductionData(requestParameters: CustomerLocationsControllerAddMonthsAndYearsForProductionDataRequest): Promise<ProductionDataListDto> {
        const response = await this.customerLocationsControllerAddMonthsAndYearsForProductionDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Bulk create locations
     */
    async customerLocationsControllerBulkCreateLocationsRaw(requestParameters: CustomerLocationsControllerBulkCreateLocationsRequest): Promise<runtime.ApiResponse<LocationBulkResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerBulkCreateLocations.');
        }

        if (requestParameters.locationBulkDto === null || requestParameters.locationBulkDto === undefined) {
            throw new runtime.RequiredError('locationBulkDto','Required parameter requestParameters.locationBulkDto was null or undefined when calling customerLocationsControllerBulkCreateLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/bulk`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationBulkDtoToJSON(requestParameters.locationBulkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationBulkResponseDtoFromJSON(jsonValue));
    }

    /**
     * Bulk create locations
     */
    async customerLocationsControllerBulkCreateLocations(requestParameters: CustomerLocationsControllerBulkCreateLocationsRequest): Promise<LocationBulkResponseDto> {
        const response = await this.customerLocationsControllerBulkCreateLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Bulk validate locations
     */
    async customerLocationsControllerBulkValidateLocationsRaw(requestParameters: CustomerLocationsControllerBulkValidateLocationsRequest): Promise<runtime.ApiResponse<LocationBulkValidateResponseDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerBulkValidateLocations.');
        }

        if (requestParameters.locationBulkDto === null || requestParameters.locationBulkDto === undefined) {
            throw new runtime.RequiredError('locationBulkDto','Required parameter requestParameters.locationBulkDto was null or undefined when calling customerLocationsControllerBulkValidateLocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/bulk-validate`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationBulkDtoToJSON(requestParameters.locationBulkDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationBulkValidateResponseDtoFromJSON(jsonValue));
    }

    /**
     * Bulk validate locations
     */
    async customerLocationsControllerBulkValidateLocations(requestParameters: CustomerLocationsControllerBulkValidateLocationsRequest): Promise<LocationBulkValidateResponseDto> {
        const response = await this.customerLocationsControllerBulkValidateLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create location
     */
    async customerLocationsControllerCreateLocationRaw(requestParameters: CustomerLocationsControllerCreateLocationRequest): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerCreateLocation.');
        }

        if (requestParameters.locationDto === null || requestParameters.locationDto === undefined) {
            throw new runtime.RequiredError('locationDto','Required parameter requestParameters.locationDto was null or undefined when calling customerLocationsControllerCreateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationDtoToJSON(requestParameters.locationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     * Create location
     */
    async customerLocationsControllerCreateLocation(requestParameters: CustomerLocationsControllerCreateLocationRequest): Promise<Location> {
        const response = await this.customerLocationsControllerCreateLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create location monthly attributes for a given customer
     */
    async customerLocationsControllerCreateLocationMonthlyForCustomerIdRaw(requestParameters: CustomerLocationsControllerCreateLocationMonthlyForCustomerIdRequest): Promise<runtime.ApiResponse<LocationMonthlyMetadataAttribute>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerCreateLocationMonthlyForCustomerId.');
        }

        if (requestParameters.locationMonthlyMetadataAttributeDto === null || requestParameters.locationMonthlyMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('locationMonthlyMetadataAttributeDto','Required parameter requestParameters.locationMonthlyMetadataAttributeDto was null or undefined when calling customerLocationsControllerCreateLocationMonthlyForCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/create-monthly-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationMonthlyMetadataAttributeDtoToJSON(requestParameters.locationMonthlyMetadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMonthlyMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Create location monthly attributes for a given customer
     */
    async customerLocationsControllerCreateLocationMonthlyForCustomerId(requestParameters: CustomerLocationsControllerCreateLocationMonthlyForCustomerIdRequest): Promise<LocationMonthlyMetadataAttribute> {
        const response = await this.customerLocationsControllerCreateLocationMonthlyForCustomerIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete location
     */
    async customerLocationsControllerDeleteLocationRaw(requestParameters: CustomerLocationsControllerDeleteLocationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerDeleteLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerDeleteLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete location
     */
    async customerLocationsControllerDeleteLocation(requestParameters: CustomerLocationsControllerDeleteLocationRequest): Promise<void> {
        await this.customerLocationsControllerDeleteLocationRaw(requestParameters);
    }

    /**
     * Delete location monthly attribute for a given customer
     */
    async customerLocationsControllerDeleteLocationMonthlyMetadataAttributeRaw(requestParameters: CustomerLocationsControllerDeleteLocationMonthlyMetadataAttributeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerLocationsControllerDeleteLocationMonthlyMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerDeleteLocationMonthlyMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/delete-monthly-attribute/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete location monthly attribute for a given customer
     */
    async customerLocationsControllerDeleteLocationMonthlyMetadataAttribute(requestParameters: CustomerLocationsControllerDeleteLocationMonthlyMetadataAttributeRequest): Promise<void> {
        await this.customerLocationsControllerDeleteLocationMonthlyMetadataAttributeRaw(requestParameters);
    }

    /**
     * Get a location by it\'s id
     */
    async customerLocationsControllerGetCustomerLocationRaw(requestParameters: CustomerLocationsControllerGetCustomerLocationRequest): Promise<runtime.ApiResponse<Location>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetCustomerLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetCustomerLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationFromJSON(jsonValue));
    }

    /**
     * Get a location by it\'s id
     */
    async customerLocationsControllerGetCustomerLocation(requestParameters: CustomerLocationsControllerGetCustomerLocationRequest): Promise<Location> {
        const response = await this.customerLocationsControllerGetCustomerLocationRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a location metrics
     */
    async customerLocationsControllerGetCustomerLocationMetricsRaw(requestParameters: CustomerLocationsControllerGetCustomerLocationMetricsRequest): Promise<runtime.ApiResponse<LocationMetricsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetCustomerLocationMetrics.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetCustomerLocationMetrics.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.virtualAccountIds) {
            queryParameters['virtualAccountIds[]'] = requestParameters.virtualAccountIds;
        }

        if (requestParameters.commodity !== undefined) {
            queryParameters['commodity'] = requestParameters.commodity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/metrics`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMetricsListDtoFromJSON(jsonValue));
    }

    /**
     * Get a location metrics
     */
    async customerLocationsControllerGetCustomerLocationMetrics(requestParameters: CustomerLocationsControllerGetCustomerLocationMetricsRequest): Promise<LocationMetricsListDto> {
        const response = await this.customerLocationsControllerGetCustomerLocationMetricsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of locations relative to a customer
     */
    async customerLocationsControllerGetCustomerLocationsRaw(requestParameters: CustomerLocationsControllerGetCustomerLocationsRequest): Promise<runtime.ApiResponse<LocationListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetCustomerLocations.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.buildingTypes) {
            queryParameters['buildingTypes[]'] = requestParameters.buildingTypes;
        }

        if (requestParameters.states) {
            queryParameters['states[]'] = requestParameters.states;
        }

        if (requestParameters.cities) {
            queryParameters['cities[]'] = requestParameters.cities;
        }

        if (requestParameters.squareFeetMin !== undefined) {
            queryParameters['squareFeetMin'] = requestParameters.squareFeetMin;
        }

        if (requestParameters.squareFeetMax !== undefined) {
            queryParameters['squareFeetMax'] = requestParameters.squareFeetMax;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of locations relative to a customer
     */
    async customerLocationsControllerGetCustomerLocations(requestParameters: CustomerLocationsControllerGetCustomerLocationsRequest): Promise<LocationListDto> {
        const response = await this.customerLocationsControllerGetCustomerLocationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the location monthly attributes list
     */
    async customerLocationsControllerGetLocationMonthlyAttributesListRaw(requestParameters: CustomerLocationsControllerGetLocationMonthlyAttributesListRequest): Promise<runtime.ApiResponse<LocationMonthlyAttributesDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetLocationMonthlyAttributesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/monthly-attributes/list`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMonthlyAttributesDtoFromJSON(jsonValue));
    }

    /**
     * Get the location monthly attributes list
     */
    async customerLocationsControllerGetLocationMonthlyAttributesList(requestParameters: CustomerLocationsControllerGetLocationMonthlyAttributesListRequest): Promise<LocationMonthlyAttributesDto> {
        const response = await this.customerLocationsControllerGetLocationMonthlyAttributesListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get location sustainability attributes
     */
    async customerLocationsControllerGetLocationSustainabilityAttributesRaw(requestParameters: CustomerLocationsControllerGetLocationSustainabilityAttributesRequest): Promise<runtime.ApiResponse<LocationSustainabilityAttributesModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetLocationSustainabilityAttributes.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetLocationSustainabilityAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/sustainability-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationSustainabilityAttributesModelFromJSON(jsonValue));
    }

    /**
     * Get location sustainability attributes
     */
    async customerLocationsControllerGetLocationSustainabilityAttributes(requestParameters: CustomerLocationsControllerGetLocationSustainabilityAttributesRequest): Promise<LocationSustainabilityAttributesModel> {
        const response = await this.customerLocationsControllerGetLocationSustainabilityAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get production data for a location
     */
    async customerLocationsControllerGetProductionDataRaw(requestParameters: CustomerLocationsControllerGetProductionDataRequest): Promise<runtime.ApiResponse<ProductionDataListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetProductionData.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetProductionData.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/get-production-data`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductionDataListDtoFromJSON(jsonValue));
    }

    /**
     * Get production data for a location
     */
    async customerLocationsControllerGetProductionData(requestParameters: CustomerLocationsControllerGetProductionDataRequest): Promise<ProductionDataListDto> {
        const response = await this.customerLocationsControllerGetProductionDataRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of virtual account groups for a given customer
     */
    async customerLocationsControllerGetVirtualAccountGroupsRaw(requestParameters: CustomerLocationsControllerGetVirtualAccountGroupsRequest): Promise<runtime.ApiResponse<VirtualAccountGroupsListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetVirtualAccountGroups.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetVirtualAccountGroups.');
        }

        const queryParameters: any = {};

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/virtual-account-groups`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountGroupsListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of virtual account groups for a given customer
     */
    async customerLocationsControllerGetVirtualAccountGroups(requestParameters: CustomerLocationsControllerGetVirtualAccountGroupsRequest): Promise<VirtualAccountGroupsListDto> {
        const response = await this.customerLocationsControllerGetVirtualAccountGroupsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get virtual accounts bill history list
     */
    async customerLocationsControllerGetVirtualAccountsBillHistoryListRaw(requestParameters: CustomerLocationsControllerGetVirtualAccountsBillHistoryListRequest): Promise<runtime.ApiResponse<VirtualAccountBillHistoryList>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetVirtualAccountsBillHistoryList.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetVirtualAccountsBillHistoryList.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerLocationsControllerGetVirtualAccountsBillHistoryList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/virtual-account-groups/{virtualAccountId}/bills`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountBillHistoryListFromJSON(jsonValue));
    }

    /**
     * Get virtual accounts bill history list
     */
    async customerLocationsControllerGetVirtualAccountsBillHistoryList(requestParameters: CustomerLocationsControllerGetVirtualAccountsBillHistoryListRequest): Promise<VirtualAccountBillHistoryList> {
        const response = await this.customerLocationsControllerGetVirtualAccountsBillHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get virtual accounts list by virtual account id
     */
    async customerLocationsControllerGetVirtualAccountsListRaw(requestParameters: CustomerLocationsControllerGetVirtualAccountsListRequest): Promise<runtime.ApiResponse<VirtualAccountsListModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerGetVirtualAccountsList.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerGetVirtualAccountsList.');
        }

        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerLocationsControllerGetVirtualAccountsList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/virtual-accounts/{virtualAccountId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))).replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountsListModelFromJSON(jsonValue));
    }

    /**
     * Get virtual accounts list by virtual account id
     */
    async customerLocationsControllerGetVirtualAccountsList(requestParameters: CustomerLocationsControllerGetVirtualAccountsListRequest): Promise<VirtualAccountsListModel> {
        const response = await this.customerLocationsControllerGetVirtualAccountsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Map a virtual account to a location
     */
    async customerLocationsControllerMapVirtualAccountRaw(requestParameters: CustomerLocationsControllerMapVirtualAccountRequest): Promise<runtime.ApiResponse<VirtualAccountMapResult>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerMapVirtualAccount.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerMapVirtualAccount.');
        }

        if (requestParameters.virtualAccountMapDto === null || requestParameters.virtualAccountMapDto === undefined) {
            throw new runtime.RequiredError('virtualAccountMapDto','Required parameter requestParameters.virtualAccountMapDto was null or undefined when calling customerLocationsControllerMapVirtualAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/virtual-account`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VirtualAccountMapDtoToJSON(requestParameters.virtualAccountMapDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountMapResultFromJSON(jsonValue));
    }

    /**
     * Map a virtual account to a location
     */
    async customerLocationsControllerMapVirtualAccount(requestParameters: CustomerLocationsControllerMapVirtualAccountRequest): Promise<VirtualAccountMapResult> {
        const response = await this.customerLocationsControllerMapVirtualAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Set location sustainability attributes
     */
    async customerLocationsControllerSetLocationSustainabilityAttributesRaw(requestParameters: CustomerLocationsControllerSetLocationSustainabilityAttributesRequest): Promise<runtime.ApiResponse<LocationSustainabilityAttributesModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerSetLocationSustainabilityAttributes.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerSetLocationSustainabilityAttributes.');
        }

        if (requestParameters.locationSustainabilityAttributesDto === null || requestParameters.locationSustainabilityAttributesDto === undefined) {
            throw new runtime.RequiredError('locationSustainabilityAttributesDto','Required parameter requestParameters.locationSustainabilityAttributesDto was null or undefined when calling customerLocationsControllerSetLocationSustainabilityAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}/sustainability-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocationSustainabilityAttributesDtoToJSON(requestParameters.locationSustainabilityAttributesDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationSustainabilityAttributesModelFromJSON(jsonValue));
    }

    /**
     * Set location sustainability attributes
     */
    async customerLocationsControllerSetLocationSustainabilityAttributes(requestParameters: CustomerLocationsControllerSetLocationSustainabilityAttributesRequest): Promise<LocationSustainabilityAttributesModel> {
        const response = await this.customerLocationsControllerSetLocationSustainabilityAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update location
     */
    async customerLocationsControllerUpdateLocationRaw(requestParameters: CustomerLocationsControllerUpdateLocationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerUpdateLocation.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerUpdateLocation.');
        }

        if (requestParameters.locationDto === null || requestParameters.locationDto === undefined) {
            throw new runtime.RequiredError('locationDto','Required parameter requestParameters.locationDto was null or undefined when calling customerLocationsControllerUpdateLocation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/{locationId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationDtoToJSON(requestParameters.locationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update location
     */
    async customerLocationsControllerUpdateLocation(requestParameters: CustomerLocationsControllerUpdateLocationRequest): Promise<void> {
        await this.customerLocationsControllerUpdateLocationRaw(requestParameters);
    }

    /**
     * Update location monthly attributes values for a given location
     */
    async customerLocationsControllerUpdateLocationMonthlyAttributesForLocationIdRaw(requestParameters: CustomerLocationsControllerUpdateLocationMonthlyAttributesForLocationIdRequest): Promise<runtime.ApiResponse<Array<LocationMonthlyAttributesValues>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerUpdateLocationMonthlyAttributesForLocationId.');
        }

        if (requestParameters.locationId === null || requestParameters.locationId === undefined) {
            throw new runtime.RequiredError('locationId','Required parameter requestParameters.locationId was null or undefined when calling customerLocationsControllerUpdateLocationMonthlyAttributesForLocationId.');
        }

        if (requestParameters.locationMonthlyAttributesValuesDto === null || requestParameters.locationMonthlyAttributesValuesDto === undefined) {
            throw new runtime.RequiredError('locationMonthlyAttributesValuesDto','Required parameter requestParameters.locationMonthlyAttributesValuesDto was null or undefined when calling customerLocationsControllerUpdateLocationMonthlyAttributesForLocationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/update-monthly-attributes-values/{locationId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"locationId"}}`, encodeURIComponent(String(requestParameters.locationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationMonthlyAttributesValuesDtoToJSON(requestParameters.locationMonthlyAttributesValuesDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationMonthlyAttributesValuesFromJSON));
    }

    /**
     * Update location monthly attributes values for a given location
     */
    async customerLocationsControllerUpdateLocationMonthlyAttributesForLocationId(requestParameters: CustomerLocationsControllerUpdateLocationMonthlyAttributesForLocationIdRequest): Promise<Array<LocationMonthlyAttributesValues>> {
        const response = await this.customerLocationsControllerUpdateLocationMonthlyAttributesForLocationIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update location monthly attributes for a given customer
     */
    async customerLocationsControllerUpdateLocationMonthlyMetadataAttributeRaw(requestParameters: CustomerLocationsControllerUpdateLocationMonthlyMetadataAttributeRequest): Promise<runtime.ApiResponse<LocationMonthlyMetadataAttribute>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerLocationsControllerUpdateLocationMonthlyMetadataAttribute.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerLocationsControllerUpdateLocationMonthlyMetadataAttribute.');
        }

        if (requestParameters.locationMonthlyMetadataAttributeDto === null || requestParameters.locationMonthlyMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('locationMonthlyMetadataAttributeDto','Required parameter requestParameters.locationMonthlyMetadataAttributeDto was null or undefined when calling customerLocationsControllerUpdateLocationMonthlyMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/locations/update-monthly-attributes/{id}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LocationMonthlyMetadataAttributeDtoToJSON(requestParameters.locationMonthlyMetadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationMonthlyMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Update location monthly attributes for a given customer
     */
    async customerLocationsControllerUpdateLocationMonthlyMetadataAttribute(requestParameters: CustomerLocationsControllerUpdateLocationMonthlyMetadataAttributeRequest): Promise<LocationMonthlyMetadataAttribute> {
        const response = await this.customerLocationsControllerUpdateLocationMonthlyMetadataAttributeRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationControllerGetBillsMonthlyFeedDirectionEnum {
    Plus = '+',
    Minus = '-'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationControllerGetBillsMonthlyFeedExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationControllerGetBillsMonthlyFeedUtilityTypeEnum {
    Electric = 'ELECTRIC',
    Naturalgas = 'NATURALGAS',
    Chilledwater = 'CHILLEDWATER',
    Fireprotection = 'FIREPROTECTION',
    Internet = 'INTERNET',
    Telephone = 'TELEPHONE',
    Hotwater = 'HOTWATER',
    Stormdrain = 'STORMDRAIN',
    Stormwater = 'STORMWATER',
    Irrigation = 'IRRIGATION',
    Propane = 'PROPANE',
    Steam = 'STEAM',
    Refuse = 'REFUSE',
    Sewer = 'SEWER',
    Water = 'WATER',
    Lighting = 'LIGHTING',
    Solarpv = 'SOLARPV',
    Oil2 = 'OIL2',
    Oil4 = 'OIL4',
    Oil6 = 'OIL6',
    Nitrogen = 'NITROGEN',
    Oxygen = 'OXYGEN',
    Diesel = 'DIESEL',
    ElectricMwh = 'ELECTRIC_MWH',
    SewerMcf = 'SEWER_MCF',
    WaterMcf = 'WATER_MCF',
    Fuelcell = 'FUELCELL'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationControllerGetDailyWeatherExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsControllerGetCustomerLocationMetricsCommodityEnum {
    Electric = 'ELECTRIC',
    Naturalgas = 'NATURALGAS',
    Chilledwater = 'CHILLEDWATER',
    Fireprotection = 'FIREPROTECTION',
    Internet = 'INTERNET',
    Telephone = 'TELEPHONE',
    Hotwater = 'HOTWATER',
    Stormdrain = 'STORMDRAIN',
    Stormwater = 'STORMWATER',
    Irrigation = 'IRRIGATION',
    Propane = 'PROPANE',
    Steam = 'STEAM',
    Refuse = 'REFUSE',
    Sewer = 'SEWER',
    Water = 'WATER',
    Lighting = 'LIGHTING',
    Solarpv = 'SOLARPV',
    Oil2 = 'OIL2',
    Oil4 = 'OIL4',
    Oil6 = 'OIL6',
    Nitrogen = 'NITROGEN',
    Oxygen = 'OXYGEN',
    Diesel = 'DIESEL',
    ElectricMwh = 'ELECTRIC_MWH',
    SewerMcf = 'SEWER_MCF',
    WaterMcf = 'WATER_MCF',
    Fuelcell = 'FUELCELL'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsControllerGetCustomerLocationsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsControllerGetCustomerLocationsOrderByEnum {
    AddressAsc = 'address.asc',
    BuildingTypeAsc = 'buildingType.asc',
    NameAsc = 'name.asc',
    StateAsc = 'state.asc',
    PostcodeAsc = 'postcode.asc',
    SquareFeetAsc = 'squareFeet.asc',
    WeatherActiveAsc = 'weatherActive.asc',
    CityAsc = 'city.asc',
    AddressDesc = 'address.desc',
    BuildingTypeDesc = 'buildingType.desc',
    NameDesc = 'name.desc',
    StateDesc = 'state.desc',
    PostcodeDesc = 'postcode.desc',
    SquareFeetDesc = 'squareFeet.desc',
    WeatherActiveDesc = 'weatherActive.desc',
    CityDesc = 'city.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsControllerGetCustomerLocationsBuildingTypesEnum {
    Building = 'BUILDING',
    Campus = 'CAMPUS',
    Storefront = 'STOREFRONT'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsControllerGetLocationMonthlyAttributesListExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerLocationsControllerGetLocationMonthlyAttributesListOrderByEnum {
    SortOrderAsc = 'sortOrder.asc',
    AttributeNameAsc = 'attributeName.asc',
    AttributeTypeAsc = 'attributeType.asc',
    AttributeUomAsc = 'attributeUom.asc',
    SortOrderDesc = 'sortOrder.desc',
    AttributeNameDesc = 'attributeName.desc',
    AttributeTypeDesc = 'attributeType.desc',
    AttributeUomDesc = 'attributeUom.desc'
}
