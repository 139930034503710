/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomIdMap,
    CustomIdMapFromJSON,
    CustomIdMapFromJSONTyped,
    CustomIdMapToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarPropertyListDto
 */
export interface EnergyStarPropertyListDto {
    /**
     * 
     * @type {Array<CustomIdMap>}
     * @memberof EnergyStarPropertyListDto
     */
    properties: Array<CustomIdMap>;
}

export function EnergyStarPropertyListDtoFromJSON(json: any): EnergyStarPropertyListDto {
    return EnergyStarPropertyListDtoFromJSONTyped(json, false);
}

export function EnergyStarPropertyListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarPropertyListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properties': ((json['properties'] as Array<any>).map(CustomIdMapFromJSON)),
    };
}

export function EnergyStarPropertyListDtoToJSON(value?: EnergyStarPropertyListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properties': ((value.properties as Array<any>).map(CustomIdMapToJSON)),
    };
}


