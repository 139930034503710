import { RouteRecordRaw } from 'vue-router'
import ErrorPage from '@/Modules/ErrorPage/views/view.error-page.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    meta: {
      showBreadcrumbs: false,
      breadcrumb: {
        titleTemplate: 'Page not found',
        subtitle: 'This page does not exist.',
      },
    },
  },
  {
    path: '/error/404',
    name: 'notfound',
    component: ErrorPage,
    meta: {
      showBreadcrumbs: false,
      breadcrumb: {
        titleTemplate: 'Page not found',
        subtitle: 'This page does not exist.',
      },
    },
  },
]
export default routes
