import { IMessageOptions } from 'element-plus/es/el-message/src/types'

export enum DialogStates {
  edit = 'edit',
  root = 'root',
}
export enum DialogWidths {
  edit = '480px',
  root = '668px',
}
export enum DialogTitles {
  edit = 'Configure Analytic Report',
  create = 'Add new Analytic Report',
  listing = 'Analytics Reports List',
  delete = 'Delete Analytic Report Confirmation',
}

export const DialogToasts: Record<string, IMessageOptions> = {
  addEditSuccess: {
    message: 'Report Configured Successfully',
    type: 'success',
  },
  addEditError: {
    message: 'Something went wrong.',
    type: 'error',
  },
  addEditInvalidForm: {
    message: 'Please correctly fill out all fields!',
    type: 'error',
  },
  removedSuccessfully: {
    message: 'Report Deleted Successfully!',
    type: 'success',
  },
}
export const FormRules = {
  name: [
    {
      required: true,
      message: 'Name is required.',
      trigger: 'blur',
    },
  ],
  groupId: [
    {
      required: true,
      message: 'Group ID is required.',
      trigger: 'blur',
    },
  ],
  reportId: [
    {
      required: true,
      message: 'Report ID is required.',
      trigger: 'blur',
    },
  ],
  datasetId: [
    {
      required: true,
      message: 'Dataset ID is required.',
      trigger: 'blur',
    },
  ],
}
