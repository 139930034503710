/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnergyStarPropertyUseAttributes,
    EnergyStarPropertyUseAttributesFromJSON,
    EnergyStarPropertyUseAttributesFromJSONTyped,
    EnergyStarPropertyUseAttributesToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarPropertyUseModel
 */
export interface EnergyStarPropertyUseModel {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseModel
     */
    propertyUseTypeName: EnergyStarPropertyUseModelPropertyUseTypeNameEnum;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseModel
     */
    propertyUseTypeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseModel
     */
    propertyUseShortDescription: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarPropertyUseModel
     */
    propertyUseDescription: string;
    /**
     * 
     * @type {Array<EnergyStarPropertyUseAttributes>}
     * @memberof EnergyStarPropertyUseModel
     */
    propertyUseAttributes: Array<EnergyStarPropertyUseAttributes>;
}

/**
* @export
* @enum {string}
*/
export enum EnergyStarPropertyUseModelPropertyUseTypeNameEnum {
    CollegeUniversity = 'collegeUniversity',
    DataCenter = 'dataCenter',
    Hospital = 'hospital',
    Hotel = 'hotel',
    K12School = 'k12School',
    MultifamilyHousing = 'multifamilyHousing',
    NonRefrigeratedWarehouse = 'nonRefrigeratedWarehouse',
    Office = 'office',
    Other = 'other',
    Parking = 'parking',
    ResidenceHallDormitory = 'residenceHallDormitory',
    Retail = 'retail',
    Supermarket = 'supermarket',
    WorshipFacility = 'worshipFacility',
    ConvenienceStoreWithGasStation = 'convenienceStoreWithGasStation',
    ConvenienceStoreWithoutGasStation = 'convenienceStoreWithoutGasStation'
}

export function EnergyStarPropertyUseModelFromJSON(json: any): EnergyStarPropertyUseModel {
    return EnergyStarPropertyUseModelFromJSONTyped(json, false);
}

export function EnergyStarPropertyUseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarPropertyUseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'propertyUseTypeName': json['propertyUseTypeName'],
        'propertyUseTypeLabel': json['propertyUseTypeLabel'],
        'propertyUseShortDescription': json['propertyUseShortDescription'],
        'propertyUseDescription': json['propertyUseDescription'],
        'propertyUseAttributes': ((json['propertyUseAttributes'] as Array<any>).map(EnergyStarPropertyUseAttributesFromJSON)),
    };
}

export function EnergyStarPropertyUseModelToJSON(value?: EnergyStarPropertyUseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'propertyUseTypeName': value.propertyUseTypeName,
        'propertyUseTypeLabel': value.propertyUseTypeLabel,
        'propertyUseShortDescription': value.propertyUseShortDescription,
        'propertyUseDescription': value.propertyUseDescription,
        'propertyUseAttributes': ((value.propertyUseAttributes as Array<any>).map(EnergyStarPropertyUseAttributesToJSON)),
    };
}


