/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MetadataAttributeDto,
    MetadataAttributeDtoFromJSON,
    MetadataAttributeDtoToJSON,
    SortMetadataAttributeDto,
    SortMetadataAttributeDtoFromJSON,
    SortMetadataAttributeDtoToJSON,
    UpdateMetadataAttributeDto,
    UpdateMetadataAttributeDtoFromJSON,
    UpdateMetadataAttributeDtoToJSON,
    VirtualAccountMetadataAttribute,
    VirtualAccountMetadataAttributeFromJSON,
    VirtualAccountMetadataAttributeToJSON,
    VirtualAccountMetadataAttributesListDto,
    VirtualAccountMetadataAttributesListDtoFromJSON,
    VirtualAccountMetadataAttributesListDtoToJSON,
    VirtualAccountMetadataAttributesValuesListDto,
    VirtualAccountMetadataAttributesValuesListDtoFromJSON,
    VirtualAccountMetadataAttributesValuesListDtoToJSON,
    VirtualAccountsAttributeMapping,
    VirtualAccountsAttributeMappingFromJSON,
    VirtualAccountsAttributeMappingToJSON,
    VirtualAccountsAttributeMappingDto,
    VirtualAccountsAttributeMappingDtoFromJSON,
    VirtualAccountsAttributeMappingDtoToJSON,
    VirtualAccountsOperatorNotes,
    VirtualAccountsOperatorNotesFromJSON,
    VirtualAccountsOperatorNotesToJSON,
    VirtualAccountsOperatorNotesDto,
    VirtualAccountsOperatorNotesDtoFromJSON,
    VirtualAccountsOperatorNotesDtoToJSON,
} from '../models';

export interface CustomerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRequest {
    customerId: number;
    metadataAttributeDto: MetadataAttributeDto;
}

export interface CustomerVirtualAccountAttributesControllerDeleteMetadataAttributeRequest {
    id: number;
    customerId: number;
}

export interface CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest {
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerVirtualAccountAttributesControllerGetMetadataAttributesExcludeEnum>;
    orderBy?: Array<CustomerVirtualAccountAttributesControllerGetMetadataAttributesOrderByEnum>;
}

export interface CustomerVirtualAccountAttributesControllerGetMetadataAttributesValuesRequest {
    id: number;
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerVirtualAccountAttributesControllerGetMetadataAttributesValuesExcludeEnum>;
    searchQuery?: string;
}

export interface CustomerVirtualAccountAttributesControllerGetOperatorNotesRequest {
    virtualAccountId: number;
    customerId: number;
}

export interface CustomerVirtualAccountAttributesControllerListAttributesMappingRequest {
    virtualAccountId: number;
    customerId: number;
}

export interface CustomerVirtualAccountAttributesControllerSortMetadataAttributesRequest {
    customerId: number;
    sortMetadataAttributeDto: Array<SortMetadataAttributeDto>;
}

export interface CustomerVirtualAccountAttributesControllerUpdateMetadataAttributeRequest {
    id: number;
    customerId: number;
    updateMetadataAttributeDto: UpdateMetadataAttributeDto;
}

export interface CustomerVirtualAccountAttributesControllerUpsertAttributesMappingRequest {
    virtualAccountId: number;
    customerId: number;
    virtualAccountsAttributeMappingDto: Array<VirtualAccountsAttributeMappingDto>;
}

export interface CustomerVirtualAccountAttributesControllerUpsertOperatorNotesRequest {
    virtualAccountId: number;
    customerId: number;
    virtualAccountsOperatorNotesDto: VirtualAccountsOperatorNotesDto;
}

/**
 * 
 */
export class VirtualAccountAttributesApi extends runtime.BaseAPI {

    /**
     * Create a new virtual account attribute for a given customer
     */
    async customerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRaw(requestParameters: CustomerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRequest): Promise<runtime.ApiResponse<VirtualAccountMetadataAttribute>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttribute.');
        }

        if (requestParameters.metadataAttributeDto === null || requestParameters.metadataAttributeDto === undefined) {
            throw new runtime.RequiredError('metadataAttributeDto','Required parameter requestParameters.metadataAttributeDto was null or undefined when calling customerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetadataAttributeDtoToJSON(requestParameters.metadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Create a new virtual account attribute for a given customer
     */
    async customerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttribute(requestParameters: CustomerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRequest): Promise<VirtualAccountMetadataAttribute> {
        const response = await this.customerVirtualAccountAttributesControllerCreateVirtualAccountMetadataAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete an existing virtual account attribute for a given customer
     */
    async customerVirtualAccountAttributesControllerDeleteMetadataAttributeRaw(requestParameters: CustomerVirtualAccountAttributesControllerDeleteMetadataAttributeRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerVirtualAccountAttributesControllerDeleteMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerDeleteMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing virtual account attribute for a given customer
     */
    async customerVirtualAccountAttributesControllerDeleteMetadataAttribute(requestParameters: CustomerVirtualAccountAttributesControllerDeleteMetadataAttributeRequest): Promise<void> {
        await this.customerVirtualAccountAttributesControllerDeleteMetadataAttributeRaw(requestParameters);
    }

    /**
     * Get the list of virtual account attributes for a given customer
     */
    async customerVirtualAccountAttributesControllerGetMetadataAttributesRaw(requestParameters: CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest): Promise<runtime.ApiResponse<VirtualAccountMetadataAttributesListDto>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerGetMetadataAttributes.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/list`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountMetadataAttributesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of virtual account attributes for a given customer
     */
    async customerVirtualAccountAttributesControllerGetMetadataAttributes(requestParameters: CustomerVirtualAccountAttributesControllerGetMetadataAttributesRequest): Promise<VirtualAccountMetadataAttributesListDto> {
        const response = await this.customerVirtualAccountAttributesControllerGetMetadataAttributesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of virtual account attributes metadata values for a given customer and custom attribute
     */
    async customerVirtualAccountAttributesControllerGetMetadataAttributesValuesRaw(requestParameters: CustomerVirtualAccountAttributesControllerGetMetadataAttributesValuesRequest): Promise<runtime.ApiResponse<VirtualAccountMetadataAttributesValuesListDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerVirtualAccountAttributesControllerGetMetadataAttributesValues.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerGetMetadataAttributesValues.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.searchQuery !== undefined) {
            queryParameters['searchQuery'] = requestParameters.searchQuery;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/values/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountMetadataAttributesValuesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of virtual account attributes metadata values for a given customer and custom attribute
     */
    async customerVirtualAccountAttributesControllerGetMetadataAttributesValues(requestParameters: CustomerVirtualAccountAttributesControllerGetMetadataAttributesValuesRequest): Promise<VirtualAccountMetadataAttributesValuesListDto> {
        const response = await this.customerVirtualAccountAttributesControllerGetMetadataAttributesValuesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get operator notes for a given virtual account
     */
    async customerVirtualAccountAttributesControllerGetOperatorNotesRaw(requestParameters: CustomerVirtualAccountAttributesControllerGetOperatorNotesRequest): Promise<runtime.ApiResponse<VirtualAccountsOperatorNotes>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountAttributesControllerGetOperatorNotes.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerGetOperatorNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/{virtualAccountId}/operator-notes`.replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountsOperatorNotesFromJSON(jsonValue));
    }

    /**
     * Get operator notes for a given virtual account
     */
    async customerVirtualAccountAttributesControllerGetOperatorNotes(requestParameters: CustomerVirtualAccountAttributesControllerGetOperatorNotesRequest): Promise<VirtualAccountsOperatorNotes> {
        const response = await this.customerVirtualAccountAttributesControllerGetOperatorNotesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of virtual account attributes mapping values
     */
    async customerVirtualAccountAttributesControllerListAttributesMappingRaw(requestParameters: CustomerVirtualAccountAttributesControllerListAttributesMappingRequest): Promise<runtime.ApiResponse<Array<VirtualAccountsAttributeMapping>>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountAttributesControllerListAttributesMapping.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerListAttributesMapping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/{virtualAccountId}`.replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VirtualAccountsAttributeMappingFromJSON));
    }

    /**
     * Get the list of virtual account attributes mapping values
     */
    async customerVirtualAccountAttributesControllerListAttributesMapping(requestParameters: CustomerVirtualAccountAttributesControllerListAttributesMappingRequest): Promise<Array<VirtualAccountsAttributeMapping>> {
        const response = await this.customerVirtualAccountAttributesControllerListAttributesMappingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Sort/reorder virtual account attributes for a given customer
     */
    async customerVirtualAccountAttributesControllerSortMetadataAttributesRaw(requestParameters: CustomerVirtualAccountAttributesControllerSortMetadataAttributesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerSortMetadataAttributes.');
        }

        if (requestParameters.sortMetadataAttributeDto === null || requestParameters.sortMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('sortMetadataAttributeDto','Required parameter requestParameters.sortMetadataAttributeDto was null or undefined when calling customerVirtualAccountAttributesControllerSortMetadataAttributes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/sort`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.sortMetadataAttributeDto.map(SortMetadataAttributeDtoToJSON),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Sort/reorder virtual account attributes for a given customer
     */
    async customerVirtualAccountAttributesControllerSortMetadataAttributes(requestParameters: CustomerVirtualAccountAttributesControllerSortMetadataAttributesRequest): Promise<void> {
        await this.customerVirtualAccountAttributesControllerSortMetadataAttributesRaw(requestParameters);
    }

    /**
     * Update an existing virtual account attribute for a given customer
     */
    async customerVirtualAccountAttributesControllerUpdateMetadataAttributeRaw(requestParameters: CustomerVirtualAccountAttributesControllerUpdateMetadataAttributeRequest): Promise<runtime.ApiResponse<VirtualAccountMetadataAttribute>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling customerVirtualAccountAttributesControllerUpdateMetadataAttribute.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerUpdateMetadataAttribute.');
        }

        if (requestParameters.updateMetadataAttributeDto === null || requestParameters.updateMetadataAttributeDto === undefined) {
            throw new runtime.RequiredError('updateMetadataAttributeDto','Required parameter requestParameters.updateMetadataAttributeDto was null or undefined when calling customerVirtualAccountAttributesControllerUpdateMetadataAttribute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateMetadataAttributeDtoToJSON(requestParameters.updateMetadataAttributeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountMetadataAttributeFromJSON(jsonValue));
    }

    /**
     * Update an existing virtual account attribute for a given customer
     */
    async customerVirtualAccountAttributesControllerUpdateMetadataAttribute(requestParameters: CustomerVirtualAccountAttributesControllerUpdateMetadataAttributeRequest): Promise<VirtualAccountMetadataAttribute> {
        const response = await this.customerVirtualAccountAttributesControllerUpdateMetadataAttributeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Store virtual account attributes mapping values
     */
    async customerVirtualAccountAttributesControllerUpsertAttributesMappingRaw(requestParameters: CustomerVirtualAccountAttributesControllerUpsertAttributesMappingRequest): Promise<runtime.ApiResponse<Array<VirtualAccountsAttributeMapping>>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountAttributesControllerUpsertAttributesMapping.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerUpsertAttributesMapping.');
        }

        if (requestParameters.virtualAccountsAttributeMappingDto === null || requestParameters.virtualAccountsAttributeMappingDto === undefined) {
            throw new runtime.RequiredError('virtualAccountsAttributeMappingDto','Required parameter requestParameters.virtualAccountsAttributeMappingDto was null or undefined when calling customerVirtualAccountAttributesControllerUpsertAttributesMapping.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/{virtualAccountId}`.replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.virtualAccountsAttributeMappingDto.map(VirtualAccountsAttributeMappingDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VirtualAccountsAttributeMappingFromJSON));
    }

    /**
     * Store virtual account attributes mapping values
     */
    async customerVirtualAccountAttributesControllerUpsertAttributesMapping(requestParameters: CustomerVirtualAccountAttributesControllerUpsertAttributesMappingRequest): Promise<Array<VirtualAccountsAttributeMapping>> {
        const response = await this.customerVirtualAccountAttributesControllerUpsertAttributesMappingRaw(requestParameters);
        return await response.value();
    }

    /**
     * Store operator notes for a given virtual account
     */
    async customerVirtualAccountAttributesControllerUpsertOperatorNotesRaw(requestParameters: CustomerVirtualAccountAttributesControllerUpsertOperatorNotesRequest): Promise<runtime.ApiResponse<VirtualAccountsOperatorNotes>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountAttributesControllerUpsertOperatorNotes.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountAttributesControllerUpsertOperatorNotes.');
        }

        if (requestParameters.virtualAccountsOperatorNotesDto === null || requestParameters.virtualAccountsOperatorNotesDto === undefined) {
            throw new runtime.RequiredError('virtualAccountsOperatorNotesDto','Required parameter requestParameters.virtualAccountsOperatorNotesDto was null or undefined when calling customerVirtualAccountAttributesControllerUpsertOperatorNotes.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-account-metadata-attributes/{virtualAccountId}/operator-notes`.replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VirtualAccountsOperatorNotesDtoToJSON(requestParameters.virtualAccountsOperatorNotesDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountsOperatorNotesFromJSON(jsonValue));
    }

    /**
     * Store operator notes for a given virtual account
     */
    async customerVirtualAccountAttributesControllerUpsertOperatorNotes(requestParameters: CustomerVirtualAccountAttributesControllerUpsertOperatorNotesRequest): Promise<VirtualAccountsOperatorNotes> {
        const response = await this.customerVirtualAccountAttributesControllerUpsertOperatorNotesRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerVirtualAccountAttributesControllerGetMetadataAttributesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVirtualAccountAttributesControllerGetMetadataAttributesOrderByEnum {
    SortOrderAsc = 'sortOrder.asc',
    CreatedAtAsc = 'createdAt.asc',
    UpdatedAtAsc = 'updatedAt.asc',
    AttributeNameAsc = 'attributeName.asc',
    AttributeTypeAsc = 'attributeType.asc',
    AttributeUomAsc = 'attributeUom.asc',
    SortOrderDesc = 'sortOrder.desc',
    CreatedAtDesc = 'createdAt.desc',
    UpdatedAtDesc = 'updatedAt.desc',
    AttributeNameDesc = 'attributeName.desc',
    AttributeTypeDesc = 'attributeType.desc',
    AttributeUomDesc = 'attributeUom.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum CustomerVirtualAccountAttributesControllerGetMetadataAttributesValuesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
