import {
  TableRepository,
  TableOptions,
  TableQuery,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import { ref, Ref } from 'vue'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { UsersService } from '@/Modules/Users/services/service.users'
import { UsersListDto } from '@/SharedModule/Api/models/UsersListDto'
import {
  UsersControllerGetUsersExcludeEnum,
  UsersControllerGetUsersOrderByEnum,
  UsersControllerGetUsersRequest,
} from '@/SharedModule/Api'

const serviceUsers = new UsersService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      searchQuery: null,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({
      lastName: true,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('id'),
      },
      {
        prettyName: ref('First Name'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('firstName'),
      },
      {
        prettyName: ref('Last Name'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('lastName'),
      },
      {
        prettyName: ref('Email'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('email'),
      },
      {
        prettyName: ref('Active'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('active'),
      },
      {
        prettyName: ref('Action'),
        labelClassName: null,
        name: ref('action'),
        width: null,
        type: null,
        modelKey: null,
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'users',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
    }),
  }
}

const useListRef = (): Ref<UsersListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  })

const adaptFilterDataToApiTypesAndExclude = (
  filterData: UsersControllerGetUsersRequest,
  sortBy: any,
  meta: any,
): UsersControllerGetUsersRequest => {
  if (sortBy) {
    filterData.orderBy = filterData.orderBy || []
    const sortKeys = Object.keys(sortBy)
    for (let i = 0; i < sortKeys.length; i++) {
      if (sortBy[sortKeys[i]] === true) {
        filterData.orderBy.push(
          (sortKeys[i] + '.asc') as UsersControllerGetUsersOrderByEnum,
        )
      } else if (sortBy[sortKeys[i]] === false) {
        // condition required to skip undefined values
        filterData.orderBy.push(
          (sortKeys[i] + '.desc') as UsersControllerGetUsersOrderByEnum,
        )
      }
    }

    if (!filterData.orderBy?.length) {
      filterData.orderBy.push('id.asc' as UsersControllerGetUsersOrderByEnum)
    }
  }
  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(UsersControllerGetUsersExcludeEnum.Filters)
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        UsersControllerGetUsersExcludeEnum.Total,
        UsersControllerGetUsersExcludeEnum.Extra,
      )
    }
  }
  return filterData
}

export default class RepositoryUsersList
  implements TableRepository<UsersListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  getList = async (): Promise<void> => {
    const listValue = await serviceUsers.getUsers(
      adaptFilterDataToApiTypesAndExclude(
        useQueryAsRequest<UsersControllerGetUsersRequest>(this.options.query),
        this.options.query.sortBy?.value,
        this.options.meta.value,
      ),
    )

    const tempListValue: any[] = []
    listValue.results.forEach((item: any) => {
      tempListValue.push({
        id: item.id,
        email: item.email,
        active: item.active ? 'Yes' : 'No',
        firstName: item.profile?.firstName,
        lastName: item.profile?.lastName,
        activatedAt: item.activatedAt,
      })
    })

    if (this.options.meta.value.excludePaginationData) {
      listValue.extra = this.list.value.extra
      listValue.total = this.list.value.total
      this.options.meta.value.excludePaginationData = false
    }
    this.list.value.results = tempListValue
    this.list.value.total = listValue.total
    this.list.value.extra = listValue.extra
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
