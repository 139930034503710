/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillPayloadFdgModel
 */
export interface BillPayloadFdgModel {
    /**
     * 
     * @type {string}
     * @memberof BillPayloadFdgModel
     */
    client?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillPayloadFdgModel
     */
    comments?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BillPayloadFdgModel
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof BillPayloadFdgModel
     */
    ref?: string;
    /**
     * 
     * @type {string}
     * @memberof BillPayloadFdgModel
     */
    vendorId?: string;
    /**
     * 
     * @type {number}
     * @memberof BillPayloadFdgModel
     */
    batchId?: number;
    /**
     * 
     * @type {string}
     * @memberof BillPayloadFdgModel
     */
    meterReference?: string;
}

export function BillPayloadFdgModelFromJSON(json: any): BillPayloadFdgModel {
    return BillPayloadFdgModelFromJSONTyped(json, false);
}

export function BillPayloadFdgModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillPayloadFdgModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'client': !exists(json, 'client') ? undefined : json['client'],
        'comments': !exists(json, 'comments') ? undefined : json['comments'],
        'file': !exists(json, 'file') ? undefined : json['file'],
        'ref': !exists(json, 'ref') ? undefined : json['ref'],
        'vendorId': !exists(json, 'vendorId') ? undefined : json['vendorId'],
        'batchId': !exists(json, 'batchId') ? undefined : json['batchId'],
        'meterReference': !exists(json, 'meterReference') ? undefined : json['meterReference'],
    };
}

export function BillPayloadFdgModelToJSON(value?: BillPayloadFdgModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client': value.client,
        'comments': value.comments,
        'file': value.file,
        'ref': value.ref,
        'vendorId': value.vendorId,
        'batchId': value.batchId,
        'meterReference': value.meterReference,
    };
}


