/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VirtualAccountMapStatus
 */
export interface VirtualAccountMapStatus {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountMapStatus
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountMapStatus
     */
    complete: number;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountMapStatus
     */
    failed: number;
}

export function VirtualAccountMapStatusFromJSON(json: any): VirtualAccountMapStatus {
    return VirtualAccountMapStatusFromJSONTyped(json, false);
}

export function VirtualAccountMapStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountMapStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'complete': json['complete'],
        'failed': json['failed'],
    };
}

export function VirtualAccountMapStatusToJSON(value?: VirtualAccountMapStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'complete': value.complete,
        'failed': value.failed,
    };
}


