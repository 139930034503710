/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetadataAttributeDto
 */
export interface MetadataAttributeDto {
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeDto
     */
    attributeName: string;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeDto
     */
    attributeType?: MetadataAttributeDtoAttributeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MetadataAttributeDto
     */
    attributeUom?: MetadataAttributeDtoAttributeUomEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MetadataAttributeDto
     */
    isMultiselect?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MetadataAttributeDto
     */
    listValues?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MetadataAttributeDto
     */
    sortOrder: number;
}

/**
* @export
* @enum {string}
*/
export enum MetadataAttributeDtoAttributeTypeEnum {
    String = 'String',
    Numeric = 'Numeric',
    Date = 'Date',
    List = 'List'
}/**
* @export
* @enum {string}
*/
export enum MetadataAttributeDtoAttributeUomEnum {
    Kw = 'KW',
    Kwh = 'KWH',
    Kwhday = 'KWHDAY',
    Usd = 'USD',
    Cad = 'CAD',
    Kvarh = 'KVARH',
    Ccf = 'CCF',
    Gal = 'GAL',
    Kgal = 'KGAL',
    Lamps = 'LAMPS',
    Therm = 'THERM',
    Cf = 'CF',
    Percent = 'PERCENT',
    Usdday = 'USDDAY',
    Units = 'UNITS',
    Kvar = 'KVAR',
    Usdkwh = 'USDKWH',
    Usdkwhday = 'USDKWHDAY',
    Ton = 'TON',
    Tonhr = 'TONHR',
    Block = 'BLOCK',
    Kva = 'KVA',
    Tonday = 'TONDAY',
    Ccfday = 'CCFDAY',
    Thermday = 'THERMDAY',
    Min = 'MIN',
    Minday = 'MINDAY',
    Calls = 'CALLS',
    Mb = 'MB',
    Mbday = 'MBDAY',
    Cubicm = 'CUBICM',
    Mcf = 'MCF',
    Dkthm = 'DKTHM',
    Sqfeet = 'SQFEET',
    Sqfeetday = 'SQFEETDAY',
    Hgal = 'HGAL',
    Btu = 'BTU',
    Mmbtu = 'MMBTU',
    Galday = 'GALDAY',
    Usdccf = 'USDCCF',
    Usdtherm = 'USDTHERM',
    Usdton = 'USDTON',
    Usdmin = 'USDMIN',
    Usdmb = 'USDMB',
    Usdsqfeet = 'USDSQFEET',
    Usdgal = 'USDGAL',
    Usdmmbtu = 'USDMMBTU',
    Mmbtuday = 'MMBTUDAY',
    Mwh = 'MWH',
    Hdd = 'HDD',
    Cdd = 'CDD',
    Tdd = 'TDD',
    Kwhdd = 'KWHDD',
    _10Cf = '10CF',
    Cgal = 'CGAL',
    Dgal = 'DGAL',
    Dcf = 'DCF',
    Gj = 'GJ',
    _10Gal = '10GAL',
    Mbtu = 'MBTU',
    NA = 'N/A',
    Cubicyard = 'CUBICYARD',
    Eru = 'ERU',
    Edu = 'EDU',
    Lbs = 'LBS'
}

export function MetadataAttributeDtoFromJSON(json: any): MetadataAttributeDto {
    return MetadataAttributeDtoFromJSONTyped(json, false);
}

export function MetadataAttributeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetadataAttributeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attributeName': json['attributeName'],
        'attributeType': !exists(json, 'attributeType') ? undefined : json['attributeType'],
        'attributeUom': !exists(json, 'attributeUom') ? undefined : json['attributeUom'],
        'isMultiselect': !exists(json, 'isMultiselect') ? undefined : json['isMultiselect'],
        'listValues': !exists(json, 'listValues') ? undefined : json['listValues'],
        'sortOrder': json['sortOrder'],
    };
}

export function MetadataAttributeDtoToJSON(value?: MetadataAttributeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attributeName': value.attributeName,
        'attributeType': value.attributeType,
        'attributeUom': value.attributeUom,
        'isMultiselect': value.isMultiselect,
        'listValues': value.listValues,
        'sortOrder': value.sortOrder,
    };
}


