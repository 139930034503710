/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocationMetrics
 */
export interface LocationMetrics {
    /**
     * 
     * @type {string}
     * @memberof LocationMetrics
     */
    month: string;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    cost: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    consumption: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    unitCost: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    supplyUsages: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    supplyUnitCost: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    distCharges: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    distUsages: number;
    /**
     * 
     * @type {number}
     * @memberof LocationMetrics
     */
    distUnitCost: number;
}

export function LocationMetricsFromJSON(json: any): LocationMetrics {
    return LocationMetricsFromJSONTyped(json, false);
}

export function LocationMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocationMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'month': json['month'],
        'cost': json['cost'],
        'consumption': json['consumption'],
        'unitCost': json['unitCost'],
        'supplyUsages': json['supplyUsages'],
        'supplyUnitCost': json['supplyUnitCost'],
        'distCharges': json['distCharges'],
        'distUsages': json['distUsages'],
        'distUnitCost': json['distUnitCost'],
    };
}

export function LocationMetricsToJSON(value?: LocationMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'month': value.month,
        'cost': value.cost,
        'consumption': value.consumption,
        'unitCost': value.unitCost,
        'supplyUsages': value.supplyUsages,
        'supplyUnitCost': value.supplyUnitCost,
        'distCharges': value.distCharges,
        'distUsages': value.distUsages,
        'distUnitCost': value.distUnitCost,
    };
}


