/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarSynchedCustomerDto
 */
export interface EnergyStarSynchedCustomerDto {
    /**
     * 
     * @type {string}
     * @memberof EnergyStarSynchedCustomerDto
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarSynchedCustomerDto
     */
    password: string;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarSynchedCustomerDto
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarSynchedCustomerDto
     */
    energyStarCustomerId: string;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarSynchedCustomerDto
     */
    createdAt: Date;
}

export function EnergyStarSynchedCustomerDtoFromJSON(json: any): EnergyStarSynchedCustomerDto {
    return EnergyStarSynchedCustomerDtoFromJSONTyped(json, false);
}

export function EnergyStarSynchedCustomerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarSynchedCustomerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'password': json['password'],
        'customerId': json['customerId'],
        'energyStarCustomerId': json['energyStarCustomerId'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function EnergyStarSynchedCustomerDtoToJSON(value?: EnergyStarSynchedCustomerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'password': value.password,
        'customerId': value.customerId,
        'energyStarCustomerId': value.energyStarCustomerId,
        'createdAt': (value.createdAt.toISOString()),
    };
}


