/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEnergyStarMeterDto
 */
export interface UpdateEnergyStarMeterDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateEnergyStarMeterDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnergyStarMeterDto
     */
    firstBillDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEnergyStarMeterDto
     */
    inUse: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateEnergyStarMeterDto
     */
    inactiveDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEnergyStarMeterDto
     */
    associated: boolean;
}

export function UpdateEnergyStarMeterDtoFromJSON(json: any): UpdateEnergyStarMeterDto {
    return UpdateEnergyStarMeterDtoFromJSONTyped(json, false);
}

export function UpdateEnergyStarMeterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEnergyStarMeterDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'firstBillDate': json['firstBillDate'],
        'inUse': json['inUse'],
        'inactiveDate': json['inactiveDate'],
        'associated': json['associated'],
    };
}

export function UpdateEnergyStarMeterDtoToJSON(value?: UpdateEnergyStarMeterDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'firstBillDate': value.firstBillDate,
        'inUse': value.inUse,
        'inactiveDate': value.inactiveDate,
        'associated': value.associated,
    };
}


