/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ESPropertyUseDetailsDto
 */
export interface ESPropertyUseDetailsDto {
    /**
     * 
     * @type {number}
     * @memberof ESPropertyUseDetailsDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDetailsDto
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDetailsDto
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDetailsDto
     */
    currentAsOf: string;
    /**
     * 
     * @type {boolean}
     * @memberof ESPropertyUseDetailsDto
     */
    temporary: boolean;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDetailsDto
     */
    _default?: string;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDetailsDto
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof ESPropertyUseDetailsDto
     */
    units: string;
}

export function ESPropertyUseDetailsDtoFromJSON(json: any): ESPropertyUseDetailsDto {
    return ESPropertyUseDetailsDtoFromJSONTyped(json, false);
}

export function ESPropertyUseDetailsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ESPropertyUseDetailsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'currentAsOf': json['currentAsOf'],
        'temporary': json['temporary'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
        'value': json['value'],
        'units': json['units'],
    };
}

export function ESPropertyUseDetailsDtoToJSON(value?: ESPropertyUseDetailsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'label': value.label,
        'currentAsOf': value.currentAsOf,
        'temporary': value.temporary,
        'default': value._default,
        'value': value.value,
        'units': value.units,
    };
}


