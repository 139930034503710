/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompletedReportsListDto,
    CompletedReportsListDtoFromJSON,
    CompletedReportsListDtoToJSON,
    ReportStatusListDto,
    ReportStatusListDtoFromJSON,
    ReportStatusListDtoToJSON,
    RunReportDto,
    RunReportDtoFromJSON,
    RunReportDtoToJSON,
} from '../models';

export interface AdminGlobalCompletedReportsControllerDeleteReportsRequest {
    reportStatusListDto?: ReportStatusListDto;
}

export interface AdminGlobalCompletedReportsControllerExecuteNowReportRequest {
    runReportDto: RunReportDto;
}

export interface AdminGlobalCompletedReportsControllerGetReportsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<AdminGlobalCompletedReportsControllerGetReportsExcludeEnum>;
    type?: Array<string>;
    featureType?: AdminGlobalCompletedReportsControllerGetReportsFeatureTypeEnum;
    format?: Array<string>;
    startDate?: Date;
    endDate?: Date;
    orderBy?: Array<AdminGlobalCompletedReportsControllerGetReportsOrderByEnum>;
}

export interface AdminGlobalCompletedReportsControllerGetStatusesRequest {
    reportIds?: string;
}

/**
 * 
 */
export class AdminGlobalCompletedReportsApi extends runtime.BaseAPI {

    /**
     */
    async adminGlobalCompletedReportsControllerDeleteReportsRaw(requestParameters: AdminGlobalCompletedReportsControllerDeleteReportsRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/admin/reports/bulk`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: ReportStatusListDtoToJSON(requestParameters.reportStatusListDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async adminGlobalCompletedReportsControllerDeleteReports(requestParameters: AdminGlobalCompletedReportsControllerDeleteReportsRequest): Promise<void> {
        await this.adminGlobalCompletedReportsControllerDeleteReportsRaw(requestParameters);
    }

    /**
     * Create and deliver a new admin global report
     */
    async adminGlobalCompletedReportsControllerExecuteNowReportRaw(requestParameters: AdminGlobalCompletedReportsControllerExecuteNowReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.runReportDto === null || requestParameters.runReportDto === undefined) {
            throw new runtime.RequiredError('runReportDto','Required parameter requestParameters.runReportDto was null or undefined when calling adminGlobalCompletedReportsControllerExecuteNowReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/admin/reports/run-now`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RunReportDtoToJSON(requestParameters.runReportDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create and deliver a new admin global report
     */
    async adminGlobalCompletedReportsControllerExecuteNowReport(requestParameters: AdminGlobalCompletedReportsControllerExecuteNowReportRequest): Promise<void> {
        await this.adminGlobalCompletedReportsControllerExecuteNowReportRaw(requestParameters);
    }

    /**
     * Get the list of completed reports for a given customer
     */
    async adminGlobalCompletedReportsControllerGetReportsRaw(requestParameters: AdminGlobalCompletedReportsControllerGetReportsRequest): Promise<runtime.ApiResponse<CompletedReportsListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.featureType !== undefined) {
            queryParameters['featureType'] = requestParameters.featureType;
        }

        if (requestParameters.format) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/admin/reports/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CompletedReportsListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of completed reports for a given customer
     */
    async adminGlobalCompletedReportsControllerGetReports(requestParameters: AdminGlobalCompletedReportsControllerGetReportsRequest): Promise<CompletedReportsListDto> {
        const response = await this.adminGlobalCompletedReportsControllerGetReportsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async adminGlobalCompletedReportsControllerGetStatusesRaw(requestParameters: AdminGlobalCompletedReportsControllerGetStatusesRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.reportIds !== undefined) {
            queryParameters['reportIds'] = requestParameters.reportIds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/admin/reports/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async adminGlobalCompletedReportsControllerGetStatuses(requestParameters: AdminGlobalCompletedReportsControllerGetStatusesRequest): Promise<void> {
        await this.adminGlobalCompletedReportsControllerGetStatusesRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum AdminGlobalCompletedReportsControllerGetReportsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminGlobalCompletedReportsControllerGetReportsFeatureTypeEnum {
    Reports = 'reports',
    Budgeting = 'budgeting'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminGlobalCompletedReportsControllerGetReportsOrderByEnum {
    CustomReportNameAsc = 'customReportName.asc',
    CreatedAtAsc = 'createdAt.asc',
    TitleAsc = 'title.asc',
    FormatAsc = 'format.asc',
    StatusAsc = 'status.asc',
    DeliveryAsc = 'delivery.asc',
    SftpConnectionsAsc = 'sftpConnections.asc',
    EmailRecipientsAsc = 'emailRecipients.asc',
    StartDateAsc = 'startDate.asc',
    EndDateAsc = 'endDate.asc',
    UtilityTypeAsc = 'utilityType.asc',
    VendorAsc = 'vendor.asc',
    LocationIdAsc = 'locationId.asc',
    HistoricalDatesAsc = 'historicalDates.asc',
    CustomReportNameDesc = 'customReportName.desc',
    CreatedAtDesc = 'createdAt.desc',
    TitleDesc = 'title.desc',
    FormatDesc = 'format.desc',
    StatusDesc = 'status.desc',
    DeliveryDesc = 'delivery.desc',
    SftpConnectionsDesc = 'sftpConnections.desc',
    EmailRecipientsDesc = 'emailRecipients.desc',
    StartDateDesc = 'startDate.desc',
    EndDateDesc = 'endDate.desc',
    UtilityTypeDesc = 'utilityType.desc',
    VendorDesc = 'vendor.desc',
    LocationIdDesc = 'locationId.desc',
    HistoricalDatesDesc = 'historicalDates.desc'
}
