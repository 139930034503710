/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentErrorsEmailDelivery,
    PaymentErrorsEmailDeliveryFromJSON,
    PaymentErrorsEmailDeliveryFromJSONTyped,
    PaymentErrorsEmailDeliveryToJSON,
    PaymentErrorsEmailDeliveryFiltersMeta,
    PaymentErrorsEmailDeliveryFiltersMetaFromJSON,
    PaymentErrorsEmailDeliveryFiltersMetaFromJSONTyped,
    PaymentErrorsEmailDeliveryFiltersMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentErrorsEmailDeliveryListDto
 */
export interface PaymentErrorsEmailDeliveryListDto {
    /**
     * 
     * @type {Array<PaymentErrorsEmailDelivery>}
     * @memberof PaymentErrorsEmailDeliveryListDto
     */
    results: Array<PaymentErrorsEmailDelivery>;
    /**
     * 
     * @type {number}
     * @memberof PaymentErrorsEmailDeliveryListDto
     */
    total?: number;
    /**
     * 
     * @type {PaymentErrorsEmailDeliveryFiltersMeta}
     * @memberof PaymentErrorsEmailDeliveryListDto
     */
    filters?: PaymentErrorsEmailDeliveryFiltersMeta;
    /**
     * 
     * @type {object}
     * @memberof PaymentErrorsEmailDeliveryListDto
     */
    extra?: object;
}

export function PaymentErrorsEmailDeliveryListDtoFromJSON(json: any): PaymentErrorsEmailDeliveryListDto {
    return PaymentErrorsEmailDeliveryListDtoFromJSONTyped(json, false);
}

export function PaymentErrorsEmailDeliveryListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentErrorsEmailDeliveryListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PaymentErrorsEmailDeliveryFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : PaymentErrorsEmailDeliveryFiltersMetaFromJSON(json['filters']),
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function PaymentErrorsEmailDeliveryListDtoToJSON(value?: PaymentErrorsEmailDeliveryListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PaymentErrorsEmailDeliveryToJSON)),
        'total': value.total,
        'filters': PaymentErrorsEmailDeliveryFiltersMetaToJSON(value.filters),
        'extra': value.extra,
    };
}


