/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FreezeVirtualAccountItemDto
 */
export interface FreezeVirtualAccountItemDto {
    /**
     * 
     * @type {Date}
     * @memberof FreezeVirtualAccountItemDto
     */
    frozenAt: Date;
    /**
     * 
     * @type {number}
     * @memberof FreezeVirtualAccountItemDto
     */
    virtualAccountId: number;
    /**
     * 
     * @type {number}
     * @memberof FreezeVirtualAccountItemDto
     */
    frozenReasoningId: number;
}

export function FreezeVirtualAccountItemDtoFromJSON(json: any): FreezeVirtualAccountItemDto {
    return FreezeVirtualAccountItemDtoFromJSONTyped(json, false);
}

export function FreezeVirtualAccountItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FreezeVirtualAccountItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frozenAt': (new Date(json['frozenAt'])),
        'virtualAccountId': json['virtualAccountId'],
        'frozenReasoningId': json['frozenReasoningId'],
    };
}

export function FreezeVirtualAccountItemDtoToJSON(value?: FreezeVirtualAccountItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frozenAt': (value.frozenAt.toISOString()),
        'virtualAccountId': value.virtualAccountId,
        'frozenReasoningId': value.frozenReasoningId,
    };
}


