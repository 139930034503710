/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BatchErrors
 */
export interface BatchErrors {
    /**
     * 
     * @type {string}
     * @memberof BatchErrors
     */
    workflowState: BatchErrorsWorkflowStateEnum;
    /**
     * 
     * @type {number}
     * @memberof BatchErrors
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof BatchErrors
     */
    autoResolvable: number;
}

/**
* @export
* @enum {string}
*/
export enum BatchErrorsWorkflowStateEnum {
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}

export function BatchErrorsFromJSON(json: any): BatchErrors {
    return BatchErrorsFromJSONTyped(json, false);
}

export function BatchErrorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BatchErrors {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'workflowState': json['workflowState'],
        'total': json['total'],
        'autoResolvable': json['autoResolvable'],
    };
}

export function BatchErrorsToJSON(value?: BatchErrors | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'workflowState': value.workflowState,
        'total': value.total,
        'autoResolvable': value.autoResolvable,
    };
}


