/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LinkVirtualAccountsDto,
    LinkVirtualAccountsDtoFromJSON,
    LinkVirtualAccountsDtoToJSON,
    RemapLocationVirtualAccountDto,
    RemapLocationVirtualAccountDtoFromJSON,
    RemapLocationVirtualAccountDtoToJSON,
    VirtualAccountHistoryResponseDto,
    VirtualAccountHistoryResponseDtoFromJSON,
    VirtualAccountHistoryResponseDtoToJSON,
    VirtualAccountUpdateDto,
    VirtualAccountUpdateDtoFromJSON,
    VirtualAccountUpdateDtoToJSON,
} from '../models';

export interface CustomerVirtualAccountsControllerGetVirtualAccountHistoryRequest {
    virtualAccountId: number;
    customerId: number;
    limit?: number;
    offset?: number;
    exclude?: Array<CustomerVirtualAccountsControllerGetVirtualAccountHistoryExcludeEnum>;
    startDate?: Date;
    endDate?: Date;
}

export interface CustomerVirtualAccountsControllerRemapVirtualAccountsRequest {
    customerId: number;
    remapLocationVirtualAccountDto: RemapLocationVirtualAccountDto;
}

export interface VirtualAccountsControllerLinkVirtualAccountsRequest {
    virtualAccountGroupId: number;
    linkVirtualAccountsDto: LinkVirtualAccountsDto;
}

export interface VirtualAccountsControllerUpdateVirtualAccountRequest {
    virtualAccountId: number;
    virtualAccountUpdateDto: VirtualAccountUpdateDto;
}

/**
 * 
 */
export class VirtualAccountsApi extends runtime.BaseAPI {

    /**
     * Get virtual account history with a given ID for a given customer
     */
    async customerVirtualAccountsControllerGetVirtualAccountHistoryRaw(requestParameters: CustomerVirtualAccountsControllerGetVirtualAccountHistoryRequest): Promise<runtime.ApiResponse<VirtualAccountHistoryResponseDto>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling customerVirtualAccountsControllerGetVirtualAccountHistory.');
        }

        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountsControllerGetVirtualAccountHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString().substr(0,10);
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString().substr(0,10);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-accounts/{virtualAccountId}/history`.replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))).replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VirtualAccountHistoryResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get virtual account history with a given ID for a given customer
     */
    async customerVirtualAccountsControllerGetVirtualAccountHistory(requestParameters: CustomerVirtualAccountsControllerGetVirtualAccountHistoryRequest): Promise<VirtualAccountHistoryResponseDto> {
        const response = await this.customerVirtualAccountsControllerGetVirtualAccountHistoryRaw(requestParameters);
        return await response.value();
    }

    /**
     * Remap location to virtual accounts
     */
    async customerVirtualAccountsControllerRemapVirtualAccountsRaw(requestParameters: CustomerVirtualAccountsControllerRemapVirtualAccountsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling customerVirtualAccountsControllerRemapVirtualAccounts.');
        }

        if (requestParameters.remapLocationVirtualAccountDto === null || requestParameters.remapLocationVirtualAccountDto === undefined) {
            throw new runtime.RequiredError('remapLocationVirtualAccountDto','Required parameter requestParameters.remapLocationVirtualAccountDto was null or undefined when calling customerVirtualAccountsControllerRemapVirtualAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/customers/{customerId}/virtual-accounts-remap`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RemapLocationVirtualAccountDtoToJSON(requestParameters.remapLocationVirtualAccountDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remap location to virtual accounts
     */
    async customerVirtualAccountsControllerRemapVirtualAccounts(requestParameters: CustomerVirtualAccountsControllerRemapVirtualAccountsRequest): Promise<void> {
        await this.customerVirtualAccountsControllerRemapVirtualAccountsRaw(requestParameters);
    }

    /**
     * Returns the Freeze virtual accounts reasons list
     */
    async virtualAccountsControllerGetFreezeVirtualAccountFreezeReasoningListRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/virtual-accounts/freeze-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Returns the Freeze virtual accounts reasons list
     */
    async virtualAccountsControllerGetFreezeVirtualAccountFreezeReasoningList(): Promise<void> {
        await this.virtualAccountsControllerGetFreezeVirtualAccountFreezeReasoningListRaw();
    }

    /**
     * Link virtual accounts
     */
    async virtualAccountsControllerLinkVirtualAccountsRaw(requestParameters: VirtualAccountsControllerLinkVirtualAccountsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.virtualAccountGroupId === null || requestParameters.virtualAccountGroupId === undefined) {
            throw new runtime.RequiredError('virtualAccountGroupId','Required parameter requestParameters.virtualAccountGroupId was null or undefined when calling virtualAccountsControllerLinkVirtualAccounts.');
        }

        if (requestParameters.linkVirtualAccountsDto === null || requestParameters.linkVirtualAccountsDto === undefined) {
            throw new runtime.RequiredError('linkVirtualAccountsDto','Required parameter requestParameters.linkVirtualAccountsDto was null or undefined when calling virtualAccountsControllerLinkVirtualAccounts.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/virtual-accounts/{virtualAccountGroupId}`.replace(`{${"virtualAccountGroupId"}}`, encodeURIComponent(String(requestParameters.virtualAccountGroupId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: LinkVirtualAccountsDtoToJSON(requestParameters.linkVirtualAccountsDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Link virtual accounts
     */
    async virtualAccountsControllerLinkVirtualAccounts(requestParameters: VirtualAccountsControllerLinkVirtualAccountsRequest): Promise<void> {
        await this.virtualAccountsControllerLinkVirtualAccountsRaw(requestParameters);
    }

    /**
     * Update a virtual account
     */
    async virtualAccountsControllerUpdateVirtualAccountRaw(requestParameters: VirtualAccountsControllerUpdateVirtualAccountRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.virtualAccountId === null || requestParameters.virtualAccountId === undefined) {
            throw new runtime.RequiredError('virtualAccountId','Required parameter requestParameters.virtualAccountId was null or undefined when calling virtualAccountsControllerUpdateVirtualAccount.');
        }

        if (requestParameters.virtualAccountUpdateDto === null || requestParameters.virtualAccountUpdateDto === undefined) {
            throw new runtime.RequiredError('virtualAccountUpdateDto','Required parameter requestParameters.virtualAccountUpdateDto was null or undefined when calling virtualAccountsControllerUpdateVirtualAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/virtual-accounts/{virtualAccountId}`.replace(`{${"virtualAccountId"}}`, encodeURIComponent(String(requestParameters.virtualAccountId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VirtualAccountUpdateDtoToJSON(requestParameters.virtualAccountUpdateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a virtual account
     */
    async virtualAccountsControllerUpdateVirtualAccount(requestParameters: VirtualAccountsControllerUpdateVirtualAccountRequest): Promise<void> {
        await this.virtualAccountsControllerUpdateVirtualAccountRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CustomerVirtualAccountsControllerGetVirtualAccountHistoryExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
