/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CSIDetails
 */
export interface CSIDetails {
    /**
     * 
     * @type {number}
     * @memberof CSIDetails
     */
    amountPaid: number;
    /**
     * 
     * @type {number}
     * @memberof CSIDetails
     */
    csiBatchId: number;
    /**
     * 
     * @type {number}
     * @memberof CSIDetails
     */
    checkNumber: number;
    /**
     * 
     * @type {Date}
     * @memberof CSIDetails
     */
    batchCreatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof CSIDetails
     */
    paymentMethod: string;
    /**
     * 
     * @type {string}
     * @memberof CSIDetails
     */
    paymentStatus: string;
    /**
     * 
     * @type {Date}
     * @memberof CSIDetails
     */
    transactionCreatedAt: Date;
}

export function CSIDetailsFromJSON(json: any): CSIDetails {
    return CSIDetailsFromJSONTyped(json, false);
}

export function CSIDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CSIDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amountPaid': json['amountPaid'],
        'csiBatchId': json['csiBatchId'],
        'checkNumber': json['checkNumber'],
        'batchCreatedAt': (new Date(json['batchCreatedAt'])),
        'paymentMethod': json['paymentMethod'],
        'paymentStatus': json['paymentStatus'],
        'transactionCreatedAt': (new Date(json['transactionCreatedAt'])),
    };
}

export function CSIDetailsToJSON(value?: CSIDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amountPaid': value.amountPaid,
        'csiBatchId': value.csiBatchId,
        'checkNumber': value.checkNumber,
        'batchCreatedAt': (value.batchCreatedAt.toISOString()),
        'paymentMethod': value.paymentMethod,
        'paymentStatus': value.paymentStatus,
        'transactionCreatedAt': (value.transactionCreatedAt.toISOString()),
    };
}


