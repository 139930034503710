/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillHistoryModel,
    BillHistoryModelFromJSON,
    BillHistoryModelFromJSONTyped,
    BillHistoryModelToJSON,
} from './';

/**
 * 
 * @export
 * @interface BillsHistoryResponseDto
 */
export interface BillsHistoryResponseDto {
    /**
     * 
     * @type {Array<BillHistoryModel>}
     * @memberof BillsHistoryResponseDto
     */
    data: Array<BillHistoryModel>;
}

export function BillsHistoryResponseDtoFromJSON(json: any): BillsHistoryResponseDto {
    return BillsHistoryResponseDtoFromJSONTyped(json, false);
}

export function BillsHistoryResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillsHistoryResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BillHistoryModelFromJSON)),
    };
}

export function BillsHistoryResponseDtoToJSON(value?: BillsHistoryResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(BillHistoryModelToJSON)),
    };
}


