import {
  CustomerUnprocessedBillsApi,
  CustomerUnprocessedBillsControllerDeleteUnprocessedBillRequest,
  CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest,
  CustomerUnprocessedBillsControllerMatchUnprocessedBillRequest,
  UnprocessedBillsPreviewListDto,
} from '@/SharedModule/Api/index'
import { ApiService } from '@/SharedModule/Services/service.api'

export class CustomersUnprocessedBillsService extends ApiService {
  private customersUnprocessedBillsApi = new CustomerUnprocessedBillsApi(
    this.getBaseOptions(),
  )

  public getCustomerUnprocessedBillsPreviews(
    requestParameters: CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest,
  ): Promise<UnprocessedBillsPreviewListDto> {
    return this.customersUnprocessedBillsApi.customerUnprocessedBillsControllerGetUnprocessedBills(
      requestParameters,
    )
  }

  public deleteCustomerUnprocessedBillsPreviews(
    requestParameters: CustomerUnprocessedBillsControllerDeleteUnprocessedBillRequest,
  ): Promise<void> {
    return this.customersUnprocessedBillsApi.customerUnprocessedBillsControllerDeleteUnprocessedBill(
      requestParameters,
    )
  }

  public matchCustomerUnprocessedBillsPreviews(
    requestParameters: CustomerUnprocessedBillsControllerMatchUnprocessedBillRequest,
  ): Promise<void> {
    return this.customersUnprocessedBillsApi.customerUnprocessedBillsControllerMatchUnprocessedBill(
      requestParameters,
    )
  }
}
