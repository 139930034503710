import {
  BillValidationDefinitionsListDto,
  ResourcesApi,
  ResourcesControllerGetBillValidationDefinitionsRequest,
} from '@/SharedModule/Api'
import { ApiService } from '@/SharedModule/Services/service.api'

export class ResourcesService extends ApiService {
  private readonly resourcesApi = new ResourcesApi(this.getBaseOptions())

  public getBillValidaitonDefinitions(
    request: ResourcesControllerGetBillValidationDefinitionsRequest = {},
  ): Promise<BillValidationDefinitionsListDto> {
    return this.resourcesApi.resourcesControllerGetBillValidationDefinitions(
      request,
    )
  }
}
