/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ReportTemplatesListDto,
    ReportTemplatesListDtoFromJSON,
    ReportTemplatesListDtoToJSON,
    RunReportDto,
    RunReportDtoFromJSON,
    RunReportDtoToJSON,
} from '../models';

export interface AdminGlobalReportsControllerGetReportTemplatesRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<AdminGlobalReportsControllerGetReportTemplatesExcludeEnum>;
    orderBy?: Array<AdminGlobalReportsControllerGetReportTemplatesOrderByEnum>;
    search?: string;
    featureType?: AdminGlobalReportsControllerGetReportTemplatesFeatureTypeEnum;
}

export interface AdminGlobalReportsControllerUpdateReportRequest {
    reportId: string;
    runReportDto: RunReportDto;
}

/**
 * 
 */
export class AdminGlobalReportsApi extends runtime.BaseAPI {

    /**
     * Get the list of report templates for a given customer
     */
    async adminGlobalReportsControllerGetReportTemplatesRaw(requestParameters: AdminGlobalReportsControllerGetReportTemplatesRequest): Promise<runtime.ApiResponse<ReportTemplatesListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.featureType !== undefined) {
            queryParameters['featureType'] = requestParameters.featureType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/admin/reports/templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ReportTemplatesListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of report templates for a given customer
     */
    async adminGlobalReportsControllerGetReportTemplates(requestParameters: AdminGlobalReportsControllerGetReportTemplatesRequest): Promise<ReportTemplatesListDto> {
        const response = await this.adminGlobalReportsControllerGetReportTemplatesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update an existing admin global report
     */
    async adminGlobalReportsControllerUpdateReportRaw(requestParameters: AdminGlobalReportsControllerUpdateReportRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.reportId === null || requestParameters.reportId === undefined) {
            throw new runtime.RequiredError('reportId','Required parameter requestParameters.reportId was null or undefined when calling adminGlobalReportsControllerUpdateReport.');
        }

        if (requestParameters.runReportDto === null || requestParameters.runReportDto === undefined) {
            throw new runtime.RequiredError('runReportDto','Required parameter requestParameters.runReportDto was null or undefined when calling adminGlobalReportsControllerUpdateReport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/admin/reports/{reportId}`.replace(`{${"reportId"}}`, encodeURIComponent(String(requestParameters.reportId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RunReportDtoToJSON(requestParameters.runReportDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update an existing admin global report
     */
    async adminGlobalReportsControllerUpdateReport(requestParameters: AdminGlobalReportsControllerUpdateReportRequest): Promise<void> {
        await this.adminGlobalReportsControllerUpdateReportRaw(requestParameters);
    }

}

/**
    * @export
    * @enum {string}
    */
export enum AdminGlobalReportsControllerGetReportTemplatesExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminGlobalReportsControllerGetReportTemplatesOrderByEnum {
    Asc = 'title.asc',
    Desc = 'title.desc'
}
/**
    * @export
    * @enum {string}
    */
export enum AdminGlobalReportsControllerGetReportTemplatesFeatureTypeEnum {
    Reports = 'reports',
    Budgeting = 'budgeting'
}
