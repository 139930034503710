const API_HOST = process.env.VUE_APP_API_HOST || ''
export const API_V1_HOST = process.env.VUE_APP_API_V1_HOST || ''
export const API_V3_HOST = process.env.VUE_APP_API_V3_HOST || API_V1_HOST
const SCHEME = process.env.VUE_APP_SCHEME || 'https'

export const API_URL = API_HOST ? `${SCHEME}://${API_HOST}` : ''
export const API_V1_URL = API_V1_HOST ? `${SCHEME}://${API_V1_HOST}` : ''
export const API_V3_URL = API_V3_HOST ? `${SCHEME}://${API_V3_HOST}` : ''

export const envs = {
  NODE_ENV: process.env.VUE_APP_NODE_ENV,
  api: {
    host: API_URL,
    path: '/api',
    static: `${API_URL}`,
  },
  v1: {
    host: API_V1_URL,
    path: '/api/v1',
    static: `${API_V1_URL}`,
  },
  v3: {
    host: API_V3_URL,
    path: '/api/v3',
    static: `${API_V3_URL}`,
  },
}
