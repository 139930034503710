/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillParkDto
 */
export interface BillParkDto {
    /**
     * 
     * @type {string}
     * @memberof BillParkDto
     */
    message: string;
    /**
     * 
     * @type {number}
     * @memberof BillParkDto
     */
    billId: number;
}

export function BillParkDtoFromJSON(json: any): BillParkDto {
    return BillParkDtoFromJSONTyped(json, false);
}

export function BillParkDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillParkDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'billId': json['billId'],
    };
}

export function BillParkDtoToJSON(value?: BillParkDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'billId': value.billId,
    };
}


