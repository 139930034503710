/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnalyzeBillDto,
    AnalyzeBillDtoFromJSON,
    AnalyzeBillDtoToJSON,
    BatchCompleteDto,
    BatchCompleteDtoFromJSON,
    BatchCompleteDtoToJSON,
    Bill,
    BillFromJSON,
    BillToJSON,
    BillReparseDto,
    BillReparseDtoFromJSON,
    BillReparseDtoToJSON,
    BulkBillWeatherCalculationsDto,
    BulkBillWeatherCalculationsDtoFromJSON,
    BulkBillWeatherCalculationsDtoToJSON,
    ExportLocationDialogFlowDto,
    ExportLocationDialogFlowDtoFromJSON,
    ExportLocationDialogFlowDtoToJSON,
    SendEmailDto,
    SendEmailDtoFromJSON,
    SendEmailDtoToJSON,
    VirtualAccountBillUpdateDto,
    VirtualAccountBillUpdateDtoFromJSON,
    VirtualAccountBillUpdateDtoToJSON,
    VirtualAccountBulkBillsUpdateDto,
    VirtualAccountBulkBillsUpdateDtoFromJSON,
    VirtualAccountBulkBillsUpdateDtoToJSON,
    WeatherCalculation,
    WeatherCalculationFromJSON,
    WeatherCalculationToJSON,
} from '../models';

export interface BatchQueueControllerTestBatchParseRequest {
    customerId: number;
    batchId: number;
}

export interface BatchQueueControllerTestExportBillRequest {
    batchCompleteDto: BatchCompleteDto;
}

export interface BillsQueueControllerAnalyzeBillRequest {
    analyzeBillDto: AnalyzeBillDto;
}

export interface BillsQueueControllerReviewBillRequest {
    analyzeBillDto: AnalyzeBillDto;
}

export interface BillsQueueControllerTestBillRegenerateObservationsRequest {
    billReparseDto: BillReparseDto;
}

export interface BillsQueueControllerTestBulkWeatherCalculationsRequest {
    bulkBillWeatherCalculationsDto: BulkBillWeatherCalculationsDto;
}

export interface BillsQueueControllerTestWeatherCalculationsRequest {
    billId: number;
}

export interface CustomerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdateRequest {
    virtualAccountBillUpdateDto: VirtualAccountBillUpdateDto;
}

export interface CustomerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdateRequest {
    virtualAccountBulkBillsUpdateDto: VirtualAccountBulkBillsUpdateDto;
}

export interface EmailerQueueControllerTestSendEmailRequest {
    sendEmailDto: SendEmailDto;
}

export interface SamQueueControllerTestExportLocationToDialogFlowRequest {
    exportLocationDialogFlowDto: ExportLocationDialogFlowDto;
}

/**
 * 
 */
export class QueueApi extends runtime.BaseAPI {

    /**
     * Test the batch reparse api
     */
    async batchQueueControllerTestBatchParseRaw(requestParameters: BatchQueueControllerTestBatchParseRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling batchQueueControllerTestBatchParse.');
        }

        if (requestParameters.batchId === null || requestParameters.batchId === undefined) {
            throw new runtime.RequiredError('batchId','Required parameter requestParameters.batchId was null or undefined when calling batchQueueControllerTestBatchParse.');
        }

        const queryParameters: any = {};

        if (requestParameters.customerId !== undefined) {
            queryParameters['customerId'] = requestParameters.customerId;
        }

        if (requestParameters.batchId !== undefined) {
            queryParameters['batchId'] = requestParameters.batchId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v3/batch-queue/parse-batch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test the batch reparse api
     */
    async batchQueueControllerTestBatchParse(requestParameters: BatchQueueControllerTestBatchParseRequest): Promise<void> {
        await this.batchQueueControllerTestBatchParseRaw(requestParameters);
    }

    /**
     * Test the batch complete async queue
     */
    async batchQueueControllerTestExportBillRaw(requestParameters: BatchQueueControllerTestExportBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.batchCompleteDto === null || requestParameters.batchCompleteDto === undefined) {
            throw new runtime.RequiredError('batchCompleteDto','Required parameter requestParameters.batchCompleteDto was null or undefined when calling batchQueueControllerTestExportBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/batch-queue/batch-complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BatchCompleteDtoToJSON(requestParameters.batchCompleteDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test the batch complete async queue
     */
    async batchQueueControllerTestExportBill(requestParameters: BatchQueueControllerTestExportBillRequest): Promise<void> {
        await this.batchQueueControllerTestExportBillRaw(requestParameters);
    }

    /**
     * Test analyze bill
     */
    async billsQueueControllerAnalyzeBillRaw(requestParameters: BillsQueueControllerAnalyzeBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.analyzeBillDto === null || requestParameters.analyzeBillDto === undefined) {
            throw new runtime.RequiredError('analyzeBillDto','Required parameter requestParameters.analyzeBillDto was null or undefined when calling billsQueueControllerAnalyzeBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/bills/analyze-bill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyzeBillDtoToJSON(requestParameters.analyzeBillDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test analyze bill
     */
    async billsQueueControllerAnalyzeBill(requestParameters: BillsQueueControllerAnalyzeBillRequest): Promise<void> {
        await this.billsQueueControllerAnalyzeBillRaw(requestParameters);
    }

    /**
     * Test review bill
     */
    async billsQueueControllerReviewBillRaw(requestParameters: BillsQueueControllerReviewBillRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.analyzeBillDto === null || requestParameters.analyzeBillDto === undefined) {
            throw new runtime.RequiredError('analyzeBillDto','Required parameter requestParameters.analyzeBillDto was null or undefined when calling billsQueueControllerReviewBill.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/bills/review-bill`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AnalyzeBillDtoToJSON(requestParameters.analyzeBillDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test review bill
     */
    async billsQueueControllerReviewBill(requestParameters: BillsQueueControllerReviewBillRequest): Promise<void> {
        await this.billsQueueControllerReviewBillRaw(requestParameters);
    }

    /**
     * Test bill regenerate observations
     */
    async billsQueueControllerTestBillRegenerateObservationsRaw(requestParameters: BillsQueueControllerTestBillRegenerateObservationsRequest): Promise<runtime.ApiResponse<Bill>> {
        if (requestParameters.billReparseDto === null || requestParameters.billReparseDto === undefined) {
            throw new runtime.RequiredError('billReparseDto','Required parameter requestParameters.billReparseDto was null or undefined when calling billsQueueControllerTestBillRegenerateObservations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/bills/regenerate-observations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BillReparseDtoToJSON(requestParameters.billReparseDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BillFromJSON(jsonValue));
    }

    /**
     * Test bill regenerate observations
     */
    async billsQueueControllerTestBillRegenerateObservations(requestParameters: BillsQueueControllerTestBillRegenerateObservationsRequest): Promise<Bill> {
        const response = await this.billsQueueControllerTestBillRegenerateObservationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Test Bill reparse
     */
    async billsQueueControllerTestBillReparseRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v3/queue/bills/reparse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test Bill reparse
     */
    async billsQueueControllerTestBillReparse(): Promise<void> {
        await this.billsQueueControllerTestBillReparseRaw();
    }

    /**
     * Test Bulk Weather Calculations
     */
    async billsQueueControllerTestBulkWeatherCalculationsRaw(requestParameters: BillsQueueControllerTestBulkWeatherCalculationsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.bulkBillWeatherCalculationsDto === null || requestParameters.bulkBillWeatherCalculationsDto === undefined) {
            throw new runtime.RequiredError('bulkBillWeatherCalculationsDto','Required parameter requestParameters.bulkBillWeatherCalculationsDto was null or undefined when calling billsQueueControllerTestBulkWeatherCalculations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/bills/bulk-weather-calculations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkBillWeatherCalculationsDtoToJSON(requestParameters.bulkBillWeatherCalculationsDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test Bulk Weather Calculations
     */
    async billsQueueControllerTestBulkWeatherCalculations(requestParameters: BillsQueueControllerTestBulkWeatherCalculationsRequest): Promise<void> {
        await this.billsQueueControllerTestBulkWeatherCalculationsRaw(requestParameters);
    }

    /**
     * Test the weather calculations apiv3 method
     */
    async billsQueueControllerTestWeatherCalculationsRaw(requestParameters: BillsQueueControllerTestWeatherCalculationsRequest): Promise<runtime.ApiResponse<Array<WeatherCalculation>>> {
        if (requestParameters.billId === null || requestParameters.billId === undefined) {
            throw new runtime.RequiredError('billId','Required parameter requestParameters.billId was null or undefined when calling billsQueueControllerTestWeatherCalculations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/v3/queue/bills/{billId}/weather-calculations`.replace(`{${"billId"}}`, encodeURIComponent(String(requestParameters.billId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WeatherCalculationFromJSON));
    }

    /**
     * Test the weather calculations apiv3 method
     */
    async billsQueueControllerTestWeatherCalculations(requestParameters: BillsQueueControllerTestWeatherCalculationsRequest): Promise<Array<WeatherCalculation>> {
        const response = await this.billsQueueControllerTestWeatherCalculationsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Test Virtual Account Bill Edit
     */
    async customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdateRaw(requestParameters: CustomerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.virtualAccountBillUpdateDto === null || requestParameters.virtualAccountBillUpdateDto === undefined) {
            throw new runtime.RequiredError('virtualAccountBillUpdateDto','Required parameter requestParameters.virtualAccountBillUpdateDto was null or undefined when calling customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/bills/virtual-accounts/bill-edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VirtualAccountBillUpdateDtoToJSON(requestParameters.virtualAccountBillUpdateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test Virtual Account Bill Edit
     */
    async customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdate(requestParameters: CustomerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdateRequest): Promise<void> {
        await this.customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBillUpdateRaw(requestParameters);
    }

    /**
     * Test Virtual Account Bulk Bills Edit
     */
    async customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdateRaw(requestParameters: CustomerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.virtualAccountBulkBillsUpdateDto === null || requestParameters.virtualAccountBulkBillsUpdateDto === undefined) {
            throw new runtime.RequiredError('virtualAccountBulkBillsUpdateDto','Required parameter requestParameters.virtualAccountBulkBillsUpdateDto was null or undefined when calling customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/bills/virtual-accounts/bulk-bills-edit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VirtualAccountBulkBillsUpdateDtoToJSON(requestParameters.virtualAccountBulkBillsUpdateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test Virtual Account Bulk Bills Edit
     */
    async customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdate(requestParameters: CustomerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdateRequest): Promise<void> {
        await this.customerVirtualAccountsBillsControllerQueueControllerTestVirtualAccountBulkBillsUpdateRaw(requestParameters);
    }

    /**
     * Test Email Send
     */
    async emailerQueueControllerTestSendEmailRaw(requestParameters: EmailerQueueControllerTestSendEmailRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sendEmailDto === null || requestParameters.sendEmailDto === undefined) {
            throw new runtime.RequiredError('sendEmailDto','Required parameter requestParameters.sendEmailDto was null or undefined when calling emailerQueueControllerTestSendEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/queue/emails`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendEmailDtoToJSON(requestParameters.sendEmailDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test Email Send
     */
    async emailerQueueControllerTestSendEmail(requestParameters: EmailerQueueControllerTestSendEmailRequest): Promise<void> {
        await this.emailerQueueControllerTestSendEmailRaw(requestParameters);
    }

    /**
     * Test the export location to dialogflow async queue
     */
    async samQueueControllerTestExportLocationToDialogFlowRaw(requestParameters: SamQueueControllerTestExportLocationToDialogFlowRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exportLocationDialogFlowDto === null || requestParameters.exportLocationDialogFlowDto === undefined) {
            throw new runtime.RequiredError('exportLocationDialogFlowDto','Required parameter requestParameters.exportLocationDialogFlowDto was null or undefined when calling samQueueControllerTestExportLocationToDialogFlow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/v3/sam-queue/export-location-dialog-flow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportLocationDialogFlowDtoToJSON(requestParameters.exportLocationDialogFlowDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Test the export location to dialogflow async queue
     */
    async samQueueControllerTestExportLocationToDialogFlow(requestParameters: SamQueueControllerTestExportLocationToDialogFlowRequest): Promise<void> {
        await this.samQueueControllerTestExportLocationToDialogFlowRaw(requestParameters);
    }

}
