/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ProviderCodePairListDto,
    ProviderCodePairListDtoFromJSON,
    ProviderCodePairListDtoToJSON,
    Vendor,
    VendorFromJSON,
    VendorToJSON,
    VendorListDto,
    VendorListDtoFromJSON,
    VendorListDtoToJSON,
    VendorPrettyNameDto,
    VendorPrettyNameDtoFromJSON,
    VendorPrettyNameDtoToJSON,
    VendorProviderDetails,
    VendorProviderDetailsFromJSON,
    VendorProviderDetailsToJSON,
    VendorProviderDetailsDto,
    VendorProviderDetailsDtoFromJSON,
    VendorProviderDetailsDtoToJSON,
    VendorWithProviderDetails,
    VendorWithProviderDetailsFromJSON,
    VendorWithProviderDetailsToJSON,
} from '../models';

export interface VendorsControllerBulkUpdateVendorPrettyNamesRequest {
    vendorPrettyNameDto: Array<VendorPrettyNameDto>;
}

export interface VendorsControllerGetProvidersByCodeRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<VendorsControllerGetProvidersByCodeExcludeEnum>;
    query?: string;
}

export interface VendorsControllerGetVendorByIdRequest {
    vendorId: number;
}

export interface VendorsControllerListVendorsRequest {
    limit?: number;
    offset?: number;
    exclude?: Array<VendorsControllerListVendorsExcludeEnum>;
    query?: string;
    blankPrettyName?: boolean;
    orderBy?: Array<VendorsControllerListVendorsOrderByEnum>;
}

export interface VendorsControllerSaveVendorProviderRequest {
    vendorProviderDetailsDto: VendorProviderDetailsDto;
}

/**
 * 
 */
export class VendorsApi extends runtime.BaseAPI {

    /**
     * Bulk update vendor pretty names
     */
    async vendorsControllerBulkUpdateVendorPrettyNamesRaw(requestParameters: VendorsControllerBulkUpdateVendorPrettyNamesRequest): Promise<runtime.ApiResponse<Array<Vendor>>> {
        if (requestParameters.vendorPrettyNameDto === null || requestParameters.vendorPrettyNameDto === undefined) {
            throw new runtime.RequiredError('vendorPrettyNameDto','Required parameter requestParameters.vendorPrettyNameDto was null or undefined when calling vendorsControllerBulkUpdateVendorPrettyNames.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/vendors/pretty-names`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.vendorPrettyNameDto.map(VendorPrettyNameDtoToJSON),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(VendorFromJSON));
    }

    /**
     * Bulk update vendor pretty names
     */
    async vendorsControllerBulkUpdateVendorPrettyNames(requestParameters: VendorsControllerBulkUpdateVendorPrettyNamesRequest): Promise<Array<Vendor>> {
        const response = await this.vendorsControllerBulkUpdateVendorPrettyNamesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of existing vendors providers codes and their associated provider ids
     */
    async vendorsControllerGetProvidersByCodeRaw(requestParameters: VendorsControllerGetProvidersByCodeRequest): Promise<runtime.ApiResponse<ProviderCodePairListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/vendors/providers-by-code`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderCodePairListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of existing vendors providers codes and their associated provider ids
     */
    async vendorsControllerGetProvidersByCode(requestParameters: VendorsControllerGetProvidersByCodeRequest): Promise<ProviderCodePairListDto> {
        const response = await this.vendorsControllerGetProvidersByCodeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get a vendor by id
     */
    async vendorsControllerGetVendorByIdRaw(requestParameters: VendorsControllerGetVendorByIdRequest): Promise<runtime.ApiResponse<VendorWithProviderDetails>> {
        if (requestParameters.vendorId === null || requestParameters.vendorId === undefined) {
            throw new runtime.RequiredError('vendorId','Required parameter requestParameters.vendorId was null or undefined when calling vendorsControllerGetVendorById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/vendors/{vendorId}`.replace(`{${"vendorId"}}`, encodeURIComponent(String(requestParameters.vendorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorWithProviderDetailsFromJSON(jsonValue));
    }

    /**
     * Get a vendor by id
     */
    async vendorsControllerGetVendorById(requestParameters: VendorsControllerGetVendorByIdRequest): Promise<VendorWithProviderDetails> {
        const response = await this.vendorsControllerGetVendorByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Get the list of existing vendors
     */
    async vendorsControllerListVendorsRaw(requestParameters: VendorsControllerListVendorsRequest): Promise<runtime.ApiResponse<VendorListDto>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.exclude) {
            queryParameters['exclude'] = requestParameters.exclude;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.blankPrettyName !== undefined) {
            queryParameters['blankPrettyName'] = requestParameters.blankPrettyName;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy[]'] = requestParameters.orderBy;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/vendors`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorListDtoFromJSON(jsonValue));
    }

    /**
     * Get the list of existing vendors
     */
    async vendorsControllerListVendors(requestParameters: VendorsControllerListVendorsRequest): Promise<VendorListDto> {
        const response = await this.vendorsControllerListVendorsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Create or update a new vendor provider
     */
    async vendorsControllerSaveVendorProviderRaw(requestParameters: VendorsControllerSaveVendorProviderRequest): Promise<runtime.ApiResponse<VendorProviderDetails>> {
        if (requestParameters.vendorProviderDetailsDto === null || requestParameters.vendorProviderDetailsDto === undefined) {
            throw new runtime.RequiredError('vendorProviderDetailsDto','Required parameter requestParameters.vendorProviderDetailsDto was null or undefined when calling vendorsControllerSaveVendorProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/vendors/external`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VendorProviderDetailsDtoToJSON(requestParameters.vendorProviderDetailsDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VendorProviderDetailsFromJSON(jsonValue));
    }

    /**
     * Create or update a new vendor provider
     */
    async vendorsControllerSaveVendorProvider(requestParameters: VendorsControllerSaveVendorProviderRequest): Promise<VendorProviderDetails> {
        const response = await this.vendorsControllerSaveVendorProviderRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum VendorsControllerGetProvidersByCodeExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum VendorsControllerListVendorsExcludeEnum {
    Total = 'total',
    Filters = 'filters',
    Extra = 'extra'
}
/**
    * @export
    * @enum {string}
    */
export enum VendorsControllerListVendorsOrderByEnum {
    IdAsc = 'id.asc',
    CodeAsc = 'code.asc',
    PrettyNameAsc = 'prettyName.asc',
    CustomPrettyNameAsc = 'customPrettyName.asc',
    IdDesc = 'id.desc',
    CodeDesc = 'code.desc',
    PrettyNameDesc = 'prettyName.desc',
    CustomPrettyNameDesc = 'customPrettyName.desc'
}
