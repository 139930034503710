/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeatherCalculation
 */
export interface WeatherCalculation {
    /**
     * 
     * @type {number}
     * @memberof WeatherCalculation
     */
    blockId: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherCalculation
     */
    billRecordId: number;
    /**
     * 
     * @type {string}
     * @memberof WeatherCalculation
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof WeatherCalculation
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof WeatherCalculation
     */
    units: string;
}

export function WeatherCalculationFromJSON(json: any): WeatherCalculation {
    return WeatherCalculationFromJSONTyped(json, false);
}

export function WeatherCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherCalculation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blockId': json['blockId'],
        'billRecordId': json['billRecordId'],
        'name': json['name'],
        'value': json['value'],
        'units': json['units'],
    };
}

export function WeatherCalculationToJSON(value?: WeatherCalculation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blockId': value.blockId,
        'billRecordId': value.billRecordId,
        'name': value.name,
        'value': value.value,
        'units': value.units,
    };
}


