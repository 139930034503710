/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillsDashboardExtra
 */
export interface BillsDashboardExtra {
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    integrityCheck: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    dataVerification1: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    dataVerification2: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    dataAudit1: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    dataAudit2: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    error: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    deleted: number;
    /**
     * 
     * @type {number}
     * @memberof BillsDashboardExtra
     */
    processed: number;
}

export function BillsDashboardExtraFromJSON(json: any): BillsDashboardExtra {
    return BillsDashboardExtraFromJSONTyped(json, false);
}

export function BillsDashboardExtraFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillsDashboardExtra {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'integrityCheck': json['integrityCheck'],
        'dataVerification1': json['dataVerification1'],
        'dataVerification2': json['dataVerification2'],
        'dataAudit1': json['dataAudit1'],
        'dataAudit2': json['dataAudit2'],
        'error': json['error'],
        'deleted': json['deleted'],
        'processed': json['processed'],
    };
}

export function BillsDashboardExtraToJSON(value?: BillsDashboardExtra | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'integrityCheck': value.integrityCheck,
        'dataVerification1': value.dataVerification1,
        'dataVerification2': value.dataVerification2,
        'dataAudit1': value.dataAudit1,
        'dataAudit2': value.dataAudit2,
        'error': value.error,
        'deleted': value.deleted,
        'processed': value.processed,
    };
}


