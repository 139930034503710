import {
  TableOptions,
  TableQuery,
  TableRepository,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import { BillsService } from '@/Modules/Bills/service/service.bills'
import {
  BillPreview,
  BillsControllerGetMyBillsPreviewsExcludeEnum,
  BillsControllerGetMyBillsPreviewsRequest,
  BillsPreviewListDto,
  Customer,
  CustomersControllerGetListExcludeEnum,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import { CustomersService } from '@/Modules/Customers/services/service.customers'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'

const serviceBills = new BillsService()
const serviceCustomers = new CustomersService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      isParked: 0,
    }),
    sort: ref({
      limit: 10,
      offset: 0,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('!'),
        labelClassName: ref('text-align-center'),
        name: null,
        type: null,
        width: ref('28'),
        modelKey: ref('priority'),
      },
      {
        prettyName: ref('Bill ID'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('80'),
        modelKey: ref('id'),
      },
      {
        prettyName: ref('Customer'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('customerId'),
      },
      {
        prettyName: ref('Vendor'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('prettyName'),
      },
      {
        prettyName: ref('Invoice Date'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('110'),
        modelKey: ref('statementDate'),
      },
      {
        prettyName: ref('Amount Due'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('160'),
        modelKey: ref('totalBillCharges'),
      },
      {
        prettyName: ref('Service Account Id'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('200'),
        modelKey: ref('accountCodes'),
      },
      {
        prettyName: ref('Status'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('workflowState'),
      },
      {
        prettyName: ref('Target Date'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('100'),
        modelKey: ref('targetDate'),
      },
      {
        prettyName: ref('Bill Type'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('110'),
        modelKey: ref('billType'),
      },
      {
        prettyName: ref('More'),
        labelClassName: null,
        modelKey: null,
        type: null,
        width: ref('70'),
        name: ref('actions'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'bills.my',
      },
      customerNames: {},
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: BillsControllerGetMyBillsPreviewsRequest,
  meta: any,
): BillsControllerGetMyBillsPreviewsRequest => {
  if (meta.excludePaginationData) {
    filterData.exclude = []
    filterData.exclude.push(
      BillsControllerGetMyBillsPreviewsExcludeEnum.Total,
      BillsControllerGetMyBillsPreviewsExcludeEnum.Extra,
    )
  }

  return filterData
}

const useListRef = (): Ref<BillsPreviewListDto> =>
  ref({
    results: [],
    billsInProcess: [],
  }) as Ref<BillsPreviewListDto>

export default class RepositoryMyBills
  implements TableRepository<BillsPreviewListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  private async retrieveCustomerNames(bills: BillPreview[]): Promise<void> {
    const customerIds = bills
      .filter((bill: BillPreview) => bill.customerId)
      .map((bill) => bill.customerId)

    if (!customerIds.length) {
      return
    }

    const customers = await serviceCustomers.getCustomers({
      exclude: [
        CustomersControllerGetListExcludeEnum.Extra,
        CustomersControllerGetListExcludeEnum.Filters,
        CustomersControllerGetListExcludeEnum.Total,
      ],
      ids: customerIds,
    })

    this.options.meta.value.customerNames = []

    customers.results.forEach((currentCustomer: Customer): void => {
      this.options.meta.value.customerNames[currentCustomer.id] =
        currentCustomer.name
    })
  }

  getList = async (): Promise<void> => {
    const listValue = await serviceBills.getMyBillsPreviews(
      adaptFilterDataToApiTypesAndExclude(
        useQueryAsRequest<BillsControllerGetMyBillsPreviewsRequest>(
          this.options.query,
        ),
        this.options.meta.value,
      ),
    )

    // we need to do this to retrieve customer names using customerIds
    await this.retrieveCustomerNames(listValue.results)

    if (this.options.meta.value.excludePaginationData) {
      listValue.extra = this.list.value.extra
      listValue.total = this.list.value.total
      this.options.meta.value.excludePaginationData = false
    }
    this.list.value = listValue
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.meta.value.queryFiltersDirtyFromUserInput = true
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
