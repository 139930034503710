/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CarbonFootprintDto
 */
export interface CarbonFootprintDto {
    /**
     * 
     * @type {boolean}
     * @memberof CarbonFootprintDto
     */
    customReportEnabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CarbonFootprintDto
     */
    groupId: string;
    /**
     * 
     * @type {string}
     * @memberof CarbonFootprintDto
     */
    reportId: string;
    /**
     * 
     * @type {string}
     * @memberof CarbonFootprintDto
     */
    datasetId: string;
}

export function CarbonFootprintDtoFromJSON(json: any): CarbonFootprintDto {
    return CarbonFootprintDtoFromJSONTyped(json, false);
}

export function CarbonFootprintDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarbonFootprintDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customReportEnabled': json['customReportEnabled'],
        'groupId': json['groupId'],
        'reportId': json['reportId'],
        'datasetId': json['datasetId'],
    };
}

export function CarbonFootprintDtoToJSON(value?: CarbonFootprintDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customReportEnabled': value.customReportEnabled,
        'groupId': value.groupId,
        'reportId': value.reportId,
        'datasetId': value.datasetId,
    };
}


