/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface CubeControllerExportRequest {
    cubeQuery: string;
    format: CubeControllerExportFormatEnum;
    fileName: string;
}

/**
 * 
 */
export class CubeApi extends runtime.BaseAPI {

    /**
     * Get cube data as csv or excel
     */
    async cubeControllerExportRaw(requestParameters: CubeControllerExportRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.cubeQuery === null || requestParameters.cubeQuery === undefined) {
            throw new runtime.RequiredError('cubeQuery','Required parameter requestParameters.cubeQuery was null or undefined when calling cubeControllerExport.');
        }

        if (requestParameters.format === null || requestParameters.format === undefined) {
            throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling cubeControllerExport.');
        }

        if (requestParameters.fileName === null || requestParameters.fileName === undefined) {
            throw new runtime.RequiredError('fileName','Required parameter requestParameters.fileName was null or undefined when calling cubeControllerExport.');
        }

        const queryParameters: any = {};

        if (requestParameters.cubeQuery !== undefined) {
            queryParameters['cubeQuery'] = requestParameters.cubeQuery;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.fileName !== undefined) {
            queryParameters['fileName'] = requestParameters.fileName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/v3/cube/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get cube data as csv or excel
     */
    async cubeControllerExport(requestParameters: CubeControllerExportRequest): Promise<string> {
        const response = await this.cubeControllerExportRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum CubeControllerExportFormatEnum {
    Csv = 'csv',
    Xlsx = 'xlsx'
}
