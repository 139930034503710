/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountsOperatorNotes
 */
export interface VirtualAccountsOperatorNotes {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountsOperatorNotes
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof VirtualAccountsOperatorNotes
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof VirtualAccountsOperatorNotes
     */
    updatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountsOperatorNotes
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountsOperatorNotes
     */
    virtualAccountId: number;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountsOperatorNotes
     */
    note: string;
    /**
     * 
     * @type {string}
     * @memberof VirtualAccountsOperatorNotes
     */
    priority: VirtualAccountsOperatorNotesPriorityEnum;
    /**
     * 
     * @type {User}
     * @memberof VirtualAccountsOperatorNotes
     */
    user: User;
}

/**
* @export
* @enum {string}
*/
export enum VirtualAccountsOperatorNotesPriorityEnum {
    Regular = 'Regular',
    High = 'High'
}

export function VirtualAccountsOperatorNotesFromJSON(json: any): VirtualAccountsOperatorNotes {
    return VirtualAccountsOperatorNotesFromJSONTyped(json, false);
}

export function VirtualAccountsOperatorNotesFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountsOperatorNotes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'userId': json['userId'],
        'virtualAccountId': json['virtualAccountId'],
        'note': json['note'],
        'priority': json['priority'],
        'user': UserFromJSON(json['user']),
    };
}

export function VirtualAccountsOperatorNotesToJSON(value?: VirtualAccountsOperatorNotes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'userId': value.userId,
        'virtualAccountId': value.virtualAccountId,
        'note': value.note,
        'priority': value.priority,
        'user': UserToJSON(value.user),
    };
}


