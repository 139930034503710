/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
    ProvidersMetadataAndMappingAttribute,
    ProvidersMetadataAndMappingAttributeFromJSON,
    ProvidersMetadataAndMappingAttributeFromJSONTyped,
    ProvidersMetadataAndMappingAttributeToJSON,
} from './';

/**
 * 
 * @export
 * @interface CustomerProviderVendor
 */
export interface CustomerProviderVendor {
    /**
     * 
     * @type {number}
     * @memberof CustomerProviderVendor
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerProviderVendor
     */
    vendorId: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerProviderVendor
     */
    providerId: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    remittanceName: string;
    /**
     * 
     * @type {AddressModel}
     * @memberof CustomerProviderVendor
     */
    remittanceAddress: AddressModel;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    mainPhone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    customerServicePhone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    webAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    provider: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    emergencyPhone: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerProviderVendor
     */
    priorityPhone: string;
    /**
     * 
     * @type {Array<ProvidersMetadataAndMappingAttribute>}
     * @memberof CustomerProviderVendor
     */
    customVendorAttributes: Array<ProvidersMetadataAndMappingAttribute>;
}

export function CustomerProviderVendorFromJSON(json: any): CustomerProviderVendor {
    return CustomerProviderVendorFromJSONTyped(json, false);
}

export function CustomerProviderVendorFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerProviderVendor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'vendorId': json['vendorId'],
        'providerId': json['providerId'],
        'name': json['name'],
        'code': json['code'],
        'remittanceName': json['remittanceName'],
        'remittanceAddress': AddressModelFromJSON(json['remittanceAddress']),
        'mainPhone': json['mainPhone'],
        'customerServicePhone': json['customerServicePhone'],
        'webAddress': json['webAddress'],
        'provider': json['provider'],
        'emergencyPhone': json['emergencyPhone'],
        'priorityPhone': json['priorityPhone'],
        'customVendorAttributes': ((json['customVendorAttributes'] as Array<any>).map(ProvidersMetadataAndMappingAttributeFromJSON)),
    };
}

export function CustomerProviderVendorToJSON(value?: CustomerProviderVendor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'vendorId': value.vendorId,
        'providerId': value.providerId,
        'name': value.name,
        'code': value.code,
        'remittanceName': value.remittanceName,
        'remittanceAddress': AddressModelToJSON(value.remittanceAddress),
        'mainPhone': value.mainPhone,
        'customerServicePhone': value.customerServicePhone,
        'webAddress': value.webAddress,
        'provider': value.provider,
        'emergencyPhone': value.emergencyPhone,
        'priorityPhone': value.priorityPhone,
        'customVendorAttributes': ((value.customVendorAttributes as Array<any>).map(ProvidersMetadataAndMappingAttributeToJSON)),
    };
}


