/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ColumnStyle
 */
export interface ColumnStyle {
    /**
     * 
     * @type {string}
     * @memberof ColumnStyle
     */
    valueFormat: string;
    /**
     * 
     * @type {number}
     * @memberof ColumnStyle
     */
    width: number;
    /**
     * 
     * @type {boolean}
     * @memberof ColumnStyle
     */
    hyperlink: boolean;
}

export function ColumnStyleFromJSON(json: any): ColumnStyle {
    return ColumnStyleFromJSONTyped(json, false);
}

export function ColumnStyleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnStyle {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valueFormat': json['valueFormat'],
        'width': json['width'],
        'hyperlink': json['hyperlink'],
    };
}

export function ColumnStyleToJSON(value?: ColumnStyle | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valueFormat': value.valueFormat,
        'width': value.width,
        'hyperlink': value.hyperlink,
    };
}


