/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccountBillHistory,
    VirtualAccountBillHistoryFromJSON,
    VirtualAccountBillHistoryFromJSONTyped,
    VirtualAccountBillHistoryToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountBillHistoryList
 */
export interface VirtualAccountBillHistoryList {
    /**
     * 
     * @type {Array<VirtualAccountBillHistory>}
     * @memberof VirtualAccountBillHistoryList
     */
    results: Array<VirtualAccountBillHistory>;
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountBillHistoryList
     */
    total: number;
}

export function VirtualAccountBillHistoryListFromJSON(json: any): VirtualAccountBillHistoryList {
    return VirtualAccountBillHistoryListFromJSONTyped(json, false);
}

export function VirtualAccountBillHistoryListFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountBillHistoryList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(VirtualAccountBillHistoryFromJSON)),
        'total': json['total'],
    };
}

export function VirtualAccountBillHistoryListToJSON(value?: VirtualAccountBillHistoryList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(VirtualAccountBillHistoryToJSON)),
        'total': value.total,
    };
}


