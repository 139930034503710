/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillWorkflowStateModel
 */
export interface BillWorkflowStateModel {
    /**
     * 
     * @type {number}
     * @memberof BillWorkflowStateModel
     */
    billId: number;
    /**
     * 
     * @type {string}
     * @memberof BillWorkflowStateModel
     */
    workflowState: BillWorkflowStateModelWorkflowStateEnum;
}

/**
* @export
* @enum {string}
*/
export enum BillWorkflowStateModelWorkflowStateEnum {
    Processing = 'processing',
    Failed = 'failed',
    BillLoaded = 'bill_loaded',
    IntegrityCheck = 'integrity_check',
    DataVerification1 = 'data_verification_1',
    DataVerification2 = 'data_verification_2',
    DataAudit1 = 'data_audit_1',
    DataAudit2 = 'data_audit_2',
    Processed = 'processed',
    Paid = 'paid'
}

export function BillWorkflowStateModelFromJSON(json: any): BillWorkflowStateModel {
    return BillWorkflowStateModelFromJSONTyped(json, false);
}

export function BillWorkflowStateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillWorkflowStateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billId': json['billId'],
        'workflowState': json['workflowState'],
    };
}

export function BillWorkflowStateModelToJSON(value?: BillWorkflowStateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billId': value.billId,
        'workflowState': value.workflowState,
    };
}


