/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SftpConnection
 */
export interface SftpConnection {
    /**
     * 
     * @type {number}
     * @memberof SftpConnection
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof SftpConnection
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SftpConnection
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof SftpConnection
     */
    deletedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof SftpConnection
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    host: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    folder: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    featureType: SftpConnectionFeatureTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof SftpConnection
     */
    type: SftpConnectionTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum SftpConnectionFeatureTypeEnum {
    PaymentFiles = 'paymentFiles',
    Reports = 'reports'
}/**
* @export
* @enum {string}
*/
export enum SftpConnectionTypeEnum {
    Credentials = 'credentials',
    Ssh = 'ssh'
}

export function SftpConnectionFromJSON(json: any): SftpConnection {
    return SftpConnectionFromJSONTyped(json, false);
}

export function SftpConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SftpConnection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'deletedAt': (new Date(json['deletedAt'])),
        'customerId': json['customerId'],
        'name': json['name'],
        'host': json['host'],
        'folder': json['folder'],
        'featureType': json['featureType'],
        'username': json['username'],
        'password': json['password'],
        'type': json['type'],
    };
}

export function SftpConnectionToJSON(value?: SftpConnection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'deletedAt': (value.deletedAt.toISOString()),
        'customerId': value.customerId,
        'name': value.name,
        'host': value.host,
        'folder': value.folder,
        'featureType': value.featureType,
        'username': value.username,
        'password': value.password,
        'type': value.type,
    };
}


