import {
  TableRepository,
  TableOptions,
  TableQuery,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import {
  CustomerUnprocessedBillsControllerGetUnprocessedBillsExcludeEnum,
  CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest,
  UnprocessedBillsPreviewListDto,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import { CustomersService } from '@/Modules/Customers/services/service.customers'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { CustomersUnprocessedBillsService } from '@/Modules/Customers/services/service.customer-unprocessed-bills'

const serviceCustomers = new CustomersService()
const serviceUnprocessedBillsCustomers = new CustomersUnprocessedBillsService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({}),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: ref('Record ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('id'),
      },
      {
        prettyName: ref('FDG ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('fdgId'),
      },
      {
        prettyName: ref('Service Account Id'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('200'),
        modelKey: ref('accountCodes'),
      },
      {
        prettyName: ref('Bill batch ID'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('batchId'),
      },
      {
        prettyName: ref('Batch Type'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('batchType'),
      },
      {
        prettyName: ref('Processed Date'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('processedAt'),
      },
      {
        prettyName: ref(''),
        labelClassName: null,
        modelKey: null,
        width: null,
        type: null,
        name: ref('downloadBatchButton'),
      },
      {
        prettyName: ref(''),
        labelClassName: null,
        modelKey: null,
        width: null,
        type: null,
        name: ref('actions'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'customer.exceptions.unprocessed',
      },
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest,
  meta: any,
): CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest => {
  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.excludePaginationData) {
      filterData.exclude.push(
        CustomerUnprocessedBillsControllerGetUnprocessedBillsExcludeEnum.Total,
        CustomerUnprocessedBillsControllerGetUnprocessedBillsExcludeEnum.Extra,
      )
    }
  }

  filterData.customerId = meta.customerId

  return filterData
}

const useListRef = (): Ref<UnprocessedBillsPreviewListDto> =>
  ref({
    results: [],
    total: 0,
    filters: undefined,
    extra: undefined,
  }) as Ref<UnprocessedBillsPreviewListDto>

export default class RepositoryCustomerUnprocessedBills
  implements TableRepository<UnprocessedBillsPreviewListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  constructor(customerId: number | null = null) {
    this.options.meta.value.customerId = customerId
  }

  async retrieveCustomerName(customerId: number): Promise<string> {
    const customer = await serviceCustomers.getCustomer({
      customerId,
    })
    return customer.name
  }

  getList = async (): Promise<void> => {
    const listValue =
      await serviceUnprocessedBillsCustomers.getCustomerUnprocessedBillsPreviews(
        adaptFilterDataToApiTypesAndExclude(
          useQueryAsRequest<CustomerUnprocessedBillsControllerGetUnprocessedBillsRequest>(
            this.options.query,
          ),
          this.options.meta.value,
        ),
      )

    if (this.options.meta.value.excludePaginationData) {
      listValue.extra = this.list.value.extra
      listValue.total = this.list.value.total
      this.options.meta.value.excludePaginationData = false
    }
    this.list.value = listValue
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
