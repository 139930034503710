/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnergyStarSuccessDto
 */
export interface EnergyStarSuccessDto {
    /**
     * 
     * @type {boolean}
     * @memberof EnergyStarSuccessDto
     */
    success: boolean;
}

export function EnergyStarSuccessDtoFromJSON(json: any): EnergyStarSuccessDto {
    return EnergyStarSuccessDtoFromJSONTyped(json, false);
}

export function EnergyStarSuccessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarSuccessDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': json['success'],
    };
}

export function EnergyStarSuccessDtoToJSON(value?: EnergyStarSuccessDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
    };
}


