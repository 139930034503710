import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-88618e48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.label,
    class: _normalizeClass(_ctx.classes),
    prop: _ctx.prop,
    rules: _ctx.rules,
    size: _ctx.size,
    "label-width": _ctx.labelWidth
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_input_number, _mergeProps({
          placeholder: _ctx.placeholder,
          modelValue: _ctx.modelValue
        }, _ctx.$attrs), null, 16, ["placeholder", "modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["label", "class", "prop", "rules", "size", "label-width"]))
}