/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContractDetails
 */
export interface ContractDetails {
    /**
     * 
     * @type {number}
     * @memberof ContractDetails
     */
    customerId?: number;
    /**
     * 
     * @type {string}
     * @memberof ContractDetails
     */
    productTier: ContractDetailsProductTierEnum;
    /**
     * 
     * @type {string}
     * @memberof ContractDetails
     */
    contractSigned: ContractDetailsContractSignedEnum;
    /**
     * 
     * @type {Date}
     * @memberof ContractDetails
     */
    contractStartDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContractDetails
     */
    contractEndDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContractDetails
     */
    partner?: ContractDetailsPartnerEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDetails
     */
    isPartnerBilling?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractDetails
     */
    constellationContract?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ContractDetailsProductTierEnum {
    Standard = 'Standard',
    Gold = 'Gold',
    Platinum = 'Platinum',
    ReportingAnalytics = 'Reporting & Analytics'
}/**
* @export
* @enum {string}
*/
export enum ContractDetailsContractSignedEnum {
    ExelonGeneration = 'Exelon Generation',
    ConstellationStandalone = 'Constellation - Standalone',
    ConstellationEmbedded = 'Constellation - Embedded',
    CnegStandalone = 'CNEG - Standalone',
    CnegEmbedded = 'CNEG - Embedded',
    ConstellationEmbeddedLite = 'Constellation - Embedded (Lite)',
    Esco = 'ESCO'
}/**
* @export
* @enum {string}
*/
export enum ContractDetailsPartnerEnum {
    Constellation = 'Constellation',
    Correlate = 'Correlate',
    PowerOptions = 'Power Options',
    WorldConnect = 'World Connect'
}

export function ContractDetailsFromJSON(json: any): ContractDetails {
    return ContractDetailsFromJSONTyped(json, false);
}

export function ContractDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerId': !exists(json, 'customerId') ? undefined : json['customerId'],
        'productTier': json['productTier'],
        'contractSigned': json['contractSigned'],
        'contractStartDate': !exists(json, 'contractStartDate') ? undefined : (json['contractStartDate'] === null ? null : new Date(json['contractStartDate'])),
        'contractEndDate': !exists(json, 'contractEndDate') ? undefined : (json['contractEndDate'] === null ? null : new Date(json['contractEndDate'])),
        'partner': !exists(json, 'partner') ? undefined : json['partner'],
        'isPartnerBilling': !exists(json, 'isPartnerBilling') ? undefined : json['isPartnerBilling'],
        'constellationContract': !exists(json, 'constellationContract') ? undefined : json['constellationContract'],
    };
}

export function ContractDetailsToJSON(value?: ContractDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customerId': value.customerId,
        'productTier': value.productTier,
        'contractSigned': value.contractSigned,
        'contractStartDate': value.contractStartDate === undefined ? undefined : (value.contractStartDate === null ? null : value.contractStartDate.toISOString().substr(0,10)),
        'contractEndDate': value.contractEndDate === undefined ? undefined : (value.contractEndDate === null ? null : value.contractEndDate.toISOString().substr(0,10)),
        'partner': value.partner,
        'isPartnerBilling': value.isPartnerBilling,
        'constellationContract': value.constellationContract,
    };
}


