/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailDeliveryStatusPayloadFeatureMetadata,
    EmailDeliveryStatusPayloadFeatureMetadataFromJSON,
    EmailDeliveryStatusPayloadFeatureMetadataFromJSONTyped,
    EmailDeliveryStatusPayloadFeatureMetadataToJSON,
    SendgridEvent,
    SendgridEventFromJSON,
    SendgridEventFromJSONTyped,
    SendgridEventToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmailDeliveryStatusPayload
 */
export interface EmailDeliveryStatusPayload {
    /**
     * 
     * @type {Array<SendgridEvent>}
     * @memberof EmailDeliveryStatusPayload
     */
    sendgridEvents?: Array<SendgridEvent>;
    /**
     * 
     * @type {EmailDeliveryStatusPayloadFeatureMetadata}
     * @memberof EmailDeliveryStatusPayload
     */
    featureMetadata?: EmailDeliveryStatusPayloadFeatureMetadata;
}

export function EmailDeliveryStatusPayloadFromJSON(json: any): EmailDeliveryStatusPayload {
    return EmailDeliveryStatusPayloadFromJSONTyped(json, false);
}

export function EmailDeliveryStatusPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDeliveryStatusPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sendgridEvents': !exists(json, 'sendgridEvents') ? undefined : ((json['sendgridEvents'] as Array<any>).map(SendgridEventFromJSON)),
        'featureMetadata': !exists(json, 'featureMetadata') ? undefined : EmailDeliveryStatusPayloadFeatureMetadataFromJSON(json['featureMetadata']),
    };
}

export function EmailDeliveryStatusPayloadToJSON(value?: EmailDeliveryStatusPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sendgridEvents': value.sendgridEvents === undefined ? undefined : ((value.sendgridEvents as Array<any>).map(SendgridEventToJSON)),
        'featureMetadata': EmailDeliveryStatusPayloadFeatureMetadataToJSON(value.featureMetadata),
    };
}


