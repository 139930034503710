/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Vendor
 */
export interface Vendor {
    /**
     * 
     * @type {number}
     * @memberof Vendor
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    prettyName: string;
    /**
     * 
     * @type {string}
     * @memberof Vendor
     */
    customPrettyName: string;
}

export function VendorFromJSON(json: any): Vendor {
    return VendorFromJSONTyped(json, false);
}

export function VendorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Vendor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'prettyName': json['prettyName'],
        'customPrettyName': json['customPrettyName'],
    };
}

export function VendorToJSON(value?: Vendor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'prettyName': value.prettyName,
        'customPrettyName': value.customPrettyName,
    };
}


