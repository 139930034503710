/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentEvent,
    PaymentEventFromJSON,
    PaymentEventFromJSONTyped,
    PaymentEventToJSON,
    PaymentEventsFiltersMeta,
    PaymentEventsFiltersMetaFromJSON,
    PaymentEventsFiltersMetaFromJSONTyped,
    PaymentEventsFiltersMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaymentEventsListDto
 */
export interface PaymentEventsListDto {
    /**
     * 
     * @type {Array<PaymentEvent>}
     * @memberof PaymentEventsListDto
     */
    results: Array<PaymentEvent>;
    /**
     * 
     * @type {number}
     * @memberof PaymentEventsListDto
     */
    total?: number;
    /**
     * 
     * @type {PaymentEventsFiltersMeta}
     * @memberof PaymentEventsListDto
     */
    filters?: PaymentEventsFiltersMeta;
    /**
     * 
     * @type {object}
     * @memberof PaymentEventsListDto
     */
    extra?: object;
}

export function PaymentEventsListDtoFromJSON(json: any): PaymentEventsListDto {
    return PaymentEventsListDtoFromJSONTyped(json, false);
}

export function PaymentEventsListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentEventsListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PaymentEventFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : PaymentEventsFiltersMetaFromJSON(json['filters']),
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function PaymentEventsListDtoToJSON(value?: PaymentEventsListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PaymentEventToJSON)),
        'total': value.total,
        'filters': PaymentEventsFiltersMetaToJSON(value.filters),
        'extra': value.extra,
    };
}


