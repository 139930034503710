/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VendorCodesFiltersDto
 */
export interface VendorCodesFiltersDto {
    /**
     * 
     * @type {string}
     * @memberof VendorCodesFiltersDto
     */
    prettyName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VendorCodesFiltersDto
     */
    codes: Array<string>;
}

export function VendorCodesFiltersDtoFromJSON(json: any): VendorCodesFiltersDto {
    return VendorCodesFiltersDtoFromJSONTyped(json, false);
}

export function VendorCodesFiltersDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VendorCodesFiltersDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'prettyName': json['prettyName'],
        'codes': json['codes'],
    };
}

export function VendorCodesFiltersDtoToJSON(value?: VendorCodesFiltersDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'prettyName': value.prettyName,
        'codes': value.codes,
    };
}


