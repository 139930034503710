import {
  TableRepository,
  TableOptions,
  TableQuery,
} from '@/SharedModule/Components/Tables/interfaces/repository.table'
import {
  BillPreview,
  BillsControllerGetBillsPreviewsBillTypesEnum,
  BillsControllerGetBillsPreviewsExcludeEnum,
  BillsControllerGetBillsPreviewsRequest,
  BillsControllerGetBillsPreviewsWorkflowStatesEnum,
  BillsPreviewListDto,
  Customer,
  CustomersControllerGetListExcludeEnum,
} from '@/SharedModule/Api'
import { ref, Ref } from 'vue'
import {
  useQueryAsRequest,
  useTablePaginationMeta,
} from '@/SharedModule/Components/Tables/composables/composables.repository.table'
import { BillsService } from '@/Modules/Bills/service/service.bills'
import { CustomersService } from '@/Modules/Customers/services/service.customers'

const serviceBills = new BillsService()
const serviceCustomers = new CustomersService()

const useQuery = (): TableQuery => {
  return {
    filters: ref({
      searchQuery: null,
      customerIds: null,
      userIds: null,
      maxDueDate: null,
      maxTargetDate: null,
      errorTypes: null,
      billTypes: null,
      vendorCodes: null,
      workflowStates: null,
      customerActive: null,
      noPayDate: null,
    }),
    sort: ref({
      limit: 20,
      offset: 0,
    }),
    sortBy: ref({
      priority: null,
      prettyName: null,
      statementDate: null,
      targetDate: null,
      billType: null,
    }),
  }
}

const useOptions = (): TableOptions => {
  return {
    columns: [
      {
        prettyName: null,
        labelClassName: null,
        modelKey: null,
        type: ref('rowSelection'),
        width: ref('30'),
        name: ref('select'),
      },
      {
        prettyName: ref('!'),
        labelClassName: ref('text-align-center'),
        name: null,
        type: null,
        width: ref('34'),
        modelKey: ref('priority'),
      },
      {
        prettyName: ref('Bill ID'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('80'),
        modelKey: ref('id'),
      },
      {
        prettyName: ref('Customer'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('customerId'),
      },
      {
        prettyName: ref('Vendor'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('prettyName'),
      },
      {
        prettyName: ref('Invoice Date'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('statementDate'),
      },
      {
        prettyName: ref('Amount Due'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('totalBillCharges'),
      },
      {
        prettyName: ref('Service Account Id'),
        labelClassName: null,
        name: null,
        type: null,
        width: ref('200'),
        modelKey: ref('accountCodes'),
      },
      {
        prettyName: ref('Status'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('workflowState'),
      },
      {
        prettyName: ref('Target Date'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('targetDate'),
      },
      {
        prettyName: ref('User'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('userName'),
      },
      {
        prettyName: ref('Bill Type'),
        labelClassName: null,
        name: null,
        width: null,
        type: null,
        modelKey: ref('billType'),
      },
      {
        prettyName: ref('More'),
        labelClassName: null,
        modelKey: null,
        type: null,
        width: ref('70'),
        name: ref('actions'),
      },
    ],
    query: useQuery(),
    tablePaginationMeta: useTablePaginationMeta(),
    meta: ref({
      route: {
        name: 'bills.find',
      },
      apiFilters: null,
      multiSelectTimeout: 700,
      inputTimeout: 500,
      sortByTimeout: 500,
      customerNames: {},
      userNames: {},
    }),
  }
}

const adaptFilterDataToApiTypesAndExclude = (
  filterData: BillsControllerGetBillsPreviewsRequest,
  sortBy: any,
  meta: any,
): BillsControllerGetBillsPreviewsRequest => {
  if (sortBy) {
    filterData.orderBy = filterData.orderBy || []
    Object.keys(sortBy).forEach((currentSortKey: string) => {
      const destructuredFilterData: any = filterData
      if (sortBy[currentSortKey] != null) {
        filterData.orderBy?.push(
          currentSortKey + (sortBy[currentSortKey] ? '.asc' : '.desc'),
        )
      }
      delete destructuredFilterData[currentSortKey]
    })

    if (!filterData.orderBy?.length) {
      delete filterData.orderBy
    }
  }

  if (filterData.maxDueDate) {
    filterData.maxDueDate = new Date(filterData.maxDueDate)
  }
  if (filterData.maxTargetDate) {
    filterData.maxTargetDate = new Date(filterData.maxTargetDate)
  }
  if (filterData.vendorCodes) {
    filterData.vendorCodes = filterData.vendorCodes.toString().split(',')
  }
  if (filterData.customerIds) {
    filterData.customerIds = filterData.customerIds
      .toString()
      .split(',')
      .map((currentId) => +currentId)
  }
  if (filterData.errorTypes) {
    filterData.errorTypes = filterData.errorTypes.toString().split(',')
  }
  if (filterData.workflowStates && filterData.workflowStates.length) {
    filterData.workflowStates = filterData.workflowStates
      .toString()
      .split(',') as BillsControllerGetBillsPreviewsWorkflowStatesEnum[]
  } else {
    delete filterData.workflowStates
  }
  if (filterData.billTypes) {
    filterData.billTypes = filterData.billTypes
      .toString()
      .split(',') as BillsControllerGetBillsPreviewsBillTypesEnum[]
  }

  if (meta.apiFilters || meta.excludePaginationData) {
    filterData.exclude = []

    if (meta.apiFilters) {
      filterData.exclude.push(
        BillsControllerGetBillsPreviewsExcludeEnum.Filters,
      )
    }
    if (meta.excludePaginationData) {
      filterData.exclude.push(
        BillsControllerGetBillsPreviewsExcludeEnum.Total,
        BillsControllerGetBillsPreviewsExcludeEnum.Extra,
      )
    }
  }

  return filterData
}

const useListRef = (): Ref<BillsPreviewListDto> =>
  ref({
    results: [],
    total: 0,
    billsInProcess: [],
    filters: undefined,
    extra: undefined,
  }) as Ref<BillsPreviewListDto>

export default class RepositoryBills
  implements TableRepository<BillsPreviewListDto>
{
  busyLoading = ref(false)
  options = useOptions()
  list = useListRef()

  private async retrieveCustomerNames(bills: BillPreview[]): Promise<void> {
    const customerIds = bills
      .filter((bill: BillPreview) => bill.customerId)
      .map((bill) => bill.customerId)

    if (!customerIds.length) {
      return
    }

    const customers = await serviceCustomers.getCustomers({
      exclude: [
        CustomersControllerGetListExcludeEnum.Extra,
        CustomersControllerGetListExcludeEnum.Filters,
        CustomersControllerGetListExcludeEnum.Total,
      ],
      ids: customerIds,
    })

    this.options.meta.value.customerNames = []

    customers.results.forEach((currentCustomer: Customer): void => {
      this.options.meta.value.customerNames[currentCustomer.id] =
        currentCustomer.name
    })
  }

  getList = async (): Promise<void> => {
    const listValue = await serviceBills.getBillsPreviews(
      adaptFilterDataToApiTypesAndExclude(
        useQueryAsRequest<BillsControllerGetBillsPreviewsRequest>(
          this.options.query,
        ),
        this.options.query.sortBy?.value,
        this.options.meta.value,
      ),
    )
    // we need to do this to retrieve customer names using customerIds
    await this.retrieveCustomerNames(listValue.results)

    if (this.options.meta.value.excludePaginationData) {
      listValue.extra = this.list.value.extra
      listValue.total = this.list.value.total
      this.options.meta.value.excludePaginationData = false
    }
    if (this.options.meta.value.apiFilters === null) {
      this.options.meta.value.apiFilters = listValue.filters

      this.options.meta.value.apiFilters.users.push({
        id: -1,
        name: 'Unassigned',
      })
    }
    this.list.value = listValue
  }

  setFilter = async (key: string, value: any): Promise<void> => {
    this.options.query.filters.value[key] = value
  }

  resetQuery = async (): Promise<void> => {
    const newQuery = useQuery()

    Object.assign(this.options.query.filters.value, newQuery.filters.value)
    Object.assign(this.options.query.sort.value, newQuery.sort.value)
  }
}
