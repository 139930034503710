/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomValueMap,
    CustomValueMapFromJSON,
    CustomValueMapFromJSONTyped,
    CustomValueMapToJSON,
} from './';

/**
 * 
 * @export
 * @interface VirtualAccountBillHistory
 */
export interface VirtualAccountBillHistory {
    /**
     * 
     * @type {number}
     * @memberof VirtualAccountBillHistory
     */
    billMetaId: number;
    /**
     * 
     * @type {CustomValueMap}
     * @memberof VirtualAccountBillHistory
     */
    maxDemand: CustomValueMap;
    /**
     * 
     * @type {Date}
     * @memberof VirtualAccountBillHistory
     */
    statementDate: Date;
    /**
     * 
     * @type {CustomValueMap}
     * @memberof VirtualAccountBillHistory
     */
    totalCharges: CustomValueMap;
    /**
     * 
     * @type {CustomValueMap}
     * @memberof VirtualAccountBillHistory
     */
    genConsumption: CustomValueMap;
    /**
     * 
     * @type {CustomValueMap}
     * @memberof VirtualAccountBillHistory
     */
    utilityGenConsumption: CustomValueMap;
    /**
     * 
     * @type {CustomValueMap}
     * @memberof VirtualAccountBillHistory
     */
    totalConsumption: CustomValueMap;
}

export function VirtualAccountBillHistoryFromJSON(json: any): VirtualAccountBillHistory {
    return VirtualAccountBillHistoryFromJSONTyped(json, false);
}

export function VirtualAccountBillHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): VirtualAccountBillHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billMetaId': json['billMetaId'],
        'maxDemand': CustomValueMapFromJSON(json['maxDemand']),
        'statementDate': (new Date(json['statementDate'])),
        'totalCharges': CustomValueMapFromJSON(json['totalCharges']),
        'genConsumption': CustomValueMapFromJSON(json['genConsumption']),
        'utilityGenConsumption': CustomValueMapFromJSON(json['utilityGenConsumption']),
        'totalConsumption': CustomValueMapFromJSON(json['totalConsumption']),
    };
}

export function VirtualAccountBillHistoryToJSON(value?: VirtualAccountBillHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billMetaId': value.billMetaId,
        'maxDemand': CustomValueMapToJSON(value.maxDemand),
        'statementDate': (value.statementDate.toISOString()),
        'totalCharges': CustomValueMapToJSON(value.totalCharges),
        'genConsumption': CustomValueMapToJSON(value.genConsumption),
        'utilityGenConsumption': CustomValueMapToJSON(value.utilityGenConsumption),
        'totalConsumption': CustomValueMapToJSON(value.totalConsumption),
    };
}


