/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VirtualAccountsOperatorNotes,
    VirtualAccountsOperatorNotesFromJSON,
    VirtualAccountsOperatorNotesFromJSONTyped,
    VirtualAccountsOperatorNotesToJSON,
} from './';

/**
 * 
 * @export
 * @interface EnergyStarVirtualAccount
 */
export interface EnergyStarVirtualAccount {
    /**
     * 
     * @type {number}
     * @memberof EnergyStarVirtualAccount
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarVirtualAccount
     */
    customerId: number;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarVirtualAccount
     */
    accountCode: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarVirtualAccount
     */
    meterSerial: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarVirtualAccount
     */
    commodity: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarVirtualAccount
     */
    billType: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarVirtualAccount
     */
    clientAccount: string;
    /**
     * 
     * @type {string}
     * @memberof EnergyStarVirtualAccount
     */
    vendorCode: string;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    accountOpened: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    accountClosed: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    lastBillStatementDate: Date;
    /**
     * 
     * @type {VirtualAccountsOperatorNotes}
     * @memberof EnergyStarVirtualAccount
     */
    operatorNotes?: VirtualAccountsOperatorNotes;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    frozenAt: Date;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarVirtualAccount
     */
    frozenReasoningId: number;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    accountPausedFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    accountPausedTo: Date;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarVirtualAccount
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarVirtualAccount
     */
    energyStarMeterId: number;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    lastBill: Date;
    /**
     * 
     * @type {number}
     * @memberof EnergyStarVirtualAccount
     */
    lastBillId: number;
    /**
     * 
     * @type {Date}
     * @memberof EnergyStarVirtualAccount
     */
    firstStartDate: Date;
}

export function EnergyStarVirtualAccountFromJSON(json: any): EnergyStarVirtualAccount {
    return EnergyStarVirtualAccountFromJSONTyped(json, false);
}

export function EnergyStarVirtualAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnergyStarVirtualAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'accountCode': json['accountCode'],
        'meterSerial': json['meterSerial'],
        'commodity': json['commodity'],
        'billType': json['billType'],
        'clientAccount': json['clientAccount'],
        'vendorCode': json['vendorCode'],
        'createdAt': (new Date(json['createdAt'])),
        'accountOpened': (new Date(json['accountOpened'])),
        'accountClosed': (new Date(json['accountClosed'])),
        'lastBillStatementDate': (new Date(json['lastBillStatementDate'])),
        'operatorNotes': !exists(json, 'operatorNotes') ? undefined : VirtualAccountsOperatorNotesFromJSON(json['operatorNotes']),
        'frozenAt': (new Date(json['frozenAt'])),
        'frozenReasoningId': json['frozenReasoningId'],
        'accountPausedFrom': (new Date(json['accountPausedFrom'])),
        'accountPausedTo': (new Date(json['accountPausedTo'])),
        'locationId': json['locationId'],
        'energyStarMeterId': json['energyStarMeterId'],
        'lastBill': (new Date(json['lastBill'])),
        'lastBillId': json['lastBillId'],
        'firstStartDate': (new Date(json['firstStartDate'])),
    };
}

export function EnergyStarVirtualAccountToJSON(value?: EnergyStarVirtualAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'accountCode': value.accountCode,
        'meterSerial': value.meterSerial,
        'commodity': value.commodity,
        'billType': value.billType,
        'clientAccount': value.clientAccount,
        'vendorCode': value.vendorCode,
        'createdAt': (value.createdAt.toISOString()),
        'accountOpened': (value.accountOpened.toISOString()),
        'accountClosed': (value.accountClosed.toISOString()),
        'lastBillStatementDate': (value.lastBillStatementDate.toISOString()),
        'operatorNotes': VirtualAccountsOperatorNotesToJSON(value.operatorNotes),
        'frozenAt': (value.frozenAt.toISOString()),
        'frozenReasoningId': value.frozenReasoningId,
        'accountPausedFrom': (value.accountPausedFrom.toISOString()),
        'accountPausedTo': (value.accountPausedTo.toISOString()),
        'locationId': value.locationId,
        'energyStarMeterId': value.energyStarMeterId,
        'lastBill': (value.lastBill.toISOString()),
        'lastBillId': value.lastBillId,
        'firstStartDate': (value.firstStartDate.toISOString()),
    };
}


