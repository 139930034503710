/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentEmailDelivery,
    PaymentEmailDeliveryFromJSON,
    PaymentEmailDeliveryFromJSONTyped,
    PaymentEmailDeliveryToJSON,
} from './';

/**
 * 
 * @export
 * @interface EmailDeliveryListDto
 */
export interface EmailDeliveryListDto {
    /**
     * 
     * @type {Array<PaymentEmailDelivery>}
     * @memberof EmailDeliveryListDto
     */
    results: Array<PaymentEmailDelivery>;
    /**
     * 
     * @type {number}
     * @memberof EmailDeliveryListDto
     */
    total?: number;
    /**
     * 
     * @type {object}
     * @memberof EmailDeliveryListDto
     */
    filters?: object;
    /**
     * 
     * @type {object}
     * @memberof EmailDeliveryListDto
     */
    extra?: object;
}

export function EmailDeliveryListDtoFromJSON(json: any): EmailDeliveryListDto {
    return EmailDeliveryListDtoFromJSONTyped(json, false);
}

export function EmailDeliveryListDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDeliveryListDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'results': ((json['results'] as Array<any>).map(PaymentEmailDeliveryFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
        'filters': !exists(json, 'filters') ? undefined : json['filters'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
    };
}

export function EmailDeliveryListDtoToJSON(value?: EmailDeliveryListDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'results': ((value.results as Array<any>).map(PaymentEmailDeliveryToJSON)),
        'total': value.total,
        'filters': value.filters,
        'extra': value.extra,
    };
}


