/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeInvoiceDateDto
 */
export interface ChangeInvoiceDateDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ChangeInvoiceDateDto
     */
    billsIds: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof ChangeInvoiceDateDto
     */
    invoiceDate: Date;
}

export function ChangeInvoiceDateDtoFromJSON(json: any): ChangeInvoiceDateDto {
    return ChangeInvoiceDateDtoFromJSONTyped(json, false);
}

export function ChangeInvoiceDateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeInvoiceDateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billsIds': json['billsIds'],
        'invoiceDate': (new Date(json['invoiceDate'])),
    };
}

export function ChangeInvoiceDateDtoToJSON(value?: ChangeInvoiceDateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billsIds': value.billsIds,
        'invoiceDate': (value.invoiceDate.toISOString().substr(0,10)),
    };
}


