/* tslint:disable */
/* eslint-disable */
/**
 * Pear API
 * The Pear API endpoint
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressModel,
    AddressModelFromJSON,
    AddressModelFromJSONTyped,
    AddressModelToJSON,
    BillErrorModel,
    BillErrorModelFromJSON,
    BillErrorModelFromJSONTyped,
    BillErrorModelToJSON,
    BillItem,
    BillItemFromJSON,
    BillItemFromJSONTyped,
    BillItemToJSON,
    BillPayloadModel,
    BillPayloadModelFromJSON,
    BillPayloadModelFromJSONTyped,
    BillPayloadModelToJSON,
    BillingLineItem,
    BillingLineItemFromJSON,
    BillingLineItemFromJSONTyped,
    BillingLineItemToJSON,
    CSIDetails,
    CSIDetailsFromJSON,
    CSIDetailsFromJSONTyped,
    CSIDetailsToJSON,
    CalculationModel,
    CalculationModelFromJSON,
    CalculationModelFromJSONTyped,
    CalculationModelToJSON,
    FileModel,
    FileModelFromJSON,
    FileModelFromJSONTyped,
    FileModelToJSON,
    Navigation,
    NavigationFromJSON,
    NavigationFromJSONTyped,
    NavigationToJSON,
    ResolvedError,
    ResolvedErrorFromJSON,
    ResolvedErrorFromJSONTyped,
    ResolvedErrorToJSON,
    TargetDate,
    TargetDateFromJSON,
    TargetDateFromJSONTyped,
    TargetDateToJSON,
    Versions,
    VersionsFromJSON,
    VersionsFromJSONTyped,
    VersionsToJSON,
} from './';

/**
 * 
 * @export
 * @interface Bill
 */
export interface Bill {
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    customerId: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    priorBillId: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    batchId: number;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    priority: number;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    clientAccount: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    remittanceName: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    billType: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    workflowType: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    workflowState: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    invoiceNumber: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    vendorCode: string;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    vendorMainPhone: string;
    /**
     * 
     * @type {boolean}
     * @memberof Bill
     */
    isParked: boolean;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    createdAt: string;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    updatedAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    startDate: string;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    adjustedEndDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    customerReady: Date;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    markedForPayment: Date;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    dueDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Bill
     */
    statementDate: string;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    paymentLogUpdated: Date;
    /**
     * 
     * @type {TargetDate}
     * @memberof Bill
     */
    targetDate: TargetDate;
    /**
     * 
     * @type {Date}
     * @memberof Bill
     */
    expectedNextBillDate: Date;
    /**
     * 
     * @type {AddressModel}
     * @memberof Bill
     */
    billToAddress: AddressModel;
    /**
     * 
     * @type {AddressModel}
     * @memberof Bill
     */
    remittanceAddress: AddressModel;
    /**
     * 
     * @type {Navigation}
     * @memberof Bill
     */
    navigation: Navigation;
    /**
     * 
     * @type {Versions}
     * @memberof Bill
     */
    versions: Versions;
    /**
     * 
     * @type {Array<number>}
     * @memberof Bill
     */
    nextBillIds: Array<number>;
    /**
     * 
     * @type {Array<BillItem>}
     * @memberof Bill
     */
    items: Array<BillItem>;
    /**
     * 
     * @type {Array<CalculationModel>}
     * @memberof Bill
     */
    calculations?: Array<CalculationModel>;
    /**
     * 
     * @type {Array<BillingLineItem>}
     * @memberof Bill
     */
    billingLineItems: Array<BillingLineItem>;
    /**
     * 
     * @type {Array<ResolvedError>}
     * @memberof Bill
     */
    resolvedErrors: Array<ResolvedError>;
    /**
     * 
     * @type {Array<BillErrorModel>}
     * @memberof Bill
     */
    errors: Array<BillErrorModel>;
    /**
     * 
     * @type {Array<FileModel>}
     * @memberof Bill
     */
    files: Array<FileModel>;
    /**
     * 
     * @type {BillPayloadModel}
     * @memberof Bill
     */
    payload?: BillPayloadModel;
    /**
     * 
     * @type {number}
     * @memberof Bill
     */
    counter: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Bill
     */
    locationIds: Array<string>;
    /**
     * 
     * @type {CSIDetails}
     * @memberof Bill
     */
    csiDetails: CSIDetails;
}

export function BillFromJSON(json: any): Bill {
    return BillFromJSONTyped(json, false);
}

export function BillFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'customerId': json['customerId'],
        'priorBillId': json['priorBillId'],
        'batchId': json['batchId'],
        'priority': json['priority'],
        'clientAccount': json['clientAccount'],
        'remittanceName': json['remittanceName'],
        'billType': json['billType'],
        'workflowType': json['workflowType'],
        'workflowState': json['workflowState'],
        'invoiceNumber': json['invoiceNumber'],
        'vendorCode': json['vendorCode'],
        'vendorMainPhone': json['vendorMainPhone'],
        'isParked': json['isParked'],
        'createdAt': json['createdAt'],
        'updatedAt': (new Date(json['updatedAt'])),
        'startDate': json['startDate'],
        'adjustedEndDate': (new Date(json['adjustedEndDate'])),
        'customerReady': (new Date(json['customerReady'])),
        'markedForPayment': (new Date(json['markedForPayment'])),
        'dueDate': (new Date(json['dueDate'])),
        'statementDate': json['statementDate'],
        'paymentLogUpdated': (new Date(json['paymentLogUpdated'])),
        'targetDate': TargetDateFromJSON(json['targetDate']),
        'expectedNextBillDate': (new Date(json['expectedNextBillDate'])),
        'billToAddress': AddressModelFromJSON(json['billToAddress']),
        'remittanceAddress': AddressModelFromJSON(json['remittanceAddress']),
        'navigation': NavigationFromJSON(json['navigation']),
        'versions': VersionsFromJSON(json['versions']),
        'nextBillIds': json['nextBillIds'],
        'items': ((json['items'] as Array<any>).map(BillItemFromJSON)),
        'calculations': !exists(json, 'calculations') ? undefined : ((json['calculations'] as Array<any>).map(CalculationModelFromJSON)),
        'billingLineItems': ((json['billingLineItems'] as Array<any>).map(BillingLineItemFromJSON)),
        'resolvedErrors': ((json['resolvedErrors'] as Array<any>).map(ResolvedErrorFromJSON)),
        'errors': ((json['errors'] as Array<any>).map(BillErrorModelFromJSON)),
        'files': ((json['files'] as Array<any>).map(FileModelFromJSON)),
        'payload': !exists(json, 'payload') ? undefined : BillPayloadModelFromJSON(json['payload']),
        'counter': json['counter'],
        'locationIds': json['locationIds'],
        'csiDetails': CSIDetailsFromJSON(json['csiDetails']),
    };
}

export function BillToJSON(value?: Bill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'customerId': value.customerId,
        'priorBillId': value.priorBillId,
        'batchId': value.batchId,
        'priority': value.priority,
        'clientAccount': value.clientAccount,
        'remittanceName': value.remittanceName,
        'billType': value.billType,
        'workflowType': value.workflowType,
        'workflowState': value.workflowState,
        'invoiceNumber': value.invoiceNumber,
        'vendorCode': value.vendorCode,
        'vendorMainPhone': value.vendorMainPhone,
        'isParked': value.isParked,
        'createdAt': value.createdAt,
        'updatedAt': (value.updatedAt.toISOString()),
        'startDate': value.startDate,
        'adjustedEndDate': (value.adjustedEndDate.toISOString()),
        'customerReady': (value.customerReady.toISOString()),
        'markedForPayment': (value.markedForPayment.toISOString()),
        'dueDate': (value.dueDate.toISOString()),
        'statementDate': value.statementDate,
        'paymentLogUpdated': (value.paymentLogUpdated.toISOString()),
        'targetDate': TargetDateToJSON(value.targetDate),
        'expectedNextBillDate': (value.expectedNextBillDate.toISOString()),
        'billToAddress': AddressModelToJSON(value.billToAddress),
        'remittanceAddress': AddressModelToJSON(value.remittanceAddress),
        'navigation': NavigationToJSON(value.navigation),
        'versions': VersionsToJSON(value.versions),
        'nextBillIds': value.nextBillIds,
        'items': ((value.items as Array<any>).map(BillItemToJSON)),
        'calculations': value.calculations === undefined ? undefined : ((value.calculations as Array<any>).map(CalculationModelToJSON)),
        'billingLineItems': ((value.billingLineItems as Array<any>).map(BillingLineItemToJSON)),
        'resolvedErrors': ((value.resolvedErrors as Array<any>).map(ResolvedErrorToJSON)),
        'errors': ((value.errors as Array<any>).map(BillErrorModelToJSON)),
        'files': ((value.files as Array<any>).map(FileModelToJSON)),
        'payload': BillPayloadModelToJSON(value.payload),
        'counter': value.counter,
        'locationIds': value.locationIds,
        'csiDetails': CSIDetailsToJSON(value.csiDetails),
    };
}


